import React from 'react';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';

function Success() {


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>

        <MiddleCompoent Text1='결제에 성공했어요' Text2='이제 조커모드를 즐길 수 있어요' />

        <PrimaryButton Text="무제한으로 조커카드 사용해보기"  />
      </div>
    </div>
  );
}

export default Success;
