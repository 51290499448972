import React from 'react';
import { useNavigate } from 'react-router-dom';
import HoxyWordIcon from '../component/Hoxyworldicon';
import { ReactComponent as LeftArrow } from "../NewComponent/assets/icon-arrow-left-small-mono.svg";

export default function Header({ Text, Link, Icon,Text2 }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    // Link 변수가 존재하면 해당 경로로 이동
    if (Link) {
      navigate(Link);
    } else {
      // Link 변수가 없으면 이전 페이지로 이동
      window.history.back();
    }
  };

  return (
    <>
      <div className='header'>
        <a onClick={handleBackClick}>
          <div className="top_bar" style={{marginLeft:'20px'}}>
              <LeftArrow />
          </div>
        </a>
        {Icon === 'yes' && (
          <div className="hoxyworld_icon">
            <HoxyWordIcon />
          </div>
        )}
      </div>

      <br />

      {/* Text 값이 비어있지 않은 경우에만 렌더링 */}
      {Text && (
        <>
          <div style={{ display: 'flex', textAlign: 'left' }}>
            <div style={{ marginTop: '40px', marginLeft: '25px', display: 'flex',flexDirection:'column' }}>
              <h2 className='title' initial="hidden" animate="visible">{Text}</h2>
              <h2 className='title' initial="hidden" animate="visible" >{Text2}</h2>
            </div>
          </div>
        </>
      )}
    </>
  );
}
