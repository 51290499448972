import hint_img from '../../assets/img/hint.webp';
import React, { useState, useEffect } from 'react';
import { Box } from '../../stories/Box';
import jwt_decode from 'jsonwebtoken/decode';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CastingReason from '../../NewComponent/CastingReason';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import Header from '../../NewComponent/header';
import axios from "axios";

function AnswerP() {
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const requestDbNumber = new URLSearchParams(location.search).get('Mymessage');
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');
  const [Answer, setAnswer] = useState('');

  const handleInputChange4 = (text) => {
    setAnswer(text);
  };

  // 프론트엔드에서 서버로 데이터 전송
const handleSubmitAnswer = async () => {
  try {
    if (!Answer.trim()) {
      alert('답장 내용을 입력하세요.');
      return;
    }

    const response = await axios.post(
      'https://backend-hoxy-one-api.hoxy.one:4004/api/reply',
      {
        request_db_number: requestDbNumber,
        recive_reply: Answer,
        recive_user_id: tokenUserID,
        recive_user_no: tokenUserNo,
      }
    );

    if (response.status === 200) {
      alert('답장이 전송되었습니다.');
      navigate(`/${tokenUserID}?explore=yes`);
    } else {
      alert('답장 전송에 실패했습니다.');
    }
  } catch (error) {
    console.error('An error occurred while submitting the answer:', error);
  }
};


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!requestDbNumber) {
      window.location.href = '/';
      alert('정상적이지 않는 접근이에요');
      return;
    }

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;

            
        fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/search`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ request_db_number: requestDbNumber }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error('힌트를 불러오는데 실패했습니다.');
                }
                return response.json();
              })
              .then((data) => {
                // 서버 응답이 온 후에 tokenUserID 설정
                setTokenUserID(tokenUserID);
                setTokenUserNo(tokenUserNo);


                if (tokenUserID === data[0].recive_user_id) {
                  setMessages(data);
                  console.log("스크롤 초기화 완료 - 데이터 기준");
                  window.scrollTo(0, 0);
                } else {
                  alert('본인의 메시지만 힌트를 확인할 수 있어요!');
                  navigate(`/`);
                }
        

    

          })
        
    
      } catch (error) {
        console.error(error);
        navigate(`/`);
        alert('정상적이지 않는 접근이에요');
      }
    } else {
      alert('답장하려면, 로그인이 필요해요!');
      navigate(`start`);
    }
    
  
    
  }, []);






  

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
       
        <br></br>

        <Header Text='답장하기' />

<div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
      {messages.length === 0 ? (
        <div></div>
      ) : (
        messages.map((message, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>

      
            <Box
              className="LargeBox"
              Label1={`${message.request_age}세 ${message.request_gender}`}
              Label2={
                <>
                  나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인 {message.request_age}세 {message.request_gender}의 {message.request_keyword ? message.request_keyword : '***'} 분께서{' '}
                  {message.request_want ? message.request_want : '***'} 해요!
                </>
              }
              ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.png`}
              Label4="#ffffff"
            /> 

                                     

          </div>
          ))
      )}  
    </div>

              <hr style={{width:'320px', border:'solid 1px #EAEAEA', marginTop:'10px'}}></hr>
              
              
            {/*  <div style={{marginTop:'20px'}}>
                <div>
                  <button
                    className='select_relationship'
                   
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>좋아요 🍀</div>
                    </div>
                  </button>

                  <button
                    className='select_relationship'
                   
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>괜찮아요 🙏</div>
                    </div>
                  </button>
                </div>                  
                
                <div>
                  <button
                    className='select_relationship'
                   
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>누군지 알겠어요 👀</div>
                    </div>
                  </button>
                  
                  <button
                    className='select_relationship'
                   
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>저도 친해지고 싶었어요 👋</div>
                    </div>
                  </button>
                  </div>
            </div> */}

             

                 
                 <div style={{display:'flex',justifyContent:'center', marginTop:'25px'}}>
                    <CastingReason onSaveText={handleInputChange4}/>
                 </div> 

                 <div style={{
                     color: "#8B95A1",
                     textAlign: "center",
                     fontFamily: "Pretendard",
                     fontSize: "13px",
                     fontStyle: "normal",
                     fontWeight: 600,
                     lineHeight: "normal",
                     letterSpacing: "-1.04px",
                     marginTop:'30px'
                 }}>
                 내 답장은 프로필에 공개되고 <br></br> 나랑 친해지고 싶은 사람에게 카카오톡으로 전달돼요      
                 </div>
                 <div onClick={handleSubmitAnswer}>
                 <PrimaryButton Text={'답장하기'}/>
                </div>
                     
        </div>
          </div>
      </div>

  );
}

export default AnswerP;
