import hint_3_img from '../../../assets/img/island.webp';
import React, { useState, useEffect } from 'react';
import Footer from '../../../component/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../NewComponent/header';
import jwt_decode from 'jsonwebtoken/decode';
import hoxy_na from '../../../NewComponent/assets/treasure.webp'
import axios from 'axios';

function Payment() {

  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");
  const navigate = useNavigate();
  const [Mid, setMid] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);
    
        const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        setUserData({
          user_no: data.user_no,
          user_name: data.user_name,
          user_email: data.user_email,
          user_phonenumber: data.user_phonenumber,
          user_kakao_year: data.user_kakao_year,
          user_gender: data.user_gender,
        });


    
        setMid(`hoxypayment_na_${tokenUserNo}_${new Date().getTime()}`);
    
        // 나머지 코드
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
    
    fetchData();
  }, []);
  


  async function onClickPayment() {
    if (!window.IMP) return;
  
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init("imp13276538"); 

    try {
      await axios({
        url: "https://backend-hoxy-one-api.hoxy.one:4004/api/payments/pg/frist",
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          merchant_uid: Mid,
        },
      }); 
  
      /* 2. 결제 데이터 정의하기 */
      const data = {
        pg: "html5_inicis.INIpayTest",
        merchant_uid: Mid,
        name: "HOXY:NA 선물 꾸러미",
        pay_method: "card",
        amount: "5900",
        buyer_name: userData.user_name,
        buyer_email: "",
        buyer_tel: userData.user_phonenumber,
        m_redirect_url: "https://hoxy.one/payment/hoxyna/pg/callback",
        currency: "KRW",
        locale: "ko",
        appCard: true,
        custom_data: { userId: tokenUserID, userNo : tokenUserNo },
        display: { card_quota: [] },
        useCardPoint: true,

      };

      /* 4. 결제 창 호출하기 */
      IMP.request_pay(data, callback);
    } catch (error) {
      console.error('결제 준비 과정에서 오류가 발생했어요:', error);
      window.location.reload();
    }
  }
  
  /* 3. 콜백 함수 정의하기 */
  function callback(response) {
    const {
      success,
      merchant_uid,
      error_msg,
    } = response;
  
    if (success) {

       axios({
        url: "https://backend-hoxy-one-api.hoxy.one:4004/api/payment/pg/result",
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          pg: "html5_inicis",
          merchant_uid: Mid,
          name: "HOXY:NA 선물 꾸러미",
          imp_uid: response.imp_uid,
          pay_method: "Card",
          amount: "5900",
          buyer_name: userData.user_name,
          buyer_tel: userData.user_phonenumber,
          userId: tokenUserID,
          userNo: tokenUserNo,
          location: window.location.href,
        },
      })
        .then((data) => {
          alert("결제에 성공했어요!");
          navigate("/jokercard");
        })
        .catch((error) => {
          alert("결제가 실패했어요. 고객센터로 문의해주세요.");
          navigate("/JokerDiscovery");
        });
    } else {
      alert('이런, 결제에 실패했어요', error_msg);
      navigate("/JokerDiscovery");
    }
  }



  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <br></br>
   <Header Text="결제하기" />


    <div style={{display:'flex'}}>
      <div style={{
        width:'75px',
        height:'75px',
        backgroundColor :'#F2F3F5',
        borderRadius:'15px',
        marginLeft:'25px'
      }}><img src={hoxy_na} width={'45px'} height={'45px'} style={{marginTop:'15px'}}></img></div>
      
      <div style={{marginLeft:'15px', textAlign:'left'}}>
      <p style={{fontSize:'13px', fontWeight:'600', color:'#8B95A1', marginTop:'5px'}}>상품명 : HOXY:NA 선물 꾸러미
      <br></br>이용 기간 : 일회성 디지털 컨텐츠 (유효기간 : 12개월까지)</p>
      <p style={{fontSize:'13px', fontWeight:'400', color:'#8B95A1', marginTop:'-10px'}}>조커카드 100장 + 1일1문답 Ai그림 이용권 1회</p>

      <p style={{fontSize:'15px', fontWeight:'700', color:'#4E5968', marginTop:'-10px'}}>가격 :  <b style={{color:'#F04452'}}>5900원</b></p>


      </div>
    </div>




    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'30px'}}>


 
  <div onClick={onClickPayment}  style={{cursor:'pointer',width:'330px', height:'45px', border:'1px solid #E6E9EC', textAlign:'center', margin:'10px auto'}}>
    <p style={{fontSize:'14px', color:'#202632', fontWeight:'500',  lineHeight:'20px'}}>결제 진행하기</p></div>
</div>

<br></br>
<p style={{fontSize:'13px', color:'#8B95A1'}}>※ 결제를 진행 시, 아래 내용과 이용약관에 동의한 것으로 간주해요<br></br>본 상품은 언제나 구매를 복원하거나 취소할 수 있어요</p>
<br></br>



<div style={{ justifyContent: 'center', display: 'flex' }}>
<div style={{marginTop:'70px', marginLeft:'15px', textAlign:'left', color:'#8B95A1', marginRight:'15px'}}>
<p style={{fontSize:'13px'}}><b>- 결제 전, 아래 사항을 꼭 확인해주세요</b></p>
<p style={{fontSize:'11px'}}>- *1일1문답 Ai그림 이용권 1회는 내가 받은 1일 1문답을 눌러서 사용할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- *결제일로부터 12개월이 지나면, 조커카드는 만료될 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 결제 완료 후 정상적으로 조커카드를 받지 못했다면  환불을 고객센터로 요청할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 오류가 발생했거나, 서비스가 제공되지 못할 때는 환불을 고객센터로 요청할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 모든 상품은 디지털 컨텐츠로서 조커카드 사용 시 환불을 진행할 수 없어요.</p>
<p style={{fontSize:'11px'}}>- 웹에서는 온라인 결제(PG)를 통해 결제되고, 앱에서는 인앱 결제(구글/애플)를 통해서 결제돼요</p>
<p style={{fontSize:'11px'}}>- 앱에서 진행한 결제는 구글플레이스토어, 애플 앱스토어를 통해서 환불을 직접 진행해야해요.</p>
<p style={{fontSize:'11px'}}>- 무제한 조커모드는 자동으로 7일(주)마다 매주 결제한 요일에 자동으로 갱신되는 구독형 상품이에요 </p>
<p style={{fontSize:'11px'}}>- 자세한 사항은 가입시 동의하셨던, 서비스 이용약관을 참고해주세요</p>
</div>
</div>

        
        </div>
        <Footer></Footer>

        </div>
  );
}

export default Payment;
