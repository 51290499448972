import React from "react";
import Search from "./assets/search.webp";
import { Link } from "react-router-dom";


export default function InstaSearch({label,value,onChange }) {

  return (
    <Link to={"/search"}>
        <div className="InstaSearchBox"> 
            <div className="flexbox"> 
                <div style={{ marginLeft: '-60px',marginTop:'8px' }}><img style={{width:'25px', height:'auto'}} src={Search}></img></div>
                <p
                style={{
                  borderRadius: '6px',
                  marginTop: '12px',
                  marginLeft: '-20px',
                  width: '250px',
                  height: '24px',
                  fontSize: '14px',
                  border: 'none',
                  outline: 'none',
                  fontFamily: 'Pretendard',
                  fontWeight: '500',
                  letterSpacing: '-0.08rem',
                  color: '#848484'
                }}
              >
                친해지고 싶은 사람 ‘인스타’를 검색해보기
              </p>

            </div>
        </div>
        </Link>
  );
}
