import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './OneBox2.css';
import hoxy_circle from '../NewComponent/assets/HoxyCircle.svg'

  const OneBox2 = ({ img_source }) => {
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>

      <div class="container" style={{overflow:'hidden'}}>
          <div id="spinner">
            <div className='center_circle'>
            </div>
          </div>
      </div>

      <div style={{position:'absolute', top:'150px', left:'95px'}}><img style={{width:'120px',height:'auto'}} src={img_source}></img></div>

      <div style={{position:'absolute', top:'325px', left:'140px'}}><img src={hoxy_circle}></img></div>

     
      
    </div>
  );
};


OneBox2.propTypes = {
  img_source: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default OneBox2;