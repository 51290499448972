import React from 'react';

export default function Intro_Block({img,Text1,Text2}) {

  return (
    <div>
        <div style={{display: 'flex', justifyContent:'flex-start', marginTop:'10px'}}> 
            <div><img style={{width:'45px',height:'45px'}} src={img}></img></div>
            <div style={{display: 'flex', flexDirection:'column',textAlign:'left',marginLeft:'15px',marginTop:'20px'}}> 
            <div className='SchoolName'>{Text1}</div>
            <div className='UserName' style={{marginLeft:'0'}}>{Text2}</div>
            </div>
        </div>
    </div>  

  );
}
