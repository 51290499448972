import React, { useState } from 'react';
import MainButton from '../../NewComponent/MainBtn';
import InformBox from '../../NewComponent/InformBox';
import HintBox from '../../NewComponent/HintBox-backup';
import SugBox from '../../NewComponent/SugBox';
import ThemeTitle from '../../NewComponent/ThemeTitle';
import JokerBox from '../../NewComponent/JokerBox';
import SelecBox from '../../NewComponent/SelecBox';
import SearchBar from '../../NewComponent/SearchBar';
import { SearchResult } from '../../NewComponent/SearchResult';
import InstaSearch from '../../NewComponent/InstaSearch';
import CastingInput from '../../NewComponent/CastingInput';
import CastingReason from '../../NewComponent/CastingReason';
import SecondaryButton from '../../NewComponent/SecondaryButton';
import KaKaoLoginbtn from '../../component/KaKaoLoginbtn';
import BoyGoingToSchool from '../../NewComponent/assets/boy_going_to_school.webp';
import OnboardingMiddele1 from '../../NewComponent/OnboardingMiddle1';
import clap from '../../NewComponent/assets/clap_onboarding.webp'
import OnboardingMiddele2 from '../../NewComponent/OnboardingMiddle2';
import KakaoMovingBox from '../../NewComponent/KakaoMovingBox';
import Shoes from '../../NewComponent/assets/Shoes Shopping.webp'
import BasketballHoop from '../../NewComponent/assets/BasketballHoop.webp'
import Art1 from '../../NewComponent/assets/Art1.webp'
import Message from '../../NewComponent/assets/Message.webp'
import StudyLamp from '../../NewComponent/assets/StudyLamp.webp'
import Mara from '../../NewComponent/assets/Mara.webp'
import Onboarding_Intro from '../../NewComponent/Intro_Block';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import PrimaryButtonSmall from '../../NewComponent/PrimaryButtonSmall';
import SecondaryButtonSmall from '../../NewComponent/SecondaryButtonSmall';
import Intro_Block from '../../NewComponent/Intro_Block';
import SexBtn from '../../NewComponent/SexBtn';
import MessageNumBox from '../../NewComponent/MessageNumBox';
import Bottomsheet from '../../NewComponent/BottomSheet/Bottomsheet';
import WhyJoker from '../../NewComponent/WhyJoker'
import MiddleSheet from '../../NewComponent/SchoolLinkPage/Middlesheet';
import { CheckboxProvider, Checkbox } from '../../NewComponent/CheckBox';
import EventBlock from '../../NewComponent/EventBlock'
import c_barrier  from "../../NewComponent/assets/c_barrier.webp"
import ProfileBox from '../../NewComponent/ProfileBox';
import sample_gun  from "../../NewComponent/assets/sample_gun.png"
import BottomNav from '../../NewComponent/BottomNav';

function CompoDemo() {
  
  return (
    <div className="main" style={{ backgroundColor: '#000', overflow:'auto' }}>
      <MainButton Label={'클릭'} />
      <InformBox CompoName={'Instagram'} iftext={'ㅎㅎ'} howtext={'ㄱㄱ'} CompoName2={'LookDetail'} />
      <InformBox CompoName={'Cupid'} iftext={'es'} howtext={'woe'}  CompoName2={'Arrow'} />
      <HintBox HintTitle={'하이'} CompoName={'Earth'} whattext={'농구'} hinthowtext={'밥먹자'} />
      <SugBox user_id={'하이'} CompoName={'Noon'} stuff={'농구'} />
      <ThemeTitle themetitle={'하이'} LookClass={'NoLook'} />
      <JokerBox num_joker={'10'} />
      <SelecBox SelecCompoName={'Earth'} option={'100'} />
      <SearchBar label={'친해지고 싶은 사람 ‘이름’을 검색해보세요'} />
      <InstaSearch label={'친해지고 싶은 사람의 인스타를 검색해보세요'} />
      <CastingInput  what={'이름'} />
      <CastingReason />
      <SecondaryButton Text='클릭' id='selected' />
      <OnboardingMiddele1 img={BoyGoingToSchool} Text1='우리학교에서 친구를 찾아볼게요' Text2='다니고 있는 학교를 알려주세요' />   
      <OnboardingMiddele2 img={clap} Text1='회원가입에 성공했어요'  Text2='HOXY를 소개해드릴게요'  />   
      <KakaoMovingBox id='left' img1={Shoes} img2={Mara} img3={Art1}/>
      <KakaoMovingBox id='right' img1={BasketballHoop} img2={Message} img3={StudyLamp} />
      <Onboarding_Intro />
      <PrimaryButtonSmall Text='내 프로필 공유하기'/>
      <SecondaryButtonSmall Text='둘러보고 하기'/>
      <Intro_Block img={clap} Text1='나도 친해지고 싶은 친구가 있었다면' Text2='친해지고 싶은 마음 표현하기'/>
      <SexBtn Text='클릭 ' id='selected' sex='man' />
      <MessageNumBox num='1'/>
      <WhyJoker />
      <CheckboxProvider>
        <Checkbox value="example">(필수)HOXY의 개인정보처리방침에 동의해요</Checkbox>
      </CheckboxProvider>
      <EventBlock img={c_barrier} text_line1='친해지고 싶은 사람들이 다가오지 못해요' text_line2='프로필을 Bio나 스토리에 공유해주세요 ' button_text='내 프로필 공유하기'/>
      <ProfileBox profile_name='김건호' profile_school='김건호' profile_insta='김건호' button_text='프로필 공유' img={sample_gun} />
      <BottomNav />
   </div>
  );
}

export default CompoDemo;
