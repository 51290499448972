import React from 'react';
import default_img from '../assets/img/success.png'
import PrimaryButtonSmall_red from './PrimaryButtonSmall_red';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 50, // 아래에서 위로 올라오도록 설정
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1, // 애니메이션 지속 시간
      ease: 'easeOut', // 이징 설정
    },
  },
};


export default function MiddleCompoent({ img, Text1, Text2}) {
  const imageUrl = img || default_img ; // 이미지가 없으면 기본 이미지 사용

  return (
    <div className='middle_component'>

      <div style={{
        
        width:"323px", 
        height:'327px',
        borderRadius:"15px",
        background:'#fff',
        color: "#000",
        textAlign: "center",
        fontFamily: "Pretendard",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "106%",
        letterSpacing: "-1.6px"
    }}>
        <div
        
        style={{
            textAlign:'left',
            marginLeft:'20px',
            marginTop:'10px'
        }}   

        >
                왜 조커카드가 필요하나요?

        </div>

        <div
        
        style={{ 
            color: "#8B95A1",
            fontFamily: "Pretendard",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "-1.12px",

            marginTop:'20px',
            textAlign:'left',
            marginLeft:'23px'
        }}
        
        >
        “ HOXY는 모두가 자연스럽게  <br></br> 친해질 수 있는 세상을  꿈꿔요 🍀”
        </div>

        <div 
        
        style={{ 
            color: "#000",
            fontFamily: "Pretendard",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "-1.12px",

            marginTop:'20px',
            textAlign:'left',
            marginLeft:'23px'
        }}

        >
        이러한 HOXY의 목표 아래에서 <br></br> 무분별한 힌트 사용으로 친해지고 싶은 사람이       </div>

        <div>
            <div 
            
                style={{ 
                    color: "#000",
                    fontFamily: "Pretendard",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "-1.12px",

                    marginTop:'20px',
                    textAlign:'left',
                    marginLeft:'23px'
                }}
                
                >친해지고 싶은 사람에게 다가가는 장벽을 줄이고자
            </div>

            <br></br>

            <div 
                
                style={{
                    color: "#FF5C00",
                    fontFamily: "Pretendard",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    letterSpacing: "-1.12px",

                    marginTop:'-18px',
                    textAlign:'left',
                    marginLeft:'23px'
                }}
                
                >조커카드를 사용해야지만 특정 인물을 유추할 수 있는 
                <br></br>
                힌트를 확인할 수 있도록 하고 있어요
            </div>

            </div>


            <div style={{marginRight:'-40px'}}>
                    <PrimaryButtonSmall_red Text='닫기'/>
            </div>
        
        </div>
    </div>
  );
}
