import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CastingInput from '../../../NewComponent/CastingInput';
import PrimaryButton from '../../../NewComponent/PrimaryButton';
import love_letter from '../../../NewComponent/assets/love_letter.webp'
import OnboardingMiddele2 from '../../../NewComponent/OnboardingMiddle2';
import cupid from '../../../NewComponent/assets/Cupid.svg'
import InformBox from '../../../NewComponent/InformBox';
import bell from '../../../NewComponent/assets/bell.webp';
import makeprofile from '../../../NewComponent/assets/makeprofile.webp';
import jwt_decode from 'jsonwebtoken/decode';


function MessageFinished() {

    const [currentContent, setCurrentContent] = useState(1);
    const [previousContent, setPreviousContent] = useState(1);
    const [userData, setUserData] = useState({});
    const VisitprofileName = localStorage.getItem('VisitprofileName');

    const [searchTerm, setSearchTerm] = useState(''); 
    const [results, setResults] = useState([]); 

    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [relationship, setRelationship] = useState('');
    const [keyword, setKeyword] = useState('');
    const [want, setWant] = useState('');
    const navigate = useNavigate();


    const location = useLocation();
    const dataReceived = location.state;
  
  
    console.log(dataReceived.age);
    console.log(dataReceived.gender);
    console.log(dataReceived.keyword);
    console.log(dataReceived.relationship);
    console.log(dataReceived.want);
    console.log(dataReceived.user_id);


    const handleNextClick = () => {
        if (currentContent < 5) {
            setPreviousContent(currentContent);
            setCurrentContent(currentContent + 1);
        }
    };

    const handlePreviousClick = () => {
        if (previousContent >= 1) {
            setCurrentContent(previousContent);
            setPreviousContent(currentContent - 1);
        }
    };


  

    const handleSubmit = async () => {
      const recive_user_id = localStorage.getItem('VisitprofileID');
      const recive_user_no = localStorage.getItem('VisitprofileUserNo');


      if (!dataReceived.gender || !dataReceived.age || !dataReceived.relationship || !dataReceived.keyword || !dataReceived.want) {
        alert('아직 채워지지 않았거나, 입력할 수 없는 문자가 있어요');
        return;
      }
    
      try {
        const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/request', {
          request_user_id: dataReceived.user_id,
          request_user_no: dataReceived.user_no,
          request_gender: dataReceived.gender,
          request_age: dataReceived.age,
          request_relationship: dataReceived.relationship,
          request_keyword: dataReceived.keyword,
          request_want: dataReceived.want,
          recive_user_id: recive_user_id,
          recive_user_no: recive_user_no,
        });
    
        handleNextClick();
      } catch (error) {
        console.error(error);
        alert('내부 서버의 오류가 발생했어요. 나중에 다시 시도해보세요!');
      }
    };
    
  
    const handleSvg1Click = () => {
      const recive_user_id = localStorage.getItem('VisitprofileID');
      navigate(`/${recive_user_id}?explore=yes`); 
    };
    

 
  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
        <div className='main_content'>
        <br></br>
        

        <div className='Content-Casting'>

            <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>
                
              <div>   

                    <OnboardingMiddele2 middle_compo='middle_component' img={love_letter} 
                     Text1={`${VisitprofileName}님을 향한 나의 진심이 담긴`}
                     Text2='속마음 편지가 완성되었어요'  />
                    
                    <br></br>

               </div>
            </div>

         <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>
                
            <div>   

                <OnboardingMiddele2 middle_compo='middle_component' img={cupid}   Text1={`${VisitprofileName}님이 답장을 남기면`} Text2='프로필에서 답장을 볼 수 있어요' />

                <br></br> 

                

                    <div style={{

position: 'fixed',
bottom: '10%',
left: '50%',
transform: 'translateX(-50%)'

}}>

{/* 
<InformBox CompoName={'bell'} src={bell} iftext={'나도 나랑 친해지고 싶은 사람을 확인하려면'} howtext={'내 HOXY 프로필 만들기'} src2={makeprofile} />
*/}
</div>   

                </div>
            </div>

        </div>
    
        <div onClick={handleSubmit} style={{ display: currentContent === 2? 'none' : 'block' }}>
        <PrimaryButton  Text={`${VisitprofileName}님에게 보내기`} />
        </div>

        <div onClick ={() => {handleNextClick(); handleSvg1Click(); }} style={{ display: currentContent === 2 ? 'block' : 'none' }}>
            <PrimaryButton Text={'완료하기'}/>
        </div>

    

        </div>
  </div>
  );
}

export default MessageFinished;
