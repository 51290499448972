import React, { useState, useEffect, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import CastingInput from '../../NewComponent/CastingInput';
import OnboardingMiddele1 from '../../NewComponent/OnboardingMiddle1';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import mother_img from '../../assets/img/Mother and daughter hugging.webp'
import axios from 'axios';
import { motion, AnimatePresence  } from 'framer-motion'
import { Checkbox, CheckboxProvider } from '../../NewComponent/CheckBox';

function OnBoarding_University() {
  const [currentContent, setCurrentContent] = useState(1);
  const [previousContent, setPreviousContent] = useState(1);

  const [tutorialStep, setTutorialStep] = useState(1)
  const [showStep, setShowStep] = useState(true);
  const [counter, setCounter] = useState(0);


  const [NokName, setNokName] = useState('');
  const [NokBirthYear, setNokBirthYear] = useState('');
  const [NokPhonenumber, setNokPhonenumber] = useState('');


  const navigate = useNavigate();



  const handleNextClick = () => {
      if (currentContent < 4) {
          setPreviousContent(currentContent);
          setCurrentContent(currentContent + 1);
      }
  };

  const handlePreviousClick = () => {
      if (previousContent >= 1) {
          setCurrentContent(previousContent);
          setPreviousContent(currentContent - 1);
      }
  };



  const handleInputChange1 = (event) => {
    const inputValue = event.target.value;
      setNokName(inputValue);
  };


const handleInputChange2 = (event) => {
  const inputValue = event.target.value;
  const numberRegex = /^[0-9]*$/;
  
  if (!numberRegex.test(inputValue)) {
    event.target.value = ''; // 입력 값을 초기화
  } else {
    setNokBirthYear(inputValue);
  }
};


    const handleInputChange3 = (event) => {
      const inputValue = event.target.value;
      const userClassRegex = /^[0-9]*$/;
      const numericValue = inputValue.replace(/\D/g, '');

      if (!userClassRegex.test(inputValue)) {
        event.target.value = ''; // 입력 값을 초기화
      } else {

        const formattedValue = formatPhoneNumber(numericValue);
        setNokPhonenumber(formattedValue);
      }
    };

    function formatPhoneNumber(phoneNumber) {
      if (phoneNumber.length < 4) {
        return phoneNumber;
      } else if (phoneNumber.length < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
      } else if (phoneNumber.length < 11) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`;
      }
      // 최대 길이를 초과하는 경우 뒷부분을 잘라냅니다.
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
    }



  
  useEffect(() => {
    if (currentContent === 4) {
      const changeStep = () => {
        setShowStep(false);
        setTimeout(() => {
          setTutorialStep((prevStep) => (prevStep === 4 ? 1 : prevStep + 1));
          setShowStep(true);
          setCounter((prevCounter) => prevCounter + 1);
        }, 500);
      };
    const timer = setInterval(changeStep, 4000);
    return () => {
      clearInterval(timer);
    };
  }
}, [currentContent]);




const checkMustAgreement = () => {
  const mustChecked = localStorage.getItem('nok_must_check')

  if (!mustChecked) {
    alert('필수 약관에 동의해야해요.');
    return false; // 약관 동의가 필요한 경우 함수 종료
  }

  else {
  handleNextClick();  
  }
};



const [dataSent, setDataSent] = useState(false); // 데이터 전송 여부 상태

const user_name = localStorage.getItem('user_name');
const user_phoneNumber = localStorage.getItem('user_phoneNumber');
const user_kakao_year = localStorage.getItem('user_kakao_year');
const nok_marketing_check = localStorage.getItem('nok_marketing_check');
const nok_information_check = localStorage.getItem('nok_marketing_check');


const handleDataSent = () => {
  if (!dataSent) { // 데이터가 이미 전송되지 않은 경우에만 실행

    const data = {
      user_name: user_name,
      user_phonenumber: user_phoneNumber,
      user_kakao_year: user_kakao_year,
      nok_marketing_check: nok_marketing_check,
      nok_information_check: nok_information_check,
      nok_name: NokName,
      nok_birth_year: NokBirthYear,
      nok_phonenumber: NokPhonenumber
    };

    // 데이터를 전송하기 전에 데이터 전송 여부 상태를 true로 설정
    setDataSent(true);

    axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/underage/check', data)
      .then((response) => {
        // 성공적으로 서버로 데이터를 전송한 경우의 처리
        console.log('서버 응답:', response.data);
        navigate('/onboarding');
      })
      .catch((error) => {
        // 오류가 발생한 경우의 처리
        alert('내부 서버에 오류가 발생했거나, 보호자 인증에 실패했어요');
        // 데이터 전송 실패 시 다시 데이터 전송 가능하도록 데이터 전송 여부 상태를 false로 설정
        setDataSent(false);
      });
  }
};

  

    
  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className='main_content'>
        <br></br>

      {(currentContent === 1 || currentContent === 2 || currentContent === 3) && (
          <a onClick={handlePreviousClick}>
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
        )}
                
      <div style={{marginTop:"160px"}}></div>

      <div className='Content-Casting'>
        <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>

           <OnboardingMiddele1  middle_compo='middle_component_25' img={mother_img}   Text1={
              <>
                만 14세 미만의 개인정보 이용에
                <br />
                보호자의 동의가 필요해요
              </>
            }
            Text2={<>
            꼭, 보호자(법정대리인)이 진행해야해요
            </>} />

                       

                          <div style={{position:'absolute', top:'49%' , left: '50%', transform: 'translate(-40%, -50%)'}}>
                                <CheckboxProvider>
                                <Checkbox value="nok_must_check">(필수) &nbsp;<a href='https://hoxy.one/term/privacy-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>개인정보처리방침</a> &nbsp;및&nbsp; <a href='https://hoxy.one/term/terms-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>이용약관</a>에 동의해요</Checkbox>
                                </CheckboxProvider>       
                          </div>

                          <div style={{position:'absolute', top:'53%' , left: '50%', transform: 'translate(-40%, -50%)'}}>
                             <CheckboxProvider>
                              <Checkbox value="nok_information_check">(선택) HOXY의&nbsp;<a href='https://hoxy.one/term/information-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>정보성메시지 수신</a>에 동의해요</Checkbox>
                              </CheckboxProvider>       
                          </div>

                          <div  style={{position:'absolute', top:'57%' , left: '50%', transform: 'translate(-40%, -50%)'}}>
                              <CheckboxProvider>
                              <Checkbox  value="nok_marketing_check">(선택) HOXY의&nbsp;<a href='https://hoxy.one/term/marketing-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>맞춤형·마케팅메시지 수신</a>에 동의해요</Checkbox>
                              </CheckboxProvider>     
                          </div>


                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0}}
                transition={{ duration: 0.3 }}
                onClick={checkMustAgreement}
                >
                  <div>
                  <PrimaryButton Text="보호자로서, 모두 동의합니다" ></PrimaryButton>
                  </div>
                  </motion.div>

       
        </div>

        <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>

        <OnboardingMiddele1  middle_compo='middle_component_25' img={mother_img}  Text1={
              <>
                보호자님께 동의완료에 대한
                <br />
                메시지를 보내드릴게요
              </>
            } Text2='카카오톡(문자)로 보호자님께 알려드리고 있어요' />


          
          <div className='middle_component' style={{marginLeft:'-20px', marginTop:'100px'}}>

          <div style={{position:'sticky', }}>
            <CastingInput num={'1'} what={'보호자 이름'} onChange={handleInputChange1} />
          </div>        

          <br></br>
          <br></br>
          <br></br>


            <div style={{position:'sticky', overflow:'none' }} >          
            {NokName.length >= 1 && (
            <motion.div                          
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0}}
            transition={{ duration: 0.3 }}
            >
          <CastingInput num={'2'} what={'보호자 출생연도'}  onChange={handleInputChange2} />
          </motion.div>
          )}
          </div>

          <br></br>
          <br></br>
          <br></br>


          <div style={{position:'sticky', overflow:'none' }} >
          {NokBirthYear.length >= 4 && (
          <motion.div                          
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{ duration: 0.3 }}
          >
          <CastingInput num={'3'} what={'보호자 전화번호'} onChange={handleInputChange3} />
          </motion.div>
          )}
          </div>
          </div>



          <AnimatePresence>
          {NokBirthYear <= 2001 && NokName !== user_name && NokPhonenumber !== user_phoneNumber && NokPhonenumber.length > 12 && (
          <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={handleDataSent}
              disabled={dataSent}
            >
              <div>
                <PrimaryButton Text="다음" />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

          
           
        </div>



      </div>




      </div>
    </div>
  );
}

export default OnBoarding_University;