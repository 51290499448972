import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Select.css';
import { useNavigate } from 'react-router-dom';
import Hint from '../pages/Hint';


export const Select = ({ labels, className, onSubmit, requestDbNumber }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();


  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    setInputValue('');
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setActiveButton(null);
  };

  const handleSubmit = () => {
    let selectedAnswer = '';
    if (activeButton !== null && labels[activeButton - 1]) {
      selectedAnswer = labels[activeButton - 1];
    } else {
      selectedAnswer = inputValue;
    }
    onSubmit(selectedAnswer);
  };


  function handleDeleteMessage() {
    // 여기서 메시지 차단 API 호출
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/block', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ requestDbNumber }), // requestDbNumber를 전송
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('요청이 실패하였습니다.');
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert('요청 중 오류가 발생하였습니다.');
      });
  }
  

  function handleBlockMessage() {
    // 여기서 메시지 차단 API 호출
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/block', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ requestDbNumber }), // requestDbNumber를 전송
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('요청이 실패하였습니다.');
        }
      })
      .then((data) => {
        alert('사용자를 성공적으로 차단했어요.');
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert('요청 중 오류가 발생하였습니다.');
      });
  }
  

  const renderButtons = () => {
    const buttons = labels.map((label, index) => (
      <button
        key={index}
        className={`${className}${index + 1} ${activeButton === index + 1 ? 'active' : ''}`}
        onClick={() => handleButtonClick(index + 1)}
      >
        {label}
      </button>
    ));

    const rows = [];
    for (let i = 0; i < buttons.length; i += 3) {
      rows.push(
        <div className="select-row" style={{marginBottom:'7px'}} key={i}>
          {buttons.slice(i, i + 3)}
        </div>
      );
    }

    return rows;
  };

  function handleLinkClick(requestDbNumber) {
    navigate(`/hint?Mymessage=${requestDbNumber}`);
  }

  function report() {
    alert('고객센터에 게시물에 대한 신고가 접수되었어요. 24시간 이내에 확인 후에 처리할게요.')
  }


  return (
    <div style={{overflowY:'hidden', overflowX: 'hidden' }} className={`${className} box`}>
      <div>
        {renderButtons()}
      </div>
      <div style={{ marginTop: '10px' }}></div>
      <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
        <input
          className="Question"
          type='text'
          placeholder="내가 직접 입력하기!"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setActiveButton(null)}

          style={{
            textIndent:'10px',
            width: '340px',
            height: '41px',
            flexShrink: '0',
            borderRadius: '25px',
            background: '#FBF6F6',
            border : 'none',
            boxShadow : 'none',
            outline : 'none',

            /*font 설저 */
            color: '#000',
            textAlign: 'left',
            marginLeft:'0px',
            fontFamily: 'Pretendard',
            fontWeight: 600,
            letterSpacing: '-0.08rem',             
            fontSize: '15px',
            fontStyle: 'normal',
            lineHeight: 'normal',
           
          }}  
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '29px' }}>

            <button className={`select-hint-button primarynf-text-black`}
             onClick={() => handleLinkClick(requestDbNumber)}>
            <div className="button-text">힌트 보기🧩</div>
          </button>

        <button
          className={`select-submit-button primarynf-text-black`}
          onClick={handleSubmit}
        >
          <div className="button-text">답변 하기</div>
        </button>

      



      </div>
      <br></br>
      <br></br>
      <a onClick={handleDeleteMessage}>
      🗑️ 메시지 삭제하기
        </a>
        <br></br>

        <a onClick={handleBlockMessage}>
        ⛔ 메시지 보낸 사용자 차단하기 
        </a>
      <br></br>

      <a onClick={report}>
      🚨 메시지 신고하기
        </a>

     
        <br></br>
        <br></br>

    </div>
  );
};

Select.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
