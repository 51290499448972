import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SearchBar from "../../NewComponent/SearchBar";
import InformBox from '../../NewComponent/InformBox';
import cupid from "../../assets/img/angel-girl 2.webp";
import lookdetail from "../../NewComponent/assets/LookDetail.svg";
import { SearchResult } from '../../NewComponent/SearchResult';

function NewSearchP() {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [searchError, setSearchError] = useState(false);

  const handleSearch = async (term) => {
    if (term.trim() !== '') {
      try {
        const nameResponse = await axios.get(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/name/${term}`);
        const idResponse = await axios.get(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/id/${term}`);
        const schoolResponse = await axios.get(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/school/${term}`);
        const combinedResults = [...nameResponse.data, ...idResponse.data, ...schoolResponse.data];

        if (combinedResults.length > 0) {
          setResults(combinedResults);
          setSearchError(false); // Clear the error state if there are results.
        } else {
          setResults([]);
          setSearchError(true);
        }
      } catch (error) {
        console.error('Error searching:', error);
        setResults([]);
        setSearchError(true);
      }
    } else {
      setResults([]);
      setSearchError(false); // Clear the error state when the search term is empty.
    }
  };

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleSearch(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>
        <SearchBar Value={searchTerm} onChange={e => setSearchTerm(e.target.value)} label={'친해지고 싶은 사람 이름을 검색해보세요'} />

        <div style={{ display: "flex", justifyContent: "center", height: '1px', background: '#D2D6DB', marginTop: '10px' }}>
        </div>

        <br></br>

        {searchTerm.trim() === '' ? (
          <div style={{display:'flex',justifyContent:'center'}}>
          <Link to={"/casting-intro"}>
          <InformBox src={cupid} iftext={'친해지고 싶은 친구가 HOXY를 안쓴다면?'} howtext={'HOXY가 나서서 도와드릴게요'} src2={lookdetail} />
          </Link>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', color: '#848484' }}>
              {results.length === 0 && !searchError && (
                <p style={{ textAlign: 'center', marginTop: '25px', color: '#848484' }}>열심히 검색결과를 찾고 있어요 🔍</p>
              )}
              {searchError && (
                <p style={{ textAlign: 'center', marginTop: '25px', color: '#848484' }}>{searchTerm ? `'${searchTerm}'에 대한 검색 결과가 없어요` : '검색어를 입력하세요'}</p>
              )}
              {results.map((result) => (
                <Link key={result.id} to={`/${result.user_id}?explore=yes`} style={{marginBottom:'10px'}} >
                  <SearchResult
                    Label1={result.user_name}
                    Label2={`@${result.user_id}`}
                    Label3={result.school_name}
                    iconType={'arrow'}
                  />
                </Link>
              ))}
            </div>
            <br></br>
            <div style={{display:'flex',justifyContent:'center'}}>
                <InformBox src={cupid} iftext={'친해지고 싶은 친구가 HOXY를 안쓴다면?'} howtext={'HOXY가 나서서 도와드릴게요'} src2={lookdetail} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewSearchP;
