import React, { useState, useEffect } from 'react';
import shopping_bag_img from '../assets/img/coupang.webp';
import { useNavigate  } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';

function Coupang() {
  const [countdown, setCountdown] = useState(5); 
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [xClicked, setXClicked] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({ user_name: '', user_id: '' });
  const [tokenUserID, setTokenUserID] = useState('');

  const lastButtonClickTimestamp = localStorage.getItem('coupang_lastButtonClickTimestamp');
  const withinTwoHours = lastButtonClickTimestamp && Date.now() - parseInt(lastButtonClickTimestamp, 10) <= 2 * 60 * 60 * 1000;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);

      } catch (error) {
        setTokenUserID("anonymous");
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
        setTokenUserID("anonymous");
        console.log("로그인되어있지 않음");
        }
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1);
      } else {
        setButtonDisabled(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(() => {
    if (withinTwoHours) {
      setCountdown(-2);
      setXClicked(true);
    }
  }, [withinTwoHours]);

  const handleButtonClick = () => {
    if (countdown > 0) {
      console.log("Button clicked! - coupang.com으로 이동");
      window.location.href = 'https://link.coupang.com/a/52BRO';
      const currentURL = window.location.href;

      axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/coupang_click', {
        user_id: tokenUserID,
        click_page: currentURL
      });

    } else {
      if (xClicked) {
        setButtonDisabled(true);
        console.log("Button clicked! - /hint로 이동");

        localStorage.setItem('coupang_lastButtonClickTimestamp', Date.now().toString());

        const urlParams = new URLSearchParams(window.location.search);
        const requestDbNumber = urlParams.get('Mymessage');

        navigate(`/hint?Mymessage=${requestDbNumber}&visited=true`);
      } else {
        setXClicked(true);
      }
    }
  };

  const handleXClick = () => {
    if (!buttonDisabled) {
      setXClicked(true);
    }
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <a href="javascript:window.history.back();">
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>

<br></br>

          <div style={{display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'40px', marginLeft:'25px', display:'flex', }}>
            <h2 className='title'>10대 친구들이 좋아하는 <br></br>상품들 구경하기</h2> <img src={shopping_bag_img} width={'32px'}height={'31px'} style={{marginTop:'3px', marginLeft:'-70px'}}></img>
            </div>
          </div>
      


          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'50px'}}>
            <div>
          <iframe src="https://ads-partners.coupang.com/widgets.html?id=693532&template=carousel&trackingCode=AF2066453&subId=&width=350&height=80&tsource=" width="350" height="70" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
          </div>
          <div style={{display:'flex', marginTop:'20px', }}>
          <div style={{marginLeft:'1px', marginRight:'25px'}}>
          <iframe src="https://ads-partners.coupang.com/widgets.html?id=693528&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
          </div>

          <div>
          <iframe src="https://ads-partners.coupang.com/widgets.html?id=693527&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
          </div>

          <div style={{ marginLeft:'25px'}}>
          <iframe src="https://ads-partners.coupang.com/widgets.html?id=693567&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>          </div>
          </div>


          <div style={{marginTop:'15px', color:'#3C414B', fontWeight:'500', fontSize:'13px'}}>
          <p>이 페이지는 쿠팡 파트너스 활동의 일환으로<br></br>이에 따른 일정액의 수수료를 제공받아요</p>
          </div>

          </div>


          <div className="welcome_ButtonBox">
          <div style={{ alignSelf: "center", position: "relative" }}>
        {countdown > 0 ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius:'5px',
              color: "#000000",
              width:'22px',
              height:'22px',
              fontSize:'15px',
              lineHeight:'22px',
              textAlign:'center',
              fontWeight: "bold",
              transform: "translate(-65%, 20%)",
              cursor: "pointer",
              visibility: xClicked ? "hidden" : "visible", 
            }}
            onClick={handleXClick}
          >
            {countdown}
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius:'5px',
              color: "#000000",
              width:'22px',
              height:'22px',
              fontSize:'15px',
              lineHeight:'22px',
              textAlign:'center',
              fontWeight: "bold",
              transform: "translate(-65%, 20%)",
              cursor: "pointer",
              visibility: xClicked ? "hidden" : "visible", 
            }}
            onClick={handleXClick}
          >
            X
          </div>
        )}
        <button
          className={`primary ${buttonDisabled ? "disabled" : ""}`}
          onClick={handleButtonClick}

        >
          {countdown > 0
            ? (
              <>
                <span>쿠팡 다녀오고, </span>
                <span style={{fontFamily:'Pretendard'}}>지금 힌트 확인하기</span>
              </>
            )
            : xClicked
            ? (
              <>
                <span>이제 돌아가, </span>
                <span style={{fontFamily:'Pretendard'}}>힌트를 눌러보세요</span>              </>
            )
            : (
              <>
                <span>쿠팡 다녀오고, </span>
                <span style={{fontFamily:'Pretendard'}}> 지금 힌트 확인하기</span>
              </>
            )
          }
        </button>
        <p style={{ color: "#000000", fontWeight: "600", fontSize: "13px" }}>
          원치 않을 경우 뒤로가기를 눌러주세요
        </p>
        </div>
      </div>

        </div>
        </div>
      </div>
  );
}

export default Coupang;
