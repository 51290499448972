import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import { ReactComponent as Search } from "../stories/assets/icon-search-mono.svg";
import { ReactComponent as Underbar } from "../stories/assets/underbar.svg";

export const SearchBar = ({ value, onChange, label }) => {
  const [isAnsVisible, setIsAnsVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAnsVisible(!isAnsVisible);
  };

  return (
    <div style={{ width: '400px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '70px' }}>
        <Search />
        <input
          type="text"
          className="search-input"
          value={value}
          onChange={onChange}
          placeholder={label}
          style={{ background:'transparent', borderRadius:'6px', width:'300px', marginTop:'-3px', height:'24px', fontSize:'18px', textIndent:'15px', border:'none', outline:'none',  fontFamily:'Pretendard',
          fontWeight: '600', letterSpacing: '-0.08rem;'   }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Underbar style={{ marginBottom: '25px' }} />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};