import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import { CSSTransition } from 'react-transition-group';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import cupid from '../../NewComponent/assets/Cupid.svg';
import Header from '../../NewComponent/header';
import teen_studio from '../../NewComponent/assets/teenstudio.webp'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';

function OpenJoker() {
  const [randomClass, setRandomClass] = useState('Open_1');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [reset, setReset] = useState(false);

  const [countdown, setCountdown] = useState(5); 
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [xClicked, setXClicked] = useState(false);
  const [user, setUser] = useState({ user_name: '', user_id: '' });
  const [tokenUserID, setTokenUserID] = useState('');

  const [randomClass2, setRandomClass2] = useState('Opened_0');
  const [buttonClicked2, setButtonClicked2] = useState(false);
  const [reset2, setReset2] = useState(false);


  const [drawnCards, setDrawnCards] = useState(null);
  const [tokenUserNo, setTokenUserNo] = useState('');
  const [apiCalled, setApiCalled] = useState(false); // API 호출 여부를 추적

  const navigate = useNavigate();


  useEffect(() => {
    // 로컬 스토리지에서 클릭 횟수 및 초기화 날짜 가져오기
    const clickCount = parseInt(localStorage.getItem('clickCount')) || 0;
    const lastResetDate = localStorage.getItem('lastResetDate');


    const token = localStorage.getItem('token');
  
    const decodedToken = jwt_decode(token);
    const tokenUserID = decodedToken.user_id;
    const tokenUserNo = decodedToken.user_no;
    setTokenUserID(tokenUserID)
    setTokenUserNo(tokenUserNo)


    // 현재 날짜 및 시간 가져오기
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();

    if (lastResetDate) {
      // 로컬 스토리지에 저장된 초기화 날짜 가져오기
      const resetDate = new Date(lastResetDate);
      const resetDay = resetDate.getDate();
      const resetMonth = resetDate.getMonth();

      if (currentMonth !== resetMonth || currentDay !== resetDay) {
        // 현재 날짜와 초기화 날짜가 다른 경우, 클릭 횟수 초기화
        localStorage.setItem('clickCount', '0');
        localStorage.setItem('lastResetDate', currentDate.toString());
        setCountdown(3);
        setButtonDisabled(false);
      } else {
        // 같은 날짜인 경우, 클릭 횟수 및 상태 설정
        setCountdown(3 - clickCount);
        setButtonDisabled(clickCount >= 3);
      }
    } else {
      // 초기화 날짜가 없는 경우, 초기화 날짜와 클릭 횟수 설정
      localStorage.setItem('lastResetDate', currentDate.toString());
      setCountdown(3 - clickCount);
      setButtonDisabled(clickCount >= 3);
    }
  }, []);

  const handleClick2 = () => {
    if (countdown > 0) {
      // 클릭 횟수를 1 감소시키고 로컬 스토리지에 저장
      const newCount = countdown - 1;
      localStorage.setItem('clickCount', (3 - newCount).toString());
      setCountdown(newCount);
      setButtonDisabled(newCount <= 0);

      const classNames = ['Open_1', 'Open_2', 'Open_3'];
      const randomIndex = Math.floor(Math.random() * classNames.length);
      setRandomClass(classNames[randomIndex]);
      setButtonClicked(true);

      // Check if 'Open_3' is selected and navigate immediately
      if (classNames[randomIndex] === 'Open_3') {
        navigate('/ads/box/callback');
      }

      // 클릭 이벤트 처리
      // ... 나머지 코드 ...
    } else {
      // 클릭 횟수를 모두 사용한 경우 알림 표시
      alert('하루에 3번만 랜덤선물 상자를 열 수 있습니다.');
    }
  };







  const handleButtonClick1 = () => {
      console.log("Button clicked! - coupang.com으로 이동");
  
      // Open Coupang in a new window/tab
      window.open('https://link.coupang.com/a/52BRO', '_blank');
  
      const currentURL = window.location.href;
  
      axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/coupang_click', {
        user_id: tokenUserID,
        click_page: currentURL,
        click_ads : "https://link.coupang.com/a/52BRO"
      });
        console.log("Button clicked! - /ads/box/success 이동");
        const randomValue = Math.floor(Math.random() * 100);

  
        if (randomValue < 30) {
          console.log("Button clicked! - /ads/box/success 이동");
        
            // API 호출을 한 번만 실행
              const requestBody = {
                user_id: tokenUserID,
                user_no: tokenUserNo,
                count: countdown,
              };
        
              // Make the API call using fetch
              fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/ads/box/random', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
              })
                .then((response) => response.json())
                .then((data) => {
                  const { message, drawnCards } = data;
                  console.log(`Message: ${message}`);
                  console.log(`Drawn Cards: ${drawnCards}`);
                  setDrawnCards(drawnCards); // Save the number of drawn cards to state
                  navigate(`/ads/box/success?drawnCards=${drawnCards}`);
                })
                .catch((error) => {
                  console.error('Error:', error);
                  alert('오류가 발생했어요, 나중에 다시 시도해주세요')
                  navigate('/jokercard')
                  
                });
    
              } else {
          console.log("Button clicked! - /ads/box/fail 이동");
          navigate(`/ads/box/fail`);
      }
    }



    const handleButtonClick2 = () => {
        console.log("Button clicked! - Teenstuido으로 이동");
    
        // Open Coupang in a new window/tab
        window.open('https://teenstudio.app/teen_store?utm_source=hoxy&utm_medium=hoxy_lucky_ads&utm_campaign=hoxy_free_ads_event_campaign&utm_content=v1', '_blank');
    
       const currentURL = window.location.href;
    
          axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/ads_click', {
          user_id: tokenUserID,
          click_page: currentURL,
          click_ads : "https://teenstudio.app/teen_store",
          ads_source : "hoxy",
          ads_medium : "hoxy_lucky_ads",
          ads_campaign : "hoxy_free_ads_event_campaign",
          ads_content : "v1"
        });

          console.log("Button clicked! - /ads/box/success 이동");
          const randomValue = Math.floor(Math.random() * 100);
    
        
          if (randomValue < 30) {
            console.log("Button clicked! - /ads/box/success 이동");
          
              // API 호출을 한 번만 실행
                const requestBody = {
                  user_id: tokenUserID,
                  user_no: tokenUserNo,
                  count: countdown,
                };
          
                // Make the API call using fetch
                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/ads/box/random', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestBody),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    const { message, drawnCards } = data;
                    console.log(`Message: ${message}`);
                    console.log(`Drawn Cards: ${drawnCards}`);
                    setDrawnCards(drawnCards); // Save the number of drawn cards to state
                    navigate(`/ads/box/success?drawnCards=${drawnCards}`);
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                    alert('오류가 발생했어요, 나중에 다시 시도해주세요')
                    navigate('/jokercard')
                    
                  });
      
                } else {
            console.log("Button clicked! - /ads/box/fail 이동");
            navigate(`/ads/box/fail`);
        }
      }
  

    

  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className="main_content">

        <br></br>


        {/* Button to change class */}
        {!buttonClicked && (
          <div>
            <MiddleCompoent img={cupid} Text1="매일 랜덤선물을 3번까지 열고" Text2="내 조커카드 늘릴 수 있어요" />
          </div>
        )}

        {/* Transitioning content */}
        <CSSTransition
          key={reset} // Use key to force re-render and reset the transition
          in={buttonClicked}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className={randomClass}>



            {/* 케이스1 : 쿠팡 이동하고 조커카드 늘리기 */}
            {randomClass === 'Open_1' && 
              <div>
                {!buttonClicked2 && (
                  
                      <Header Text="쿠팡 구경하고" Text2="우리학교 조커카드 늘리기"/>

                )}

                {!buttonClicked2 && (

                <div className='flexbox'>
                <div className='coupang_middle'>
                <div>
                <iframe src="https://ads-partners.coupang.com/widgets.html?id=693532&template=carousel&trackingCode=AF2066453&subId=&width=350&height=80&tsource=" width="350" height="70" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
                </div>
                <div style={{display:'flex', marginTop:'20px', }}>
                <div style={{marginLeft:'1px', marginRight:'25px'}}>
                <iframe src="https://ads-partners.coupang.com/widgets.html?id=693528&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
                </div>

                <div>
                <iframe src="https://ads-partners.coupang.com/widgets.html?id=693527&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
                </div>

                <div style={{ marginLeft:'25px'}}>
                <iframe src="https://ads-partners.coupang.com/widgets.html?id=693567&template=carousel&trackingCode=AF2066453&subId=&width=100&height=100&tsource=" width="100" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>          
                </div>
                </div>
                <br></br>
                <div className='open_coupang_font'>"이 페이지는 쿠팡 파트너스 활동의 일환으로 <br></br> 이에 따른 일정액의 수수료를 제공받아요</div>
                </div>

                </div>
                )}

                <div>
                    {!buttonClicked2 && (
                    <div className='open_coupang_font_blur'>원치 않을 경우 뒤로가기를 눌러주세요</div>
                    )}

                     {/* Button to change class */}
                     {!buttonClicked2 && (
                      <div onClick={handleButtonClick1} >
                         <PrimaryButton Text="쿠팡 다녀오고 조커카드 늘리기" />
                      </div>
                     )}
                </div>
              </div>
            }

           
            {/* 케이스2 : 틴스튜디오 이동하고 조커카드 늘리기 */}
            {randomClass === 'Open_2' && 
              <div>
                  {!buttonClicked2 && (
                 
                    <Header Text="틴스튜디오 구경하고" Text2="우리학교 조커카드 늘리기"/>
    
                  )}

                  {!buttonClicked2 && (
                  <MiddleCompoent img={teen_studio} Text1='틴스튜디오에서' Text2='10대에게 핫한 신상 상품을 구경해보세요'/>
                  )}

                  <div>
                       
                      {!buttonClicked2 && (
                      <div className='open_coupang_font_blur'>원치 않을 경우 뒤로가기를 눌러주세요</div>
                      )}

                      {!buttonClicked2 && (
                        <div onClick={handleButtonClick2} >
                         <PrimaryButton  Text="틴스튜디오 다녀오고 조커카드 늘리기" />
                        </div>
                     )}

                  </div>
                </div>  
              }         



          {/* 케이스3 : 애드몹 이동하고 조커카드 늘리기 */}
            {randomClass === 'Open_3' && 
               <Link to='/ads/box/callback'>
               </Link>
              }


          </div>
        </CSSTransition>

        {/* Button to change class */}
        {!buttonClicked && (
          <div onClick={handleClick2}>
          <PrimaryButton Text={`랜덤선물 상자 열기 (오늘 ${countdown}번 남았어요)`} />
          </div>
        )}
      </div>
    </div>
  );
}

export default OpenJoker;
