import React from "react";

export default function MessageNumBox({ num }) {
  const remainingSteps = 6 - num; // 남은 단계 계산

  return (
    <div className="message_num_box">
      <div className="flexbox">
        <div>💌 완성까지 {remainingSteps}단계 남았어요</div>
      </div>
    </div>
  );
}
