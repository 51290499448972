import React from 'react';
import PropTypes from 'prop-types';
import './Head.css';
import '../component/Hamburger';
import Ham from '../component/Hamburger';
import Sea from '../component/SearchIcon';
import { Link } from 'react-router-dom';
import Arr from '../component/Arrow';
import IconX from '../component/X-mono';
import IconXB from '../component/X-mono-black';
import Logo1 from '../component/Logo1';
import Logo2 from '../component/Logo2';
import Logo3 from '../component/Logo3';
import HoxyWordIcon from '../component/Hoxyworldicon';
import { useNavigate } from 'react-router-dom';

export const Head_old = ({ Label, Label2, Label3, Label4, className }) => {
  const HeadClassName = `${className}`;
  const VisitprofileID = localStorage.getItem('VisitprofileID');

  const navigate = useNavigate();

  const handleGoBack = () => {
    window.history.back();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleHome = () => {
    navigate(`/${VisitprofileID}?explore=yes`);
  };


  return (
    <div className={HeadClassName + 'box'}>
      <div className={HeadClassName + 'Navigator-1'}>
        <Link to="/menu">
          <Ham></Ham>
        </Link>
      </div>
      <div className={HeadClassName + 'Navigator-2'}>
        <button className="invisible-button" onClick={handleGoBack}>
          <Arr></Arr>
        </button>
      </div>
      <div className={HeadClassName + 'margin1'}></div>
      <div className={HeadClassName + 'Icon-1'}>
        <button className="invisible-button" onClick={handleRefresh}>
          <Logo1></Logo1>
        </button>
      </div>
      <div className={HeadClassName + 'Icon-2'}>
        <button className="invisible-button" onClick={handleGoBack}>
          <Logo2></Logo2>
        </button>
      </div>
      <div className={HeadClassName + 'Icon-3'}>
      <button className="invisible-button" onClick={handleHome}>
          <Logo3></Logo3>
        </button>
      </div>
      <div className={HeadClassName + 'margin2'}></div>
      <div className={HeadClassName + 'Navigator-3'}>
        <Link to="../search">
          <Sea></Sea>
        </Link>
      </div>
      <div className={HeadClassName + 'Navigator-4 imo-hoxyworld-container'}>
        <div className="hoxyworld_icon">
          <HoxyWordIcon></HoxyWordIcon>
        </div>
      </div>
      <div className={HeadClassName + 'Navigator-5'}>
        <button className="invisible-button" onClick={handleHome}>
          <IconXB></IconXB>
        </button>
      </div>
    </div>
  );
};

Head_old.propTypes = {
  Label1: PropTypes.string.isRequired,
  Label2: PropTypes.string.isRequired,
  Label3: PropTypes.string.isRequired,
  Label4: PropTypes.string.isRequired,
  className: PropTypes.string,
};