import React from 'react';
import { createRoot } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

// URL에서 'app' 매개변수 가져오기
function getQueryParam(param) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(param);
}

const appQueryParam = getQueryParam('app');

if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

// 로컬 스토리지에 'app' 값을 저장
if (appQueryParam === 'yes') {
  localStorage.setItem('app', 'true');
} 

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
