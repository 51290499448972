import React, { useState, useEffect } from 'react';
import Footer from '../component/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../NewComponent/header';
import joker_mode from '../NewComponent/assets/joker_mode.webp'
import hoxy_na from '../NewComponent/assets/hoxy-na.svg'
import { motion, useAnimation  } from 'framer-motion';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';

function JokerDiscovery() {

  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");
  const location = useLocation();
  const requestDbNumber = new URLSearchParams(location.search).get('Mymessage');
    const [Mid, setMid] = useState({});

  const navigate = useNavigate();

  const handlePayment = () => {
    const app = localStorage.getItem('app');
    if(app == 'true') {

    navigate('/payment/callback');
    }
    else {
      alert('HOXY앱에서만 조커모드를 사용할 수 있어요')
    }
  }

  const handlePayment_hoxyNa = () => {
    
    const app = localStorage.getItem('app');

    if(app == 'true') {
    navigate('/payment/hoxyna/callback');
      }
      else {
        navigate('/payment/hoxyna/pg');
      }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);
    
        const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        setUserData({
          user_no: data.user_no,
          user_name: data.user_name,
          user_email: data.user_email,
          user_phonenumber: data.user_phonenumber,
          user_kakao_year: data.user_kakao_year,
          user_gender: data.user_gender,
        });
    
        setMid(`hoxypayment_na_${tokenUserNo}_${new Date().getTime()}`);
    
        // 나머지 코드
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
    
    fetchData();
  }, []);
  



  
  



  const buttonStyle = {
    display: 'flex',
    width: '260px',
    padding: '15px 120px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    background: '#FF5C00',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginTop : '20px',

  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap',
  };



  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
      <br></br>
   <Header />




   <div style={{ justifyContent: 'center', display: 'flex', marginTop:'30px' }}>
      <div style={{ width: '320px', height: '350px', borderRadius: '20px', backgroundColor: '#FBF5F5' }}>
        <p style={{
          color: 'var(--Hoxy-Orange, #FF5C00)',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '25px',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: 'normal',
          letterSpacing: '-2px',
          marginTop: '40px'
        }}>
          무제한 조커카드를 쓰는 <br></br>
          <span style={{ textDecoration: 'underline' }}>조커가 되고 싶다면?</span> 💫
        </p>

        <motion.img
          src={joker_mode}
          width='120px'
          initial={{ scale: 1 }} // Initial scale
          animate={{ scale: [1, 1.1, 1] }} // Animate to scale 1, 1.1, and back to 1
          transition={{ repeat: Infinity, duration: 0.5 }} // Repeat infinitely with a 1.5-second duration
        />

        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <motion.button
            style={buttonStyle}
            onClick={handlePayment}
          >
            <span style={buttonTextStyle}>무제한 조커모드 시작하기</span>
          </motion.button>
        </div>
      </div>
    </div>

<div style={{marginTop:'40px'}} onClick={handlePayment_hoxyNa}>
<img src={hoxy_na} ></img>
</div>


<div style={{ justifyContent: 'center', display: 'flex' }}>
<div style={{marginTop:'70px', marginLeft:'15px', textAlign:'left', color:'#8B95A1', marginRight:'15px'}}>
<p style={{fontSize:'13px'}}><b>- 결제 전, 아래 사항을 꼭 확인해주세요</b></p>
<p style={{fontSize:'11px'}}>- 결제 완료 후 정상적으로 조커카드를 받지 못했다면  환불을 고객센터로 요청할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 오류가 발생했거나, 서비스가 제공되지 못할 때는 환불을 고객센터로 요청할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 모든 상품은 디지털 컨텐츠로서 조커카드 사용 시 환불을 진행할 수 없어요.</p>
<p style={{fontSize:'11px'}}>- 웹에서는 온라인 결제(PG)를 통해 결제되고, 앱에서는 인앱 결제(구글/애플)를 통해서 결제돼요</p>
<p style={{fontSize:'11px'}}>- 앱에서 진행한 결제는 구글플레이스토어, 애플 앱스토어를 통해서 환불을 직접 진행해야해요.</p>
<p style={{fontSize:'11px'}}>- 무제한 조커모드는 자동으로 7일(주)마다 매주 결제한 요일에 자동으로 갱신되는 구독형 상품이에요 </p>
<p style={{fontSize:'11px'}}>- 자세한 사항은 가입시 동의하셨던, 서비스 이용약관을 참고해주세요</p>
</div>
</div>

        
        <Footer></Footer>
        </div>
        </div>
        </div>
  );
}

export default JokerDiscovery;
