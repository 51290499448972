import '../stories/OneBox.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import HoxyWordIcon from '../component/Hoxyworldicon';
import Logo1 from '../component/Logo2';

function Menu() {
  const navigate = useNavigate();


  const token = localStorage.getItem('token');


  function go_myProfile() {
    if (token) {
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.user_id;
      navigate(`/${userId}?explore=yes`);
    } else {
      alert('로그인이 필요합니다');
      navigate('/hoxypass');
  }
}


      
        return (
        <div class="main" style={{backgroundColor: '#FF5C00'}}>
          <div class="main_content" style={{backgroundColor: '#FF5C00',height:'100vh', overflow:'hidden', touchAction:'none',}}>
            <section class='section'>
            <div class='masthead-image' id='master-container'>
            <div className='header'>
                <a href="javascript:window.history.back();">
                  <div  className='hoxyoption_icon'>
                    <Logo1></Logo1>
                  </div>
                </a>
                <div className="hoxyworld_icon">
                <HoxyWordIcon color="#FFFFFF" />
                </div>
              </div>
                    <div>
                    <h1 id='master' style={{position:  'relative',marginTop: '0px',width: '200px' , height: 'auto',display:'flex',flexDirection:'column'}}>
                        <button  class='master-container-menu'onmouseover="changeText(this)" onmouseout="resetText(this)" onClick={go_myProfile}>
                          <div class='cta-link' href='1' style={{textDecoration:'none'}}>내 페이지</div>
                        </button>
                    <Link to="../ourschool">
                        <button class='master-container-menu' onmouseover="changeText(this)" onmouseout="resetText(this)">
                          <div class='cta-link' href='2' style={{textDecoration:'none'}}>우리학교</div>
                        </button>
                      </Link>

                      <Link to="../share">
                        <button class='master-container-menu' onmouseover="changeText(this)" onmouseout="resetText(this)">
                          <div class='cta-link' href='2' style={{textDecoration:'none'}}>프로필 공유하기</div>
                        </button>
                      </Link>

                   {/*}   <Link to="../gift">
                        <button class='master-container-menu' onmouseover="changeText(this)" onmouseout="resetText(this)">
                          <div class='cta-link' href='2' style={{textDecoration:'none'}}>내가 받은 선물</div>
                        </button>
        </Link> */}

                      <Link to="../daejeon/landing">
                        <button class='master-container-menu' onmouseover="changeText(this)" onmouseout="resetText(this)">
                          <div class='cta-link' href='2' style={{textDecoration:'none'}}>대신 전해드립니다</div>
                        </button>
                      </Link>
                   
                      <Link to="../cs">
                      <button class='master-container-menu' onmouseover="changeText(this)" onmouseout="resetText(this)">
                          <div class='cta-link' href='7' style={{textDecoration:'none'}}>HOXY 고객센터</div>
                      </button>
                      </Link>
                    </h1>
                    </div>
            </div>
          </section>
        </div>
        </div>
        );
      };

export default Menu;
