import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { images } from "./image-data";

// style
const Wrapper = styled(motion.div)`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
`;

const SlideWrap = styled.div`
  width: 250px;
  height: 250px;
`;

const Box = styled(motion.img)`
  position: absolute;
  width: 250px;
  height: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
`;
// animation
const boxVariants = {
    entry: (back) => ({
      x: back ? -500 : 500,
      opacity: 0,
      scale: 0
    }),
    center: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: { duration: 0.5 }
    },
    exit: (back) => ({
      x: back ? 500 : -500,
      opacity: 0,
      scale: 0,
      transition: { duration: 0.5 }
    })
  };
  

// render
function Test() {
  const [visible, setVisible] = useState(0);
  const [back, setBack] = useState(false);
  //박스마다 이미지 적용
  const imageIndex = wrap(0, images.length, visible);

  const nextPlease = () => {
    setBack(false);
    setVisible((prev) =>
      prev === images.length - 1 ? images.length - 1 : prev + 1
    );
  };
  const prevPlease = () => {
    setBack(true);
    setVisible((prev) => (prev === 0 ? 0 : prev - 1));
  };

  return (
    <Wrapper>
      <SlideWrap>
        <AnimatePresence custom={back}>
          <Box
            custom={back}
            variants={boxVariants}
            src={images[imageIndex]}
            initial="entry"
            animate="center"
            exit="exit"
            key={visible}
          />
        </AnimatePresence>
      </SlideWrap>
      <button onClick={prevPlease}>prev</button>
      <button onClick={nextPlease}>next</button>
    </Wrapper>
  );
}

export default Test;
