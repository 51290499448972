import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

export default function SkeletonLoader() {
  const [viewBoxWidth, setViewBoxWidth] = useState(390); // 초기 값 설정

  useEffect(() => {
    // 화면 크기가 변경될 때마다 호출되는 함수
    const handleResize = () => {
      // 원하는 로직에 따라 viewBoxWidth 값을 계산
      const newViewBoxWidth = window.innerWidth > 600 ? 600 : 390;
      setViewBoxWidth(newViewBoxWidth);
    };

    // 처음 컴포넌트가 마운트될 때 resize 이벤트에 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열은 컴포넌트가 처음 마운트될 때만 실행하도록 합니다.

  // viewBoxWidth에 대한 비율 계산
  const viewBoxRatio = viewBoxWidth / 390;

  return (
    <ContentLoader viewBox={`0 0 ${viewBoxWidth} 860`}>

      <rect x={145 * viewBoxRatio} y={99 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={100 * viewBoxRatio} height={100 * viewBoxRatio} />
      <rect x={84 * viewBoxRatio} y={222 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={220 * viewBoxRatio} height={50 * viewBoxRatio} />
    
      <rect x={40 * viewBoxRatio} y={305 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={150 * viewBoxRatio} height={100 * viewBoxRatio} />
      <rect x={200 * viewBoxRatio} y={305 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={150 * viewBoxRatio} height={100 * viewBoxRatio} />
     
      <rect x={40 * viewBoxRatio} y={418 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={310 * viewBoxRatio} height={50 * viewBoxRatio} />
      <rect x={40 * viewBoxRatio} y={478 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={310 * viewBoxRatio} height={50 * viewBoxRatio} />
      <rect x={40 * viewBoxRatio} y={538 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={310 * viewBoxRatio} height={50 * viewBoxRatio} />
      <rect x={40 * viewBoxRatio} y={598 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={310 * viewBoxRatio} height={50 * viewBoxRatio} />
     
      <rect x={143 * viewBoxRatio} y={712 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={103 * viewBoxRatio} height={16 * viewBoxRatio} />
      <rect x={112 * viewBoxRatio} y={734 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={166 * viewBoxRatio} height={16 * viewBoxRatio} />

     </ContentLoader>
  );
}
