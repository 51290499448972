import React, { useState, useEffect } from "react";
import { SelectBox } from "../../stories/SelectBox";
import { Link } from "react-router-dom";
import axios from "axios";
import { SearchBar } from "../../stories/SearchBar";

export default function Content() {
  const [searchText, setSearchText] = useState("");
  const [schools, setSchools] = useState([]);
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const delay = 500; // Debounce delay time (ms)
    const timer = setTimeout(handleSearch, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  useEffect(() => {
    const updatedBoxes = schools.map((school, index) => (
      <Link
        to="/Almost"
        key={index}
        onClick={() => {
          console.log("Selected school:", school.SCHUL_NM);
          localStorage.setItem("user_school", school.SCHUL_NM);
        }}
      >
        <div style={{ marginTop: "10px" }}></div>
        <SelectBox
          Label4="#ffffff"
          className="SchoolBox"
          Label1={
            <div>
              {school.SCHUL_NM.length > 1 ? (
                <>
                  {school.SCHUL_NM} 
                  <br />
                </>
              ) : (
                school.SCHUL_NM
              )}
               ({school.LCTN_SC_NM})
            </div>
          }
        />
      </Link>
    ));

    setBoxes(updatedBoxes);
  }, [schools]);

  const handleBoxClick = (schoolName) => {
    console.log(schoolName);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://open.neis.go.kr/hub/schoolInfo`,
        {
          params: {
            KEY: "b18268eeb3c94d5d9d053605360ce7b6", // Replace with a valid API key
            Type: "json",
            pIndex: 1,
            pSize: 10,
            SCHUL_NM: searchText,
          },
        }
      );

      const foundSchools =
        response.data &&
        response.data.schoolInfo &&
        response.data.schoolInfo[1].row
          ? response.data.schoolInfo[1].row
          : [];
      setSchools(foundSchools);
    } catch (error) {
      console.error("An error occurred while searching for schools:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SearchBar
          Value={searchText}
          onChange={handleSearchChange}
          label="내가 다니는 학교 이름은?"
        />
      </div>
      <div>{boxes}</div>
    </div>
  );
}
