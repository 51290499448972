import React from 'react';
import PropTypes from 'prop-types';
import './SelectBox.css';

export const SelectBox = ({ Label1, Label2, ImgUrl, Label4, className }) => {
  const SelectBoxClassName = `${className}`;


  return (
    <div style={{ display: 'flex', flexDirection: 'column', cursor: "pointer" }}>
      <div className={SelectBoxClassName + ' block'} style={{ position: 'relative', display: 'flex', flexDirection: 'column', cursor: "pointer" }}>
        <div className={SelectBoxClassName}>
          <div className={SelectBoxClassName + 'img'}>
            <img src={ImgUrl} width={'22px'} height={'22px'} />
          </div>
          <div className={SelectBoxClassName + 'Who'}>{Label1}</div>
          <div style={{display:'flex', zIndex:'90'}}>
          <div className={SelectBoxClassName + 'Content'}>{Label2}</div>
          <div className={SelectBoxClassName + 'navi'}>
            <button
              style={{ width: '40px', background: 'transparent', border: 'none', boxShadow: 'none', outline: 'none', cursor: "pointer" }}
             
            >
              <svg width='34' height='36' viewBox='0 0 34 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M12.5426 29.4864C12.0617 29.4864 11.5808 29.2951 11.2045 28.9336C10.8534 28.5749 10.6562 28.0895 10.6562 27.5834C10.6562 27.0773 10.8534 26.5918 11.2045 26.2332L19.2957 18.0045L11.2045 9.79698C10.8534 9.43835 10.6562 8.95287 10.6562 8.44679C10.6562 7.94071 10.8534 7.45524 11.2045 7.0966C11.5571 6.73953 12.0345 6.53906 12.5321 6.53906C13.0297 6.53906 13.5071 6.73953 13.8597 7.0966L23.2681 16.6649C23.6192 17.0235 23.8163 17.509 23.8163 18.0151C23.8163 18.5212 23.6192 19.0066 23.2681 19.3653L13.8597 28.9336C13.5043 29.2951 13.0234 29.4864 12.5426 29.4864Z'
                  fill={Label4}
                />
              </svg>
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectBox.propTypes = {
  Label1: PropTypes.string.isRequired,
  Label2: PropTypes.string.isRequired,
  ImgUrl: PropTypes.string.isRequired,
  Label4: PropTypes.string.isRequired,
  className: PropTypes.string,
};
