import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Head } from '../stories/Head';
import { Link, useNavigate } from 'react-router-dom';
import SadEmojiimg_URL from '../assets/img/sad-emoji.webp';
import school2 from '../assets/img/school2.webp';
import axios from 'axios'; 
import KaKaoLoginbtn  from '../component/KaKaoLoginbtn';
import { LargeButton } from '../stories/LargeButton';

function Welcome() {
  const [tutorialStep, setTutorialStep] = useState(1);
  const [showStep, setShowStep] = useState(true);
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  

  useEffect(() => {
    const r1 = localStorage.getItem('r1');
    const r2 = localStorage.getItem('r2');
    const r3 = localStorage.getItem('r3');

    if (!r1 || !r2 || !r3) {
      fetchLocationData();
    }
  }, []);

  const fetchLocationData = async () => {

    // 힌트 무료화를 위한 Welcome Page 타겟팅 중단
  //  try {
    //  const response = await axios.post(
      //  'https://backend-hoxy-one-api.hoxy.one:4004/api/logging/welcomepage/targeting'
     // );
     // const locationData = response.data;

    //  localStorage.setItem('r1', locationData.user_r1);
    //  localStorage.setItem('r2', locationData.user_r2);
    //  localStorage.setItem('r3', locationData.user_r3);

     // window.location.reload();
   // } catch (error) {
    //  console.error('Error occurred while fetching location data:', error);
   // }
  };

  useEffect(() => {
    const changeStep = () => {
      setShowStep(false); 
      setTimeout(() => {
        setTutorialStep((prevStep) => (prevStep === 4 ? 1 : prevStep + 1));
        setShowStep(true); 
        setCounter((prevCounter) => prevCounter + 1); 
      }, 500);
    };

    const timer = setInterval(changeStep, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);



  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <div>
          <br />
          <div style={{ marginTop: '10px' }}>
            <Head className="MainHead" />
          </div>

          
          <CSSTransition
            in={showStep && tutorialStep === 1}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)} 
          >

        <div className='welcome_box'>
        <div>
        <div>
          <div style={{width:'300px', height:'60px', display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'10px', marginLeft:'-10px'}}>
            <h2 className='subtitle'>혹시 우리 학교에는 몇 명이?</h2> <br></br>
            <h2 className='title'><b style={{color:'#FF5C00'}}>나랑 친해지고 싶은 사람</b>을 <br></br>
        지금, 알아볼 수 있어요</h2>
            </div>
          </div>
          <div style={{width:'1px', height:'60px', display:'flex'}}>
            <h2 className='title' style={{display:'none' }}></h2>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'30px'}}>
          <img
                      src="https://resource.hoxy.one/img_component/welcome_c_1-1.webp"
                      className="welcome_image"
                      alt="Welcome Image"
                      width={'320px'}
                    />      
        </div>
        <br></br>

        </div>
        </div>
  </CSSTransition>

          
          <CSSTransition
            in={showStep && tutorialStep === 2}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)}
          >
          <div className='welcome_box'>
            <div>
            <div>
            <div style={{width:'325px', height:'70px', display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'10px', marginLeft:'-5px'}}>
                <h2 className='subtitle'>HOXY에서 내 프로필 만들고</h2> <br></br>
                <h2 className='title'>Instagram Bio에 공유하면<br></br><b style={{color:'#FF5C00'}}>나랑 친해지고 싶은 친구가 와요!</b></h2>
                </div>
              </div>
              <div style={{width:'1px', height:'70px', display:'flex'}}>
                <h2 className='title' style={{display:'none' }}></h2>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px'}}>
            <img src="https://resource.hoxy.one/img_component/welcome_c_3.webp"width={'140px'} height={'140px'}></img>
            <br></br>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'15px'}}>

            <div style={{ width: '200px', height: '40px', backgroundColor: '#FAFAFA', lineHeight: '40px', borderRadius: '60px', color: '#1D2468', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontSize: '13px', fontWeight: '800', letterSpacing: '-1.04px', marginRight: '5px' }}>
            {localStorage.getItem('r1') ? `${localStorage.getItem('r1')} ${localStorage.getItem('r2')}` : '내 근처'} 학교들</p>
              <img  src={school2} width={'20px'} height={'20px'} alt="School Icon" />
            </div>


            </div>

            <div style={{justifyContent: 'center', alignItems: 'center', marginTop:'15px'}}>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>   <span style={{ color: '#FF5C00' }}>
                    이미, <b>{localStorage.getItem('r1') ? `${localStorage.getItem('r1')} ${localStorage.getItem('r2')}` : '내 근처'}</b> 학교들도
                  </span><br />HOXY에서 친해지고 있어요</p>
            </div>

            </div>
            </div> 
          </CSSTransition>
     
          <CSSTransition
            in={showStep && tutorialStep === 3}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)}
          >
        <div className='welcome_box'>
        <div>
        <div>
          <div style={{width:'325px', height:'70px', display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'10px', marginLeft:'-8px'}}>
            <h2 className='subtitle'>친해지고 싶은 사람이, 내 프로필에 도착하면</h2> <br></br>
            <h2 className='title'>알림톡으로 알려드릴게요, 그리고<br></br><b style={{color:'#FF5C00'}}>우리 학교 친구의 모습도 한눈에 </b><br></br>볼 수 있어요</h2>
            </div>
          </div>
          <div style={{width:'1px', height:'70px', display:'flex'}}>
            <h2 className='title' style={{display:'none' }}></h2>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px'}}>
        <img
                    src="https://resource.hoxy.one/img_component/welcome_c_7.webp"
                    className="welcome_image"
                    alt="Welcome Image"
                    width={'320px'}

                  />        
        </div>

        </div>
        </div>          
        </CSSTransition>

          <CSSTransition
            in={showStep && tutorialStep === 4}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)}
          >
        <div className='welcome_box'>
        <div>
        <div>
          <div style={{width:'300px', height:'70px', display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'20px', marginLeft:'-18px'}}>
            <h2 className='subtitle'>이제, 대기없이 HOXY를 쓸 수 있어요</h2> <br></br>
            <h2 className='title'>지금 10초만에, 가입하고<br></br><b style={{color:'#FF5C00'}}>한정판 혜택들을 선점해보세요</b></h2>
            </div>
          </div>
          <div style={{width:'1px', height:'70px', display:'flex'}}>
            <h2 className='title' style={{display:'none' }}></h2>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'40px'}}>
        <img
                    src="https://resource.hoxy.one/img_component/welcome_c_6.webp"width={'140px'} height={'140px'}
                    className="welcome_image"
                    alt="Welcome Image"
                  />        
        </div>

        </div>
        </div>          
        </CSSTransition>




          
          <div  className="welcome_ButtonBox">

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:'0px'}}>
            <p>
              오직 지금만, 나의 가입 순서를 보장받아요
              <br />
              <b>나중에는 초대장이 없으면 회원가입이 힘들어요</b>
            </p>
            <img src={SadEmojiimg_URL} width={20} height={20} alt="Sad Emoji" style={{marginTop:'20px', marginLeft : '5px'}} />
          </div>

          <Link to='/login'><LargeButton className="primary"  label2="전화번호로 HOXY 시작하기"></LargeButton></Link>  <br></br>
          <div style={{ display: 'flex', justifyContent: 'center' }}>


        </div>
        </div>




        
        </div>
      </div>
    </div>
  );
}

export default Welcome;
