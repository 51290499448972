import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tittle } from '../stories/Tittle';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import vote_img from '../assets/img/vote-ballot 2.webp'

function Request() {
  const navigate = useNavigate();
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [relationship, setRelationship] = useState('');
  const [keyword, setKeyword] = useState('');
  const [want, setWant] = useState('');
  const [relationshipData, setRelationshipData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const [wantData, setWantData] = useState([]);
  const [shuffledRelationshipData, setShuffledRelationshipData] = useState([]);
  const [shuffledKeywordData, setShuffledKeywordData] = useState([]);
  const [shuffledWantData, setShuffledWantData] = useState([]);
  const [tokenUserID, setTokenUserID] = useState('');
  const [userData, setUserData] = useState({});
  const [hoxyGender, setHoxyGender] = useState(''); 
  const [hoxyAge, setHoxyAge] = useState(''); 
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState([]);

  const [Data1, setData1] = useState('');
  const [Data2, setData2] = useState('');
  const [Data3, setData3] = useState('');
  const [Data4, setData4] = useState('');
  const [Data5, setData5] = useState('');


  const [shuffledSelectData1, setShuffledSelectData1] = useState([]);
  const [shuffledSelectData2, setShuffledSelectData2] = useState([]);
  const [shuffledSelectData3, setShuffledSelectData3] = useState([]);
  const [shuffledSelectData4, setShuffledSelectData4] = useState([]);
  const [shuffledSelectData5, setShuffledSelectData5] = useState([]);

  const [SelectData1, setSelectData1] = useState('');
  const [SelectData2, setSelectData2] = useState('');
  const [SelectData3, setSelectData3] = useState('');
  const [SelectData4, setSelectData4] = useState('');
  const [SelectData5, setSelectData5] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          vote_name: '첫인상투표',
          vote_num: 1,
        };
  
        const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_question', postData);
        console.log(response.data); // 서버로부터 받은 응답 출력
        setData(response.data);
  
      } catch (error) {
        console.error('Error sending POST request:', error);
      }
    };
  
    fetchData();
  }, []);
  



  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);
        fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID }),
          })
          
          .then((response) => response.json())
          .then((data) => {
            setUserData({
              user_kakao_year: data.user_kakao_year,
              user_gender: data.user_gender,
            });

     

          })
          .catch((error) => {
            console.log('API Error:', error);
          });
      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
      console.log('로그인되어있지 않음');
      setTokenUserID('anonymous');
      handlePopupOpen();
    }
  }, []);

  useEffect(() => {
    if (userData && userData.user_kakao_year && userData.user_gender) {
      const today = new Date();
      const birthDate = new Date(userData.user_kakao_year, 1, 16);
      const hoxy_age =
        today.getFullYear() - birthDate.getFullYear() + 1;
      const hoxy_gender = userData.user_gender === 'female' ? '여성' : '남성';

      setAge(hoxy_age); 
      setGender(hoxy_gender); 
    }
  }, [userData]);

  const handleClickGender = (value) => {
    setGender(value);
  };

  const handleClickRelationship = (value) => {
    setRelationship(value);
  };

  const handleClickData1 = (value) => {
    setData1(value);
  };

  const handleClickData2 = (value) => {
    setData2(value);
  };

  const handleClickData3 = (value) => {
    setData3(value);
  };

  const handleClickData4 = (value) => {
    setData4(value);
  };

  const handleClickData5 = (value) => {
    setData5(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!gender || !age || !relationship || !Data1 || !Data2 || !Data3 || !Data4 || !Data5) {
      alert('아직 채워지지 않았거나, 입력할 수 없는 문자가 있어요');
      return;
    }

    const recive_user_id = localStorage.getItem('VisitprofileID');

    try {
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote', {
        vote_user_id: tokenUserID,
        vote_name: "첫인상투표",
        vote_num: 1,
        vote_gender: gender,
        vote_age: age,
        vote_relationship: relationship,
        vote_a1: Data1,
        vote_a2: Data2,
        vote_a3: Data3,
        vote_a4: Data4,
        vote_a5: Data5,
        recive_user_id: recive_user_id,
      });



      console.log(response.data);

      alert('성공적으로 요청이 전송되었어요!');

      navigate(`/${recive_user_id}?explore=yes`);
    } catch (error) {
      console.error(error);
      alert('내부 서버의 오류가 발생했어요. 나중에 다시 시도해보세요!');
    }
  };

  const recive_user_name = localStorage.getItem('VisitprofileName');

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleShuffleRelationship = () => {
    const shuffledRelationships = shuffleArray(relationshipData);
    setShuffledRelationshipData(shuffledRelationships.slice(0, 4));
  };
  
  const handleShuffleSet1 = () => {
    if (Data1.length < 4) {
      alert('4개의 선택지 중에서, 가장 가까운 것을 골라보세요!')
      return;
    }
    const shuffledSelectData1 = shuffleArray(Data1);
    setShuffledSelectData1(shuffledSelectData1.slice(0, 4));
  };
  
  
  const handleShuffleSet2 = () => {
    if (Data2.length < 4) {
      alert('4개의 선택지 중에서, 가장 가까운 것을 골라보세요!');
      return;
    }
    const shuffledSelectData2 = shuffleArray(Data2);
    setShuffledSelectData2(shuffledSelectData2.slice(0, 4));
  };
  
  const handleShuffleSet3 = () => {
    if (Data3.length < 4) {
      alert('4개의 선택지 중에서, 가장 가까운 것을 골라보세요!');
      return;
    }
    const shuffledSelectData3 = shuffleArray(Data3);
    setShuffledSelectData3(shuffledSelectData3.slice(0, 4));
  };
  
  const handleShuffleSet4 = () => {
    if (Data4.length < 4) {
      alert('4개의 선택지 중에서, 가장 가까운 것을 골라보세요!');
      return;
    }
    const shuffledSelectData4 = shuffleArray(Data4);
    setShuffledSelectData4(shuffledSelectData4.slice(0, 4));
  };
  
  const handleShuffleSet5 = () => {

    const shuffledSelectData5 = shuffleArray(SelectData5);
    setShuffledSelectData5(shuffledSelectData5.slice(0, 4));
  };
  

  

  useEffect(() => {
    const fetchData = async () => {
      try {

        const postData1 = {
          vote_name: '첫인상투표',
          vote_num: 1,
          vote_question_number:1
        };

        const postData2 = {
          vote_name: '첫인상투표',
          vote_num: 1,
          vote_question_number:2
        };

        const postData3 = {
          vote_name: '첫인상투표',
          vote_num: 1,
          vote_question_number:3
        };

        const postData4 = {
          vote_name: '첫인상투표',
          vote_num: 1,
          vote_question_number:4
        };


        const postData5 = {
          vote_name: '첫인상투표',
          vote_num: 1,
          vote_question_number:5
        };

        const relationshipResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/relationship/');
        const SelectData1Response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_select', postData1);
        const SelectData2Response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_select', postData2);
        const SelectData3Response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_select', postData3);
        const SelectData4Response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_select', postData4);
        const SelectData5Response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_select', postData5);

        const shuffledRelationships = shuffleArray(relationshipResponse.data);
        setRelationshipData(shuffledRelationships);
        setShuffledRelationshipData(shuffledRelationships.filter((data) => data.select_setting === 'default').slice(0, 4));

        const shuffledSelectData1 = shuffleArray(SelectData1Response.data);
        setSelectData1(shuffledSelectData1);
        setShuffledSelectData1(shuffledSelectData1.filter((data) => data.vote_select_setting === 'default').slice(0, 4));
      

        const shuffledSelectData2 = shuffleArray(SelectData2Response.data);
        setSelectData2(shuffledSelectData2);
        setShuffledSelectData2(shuffledSelectData2.filter((data) => data.vote_select_setting === 'default').slice(0, 4));

        const shuffledSelectData3 = shuffleArray(SelectData3Response.data);
        setSelectData3(shuffledSelectData3);
        setShuffledSelectData3(shuffledSelectData3.filter((data) => data.vote_select_setting === 'default').slice(0, 4));

        const shuffledSelectData4 = shuffleArray(SelectData4Response.data);
        setSelectData4(shuffledSelectData4);
        setShuffledSelectData4(shuffledSelectData4.filter((data) => data.vote_select_setting === 'default').slice(0, 4));

        const shuffledSelectData5 = shuffleArray(SelectData5Response.data);
        setSelectData5(shuffledSelectData5);
        setShuffledSelectData5(shuffledSelectData5.filter((data) => data.vote_select_setting === 'default').slice(0, 4));
   
        
      } 
      
      catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };


  

  return(
    
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', justifyContent: 'center', textAlign: 'center' }}>
          <a href="javascript:window.history.back();">
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
          <br></br>

          <div style={{display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'40px', marginLeft:'25px', display:'flex', }}>

            <h2 className='title'>{recive_user_name}님 첫인상 투표하기</h2> <img src={vote_img} width={'32px'}height={'31px'} style={{marginTop:'-30px', marginLeft:'5px'}}></img>
            </div>
          </div>

  {userData && userData.user_kakao_year && userData.user_gender ? null : (
    <>
      <div style={{ marginTop: '35px' }}>
      <div style={{ marginRight: '40px' }}>
        <Tittle className="normalQ" label1="나의 성별은 어떻게 되나요?" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginRight: '135px' }}>
        <button
          className={`select_request ${gender === '남성' ? 'active' : ''}`}
          data-value="남성"
          onClick={() => handleClickGender('남성')}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>남성</div>
            <img
              className="request_img"
              src="https://resource.hoxy.one/request_3d_emoji/boy.png"
              alt="남성 이미지"
              effect="blur"
            />
          </div>
        </button>
        <button
          className={`select_request ${gender === '여성' ? 'active' : ''}`}
          data-value="여성"
          onClick={() => handleClickGender('여성')}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>여성</div>
            <img
              className="request_img"
              src="https://resource.hoxy.one/request_3d_emoji/girl.png"
              alt="여성 이미지"
              effect="blur"
            />
          </div>
        </button>
      </div>

      {/* 추가적인 내용 */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
        <div style={{ marginRight: '40px' }}>
          <Tittle className="normalQ" label1="나의 나이는 어떻게 되나요?" />
        </div>
        <div style={{ marginTop: '0px' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <input
            className="input_request"
            placeholder="나이를 입력해주세요"
            type="number"
            onChange={(e) => setAge(e.target.value)}
          />
        </div>
      </div>
      </div>
    </>
  )}



          {/* Display shuffled relationshipData */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
          <div style={{ marginRight: '20px' }}>
              <Tittle className="normalQ" label1={`나와 ${recive_user_name}님의 현재 관계는?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:"column"}}>
              <div>
                {shuffledRelationshipData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${relationship === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickRelationship(data.select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
              <button className="shuffle-button" onClick={handleShuffleRelationship}>
              &#8592; &#8594;               
              </button>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '20px' }}>
            <Tittle className="normalQ" label1={`${recive_user_name}님과 가장 ${data.length > 0 ? data[0].vote_question : ''}은?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
                {shuffledSelectData1.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${Data1 === data.vote_select_name ? 'active' : ''}`}
                    data-value={data.vote_select_name}
                    onClick={() => handleClickData1(data.vote_select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <div>{data.vote_select_name}</div>
                      <img
                        className="request_img"
                        src={data.vote_select_img}
                        alt={data.request_img}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
              </div>
             {/*  <button className="shuffle-button" onClick={handleShuffleSet1}>
              &#8592; &#8594;               
                </button> 주석사용에 따른 br ->> */} <br></br>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '-50px' }}>
            <Tittle className="normalQ" label1={`${recive_user_name}님과 가장 ${data.length > 0 ? data[1].vote_question : ''}은?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
              {shuffledSelectData2.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${Data2 === data.vote_select_name ? 'active' : ''}`}
                    data-value={data.vote_select_name}
                    onClick={() => handleClickData2(data.vote_select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <div>{data.vote_select_name}</div>
                      <img
                        className="request_img"
                        src={data.vote_select_img}
                        alt={data.request_img}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
            {/*  <button className="shuffle-button" onClick={handleShuffleSet2}>
              &#8592; &#8594;               
              </button> 주석사용에 따른 br ->> */} <br></br>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '-40px' }}>
            <Tittle className="normalQ" label1={`${recive_user_name}님과 가장 ${data.length > 0 ? data[2].vote_question : ''}은?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
              {shuffledSelectData3.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${Data3 === data.vote_select_name ? 'active' : ''}`}
                    data-value={data.vote_select_name}
                    onClick={() => handleClickData3(data.vote_select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <div>{data.vote_select_name}</div>
                      <img
                        className="request_img"
                        src={data.vote_select_img}
                        alt={data.request_img}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
             {/*  <button className="shuffle-button" onClick={handleShuffleSet3}>
              &#8592; &#8594;               
              </button> 주석사용에 따른 br ->> */} <br></br>
            </div>
          </div>



          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '-80px' }}>
            <Tittle className="normalQ" label1={`${recive_user_name}님과 가장 ${data.length > 0 ? data[3].vote_question : ''}은?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
              {shuffledSelectData4.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${Data4 === data.vote_select_name ? 'active' : ''}`}
                    data-value={data.vote_select_name}
                    onClick={() => handleClickData4(data.vote_select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <div>{data.vote_select_name}</div>
                      <img
                        className="request_img"
                        src={data.vote_select_img}
                        alt={data.request_img}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
            {/*   <button className="shuffle-button" onClick={handleShuffleSet4}>
              &#8592; &#8594;               
              </button> 주석사용에 따른 br ->> */} <br></br>
            </div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '-20px' }}>
            <Tittle className="normalQ" label1={`${recive_user_name}님과 가장 ${data.length > 0 ? data[4].vote_question : ''}은?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
              {shuffledSelectData5.map((data) => (
              <button
                key={data.idx}
                className={`select_request2 ${Data5 === data.vote_select_name ? 'active' : ''}`}
                data-value={data.vote_select_name}
                onClick={() => handleClickData5(data.vote_select_name)}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>{data.vote_select_name}</div>
                  <img
                    className="request_img"
                    src={data.vote_select_img}
                    alt={data.request_img}
                    effect="blur"
                  />
                </div>
              </button>
            ))}
          </div>
        <button className="shuffle-button" onClick={handleShuffleSet5}>
            &#8592; &#8594;               
              </button> 
            </div>
          </div>

          <div style={{ justifyContent: 'center', marginTop: '30px' }}>
            <a href="https://bit.ly/%ED%98%B9%EC%8B%9C%ED%94%BC%EB%93%9C%EB%B0%B1" target="_blank" rel="noopener noreferrer">
              <img src="https://resource.hoxy.one/img_component/request_survey.svg" width={'340px'} alt="설문 조사 이미지" />
            </a>
          </div>
          <div style={{ marginTop: '30px', marginBottom: '25px' }}>
            <button className="primary" onClick={handleSubmit}>
              요청 보내기
            </button>
            <br></br>
          </div>
        </div>

             {/* Render the popup when showPopup is true */}
      {showPopup && (
        <div className="popup-container" onClick={handlePopupClose}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2 >답장이 오면, 알려드릴까요? 🔔</h2>
             

            <p>가입 후 메시지를 보내도, 친해지고 싶은 사람은<br></br>내가 누군지 정확히는 알 수 없어요 💌</p>
            <div className="button-container">
              <button className="delete-button" onClick={handlePopupClose}>
                계속하기
              </button>
              <Link to="/welcome">
              <button className="retry-button" >
                가입하고, 알림받기
              </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}

export default Request;
