// Tab.js
import React from 'react';
import ArrowRight from '../../../NewComponent/assets/arrow-right.webp'
import { Link } from 'react-router-dom'
import soap_bubble from '../../../NewComponent/assets/soap-bubble 2.webp';

export const ISubscribed = () => {

  return (

    <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '27px' }}>
              <div style={{marginLeft:'10px', marginTop:'5px'}}><img style={{width:'40px'}} src={soap_bubble}></img></div>
              <div className='Unknown'>
              3학년 최형빈의 1일 1문답
              </div>
              <Link to='/VoteResult'><div style={{ marginTop: '12px', marginRight: '20px' }}><img style={{width:'19px'}} src={ArrowRight}></img></div></Link>
            </div>        
            
            <div style={{marginTop:'34px'}}></div>
            
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '27px' }}>
              <div style={{marginLeft:'10px', marginTop:'5px'}}><img style={{width:'40px'}}  src={soap_bubble}></img></div>
              <div className='Unknown'>
              3학년 최형빈의 1일 1문답
              </div>
              <Link to='/VoteResult'><div style={{ marginTop: '12px', marginRight: '20px' }}><img style={{width:'19px'}} src={ArrowRight}></img></div></Link>
            </div>        
            
            <div style={{marginTop:'34px'}}></div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '27px' }}>
              <div style={{marginLeft:'10px', marginTop:'5px'}}><img  style={{width:'40px'}} src={soap_bubble}></img></div>
              <div className='Unknown'>
              3학년 최형빈의 1일 1문답
              </div>
              <Link to='/VoteResult'><div style={{ marginTop: '12px', marginRight: '20px' }}><img style={{width:'19px'}} src={ArrowRight}></img></div></Link>
            </div>        
            
            <div style={{marginTop:'34px'}}></div>

    </div>
  );
};
