import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
  const [showBusinessInfo, setShowBusinessInfo] = useState(false);

  const toggleBusinessInfo = () => {
    setShowBusinessInfo(!showBusinessInfo);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="team-hoxy">
          <b>© 2023 TEAM HOXY</b>
        </div>

        <div className="footer-links">
        <a href={process.env.PUBLIC_URL + "/term/terms-20230727.html"} target='_blank' rel="noreferrer">서비스 이용약관</a>
        <a href={process.env.PUBLIC_URL + "/term/privacy-20230727.html"} target='_blank' rel="noreferrer">개인정보처리방침</a>
        <a href={process.env.PUBLIC_URL + "/term/protect-teens-20230727.html"} target='_blank' rel="noreferrer">청소년보호정책</a>
      </div>

          <div className="toggle-business-info" onClick={toggleBusinessInfo}>
            <p>사업자정보 확인하기 ▼</p>
          </div>
          <div className={`business-info ${showBusinessInfo ? 'show' : ''}`}>
            
            <p>르도드코퍼레이션 (Rdod Corporation)<br></br>
            사업자 등록번호 : 412-51-00845 <a href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4125100845' target='_blank' style={{textDecoration:'none', color:'#968d8d'}}> [ 사업자 정보 확인 ] </a>| 대표자 : 최형빈<br></br>
            주소 : 대구광역시 수성구 달구벌대로528길 15 405호<br></br>
            연락처 : 0507-0178-0535<br></br>
           통신판매업신고번호 : 2023-대구수성구-1089</p>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
