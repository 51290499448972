import React, { useState, useEffect } from 'react';
import Header from '../../NewComponent/header';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import sample_img from '../../assets/img/sample_img.png'
import PrimaryButton from '../../NewComponent/PrimaryButton';
import jwt_decode from 'jsonwebtoken/decode';

function Future() {

  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);


      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
        console.log("로그인되어있지 않음");
 }
  }, []);

  const handlePopupOpen = async () => {
    try {
      const response = await fetch(
        'https://backend-hoxy-one-api.hoxy.one:4004/api/application/join',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: tokenUserID,
            user_no: tokenUserNo,
            application_type: 'hoxy-na',
            location: window.location.href,
          }),
        }
      );

      if (response.ok) {
        alert('사전신청이 완료되었어요 곧 찾아올게요!');
      } else if (response.status === 400) {
        alert('이미 신청했어요 곧 찾아올게요!');
      } else {
        console.error('Backend insertion failed');
      }
    } catch (error) {
      console.error('Error connecting to the backend:', error);
    }
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
       
        <br></br>

        <Header Text='나의 미래 모습 예상하기' />

        <br></br>        
        <br></br>


        <h3 style={{color:'#FF5C00', fontWeight:'600', fontSize:'18px'}}>곧 찾아올 기능이에요 🔔</h3>
        <MiddleCompoent size='250px'img={sample_img} Text1="친구들이 남겨준 답변으로" Text2="내 미래를 그려보았어요" />
        
        <div onClick={handlePopupOpen}>
        <PrimaryButton Text='사전신청 하기'/>
        </div>

      </div>
      </div>
      </div>

  );
}

export default Future;
