import React from 'react';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import fail_box from '../../NewComponent/assets/fail-box.webp';
import { Link } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';

function AdSuccess() {

  const token = localStorage.getItem('token');
  const decodedToken = jwt_decode(token);
  const tokenUserID = decodedToken.user_id;

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>

        <MiddleCompoent img={fail_box} Text1='랜덤선물을 열었지만' 
          Text2={
            <span>
              아무것도 얻지 못했어요
            </span>
          }/>




        
        <Link to={`/${tokenUserID}?explore=yes`}>
        <PrimaryButton Text="내 프로필로 이동하기"  />
        </Link>
      </div>
    </div>
  );
}

export default AdSuccess;
