import React from "react";
import { ReactComponent as RightArrow } from "./assets/icon-arrow-right-small-mono.svg";

export default function SelecBox({option,src }) {

  return (
    
        <div className="SelecBox"> 
            <div className="flexbox"> 
                <div style={{ marginLeft: '2px',marginTop:'13px' }}><img src={src}></img></div>
                <div style={{
                     color: '#000',
                     fontFamily: 'Pretendard',
                     fontSize: '16px',
                     fontStyle: 'normal',
                     fontWeight: '600',
                     lineHeight: 'normal',
                     letterSpacing: '-0.8px',
                     textAlign :'left',
                     marginLeft:'15px',
                     marginTop:'15px'
                }}>{option}</div>
                <div style={{marginTop:'17px',marginLeft:'15px'}}><RightArrow /></div>
            </div>
        </div>
    
  );
}
