import React from 'react';
import { motion } from 'framer-motion';
import { Link } from  'react-router-dom';
import { linear } from 'popmotion';

export default function PrimaryButtonSmall({ Text, Text2, Link1, Link2, requestDbNumber }) {
  const buttonContainerStyle = {
    display:'flex',
    position: 'fixed',
    bottom: '28px',
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const buttonStyle = {
    display: 'flex',
    width: '100px',
    padding: '14px 80px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#FF5C00',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  const buttonStyle2 = {
    display: 'flex',
    width: '100px',
    padding: '14px 80px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#fff',
    border: '2px solid var(--Hoxy-Orange, #FF5C00)',    
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle2 = {
    color: 'var(--Hoxy-Orange, #FF5C00)',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  function handleDeleteMessage() {
    // 여기서 메시지 차단 API 호출
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/block', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ requestDbNumber }), // requestDbNumber를 전송
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('요청이 실패하였습니다.');
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert('요청 중 오류가 발생하였습니다.');
      });
  }
  

  return (
    <div style={buttonContainerStyle}>
    <Link to={Link1}>
      <motion.button style={buttonStyle2} whileTap={{ scale: 0.95 }}>
        <span style={buttonTextStyle2}>{Text2}</span>
      </motion.button>
    </Link>
  
    <div style={{ marginLeft: '10px' }}></div>
  
    <Link to={Link2}>
      <motion.button style={buttonStyle} whileTap={{ scale: 0.95 }}>
        <span style={buttonTextStyle}>{Text}</span>
      </motion.button>
    </Link>
  </div>
  
  );
}


