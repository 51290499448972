import { Tittle } from '../stories/Tittle';
import { useNavigate } from 'react-router-dom';

function ErrorP() {
  const navigate = useNavigate();

  const handleSvg1Click = () => {
    navigate("/welcome");
  };

  const handleSvg2Click = () => {
    navigate("/search");
  };

  return (
    <div class="main" style={{backgroundColor: '#FFFFFF'}}>
      <div class="main_content" style={{backgroundColor: '#FFFFFF'}}>
        <div>

        <div class="top_bar">
        </div>
          <div style={{display:'flex',justifyContent:'center', marginTop:'100px'}}>
            <img src='https://resource.hoxy.one/logos/hoxy_logo_2.svg' width={'65px'} alt="logo"></img>
          </div>
          <div style={{display:'flex',justifyContent:'center', marginTop:'43px'}}>
            <Tittle className='normal' label1=' 프로필을 찾지 못했어요 👀' label2='나의 프로필을 만들어보는건 어때요?' />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:'45px', marginBottom:'50px'}}>
            <img src='https://resource.hoxy.one/img_component/ErrorP_1.svg' alt="image1" onClick={handleSvg1Click} style={{cursor: 'pointer'}} />
            <div style={{marginTop: '15px'}}></div>
            <img src='https://resource.hoxy.one/img_component/ErrorP_2.svg' alt="image2" onClick={handleSvg2Click} style={{cursor: 'pointer'}} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorP;
