import { ReactComponent as MainPlogo } from "../stories/assets/MainPlogo.svg";

const Logo1 = () => {
    return (
        <div style={{
        fontFamily: 'Pretendard',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '15px',
        display : 'flex',
        textAlign: 'center',
        letterSpacing: '-0.6px',
        marginTop : '0px',
        color: '#000000'}}>
                        <MainPlogo  /> 
            <div style={ {marginTop : '3px', marginLeft:'4px' }}>HOXY</div>
        </div>
  );
  };
  
  export default Logo1;