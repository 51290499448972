import React from 'react';
import OnboardingMiddele2 from './OnboardingMiddle2';
import cupid from '../NewComponent/assets/cupid_big.webp'
import PrimaryButtonSmall from './PrimaryButtonSmall';
import jwt_decode from 'jsonwebtoken/decode';

export default function LetStart({}) {

  const token = localStorage.getItem('token');
  const decodedToken = jwt_decode(token);
  const tokenUserID = decodedToken.user_id;

  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
    <div className='main_content'>
    <br></br>
    <div style={{display:'flex',flexDirection:'column'}}>
       <OnboardingMiddele2 middle_compo='middle_component' img={cupid} Text1='내 프로필을 공유하며' Text2='본격적으로 HOXY를 시작해볼까요?' Text3='먼저 둘러보고 나중에 프로필을 공유해두돼요'/>
       <div style={{display:'flex'}}> 
        <PrimaryButtonSmall Text='내 프로필 공유하기' Link2={"/fristsharelanding"} Text2='둘러보고 하기' Link1={`/${tokenUserID}?explore=yes`} />
       </div>
    </div>     
    </div>
    </div>
  );
}
