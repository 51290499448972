// Checkbox.js
import React, { createContext, useContext, useEffect, useState } from 'react';

// CheckboxContext 생성
const CheckboxContext = createContext();

// CheckboxProvider 컴포넌트 정의
export function CheckboxProvider({ children }) {
  const [state, setState] = useState({});

  const toggleValue = ({ value, checked }) => {
    setState((prevState) => ({ ...prevState, [value]: checked }));
  };

  const isChecked = (value) => state[value] || false;

  const isDisabled = (value) => {
    // Implement your logic for disabled state if needed
    return false;
  };

  // 초기화: "information_check" 및 "marketing_check"를 false로 설정
  useEffect(() => {
    localStorage.setItem("information_check", "false");
    localStorage.setItem("marketing_check", "false");
  }, []);

  return (
    <CheckboxContext.Provider value={{ isChecked, isDisabled, toggleValue }}>
      {children}
    </CheckboxContext.Provider>
  );
}

// Checkbox 컴포넌트 정의
export function Checkbox({ children, disabled, value }) {
  const context = useContext(CheckboxContext);

  useEffect(() => {
    // 컴포넌트가 처음 마운트될 때 로컬 스토리지에서 체크 상태를 가져옵니다.
    const savedValue = localStorage.getItem(value);
    if (savedValue !== null) {
      context.toggleValue({ value, checked: savedValue === 'true' });
    }
  }, [value, context]);

  if (!context) {
    return (
      <label>
        <input
          style={{ width: '25px' }}
          type="checkbox"
          disabled={disabled}
          checked={false}
          onChange={({ target: { checked } }) => {
            console.log('No context', checked);
            localStorage.setItem(value, checked ? 'true' : 'false');
          }}
        />
        {children}
      </label>
    );
  }

  const { isDisabled, isChecked, toggleValue } = context;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <label
        style={{
          display: 'flex',
          color: '#000',
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'Pretendard',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.52px',
          width: '320px',
        }}
      >
        <input
          style={{ width: '25px' }}
          type="checkbox"
          disabled={isDisabled(disabled)}
          checked={isChecked(value)}
          onChange={({ target: { checked } }) => {
            toggleValue({ checked, value });
            localStorage.setItem(value, checked ? 'true' : 'false');
          }}
        />
        {children}
      </label>
    </div>
  );
}
