import React from "react";
import JokerCardImg from '../assets/img/joker_card.webp'
import { ReactComponent as JokerCard } from "./assets/JokerCard.svg";
import { motion } from "framer-motion";


export default function JokerBox({num_joker}) {

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }} // 초기 상태 (페이드 인 및 축소)
      animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태 (페이드 인 및 확대)
      transition={{ duration: 1, ease: "easeOut" }} // 애니메이션 지속 시간 및 이징 함수 설정
    >
        <div className="JokerBox"> 
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}> 
                <div style={{ marginTop: '25px'}}><img  src={JokerCardImg} style={{width:'65px',height:'65px'}} /></div>
                <div style={{ marginTop: '5px'}}><JokerCard /></div>
                <div style={{
                    color: '#8B95A1',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    letterSpacing: '-1.12px',

                    marginTop: '10px'
                }}>나의 조커카드 개수</div>

                <div style={{
                     color: '#000',
                     textAlign: 'center',
                     fontFamily: 'Pretendard',
                     fontSize: '32px',
                     fontStyle: 'normal',
                     fontWeight: '800',
                     lineHeight: 'normal',
                     letterSpacing: '-2.24px',

                     marginTop: '10px'
                }}>{num_joker}</div>

                <div style={{
                    color: '#8B95A1',
                    textAlign: 'center',
                    fontfamily: 'Pretendard',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    letterSpacing: '-0.72px',

                    marginTop: '15px'
                }}>조커 카드는 매일, 상자를 열어서<br></br> 늘릴 수 있어요</div>
            </div>
        </div>
        </motion.div>
  );
}
