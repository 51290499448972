import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CastingInput from '../../../NewComponent/CastingInput';
import PrimaryButton from '../../../NewComponent/PrimaryButton';
import CastingReason from '../../../NewComponent/CastingReason';
import Header from '../../../NewComponent/header';
import MiddleCompoent from '../../../NewComponent/MiddleCompoent';
import img_src from '../../../NewComponent/assets/Complete.png'
import SexBtn from '../../../NewComponent/SexBtn';
import MessageNumBox from '../../../NewComponent/MessageNumBox';
import jwt_decode from 'jsonwebtoken/decode';
import { motion } from 'framer-motion';
import Bacchus from '../../../assets/img/Bacchus.webp'
import chupachups from '../../../assets/img/chupachups.webp'
import hands from '../../../NewComponent/assets/ClovaHands.webp'
import * as PortOne from '@portone/browser-sdk/v2';


function MessageMain() {

    const [currentContent, setCurrentContent] = useState(1);
    const [previousContent, setPreviousContent] = useState(1);
    const [tokenUserID, setTokenUserID] = useState("");
    const [tokenUserNo, setTokenUserNo] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [relationship, setRelationship] = useState('');
    const [keyword, setKeyword] = useState('');
    const [want, setWant] = useState('');
    const [Gift, setGift] = useState('');



    const [relationshipData, setRelationshipData] = useState([]);
    const [keywordData, setKeywordData] = useState([]);
    const [wantData, setWantData] = useState([]);
    const [shuffledRelationshipData, setShuffledRelationshipData] = useState([]);
    const [shuffledKeywordData, setShuffledKeywordData] = useState([]);
    const [shuffledWantData, setShuffledWantData] = useState([]);

    const [userData, setUserData] = useState({});
    const [hoxyGender, setHoxyGender] = useState(''); 
    const [hoxyAge, setHoxyAge] = useState(''); 
    const [Mid, setMid] = useState({});


    const VisitprofileName = localStorage.getItem('VisitprofileName');
    const [selectedSex, setSelectedSex] = useState('');



    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
     
    
       
          if(!token) {
            setTokenUserID("anonymous");
            setTokenUserNo("0");
            setShowPopup(true)
          }
    
      
          if (token) {
            try {

              const decodedToken = jwt_decode(token);
              const tokenUserID = decodedToken.user_id;
              const tokenUserNo = decodedToken.user_no;
              setTokenUserID(tokenUserID);
              setTokenUserNo(tokenUserNo);

              const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
                },
                body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
              });
    
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
    
              const data = await response.json();
    
              setUserData({
                user_no: data.user_no,
                user_name: data.user_name,
                user_email: data.user_email,
                user_phonenumber: data.user_phonenumber,
                user_kakao_year: data.user_kakao_year,
                user_gender: data.user_gender,
              });

              setMid(`hoxypayment_${tokenUserNo}_${new Date().getTime()}`)


              if (data.user_kakao_year) {
                setCurrentContent(3);

                const userKakaoYearNumber = parseInt(data.user_kakao_year, 10);
        
                const today = new Date();
                const birthDate = new Date(userKakaoYearNumber, 1, 16);
                const hoxy_age =
                today.getFullYear() - birthDate.getFullYear() + 1;
                const hoxy_gender = userData.user_gender === 'female' ? '여성' : '남성';
          
                setAge(hoxy_age); 
                setGender(hoxy_gender); 
        
              }
    
              // 나머지 코드
            } catch (error) {
              console.log('API Error 입니다:', error);
            }
          } else {
            console.log('로그인되어있지 않음');
          }
        } catch (error) {
          console.log('API Error:', error);
        }
      };
    
      fetchData();
    }, []);
    
    



    const handleSexSelect = (sex) => {
      setSelectedSex(sex);

      if(sex == 'female') {
        setGender("여성"); // 선택한 성별을 setGender 변수에 저장
      }

      if(sex == 'male') {
        setGender("남성"); // 선택한 성별을 setGender 변수에 저장
      }
    };


    const handleNextClick = () => {
        if (currentContent < 5) {
            setPreviousContent(currentContent);
            setCurrentContent(currentContent + 1);
        }

       
    };

    const handlePreviousClick = () => {
        if (previousContent >= 1) {
            setCurrentContent(previousContent);
            setPreviousContent(currentContent - 1);
        }
    };


    const handlePopupButtonClick = () => {
      navigate('/start');
    };

    const handlePopupButtonCloseClick = () => {
      setShowPopup(false)
    };


    const navigate = useNavigate();

  
  const handleAgeChange = (e) => {
    setAge(e.target.value); // 입력된 나이를 setAge 변수에 저장
  };

  const [relationshipText, setrelationshipText] = useState('');
  const [keywordText, setkeywordText] = useState('');
  const [wantText, setwantText] = useState('');


  const handleClickRelationship = (value) => {
    setRelationship(value);
  };
  const relationshipTextChange = (text) => {
    setRelationship(text);
  };



  const handleClickKeyword = (value) => {
    setKeyword(value);
  };
  const keywordTextChange = (text) => {
    setKeyword(text);
  };


  const handleClickWant = (value) => {
    setWant(value);
  };

  
  const wantTextChange = (text) => {
    setWant(text);
  };


  
  const handleClickGift = (value) => {
    setGift(value);
  };


    const shuffleArray = (array) => {
      const shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    };
  
    const handleShuffleRelationship = () => {
      const shuffledRelationships = shuffleArray(relationshipData);
      setShuffledRelationshipData(shuffledRelationships.slice(0, 4));
    };
    
    const handleShuffleKeyword = () => {
      const shuffledKeywords = shuffleArray(keywordData);
      setShuffledKeywordData(shuffledKeywords.slice(0, 4));
    };
    
    const handleShuffleWant = () => {
      const shuffledWants = shuffleArray(wantData);
      setShuffledWantData(shuffledWants.slice(0, 4));
  
    };
  
    useEffect(() => {
      
      const fetchData = async () => {
        try {
          const relationshipResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/relationship/');
          const keywordResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/keyword/');
          const wantResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/want/');
  
           const shuffledRelationships = shuffleArray(relationshipResponse.data);
          setRelationshipData(shuffledRelationships);
          setShuffledRelationshipData(shuffledRelationships.filter((data) => data.select_setting === 'default').slice(0, 4));
  
          const shuffledKeywords = shuffleArray(keywordResponse.data);
          setKeywordData(shuffledKeywords);
          setShuffledKeywordData(shuffledKeywords.filter((data) => data.select_setting === 'default').slice(0, 4));
  
          const shuffledWants = shuffleArray(wantResponse.data);
          setWantData(shuffledWants);
          setShuffledWantData(shuffledWants.filter((data) => data.select_setting === 'default').slice(0, 6));
        } 
        
        catch (error) {
          console.error(error);
        }
      };
  
      fetchData();
    }, []);
  





    async function requestPayment() {
      const response = await PortOne.requestPayment({ /* 객체 생략 */
        // 고객사 storeId로 변경해주세요.
        storeId: 'store-8ad9d838-272e-48fc-996c-44e7b830833d',
        paymentId: `hoxypayment-${tokenUserNo}-${new Date().getTime()}`,
        orderName: 'HOXY 익명 찔러보기 선물조각 선물 - 박카스',
        totalAmount: 500,
        currency: 'CURRENCY_KRW',
        pgProvider: 'TOSSPAYMENTS',
        payMethod: 'CARD',
        channelKey :'channel-key-38e98750-4fa0-4ba6-83d2-0b63dfb94203',
        redirectUrl : 'http://localhost:3000/payment/gift/callback',
        customer: { fullName: userData.user_name, email: userData.user_email, phoneNumber: userData.user_phonenumber },
        custom_data: { userId: tokenUserID, userNo: tokenUserNo },
        productType : "PRODUCT_TYPE_DIGITAL",
      });
    
      if (response.code != null) {
        return alert(response.message);
      }

      const validation = await axios({
        url: "https://backend-hoxy-one-api.hoxy.one:4004/api/payments/gift/complete", // 앞서 구현한 API 주소
        method: "POST",
        body: {
          paymentId: response.paymentId,
        },
      });

      if (validation.status === 200 && validation.data === "Payment successful") {
        alert("결제 완료!");
      } else {
        alert("결제 인증에 실패했어요!");
      }

    }
    

         
    const handleButtonClick = () => {
   
    

      navigate('/MessageFinished', {
        state: {
          age: age,
          gender: gender,
          keyword: keyword,
          relationship: relationship,
          want: want,
          user_id : tokenUserID,
          user_no : tokenUserNo

        },
      });
    };
       
    {/* 선물하기 있을 때
    
    const handleButtonClick = () => {
      
      if(Gift === 'no' || Gift === 'chupachups') {
        navigate('/MessageFinished', {
          state: {
            age: age,
            gender: gender,
            keyword: keyword,
            relationship: relationship,
            want: want,
            user_id : tokenUserID,
            user_no : tokenUserNo

          },
        });
      }

      else if(Gift === 'Bacchus') {
        requestPayment();

        
      }

      else {

      }
    }; */}

  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
        <div className='main_content'>
        <br></br>
        

        <div className='Content-Casting'>
            <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>
                
                <Header className="CastingTitle" Text="나의 성별을" Text2='알려주세요' />

                <SexBtn
                    Style='sexbuttonContainerStyle1'
                    Text='남성'
                    defaultSex='male'
                    onSelect={handleSexSelect}
                    isSelected={selectedSex === 'male'}
                  />
                  <SexBtn
                    Style='sexbuttonContainerStyle2'
                    Text='여성'
                    defaultSex='female'
                    onSelect={handleSexSelect}
                    isSelected={selectedSex === 'female'}
                  />

                
                <MessageNumBox num={currentContent} />
            </div>

            <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>
                
                <Header className="CastingTitle" Text="나의 나이를" Text2='알려주세요' />

                <CastingInput what={'나의 나이'} onChange={handleAgeChange}/> 

                <MessageNumBox num={currentContent} />
            </div>

            <div id='Content3' style={{ display: currentContent === 3 ? 'block' : 'none'}}>
            <Header className="CastingTitle" Text={`나와 ${VisitprofileName}님은`} Text2='지금 어떤 관계인가요?' />
                
                <div className='tip_sentence'>
                    <div>팁 💡:  {VisitprofileName}님과 나는 현재 어떤 관계인지 <br></br>고민하면서 답변을 선택하거나 직접 입력해주세요</div>
                </div>

                <div style={{marginTop:'70px'}}>
                {shuffledRelationshipData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_relationship ${relationship === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickRelationship(data.select_name)}
                    disabled={relationshipText.trim() !== ''} 
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>

                <button className="shuffle_button2" onClick={handleShuffleRelationship}>
                &#8592; 추천 답변 더보기 &#8594;               
                 </button>

                 <div style={{marginLeft:'24px', marginTop:'15px'}}>
                 <CastingReason onSaveText={relationshipTextChange} />
                 </div>

                <MessageNumBox num={currentContent} />
            </div>
            <div id='Content4' style={{ display: currentContent === 4 ? 'block' : 'none' }}>
                
               <Header className="CastingTitle" Text={`${VisitprofileName}님에게 나를 어떤`} Text2='키워드로 설명할 수 있을까요?' />

                
                <div className='tip_sentence'>
                    <div>팁 💡: {VisitprofileName}님이 나를 어떻게 생각하고 있을지 <br></br>고민하면서 답변을 선택하거나 직접 입력해주세요</div>
                </div>

                <div style={{marginTop:'70px'}}>
                {shuffledKeywordData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_relationship ${keyword === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickKeyword(data.select_name)}
                    disabled={keywordText.trim() !== ''} 

                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>

                <button className="shuffle_button2" onClick={handleShuffleKeyword}>
                &#8592; 추천 답변 더보기 &#8594;               
                 </button>

                 <div style={{marginLeft:'24px', marginTop:'15px'}}>
                 <CastingReason onSaveText={keywordTextChange} />
                 </div>
                 
                <MessageNumBox num={currentContent} />
            </div>

            <div id='Content5' style={{ display: currentContent === 5 ? 'block' : 'none' }}>
                
            <Header className="CastingTitle" Text={`${VisitprofileName}님과`} Text2='어떻게 친해지고 싶나요?' />

                
                <div className='tip_sentence'>
                    <div>팁 💡: {VisitprofileName}님과 내가 어떻게 친해지면 좋을 것 같을지 <br></br>답변을 선택하거나 직접 입력해주세요</div>
                </div>

                <div style={{marginTop:'70px'}}>
                {shuffledWantData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_relationship ${want === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickWant(data.select_name)}
                    disabled={wantText.trim() !== ''} 
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>

                <button className="shuffle_button2" onClick={handleShuffleWant}>
                &#8592; 추천 답변 더보기 &#8594;               
                 </button>

                 <div style={{marginLeft:'24px', marginTop:'15px'}}>
                 <CastingReason onSaveText={wantTextChange} />
                 </div>

  


                <MessageNumBox num={currentContent} />
            </div>


{/*
            <div id='Content6' style={{ display: currentContent === 6 ? 'block' : 'none' }}>
                
            <Header className="CastingTitle" Text={`${VisitprofileName}님께 작은 선물을 함께`} Text2='보내보는건 어때요?' />
                
                <div className='tip_sentence'>
                    <div>팁 💡: {VisitprofileName}님께 선물 조각을 선물할 수 있어요. <br></br>선물 조각이 모이면 기프티콘으로 교환할 수 있어요</div>
                </div>


                <div style={{marginTop:'70px'}}>


                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <button
                  className={`select_relationship ${Gift === 'ChupaChups' ? 'active' : ''}`}
                  data-value={'ChupaChups'}
                  onClick={() => handleClickGift('ChupaChups')}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>츄파춥스 0원</div>
                    <img
                      className="request_img"
                      src={chupachups}
                    />
                  </div>
                </button>

                <button
                  className={`select_relationship ${Gift === 'Bacchus' ? 'active' : ''}`}
                  data-value='Bacchus'
                  onClick={() => handleClickGift('Bacchus')}

                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>박카스 500원</div>
                    <img
                      className="request_img"
                      src={Bacchus}
                    />
                  </div>
                </button>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop:'10px' }}>

              <motion.button
                className={`sexbuttonStyle1 ${Gift === 'no' ? 'sexbuttonStyle2' : ''}`}
                whileTap={{ scale: 0.95 }}
                style={{ display: 'flex', width:'350px' }}
                onClick={() => handleClickGift('no')}

              >
                <span className="buttonTextStyle" style={{ marginTop: '15px', fontSize:'16px' }}>
                  괜찮아요
                </span>
                <div>
                  <img style={{ marginTop: '8px', marginLeft: '5px' }} src={hands} width={25}  />
                </div>
              </motion.button>
              </div>



                </div>




  
                <MessageNumBox num={currentContent} />

                </div> */}


            <div onClick={handleNextClick} style={{ display: currentContent === 5? 'none' : 'block' }}>
            <PrimaryButton  Text={'다음'}/>
            </div>


            
            <div onClick ={() => {handleNextClick(); handleButtonClick();}} style={{ display: currentContent === 5 ? 'block' : 'none' }}>
            <PrimaryButton Text={'다음'}/>
        </div>


      
        </div>

        {showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
          >
            <h2 style={{ fontSize: '22px' }}>
              나에 대한 {VisitprofileName}님의<br></br>답장이 오면 알려드릴게요
            </h2>
            <p>
              가입 후 메시지를 보내도, {VisitprofileName}님은
              <br />
              내가 누군지 정확히 알아 낼 수 없어요 🔒
            </p>
            
            <div className="button-container">
              <button
                className="delete-button"
                onClick={handlePopupButtonCloseClick}
            >
            괜찮아요
            </button>
              <motion.button
                className="retry-button"
                onClick={handlePopupButtonClick}
              >
                가입하고, 계속하기
              </motion.button>
            </div>
        </motion.div>
        </motion.div>
      )}

  </div>
  </div>
  );
}

export default MessageMain;
