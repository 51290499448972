
import share_img from '../assets/img/instagram-notification.webp';
import SadEmojiimg_URL from '../assets/img/sad-emoji.webp';
import img_cat from '../assets/img/cat_img.webp';
import img_insta from '../assets/img/instagram_logo.webp';
import img_iphone from '../assets/img/apple-iphone.webp';
import img_location from '../assets/img/island.webp';
import img_earth from '../assets/img/Earth.webp';


import { LargeButton } from '../stories/LargeButton';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Landingpage_box from '../component/Landingpage_box';
import Header from '../NewComponent/header';
import PrimaryButton from '../NewComponent/PrimaryButton';

function FirstShareLanding() {

  const [visibleIndex, setVisibleIndex] = useState(0);
  const [locationValue, setLocationValue] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // 로컬 스토리지에서 r3 가져오기
    const r3FromLocalStorage = localStorage.getItem('r3');
    const locationText = r3FromLocalStorage ? r3FromLocalStorage : '시지동';
    setLocationValue(locationText);
  }, []);

  useEffect(() => {
    const delay = 500; // 박스가 나타나는 지연시간 (밀리초)

    if (visibleIndex < 5) {
      const timer = setTimeout(() => {
        setVisibleIndex(visibleIndex + 1);
      }, delay);

      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 클리어
    }
  }, [visibleIndex]);




  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <br></br>

      <Header className="CastingTitle" Text="내 HOXY 프로필 링크를" Text2='공유해 시작할 수 있어요' />

          
          
 <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '25px', display: 'flex' }}>
  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px', marginBottom: '10px',  marginRight: 'auto' }}>
    <Landingpage_box
      imgSrc={img_cat}
      imgAlt="Cat Icon"
      text="우리 학교 18세"
      boldText="여학생"
      isVisible={visibleIndex >= 1}
    />
    <Landingpage_box
      imgSrc={img_insta}
      imgAlt="insta Icon"
      text="맞팔하고"
      boldText="싶어해요!"
      isVisible={visibleIndex >= 2}
    />
  </div>
</div>

<div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '1px', display: 'flex' }}>
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: '25px', marginLeft: 'auto' }}>
    <Landingpage_box
      imgSrc={img_iphone}
      imgAlt="Iphone Img"
      text="iPhone을"
      boldText="사용해요!"
      isVisible={visibleIndex >= 3}
    />
    <Landingpage_box
      imgSrc={img_location}
      imgAlt="Location Img"
      text={`${locationValue}에`}
      boldText="살아요!"
      isVisible={visibleIndex >= 4}
    />
    <Landingpage_box
      imgSrc={img_earth}
      imgAlt="Earth Img"
      text="지금,"
      boldText="500m 안에 있어요!"
      isVisible={visibleIndex >= 5}
    />


  </div>


</div>










        <div className="welcome_ButtonBox">

  

        <div style={{ alignSelf: 'center', marginBottom:'10px' }}>
          <Link to='/FristShare'>
            <PrimaryButton Text={"링크 공유해, 친해지고 싶은 사람 알기"} />
            
          </Link>
        </div>


        </div>
        </div>

      </div>

  );
}

export default FirstShareLanding;
