import React, { useState, useEffect } from 'react';

const LandingpageBox = ({ imgSrc, imgAlt, text, boldText, isVisible }) => {
    const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div
      style={{
        width: '185px',
        height: '45px',
        borderRadius: '15px',
        backgroundColor: '#FBF5F5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '600',
        marginTop: '10px',
        opacity: isVisible ? 1 : 0, // isVisible 값에 따라 서서히 나타나도록 설정
        transition: 'opacity 0.5s ease-in-out', // 애니메이션 설정
      }}
    >
      <img src={imgSrc} width={'20px'} height={'20px'} alt={imgAlt} style={{ marginRight: '2px' }} />
      <div style={{ textAlign: 'center' }}>
        {text} <b style={{ fontWeight: '800' }}>{boldText}</b>
      </div>
    </div>
  );
};

export default LandingpageBox;
