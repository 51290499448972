import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import CastingReason from '../../NewComponent/CastingReason';
import Header from '../../NewComponent/header';
import OnboardingMiddele2 from '../../NewComponent/OnboardingMiddle2';
import { motion, AnimatePresence } from 'framer-motion';
import InformBox from '../../NewComponent/InformBox';
import subscribe from '../../NewComponent/assets/subscribe.webp';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import qa_img from '../../assets/img/qa.webp';
import jwt_decode from 'jsonwebtoken/decode';
import sub_img from '../../assets/img/sub.svg';

function TodayQuestion() {
  const [currentContent, setCurrentContent] = useState(1);
  const [previousContent, setPreviousContent] = useState(1);
  const [answer, setAnswer] = useState('');
  const [questionText1, setQuestionText1] = useState('');
  const [questionText2, setQuestionText2] = useState('');
  const [questionImg, setQuestionImg] = useState('');
  const [questionNum, setQuestionNum] = useState(''); // 질문 번호 추가
  const [showPopup, setShowPopup] = useState(false);
  const [tokenUserNo, settokenUserNo] = useState('');
  const [tokenUserID, settokenUserID] = useState('');

  

  const navigate = useNavigate();
  const VisitprofileID = localStorage.getItem('VisitprofileID');
  const VisitprofileName = localStorage.getItem('VisitprofileName');
  const VisitprofileUserNo = localStorage.getItem('VisitprofileUserNo');

  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 9); // 9시간을 더해서 한국 시간대로 변환
  const currentDateStr = currentDate.toISOString().slice(0, 10);
  const dayOfWeek = new Intl.DateTimeFormat('ko-KR', { weekday: 'long' }).format(new Date(currentDateStr));


  const handleInputChange4 = (text) => {
    setAnswer(text);
  };

  const handleNextClick = () => {
    if (currentContent < 5) {
      setPreviousContent(currentContent);
      setCurrentContent(currentContent + 1);
    }
  };

  const handlePreviousClick = () => {
    if (previousContent >= 1) {
      setCurrentContent(previousContent);
      setPreviousContent(currentContent - 1);
    }
  };

  const handleButtonClick = () => {
    navigate(`/${VisitprofileID}?explore=yes`);
  };

  const fetchQuestionText = async () => {
    try {

      const token = localStorage.getItem('token');
      let tokenUserID, tokenUserNo;

      if (token) {
        const decodedToken = jwt_decode(token);
        tokenUserID = decodedToken.user_id;
        tokenUserNo = decodedToken.user_no;
        settokenUserID(tokenUserID)

      } else {
        tokenUserNo = "0";
        tokenUserID = "anonymous";
        settokenUserID(tokenUserID)

      }

      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/1day1/question', { question_date: currentDateStr });
      const questionData = response.data[0];
      const { question_text_1, question_text_2, question_img, question_num } = questionData;
      setQuestionText1(`Q. ${VisitprofileName}님${question_text_1}`);
      setQuestionText2(question_text_2);
      setQuestionImg(question_img);
      setQuestionNum(question_num); // 질문 번호 설정
    } catch (error) {
      console.error('질문 텍스트 가져오기 오류:', error);
    }
  };

  const sendAnswerToBackend = async () => {
    try {

      const token = localStorage.getItem('token');
      let tokenUserID, tokenUserNo;

      if (token) {
        const decodedToken = jwt_decode(token);
        tokenUserID = decodedToken.user_id;
        tokenUserNo = decodedToken.user_no;

      } else {
        tokenUserNo = "0";
        tokenUserID = "anonymous";
      }
      

      const requestData = {
        question_num: questionNum, // 질문 번호 추가
        question_date: currentDateStr,
        question_user_id: tokenUserID,
        question_user_no: tokenUserNo,
        question_answer: answer,
        recive_user_id: VisitprofileID,
        recive_user_no:VisitprofileUserNo
      };
      
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/1day1', requestData);
      console.log('요청이 성공적으로 처리되었습니다.');
      handleNextClick();

    } catch (error) {
      console.error('답변 보내기 오류:', error);
    }
  };



  const handleSubscribeClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const app = localStorage.getItem('app');

      const decodedToken = jwt_decode(token);
      const tokenUserID = decodedToken.user_id;
      const tokenUserNo = decodedToken.user_no;

      if (!token) {
        alert('1일 1문답을 구독하려면, 나의 계정이 필요해요')
        return;

      }

      if (!app) {
        alert('1일 1문답을 구독하려면, HOXY 앱에서만 가능해요')
        return;
      }



      // 여기에서 API를 호출하여 구독을 처리합니다.
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/1day1/subscribe', {
        user_id: tokenUserID, // 사용자 아이디 설정
        user_no: tokenUserNo, // 사용자 번호 설정
        recive_user_id: VisitprofileID, // 수신 사용자 아이디 설정
        recive_user_no: VisitprofileUserNo, // 수신 사용자 번호 설정
        recive_user_name: VisitprofileName // 수신 사용자 번호 설정
      });

      // API 호출이 성공하면 결과를 확인하고 알림을 표시합니다.
      if (response.status === 200) {
        alert(`${VisitprofileName}님의 1일 1문답을 구독했어요.`);
        // 여기에서 추가로 필요한 작업을 수행할 수 있습니다.
      }
    } catch (error) {
      console.error('구독하기 오류:', error);
      alert('로그인 되어있지 않거나, 서버 오류로 인해 구독을 처리할 수 없어요');
    }
  };

  useEffect(() => {
    fetchQuestionText();
  }, []);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
  };


  


  const handlePopupButtonClick = () => {
    navigate('/start');
  };

  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className="main_content">
        <br />
        <div className="Content-Casting">
          <div id="Content1" style={{ display: currentContent === 1 ? 'block' : 'none' }}>
            <Header className="CastingTitle" />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
              <motion.div
                className="today_date"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <div style={{ marginTop: '6px' }}>
                  {currentDateStr} {dayOfWeek} ☀️
                </div>
              </motion.div>
            </div>
            <div style={{ marginTop: '0px' }}>
              <OnboardingMiddele2 img={questionImg} Text1={questionText1} Text2={questionText2} />
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CastingReason onSaveText={handleInputChange4} />
            </div>
          </div>

          <div id="Content2" style={{ display: currentContent === 2 ? 'block' : 'none' }}>
            <motion.div variants={containerVariants} initial="hidden" animate="visible">
              <MiddleCompoent img={qa_img} Text1={`1일 1문답 답변을 ${VisitprofileName}님께`} Text2="성공적으로 전달했어요" />
            </motion.div>
            <div
              style={{
                position: 'fixed',
                bottom: '11%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
              onClick={handleSubscribeClick}
            >
              <InformBox
                CompoName={'bell'}
                src={subscribe}
                iftext={'매일 1일 1문답 푸시알림을 받고 싶다면'}
                howtext={`${VisitprofileName}님 1일 1문답 구독하기`}
                src2={sub_img}
            /> 
            </div>
          </div>
        </div>
        <div
          onClick={handleNextClick}
          style={{ display: currentContent === 2 ? 'none' : 'block' }}
        >

          {!answer && tokenUserID == VisitprofileID && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
               
                >
        <Link to={'/share'}>
        <PrimaryButton Text={`오늘의 1일 1문답, 친구들에게 답변받기`} animate={'highlight'}/>
        </Link>
        </motion.div>
        )}
        


          <AnimatePresence>
            {answer && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                onClick={() => {
                  sendAnswerToBackend();
                }}
              >
                <div>
                  <PrimaryButton Text={`${VisitprofileName}님에게 답변 남기기`} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div
          onClick={() => {
            handleButtonClick();
          }}
          style={{ display: currentContent === 2 ? 'block' : 'none' }}
        >
          <PrimaryButton Text={'완료하기'} />
        </div>

        {showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
          >
            <h2 style={{ fontSize: '22px' }}>
              나에 대한 {VisitprofileName}님의<br></br>답장이 오면 알려드릴게요
            </h2>
            <p>
              가입 후 메시지를 보내도, {VisitprofileName}님은
              <br />
              내가 누군지 정확히 알아 낼 수 없어요 🔒
            </p>
            
            <div className="button-container">
              <button
                className="delete-button"
                style={{ visibility: 'hidden' }}
              >
                계속하기
              </button>
              <motion.button
                className="retry-button"
                style={{ fontSize: '14px', fontWeight: '600' }}
                onClick={handlePopupButtonClick}
              >
                가입하고, 계속하기
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}

      </div>
    </div>



  );
}

export default TodayQuestion;
