import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jsonwebtoken/decode';
import { ReactComponent as StartLogo } from "../stories/assets/startlogo.svg";
import { CSSTransition } from 'react-transition-group';

function HoxyPass() {
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstRender = useRef(true);
  const [showLogo, setShowLogo] = useState(false); // 초기에 로딩 컴포넌트를 보이게 설정

  useEffect(() => {
    const autoLogin = async () => {
      const token = localStorage.getItem('token');
      const queryParams = new URLSearchParams(location.search);
      const appParam = queryParams.get('app');

      if (appParam === 'yes' && token) {
        setShowLogo(false)
        try {
          const decodedToken = jwt_decode(token);
          const userId = decodedToken.user_id;
          const userNo = decodedToken.user_no;
          localStorage.setItem('user_id', userId);
          navigate(`/${userId}?explore=yes`);

            {/* await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); */}
            
        } catch (error) {
          console.error('자동 로그인 실패:', error);
          navigate('/start');
        }
      } else {
        setShowLogo(true);
        // 'app' 파라미터가 'yes'가 아닌 경우에는 2초 동안 대기 후 '/start' 페이지로 이동합니다.
        if (token) {
          try {
            const decodedToken = jwt_decode(token);
            const userId = decodedToken.user_id;
            const userNo = decodedToken.user_no;
            localStorage.setItem('user_id', userId);
  
            {/* await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); */}

            setTimeout(() => {
              navigate(`/${userId}?explore=yes`);
            }, 2000);
  
          } catch (error) {
            console.error('자동 로그인 실패:', error);
          }
        }

        else {

          setTimeout(() => {
            navigate('/start');
          }, 2000);

        }
        
      
      }
    };

    if (isFirstRender.current) {
      autoLogin();
      isFirstRender.current = false;
    }
  }, [navigate, location]);

  return (
    showLogo && (
      <div className="main" style={{ backgroundColor: '#FF5C00' }}>
        <div className="main_content" style={{ backgroundColor: '#FF5C00', touchAction: 'none', height: '100vh', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CSSTransition in={showLogo} classNames="fade">
              <StartLogo className="content" />
            </CSSTransition>
          </div>
        </div>
      </div>
    )
  );
}

export default HoxyPass;
