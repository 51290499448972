// Tab.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Alarm } from './Alarm';
import { ISubscribed } from './ISubscribed';
import Header from '../../../NewComponent/header';

const TabMenu = styled.ul`
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content : center;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 0px;

  .submenu {
    padding: 1rem;
    cursor: pointer;
  }

  .focused {
    background-color: #fff;
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;



export const Tab = () => {
  const menuArr = [
    { name: '알림', content: <Alarm /> },
    { name: '내가 구독한 친구', content: <ISubscribed />},
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const selectMenuHandler = (index) => {
    setCurrentTab(index);
  };

  return (

    <div className="main" style={{ backgroundColor: '#Fff' }}>
        <div className='main_content'>
            <div>

            <br></br>

            <Header></Header>

            <TabMenu>
              {menuArr.map((a, i) => (
                <li
                  className={`${currentTab === i ? 'submenu focused' : 'submenu'}`}
                  onClick={() => {
                    selectMenuHandler(i);
                  }}
                  key={i}
                >
                  {menuArr[i].name}
                </li>
              ))}
            </TabMenu>
            <Desc>
              <p>{menuArr[currentTab].content}</p>
            </Desc>
          </div>
        
    </div>
   </div>
  );
};
