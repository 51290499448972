import PropTypes from 'prop-types';
import './SmallNavi.css';

export const SmallNavi = ({ label1, label2, className }) => {
  const NaviClassName = `small-Navi ${className}`;

  return (
    <div className={NaviClassName+'box'}>
      <button className={NaviClassName}>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <div className='SmallNaviText' style={{marginTop:'3px'}}>
          {label1} </div>&nbsp;
          <div className='SmallNaviText2'>{label2}</div>
      </div>
      </button>
    </div>
  );
};

SmallNavi.propTypes = {
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};