import hint_3_img from '../../assets/img/joker.webp';
import React, { useState, useEffect } from 'react';
import Footer from '../../component/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../NewComponent/header';



function Payment() {

  const location = useLocation();
  const requestDbNumber = new URLSearchParams(location.search).get('Mymessage');

  const navigate = useNavigate();

  const handlePayment = () => {
    navigate('/payment/callback');
  }


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <br></br>
   <Header Text="결제하기" />


    <div style={{display:'flex'}}>
      <div style={{
        width:'75px',
        height:'75px',
        backgroundColor :'#F2F3F5',
        borderRadius:'15px',
        marginLeft:'25px'
      }}><img src={hint_3_img} width={'45px'} height={'45px'} style={{marginTop:'15px'}}></img></div>
       
      <div style={{marginLeft:'15px', textAlign:'left'}}>
      <p style={{fontSize:'13px', fontWeight:'600', color:'#8B95A1', marginTop:'5px'}}>상품명 : 무제한 조커 모드
      <br></br>이용 기간 : 1주(7일) / 자동 갱신형 구독</p>
      <p style={{fontSize:'13px', fontWeight:'400', color:'#8B95A1', marginTop:'-10px'}}>조커카드를 무제한으로 이용할 수 있어요</p>

      <p style={{fontSize:'15px', fontWeight:'700', color:'#4E5968', marginTop:'-10px'}}>가격 :  <b style={{color:'#F04452'}}>2900원 / 매주</b></p>


      </div>
    </div>




    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'30px'}}>


 
  <div onClick={handlePayment}  style={{cursor:'pointer',width:'330px', height:'45px', border:'1px solid #E6E9EC', textAlign:'center', margin:'10px auto'}}>
    <p style={{fontSize:'14px', color:'#202632', fontWeight:'500',  lineHeight:'20px'}}>결제 진행하기</p></div>
</div>

<br></br>
<p style={{fontSize:'13px', color:'#8B95A1'}}>※ 결제를 진행 시, 아래의 상품 면책조항과 환불 정책에 동의한 것으로 간주해요<br></br>본 상품은 언제나 구매를 복원하거나 취소할 수 있어요</p>
<br></br>



<div style={{marginLeft:'15px', textAlign:'left', color:'#8B95A1', marginRight:'15px'}}>
<h5 style={{color:'#202632'}}>상품 면책조항</h5>
<div style={{marginTop:'-15px'}}>
<p style={{fontSize:'11px'}}>- 본 상품에서 제공하는 것은 예상 정보로서 어떠한 정확성/신뢰성 책임지지 않아요.</p>
<p style={{fontSize:'11px'}}>- 본 상품은 외부 DB에 의존하는 서비스를 포함하기에 어떠한 정확성/신뢰성을 보장하지 않아요.</p></div>

<h5 style={{color:'#202632'}}>환불 정책 </h5>
<div style={{marginTop:'-15px'}}>
<p style={{fontSize:'11px'}}>- 결제 완료 후 힌트를 사용자가 확인 하지 않았다면, 환불을 결제를 진행한 앱스토어에서 가능해요.</p>
<p style={{fontSize:'11px'}}>- 결제 과정에서 오류가 등으로 사용이 비정상적일 때, 환불을 고객센터로 요청할 수 있어요.</p>
<p style={{fontSize:'11px'}}>- 만약 결제 완료 후 힌트를 확인했다면, 상품에 사용으로 간주되며 환불을 진행할 수 없어요.</p>
<p style={{fontSize:'11px'}}>- 비정상적인 방법으로 환불 정책, 결제 정책을 악용한다면 서비스를 차단하고 거부할 수 있어요</p>

</div>

</div>

        
        </div>
        <Footer></Footer>

        </div>
  );
}

export default Payment;
