import React, { useState, useEffect }  from "react";
import PrimaryButton from "./PrimaryButton";
import c_barrier  from "../NewComponent/assets/c_barrier.webp"
import { motion } from 'framer-motion';

export default function EventBlock({img,text_line1,text_line2,animate,button_text} ) {

    const [buttonAnimate, setButtonAnimate] = useState('initial');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonAnimate((prevAnimate) => (prevAnimate === 'highlight' ? 'initial' : 'highlight'));
    }, 600); // 1초마다 애니메이션을 토글

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시에 인터벌 해제
    };
  }, []);

  const buttonContainerStyle = {
    display:'flex',
    justifyContent:'center',
    marginTop:'43px'
  };

  const buttonStyle = {
    display: 'flex',
    width: '308px',
    padding: '14px 119px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#FF5C00',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap',
  };

  const buttonVariants = {
    initial: {
      scale: 1,
      x: 0,
      rotate: 0,
    },
    highlight: {
      scale: 1.05,
      x: [0, -5, 5, -3, 3, 0], // 좌우로 움직임
      rotate: [0, -5, 5, -3, 3, 0], // 텍스트 회전 애니메이션
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10,
      },
    },
  };

  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <div style={{ background:'#FBF5F5', width:'340px',height:'280px',borderRadius:'15px'}}>
        <div style={{marginTop:'24px'}}><img style={{width:'100px'}} src={img} ></img></div>
        <div style={{marginTop:'3px'}} className="EventBlock_font">{text_line1}<br></br>{text_line2}</div>
        <div style={buttonContainerStyle} >
            <motion.button
                style={buttonStyle}
                variants={buttonVariants}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'} // animate prop에 따라 애니메이션 활성화
                whileTap={{ scale: 0.95 }}
            >
                <span style={buttonTextStyle}>{button_text}</span>
            </motion.button>
            </div>
      </div>
    </div>
  );
}
