import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jsonwebtoken/decode';
import { ReactComponent as StartLogo } from "../stories/assets/startlogo.svg";
import { CSSTransition } from 'react-transition-group';

function HoxyPass() {
  const navigate = useNavigate();
  const isFirstRender = useRef(true); 
  const [showLogo] = useState(true);
  

  useEffect(() => {
    const autoLogin = async () => {

      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          const userId = decodedToken.user_id;
          const userNo = decodedToken.user_no;
          localStorage.setItem('user_id', userId);


           {/* await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); */}

          const timeout = setTimeout(() => {
            navigate(`/${userId}?explore=yes`);
          }, 2000); 
        } catch (error) {
          console.error('자동 로그인 실패:', error);

          const timeout = setTimeout(() => {
            navigate('/start');

          }, 2000); 
        }
      } else {

        const timeout = setTimeout(() => {
          navigate('/start');
        }, 2000); 
      }
    };

    if (isFirstRender.current) { 
      autoLogin();
      isFirstRender.current = false; 
    }
  }, [navigate]);

  return (
<div className="main" style={{ backgroundColor: '#FF5C00' }}>
  <div className="main_content" style={{ backgroundColor: '#FF5C00', touchAction: 'none', height: '100vh', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
    
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <CSSTransition in={showLogo} timeout={1000} classNames="fade">
      <StartLogo className="content" />
    </CSSTransition>
    </div>
  </div>
</div>

  
  );
}

export default HoxyPass;
