import React from 'react';
import { motion } from 'framer-motion';

export default function SecondaryButtonSmall2({ Text, img }) {


  const buttonStyle = {
    display: 'flex',
    width: '100px',
    padding: '15px 80px',
    justifyContent: 'center',
    background : '#FBF6F6',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    border: '3px solid #fff',    
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    color: '#FF5C00',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  return (
    <div>
      <motion.button
        style={buttonStyle}
        whileTap={{ scale: 0.95 }}
      >
        <span style={buttonTextStyle}>{Text}</span> <img src={img} width='22px' style={{ marginLeft: '-3px', marginTop: '-2px' }} />
      </motion.button>
    </div>
  );
}
