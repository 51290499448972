import { useEffect, useRef } from "react";

function KakaoAdFit({ unit, width, height, disabled }) {
  const scriptElementWrapper = useRef(null);

  useEffect(() => {
    if (!disabled) {
      const loadScript = () => {
        const script = document.createElement("script");
        script.src = "https://t1.daumcdn.net/kas/static/ba.min.js";
        script.type = "text/javascript";
        script.onload = () => {
          console.log("애드핏 호출 완료");
        };

        // 광고 스크립트를 페이지의 body 끝 부분에 추가합니다.
        document.body.appendChild(script);
      };

      loadScript();
   

      return () => {
        if (window.kakao) {
          window.kakao.destroyAdFit(unit);
          console.log("애드핏 삭제!");
        }
      };
    }
  }, [unit, disabled]);

  return (
    <div>
      <ins
        className="kakao_ad_area"
        data-ad-unit={unit}
        data-ad-width={width}
        data-ad-height={height}
      ></ins>
    </div>
  );
}

export default KakaoAdFit;
