import { ReactComponent as MainPlogo2 } from "../stories/assets/MainPlogo2.svg";

const Logo1 = () => {
    return (
        <div style={{
     
        fontFamily: 'Pretendard',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '20px',
        display : 'flex',
        textAlign: 'center',
        letterSpacing: '-0.6px',
        marginTop : '-2px',
        textAlign: 'center',        
        color: '#000000',}}>
            <MainPlogo2  /> 
            <div style={ {marginTop : '6px', marginLeft:'5px'}}>HOXY</div>
        </div>
  );
  };
  
  export default Logo1;