import React, { useState, useEffect, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import CastingInput from '../../NewComponent/CastingInput';
import OnboardingMiddele1 from '../../NewComponent/OnboardingMiddle1';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import axios from 'axios';
import { motion, AnimatePresence  } from 'framer-motion'
import Two_Student from '../../assets/img/boy-going-to-school 2.webp';
import password_img from '../../assets/img/strong_password.webp';
import { CheckboxProvider, Checkbox } from '../../NewComponent/CheckBox';
import CastingSelec from '../../NewComponent/CastingSelec';
import jwt_decode from 'jsonwebtoken/decode';
import * as amplitude from '@amplitude/analytics-browser';

function OnBoarding_PhoneNum() {
  const [currentContent, setCurrentContent] = useState(1);
  const [previousContent, setPreviousContent] = useState(1);

  const [tutorialStep, setTutorialStep] = useState(1)
  const [showStep, setShowStep] = useState(true);
  const [counter, setCounter] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [locationValue, setLocationValue] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [Password, setPassword] = useState('');
  const [CheckPassword, setCheckPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);


  const [Name, setNameId] = useState('');
  const [Birth, setBirthId] = useState('');
  const [Gender, setGenderId] = useState("");

  localStorage.setItem('user_kakao_email', "normal_signup");
  localStorage.setItem('user_kakao_age', "normal_signup");
  localStorage.setItem('user_kakao_id', "normal_signup");
  localStorage.setItem('user_kakao_imgurl', "normal_signup");
  
  

     
  const navigate = useNavigate();



  const handleNextClick = () => {
      if (currentContent < 4) {
          setPreviousContent(currentContent);
          setCurrentContent(currentContent + 1);
      }
  };

  const handlePreviousClick = () => {
      if (previousContent >= 1) {
          setCurrentContent(previousContent);
          setPreviousContent(currentContent - 1);
      }
  };







  
  useEffect(() => {
    if (currentContent === 4) {
      const changeStep = () => {
        setShowStep(false);
        setTimeout(() => {
          setTutorialStep((prevStep) => (prevStep === 4 ? 1 : prevStep + 1));
          setShowStep(true);
          setCounter((prevCounter) => prevCounter + 1);
        }, 500);
      };
    const timer = setInterval(changeStep, 4000);
    return () => {
      clearInterval(timer);
    };
  }
}, [currentContent]);

function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length < 4) {
    return phoneNumber;
  } else if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  } else if (phoneNumber.length < 11) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`;
  }
  // 최대 길이를 초과하는 경우 뒷부분을 잘라냅니다.
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
}

    
const handleGenderChange = (newValue) => {
  setGenderId(newValue);
  localStorage.setItem('user_gender', newValue);

};
  
  const handleInputChange_phoneumber = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');


    if (!isNaN(value) && value.length <= 11) {
      const formattedValue = formatPhoneNumber(numericValue);
      setPhoneNumber(value);
      localStorage.setItem('user_phoneNumber', formattedValue);

    }
  };

  const handleInputChange_login_phonenumber = async (e) => {
    setShowPasswordInput(false);
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');

  
    if (!isNaN(value) && value.length <= 11) {
      const formattedValue = formatPhoneNumber(numericValue);
      setPhoneNumber(value);
      localStorage.setItem('user_phoneNumber', formattedValue);
  
      if (value.length === 11) { // 전화번호가 11자리일 때만 API 요청 보냄
        try {
          const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/checkPhoneNumber', { user_phonenumber: value });
  
          if (response.data.exists) {
            if (response.data.hasKakaoId) {
              alert('이전에, 카카오계정으로 가입한적 있어요. 카카오로 시작해주세요');
              navigate('/start');
            } else {
              setShowPasswordInput(true);
            }
          } else {

        
              setCurrentContent(2);
            
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {

      
      const numericValue = phoneNumber.replace(/\D/g, '');
      const formattedValue = formatPhoneNumber(numericValue);

      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/login', {
        user_phonenumber: formattedValue, 
        user_password: Password
      });

      const { token } = response.data;

      localStorage.setItem('token', token);
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.user_id;
      const userNo = decodedToken.user_no;

      localStorage.setItem('user_id', userId);


      await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); 

      amplitude.init("4cb7df50686c6cb578ab1fedb0e182ab", userId); 

      navigate(`/${userId}`);
    } catch (error) {
      console.error('로그인 실패:', error);
      if (error.response && error.response.status === 401) {
        alert('없는 계정이거나, 아이디 또는 비밀번호가 잘못되었습니다')
      } else {
        alert('서버 오류가 발생했습니다. 나중에 다시 시도해주세요.')

      }
    }
  };
  
  const handleInputChange_password = (event) => {
    const inputValue = event.target.value;
    setPassword(inputValue);
    localStorage.setItem('user_Password', inputValue);
  };
  
  const handleInputChange_password_check = (event) => {
    const inputValue = event.target.value;
    setCheckPassword(inputValue);

  };
  

  const nameInputChange = (e) => {
    const value = e.target.value;

    if (value.length <= 3) {
      setNameId(e.target.value);
      localStorage.setItem('user_name', e.target.value); 
    }
  };



  const birthInputChange = (e) => {
    const inputValue = e.target.value;
    const numberRegex = /^[0-9]*$/;
  
    if (numberRegex.test(inputValue) && inputValue.length <= 4) {
      const currentYear = new Date().getFullYear();
      const userBirthYear = parseInt(inputValue, 10);
      const koreanAge = currentYear - userBirthYear + 1;
  
      setBirthId(inputValue);
      localStorage.setItem('user_kakao_year', inputValue);
      localStorage.setItem('user_grade', koreanAge.toString());
    }
  };




  const checkMustAgreement = () => {
    const mustChecked = localStorage.getItem('must_check')

    if (!mustChecked) {
      alert('필수 약관에 동의해야해요.');
      return false; // 약관 동의가 필요한 경우 함수 종료
    }

    if(!Name || !phoneNumber || !Birth || !Gender)
    {
      alert('입력되지 않은 값이 있어요');
      return false; // 약관 동의가 필요한 경우 함수 종료
    }

    else {
    handleNextClick();  
    }
  };
  
  
  const handleNextOnboarding = () => {

    const currentYear = new Date().getFullYear();
    const userBirthYear = parseInt(Birth, 10);
    const koreanAge = currentYear - userBirthYear + 1;
    const numericKoreanAge = parseInt(koreanAge, 10);

    if (numericKoreanAge <= 15) {
      navigate('/onboarding_underage');
    } else if (numericKoreanAge >= 20) {
      navigate('/OnBoarding_University');
    } else {
      navigate('/onboarding');
    }
};


  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className='main_content'>

      {(currentContent === 1 || currentContent === 2) && (
          <a onClick={handlePreviousClick}>
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
        )}
                

      <div className='Content-Casting'>
        <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>

           <OnboardingMiddele1  middle_compo='middle_component_25' img={Two_Student} Text1='사용자님에 대해 알려주세요' Text2='시작하기 위해 사용자님의 정보가 필요해요' />

          
  
            <div className='middle_component' style={{marginLeft:'-20px', marginTop:'100px'}}>
            <div style={{position:'sticky'}}>
            <CastingInput num={'1'} what={'전화번호'} value={phoneNumber} onChange={handleInputChange_login_phonenumber} />
            </div>        

            {showPasswordInput && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              >
            <div style={{ marginTop: '70px',position:'sticky', overflow:'none' }} >
            <CastingInput num={'2'} what={'비밀번호'} type='password' onChange={handleInputChange_password} />
            </div>
            </motion.div>
            )}
            </div>

              <AnimatePresence>
                {phoneNumber.length === 11 && Password.length >= 1 && showPasswordInput && (
                  <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.3 }}
                  onClick={handleLoginSubmit}
                  >
                    <div >
                    <PrimaryButton Text="이미 만든 계정으로, 로그인" ></PrimaryButton>
                    </div>
                    </motion.div>
                )}
              </AnimatePresence>




      

  
     
   
        </div>

        <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>

        <OnboardingMiddele1  middle_compo='middle_component_25' img={Two_Student} Text1='사용자님에 대해 알려주세요' Text2='시작하기 위해 사용자님의 정보가 필요해요' />



        <div className='middle_component' style={{marginLeft:'-20px', marginTop:'100px'}}>
            <div style={{position:'sticky', }}>
            <CastingInput num={'1'} what={'전화번호'} value={phoneNumber} onChange={handleInputChange_phoneumber} />
            </div>        

            <br></br>
          <br></br>
          <br></br>


            <div style={{position:'sticky', overflow:'none' }} >
            {phoneNumber.length === 11 && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              >
            <CastingInput num={'3'} what={'이름'} value={Name} onChange={nameInputChange} />
            </motion.div>
            )}
          </div>
          
          <br></br>
          <br></br>
          <br></br>


          <div style={{ position:'sticky', overflow:'none' }} >
            {Name.length >= 1 && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              >
            <CastingSelec what="성별" type="gender"  onChange={handleGenderChange} />
            </motion.div>
            )}
            </div>

            <br></br>
          <br></br>
          <br></br>


            <div style={{ position:'sticky', overflow:'none' }} >
            {Gender.length >= 1 && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              >
            <CastingInput num={'5'} what={'출생년도'} value={Birth} onChange={birthInputChange} />
            </motion.div>
            )}
            </div>


            {Birth.length >= 3 && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              style={{marginTop:'-25px'}}
              >


            <div style={{ marginTop: '3px',position:'sticky', overflow:'none' }} >
            <CheckboxProvider>
            <Checkbox value="must_check" >(필수) &nbsp;<a href='https://hoxy.one/term/privacy-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>개인정보처리방침</a> &nbsp;및&nbsp; <a href='https://hoxy.one/term/terms-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>이용약관</a>에 동의해요</Checkbox>
            </CheckboxProvider>  
            </div>
            <br></br>
         

            <div style={{ marginTop: '3px',position:'sticky', overflow:'none' }} >
            <CheckboxProvider>
            <Checkbox value="information_check" >(선택) HOXY의&nbsp;<a href='https://hoxy.one/term/information-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>정보성메시지 수신</a>에 동의해요</Checkbox>
            </CheckboxProvider>    
            </div> 
            <br></br>

            <div style={{ marginTop: '3px',position:'sticky', overflow:'none' }} >
            <CheckboxProvider>
            <Checkbox value="marketing_check">(선택) HOXY의&nbsp;<a href='https://hoxy.one/term/marketing-20230727.html' style={{color:'#000', textDecorationLine:'underline'}}>맞춤형·마케팅메시지 수신</a>에 동의해요</Checkbox>
            </CheckboxProvider>     
            </div>


   

            </motion.div>
            )}




            </div>



      
  
               <AnimatePresence>
                {Birth && (
                  <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.3 }}
                  onClick={() => { checkMustAgreement();  }}                  
                  >
                    <div >
                    <PrimaryButton Text="다음" ></PrimaryButton>
                    </div>
                    </motion.div>
                )}
              </AnimatePresence>

        </div>





        <div id='Content3' style={{ display: currentContent === 3 ? 'block' : 'none' }}>

        <OnboardingMiddele1  middle_compo='middle_component_25' img={password_img} Text1='강력한 나만의 비밀번호가 필요해요' Text2='로그인 시 사용할 비밀번호를 입력해주세요' />



        <div className='middle_component' style={{marginLeft:'-20px', marginTop:'100px'}}>
            <div style={{position:'sticky', }}>
            <CastingInput num={'2'} what={'비밀번호'} type='password' onChange={handleInputChange_password} />
            </div>        

            {phoneNumber.length === 11 && (
              <motion.div                          
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0}}
              transition={{ duration: 0.3 }}
              >
            <div style={{ marginTop: '65px',position:'sticky', overflow:'none' }} >
            <CastingInput num={'2'} what={'비밀번호 확인'} type='password' onChange={handleInputChange_password_check} />
            </div>
            </motion.div>
            )}
      </div>





       <AnimatePresence>
        { Password.length >= 1 && Password === CheckPassword && (
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{ duration: 0.3 }}
          onClick={handleNextOnboarding}
          >
            <div >
            <PrimaryButton Text="다음" ></PrimaryButton>
            </div>
            </motion.div>
        )}
      </AnimatePresence>

      </div>






        </div>
      </div>
    </div>
  );
}

export default OnBoarding_PhoneNum;