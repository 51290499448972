import React from 'react';
import { BOTTOM_SHEET_HEIGHT} from './MiddleSheetOption';
import styled from 'styled-components';
import { motion } from "framer-motion";
import useMiddleSheet from './useMiddleSheet';
import Header from './Header2';
import Content2 from './Content2';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 1;
  top: calc((100% - var(--sheet-height)) / 2); /* Automatically adjusts based on device height */
  left: calc((100% - var(--sheet-width)) / 2); /* Automatically centers based on device width */
  right: 0;

  border-radius: 15px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  height: var(--sheet-height); /* Automatically adjusts based on device height */

  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

  transition: transform 650ms ease-out;

  width: var(--sheet-width); /* Automatically adjusts based on device width */
`;

// Set CSS variables based on the device dimensions
const deviceWidth = 390;
const deviceHeight = 844;

const sheetWidth = 323;
const sheetHeight = 537;

document.documentElement.style.setProperty('--sheet-width', `${sheetWidth}px`);
document.documentElement.style.setProperty('--sheet-height', `${sheetHeight}px`);


const BottomSheetContent = styled.div`
  overflow: auto;                            
  -webkit-overflow-scrolling: touch;
`

function MiddleSheet({ onClose, user_id , user_class, user_grade, user_name ,user_gender }) {

  const { sheet, content } = useMiddleSheet();

  return (
    <Wrapper ref={sheet}>
      <Header />
      <BottomSheetContent ref={content}>
        <Content2 onClose={onClose} user_id={user_id} user_class={user_class} user_grade={user_grade} user_name={user_name} user_gender={user_gender} />
      </BottomSheetContent>
    </Wrapper>
  );
}

export default MiddleSheet;