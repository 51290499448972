import React, { useState,useEffect } from 'react';
import Header from '../../NewComponent/header';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import img_src from '../../assets/img/alarm-clock (2).png'
import PrimaryButton from '../../NewComponent/PrimaryButton';
import SecondaryButton from '../../NewComponent/SecondaryButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';

function Landing() {
  const [tokenUserID, setTokenUserID] = useState('');
  const navigate = useNavigate();
  const [schoolName, setSchoolName] = useState('');
  const [schoolCode, setschoolCode] = useState('');
  const [studentCount, setStudentCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      const decodedToken = jwt_decode(token);
      const tokenUserID = decodedToken.user_id;
      setTokenUserID(tokenUserID);
  
      fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
           method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
      },
      body: JSON.stringify({ user_id: tokenUserID, user_token: token })
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error('사용자를 찾을 수 없음');
          }
          return response.json();
        })
        .then((userData) => {
          const { school_name, school_code } = userData;
  
          setSchoolName(school_name);
          setschoolCode(school_code);


          const schoolApiUrl = 'https://backend-hoxy-one-api.hoxy.one:4004/api/school/school_info';

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ school_code: school_code }),
          };

          fetch(schoolApiUrl, requestOptions)
            .then((response) => {
              if (!response.ok) {
                throw new Error('학생수를 가져올 수 없습니다.');
              }
              return response.json();
            })
            .then((data) => {
              setStudentCount(data.student_count);


              if (data.student_count >= 40) {
                alert('많은 학교가 몰리고 있어, 40명이 넘었지만 오픈되지 않았을 수 있어요. 고객센터로 연락주시면, 빠르게 조치해드릴게요');
              }

           
            })
            .catch((error) => {
              console.error(error);
            });
  
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert('로그인이 필요해요');
      navigate('/');
    }
  }, []);
  
  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
    <div className="main_content" style={{ backgroundColor: '#FFFFFF'}}>   
    <div> 
    <br></br>
      <Header Text="대신 전해드립니다"/>

      <MiddleCompoent img={img_src} Text1="우리학교 친구 40명이 모이지 않아서" Text2={<span>대.전이 열리지 않았어요 <span style={{ color: '#FF5C00' }}>({studentCount}/40명)</span></span>} />

      <a href="https://forms.gle/s5rXVWo3bfNAeE1G7" target="_blank">
      <SecondaryButton Text="내가 바라는 기능을 알려주기" />
      </a>
      <Link to='/share'>
      <PrimaryButton Text="공유하고, 우리학교 친구 데려오기"  />
      </Link>
      </div>  
    </div>
    </div>
  );
}

export default Landing;
