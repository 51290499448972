import React, { useState } from 'react';

export default function CastingReason({ onSaveText }) {
  const [answerText, setAnswerText] = useState('');

  const handleAnswerTextChange = (e) => {
    const text = e.target.value;
    setAnswerText(text);
    autoAdjustTextareaHeight(e.target);

    // 텍스트를 저장하는 함수를 호출하여 저장
    onSaveText(text);
  };

  const autoAdjustTextareaHeight = (element) => {
    // 스크롤 높이로 설정하기 전에 높이 초기화
    element.style.height = 'auto';

    // 내용의 실제 높이를 계산하여, 한 줄보다 높으면 높이를 조정
    if (element.scrollHeight > element.clientHeight) {
      element.style.height = element.scrollHeight + 'px';
    }
  };

  return (
    <div className="CastingReasonBox">
      <textarea
        style={{ width: '271px', minHeight: '30px' }}
        className="CastingReasonInput"
        value={answerText}
        onChange={handleAnswerTextChange}
        placeholder="탭해서 직접 입력하기"
      ></textarea>
    </div>
  );
}