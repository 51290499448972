import React from 'react';

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 8,
    width: '317px',
    backgroundColor: "#e0e0de",
    borderRadius: 60,
    marginTop: '16px',
    marginLeft: '17px'
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    background: bgcolor, // Use 'background' instead of 'backgroundColor'
    borderRadius: 'inherit',
    textAlign: 'right'
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
      </div>
    </div>
  );
};

export default ProgressBar;
