import React from 'react';
import { motion } from 'framer-motion';
import default_img from '../assets/img/success.png'

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 50, // 아래에서 위로 올라오도록 설정
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1, // 애니메이션 지속 시간
      ease: 'easeOut', // 이징 설정
    },
  },
};


export default function MiddleCompoent({ size, img, Text1, Text2 }) {
  const imageUrl = img || default_img; // 이미지가 없으면 기본 이미지 사용
  const imageSize = size || '130px'; // 이미지가 없으면 기본 이미지 사용
  return (
    <div className='middle_component'>
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        <motion.img
          src={imageUrl}
          alt="박수 치는 이미지"
          style={{
            width: imageSize,
            height: imageSize
          }}
        />

        <motion.p
          className='middle_component_text'
          variants={containerVariants}
          initial='hidden'
          animate='visible'
        >
          {Text1} <br></br>
          {Text2}
        </motion.p>
      </motion.div>
    </div>
  );
}
