import React, { useState, useEffect } from 'react';
import { LargeButton } from '../stories/LargeButton';
import { useNavigate } from 'react-router-dom';
import { Tittle } from '../stories/Tittle';
import { isMobilePhone } from '@toss/validators';

function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [gender, setGender] = useState('');
  const [grade, setGrade] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  useEffect(() => {
    const storedName = localStorage.getItem('user_name');
    const storedPhoneNumber = localStorage.getItem('user_phoneNumber');
    const storedGender = localStorage.getItem('user_gender');
    const storedGrade = localStorage.getItem('user_grade');

    if (storedName) setName(storedName);
    if (storedPhoneNumber) setPhoneNumber(storedPhoneNumber);
    if (storedGender) setGender(storedGender);
    if (storedGrade) setGrade(storedGrade);
  }, []);

  useEffect(() => {
    localStorage.setItem('user_name', name);
    localStorage.setItem('user_phoneNumber', phoneNumber);
    localStorage.setItem('user_gender', gender);
    localStorage.setItem('user_grade', grade);
  }, [name, phoneNumber, gender, grade]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value.replace(/[^0-9]/g, '');
    const formattedWithHyphens = formattedPhoneNumber.replace(
      /^([0-9]{3})([0-9]{4})([0-9]{4})$/,
      '$1-$2-$3'
    );
    setPhoneNumber(formattedWithHyphens);
    setIsPhoneNumberValid(isMobilePhone(formattedWithHyphens));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmedPasswordChange = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const isValidPhoneNumber = isMobilePhone(phoneNumber, 'ko-KR');
    setIsPhoneNumberValid(isValidPhoneNumber);

    const isMatch = password === confirmedPassword;
    setIsPasswordMatch(isMatch);

    if (isValidPhoneNumber && isMatch) {

      localStorage.setItem('user_name', name);
      localStorage.setItem('user_phoneNumber', phoneNumber);
      localStorage.setItem('user_gender', "male"); //애플 심사 대응
      localStorage.setItem('user_grade', grade);
      localStorage.setItem('user_Password', password);

      localStorage.setItem('user_kakao_email', "normal_signup");
      localStorage.setItem('user_kakao_year', "normal_signup");
      localStorage.setItem('user_kakao_age', "normal_signup");
      localStorage.setItem('user_kakao_id', "normal_signup");
      localStorage.setItem('user_kakao_imgurl', "normal_signup");      

      localStorage.setItem("information_check", "normal_signup");
      localStorage.setItem("marketing_check", "normal_signup");

      if (grade === '20') {
        localStorage.setItem('user_school', '20세 이상 사용자');
        navigate('/almost');
      } else {
        navigate('/onboarding');
      }
    }
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <div>

<a href="javascript:window.history.back();">
<div class="top_bar">
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left top_icon">
  <polyline points="15 18 9 12 15 6"></polyline></svg>        
</div>
</a>
  <div style={{display:'flex',justifyContent:'center', marginTop:'20px'}}>
    <img src='https://resource.hoxy.one/logos/hoxy_logo_2.svg' width={'65px'} alt="logo"></img>

  
  </div>        


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '55px' }}>
            <Tittle className='normal' label1='"누가 나랑 친해지고 싶어할까?"' label2='평균 가입시간 10초, 나의 프로필을 만들어보세요'></Tittle>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', justifyContent: 'center', textAlign: 'center' }}>
          <form onSubmit={handleFormSubmit}>
            <div style={{ display: "flex", flexDirection: 'column', marginTop: '60px' }}>

              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className='Nameinput'
                  placeholder='이름'
                  value={name}
                  onChange={handleNameChange}
                  required
                ></input>
              </div>

              <div style={{ marginTop: '16px' }}></div>
              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className='Nameinput'
                  placeholder='전화번호 (예: 010-1234-5678)'
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                ></input>
                {!isPhoneNumberValid && <span style={{ color: 'red' }}><br></br><br></br>올바른 전화번호가 아니에요 !</span>}
              </div>

              <div style={{ marginTop: '16px' }}></div>
              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className='Nameinput'
                  placeholder='사용할 비밀번호'
                  type='password'
                  value={password}
                  onChange={handlePasswordChange}
                  required
                ></input>
              </div>

              <div style={{ marginTop: '16px' }}></div>
              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className='Nameinput'
                  placeholder='사용할 비밀번호 확인'
                  type='password'
                  value={confirmedPassword}
                  onChange={handleConfirmedPasswordChange}
                  required
                ></input>
                {!isPasswordMatch && <span style={{ color: 'red' }}><br></br><br></br>비밀번호가 일치하지 않아요 !</span>}
              </div>

           {/* 애플 심사 대응!  <div style={{ marginTop: '16px' }}></div>
           성별 빼고 로컬스토리지에도 자동 가입 

              <div>
                <select
                  className='SelectBox'
                  value={gender}
                  onChange={handleGenderChange}
                  required
                >
                  <option value="">성별</option>
                  <option value="female">여성</option>
                  <option value="male">남성</option>
                </select>
  </div> */}

              <div style={{ marginTop: '16px' }}></div>

              <div>
                <select
                  className='SelectBox'
                  value={grade}
                  onChange={handleGradeChange}
                  required
                >
                  <option value="">출생년도</option>
                  <option value="20">20세 이상이에요 :)</option>
                  <option value="19">2005년 (고등학교 3학년)</option>
                  <option value="18">2006년 (고등학교 2학년)</option>
                  <option value="17">2007년 (고등학교 1학년)</option>
                  <option value="16">2008년 (중학교 3학년)</option>
                  <option value="15">2009년 (중학교 2학년)</option>
                  <option value="14">2010년 (중학교 1학년)</option>
                </select>
              </div>
          
              <div style={{marginTop:'30px', display: "flex", flexDirection: 'column',}}>
              <div style={{ display: 'flex', float: 'left', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', marginTop: '3px', marginRight: '25px', textAlign:'left', lineHeight:'25px', marginLeft:'5px' }}>
                <span><a href={process.env.PUBLIC_URL + "/term/privacy-20230727.html"} target='_blank' style={{ textDecoration: "underline", color: '#000000' }}>개인정보처리방침</a>을 확인하고, 동의해주세요.</span>
                </div>
              </div>

              <div style={{ display: 'flex', float: 'left', justifyContent: 'center', marginTop: '10px' }}>
                <div style={{ fontSize: '14px', marginTop: '3px', marginRight: '33px', textAlign:'left', lineHeight:'25px', marginLeft:'5px' }}>
                <span><a href={process.env.PUBLIC_URL + "/term/terms-20230727.html"} target='_blank' style={{ textDecoration: "underline", color: '#000000'}}>서비스 이용약관</a>을 확인하고, 동의해주세요.</span>
                </div>
              </div>
              </div>

              <div style={{ marginTop: '35px', marginBottom:'35px' }}>
                <LargeButton className='primary' label='10초만에 가입하고' label2='내 프로필 만들기'></LargeButton>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
