import React from "react";
import { ReactComponent as Cupid } from "./assets/Cupid.svg";
import { ReactComponent as Hands } from "./assets/Hands.svg";
import { ReactComponent as Instagram } from "./assets/Instagram.svg";
import { ReactComponent as ClovaHands } from "./assets/ClovaHands.svg";
import { ReactComponent as Hands2 } from "./assets/Hands2.svg";
import { ReactComponent as Earth } from "./assets/Earth.svg";
import { ReactComponent as Vote } from "./assets/Vote.svg";
import { ReactComponent as Noon } from "./assets/Noon.svg";
import { ReactComponent as First } from "./assets/First.svg";
import { ReactComponent as Second } from "./assets/Second.svg";
import { ReactComponent as Third } from "./assets/Third.svg";
import { ReactComponent as GoHoxy } from "./assets/GoHoxy.svg";
import { ReactComponent as GoInsta } from "./assets/GoInsta.svg";


export default function SugBox({stuff,user_id,CompoName}) {

    const Sugcomponents = {
        Cupid: <Cupid />,
        Hands: <Hands />,
        Instagram : <Instagram />,
        ClovaHands : <ClovaHands />,
        Hands2 : <Hands2 />,
        Vote : <Vote />,
        Noon : <Noon />,
        First : <First />,
        Second : <Second />,
        Third : <Third />,
        Earth : <Earth />,
      };

  return (

        <div className="SugBox"> 
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}> 
                <div style={{ marginTop: '5px' }}>{Sugcomponents[CompoName]}</div>
                <div className="SugText">{user_id}님과<br></br> <b>{stuff} 어때요?</b></div>
                <div style={{ marginTop: '4px', display:'flex',justifyContent:'center'}}>
                    <div><GoHoxy />,</div>
                    <div><GoInsta /></div>
                </div>
                <div></div>
            </div>
        </div>
    
  );
}
