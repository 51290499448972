import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

export default function SkeletonLoader() {
  const [viewBoxWidth, setViewBoxWidth] = useState(390); // 초기 값 설정

  useEffect(() => {
    // 화면 크기가 변경될 때마다 호출되는 함수
    const handleResize = () => {
      // 원하는 로직에 따라 viewBoxWidth 값을 계산
      const newViewBoxWidth = window.innerWidth > 600 ? 600 : 390;
      setViewBoxWidth(newViewBoxWidth);
    };

    // 처음 컴포넌트가 마운트될 때 resize 이벤트에 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열은 컴포넌트가 처음 마운트될 때만 실행하도록 합니다.

  // viewBoxWidth에 대한 비율 계산
  const viewBoxRatio = viewBoxWidth / 390;

  return (
    <ContentLoader viewBox={`0 0 ${viewBoxWidth} 860`}>

      <rect x={32 * viewBoxRatio} y={80 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={320 * viewBoxRatio} height={170 * viewBoxRatio} />

      <rect x={122 * viewBoxRatio} y={269 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={145 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={46 * viewBoxRatio} y={313 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={145 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={198 * viewBoxRatio} y={313 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={145 * viewBoxRatio} height={40 * viewBoxRatio} />

      <rect x={0 * viewBoxRatio} y={380 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={390 * viewBoxRatio} height={50 * viewBoxRatio} />
     
      <rect x={25 * viewBoxRatio} y={454 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={340 * viewBoxRatio} height={70 * viewBoxRatio} />

      <rect x={25 * viewBoxRatio} y={547 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={174 * viewBoxRatio} height={17 * viewBoxRatio} />
      <rect x={300 * viewBoxRatio} y={547 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={72 * viewBoxRatio} height={18 * viewBoxRatio} />
         
      <rect x={25 * viewBoxRatio} y={586 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={150 * viewBoxRatio} height={150 * viewBoxRatio} />
      <rect x={192 * viewBoxRatio} y={586 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={150 * viewBoxRatio} height={150 * viewBoxRatio} />
      <rect x={357 * viewBoxRatio} y={586 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={150 * viewBoxRatio} height={150 * viewBoxRatio} />

      <rect x={25 * viewBoxRatio} y={766 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={174 * viewBoxRatio} height={17 * viewBoxRatio} />
      <rect x={25 * viewBoxRatio} y={797 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={340 * viewBoxRatio} height={100 * viewBoxRatio} />
      <rect x={25 * viewBoxRatio} y={904 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={340 * viewBoxRatio} height={100 * viewBoxRatio} />
    </ContentLoader>
  );
}
