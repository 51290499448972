import React, { useState } from 'react';
import { ReactComponent as TutorialMessage } from '../stories/assets/TutorialMessage.svg';

function Tutorial() {
  const [position, setPosition] = useState({ marginTop: 0, marginLeft: 0 });
  const [clicked, setClicked] = useState(false);

  const handleDivClick = () => {
    const newMarginTop = Math.floor(Math.random() * 200);
    const newMarginLeft = Math.floor(Math.random() * 200);

    setPosition({ marginTop: '100', marginLeft: '100' });

    if (!clicked) {
      setClicked(true);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '100',
        marginTop: `${position.marginTop}px`,
        marginLeft: `${position.marginLeft}px`,
        cursor: 'pointer',
      }}
      onClick={handleDivClick}
    >
      <TutorialMessage></TutorialMessage>
      <div style={{ marginTop: '-55px' }}>
        <div
          style={{
            color: '#000',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.8px',
          }}
        >
          {clicked ? "여기를 눌러보세요!" : "나와 친해지고 싶은 사람의 ‘요청’이 도착!"}
        </div>
        <div
          style={{
            color: '#000',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            letterSpacing: '-0.8px',
          }}
        >
          {clicked ? "누군지 알 수 있는 답장과 힌트가 있어요" : "이제 힌트를 보거나 답장을 볼 수 있어요 💌"}
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
