import React from 'react';
import { BOTTOM_SHEET_HEIGHT} from './BottomSheetOption';
import styled from 'styled-components';
import { motion } from "framer-motion";
import useBottomSheet from './useBottomSheet';
import Header from './Header';
import Content from './Content';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: calc(100% - 300px); /*시트가 얼마나 높이 위치할지*/
  left: 0;
  right: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  height: ${BOTTOM_SHEET_HEIGHT}px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  width: 100%;
`

const BottomSheetContent = styled.div`
  overflow: auto;                            
  -webkit-overflow-scrolling: touch;
  height: ${BOTTOM_SHEET_HEIGHT}px;
  margin-top: 30px;

`

function BottomSheet({needCard, needReason, needReasonText}) {


  const { sheet, content } = useBottomSheet();

  return (
    <Wrapper ref={sheet}>
      <BottomSheetContent ref={content}>
        <Content needCard={needCard} needReason={needReason} needReasonText={needReasonText}/>
      </BottomSheetContent>
    </Wrapper>
  );
}

export default BottomSheet;