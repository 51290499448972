import React from 'react';
import PrimaryButton from '../../../NewComponent/PrimaryButton';
import MiddleCompoent from '../../../NewComponent/MiddleCompoent';

function Success() {


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>
      
        <MiddleCompoent Text1="결제에 성공했어요" Text2="이제 내 미래 모습을 예상할 수 있어요"/>


        <PrimaryButton Text="나의 미래 모습 사진 잠금해제"  />
      </div>
    </div>
  );
}

export default Success;
