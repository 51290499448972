import React from "react";

export default function SliceBar() {


  return (
    <div style={{
      width: 'auto',
      height: '20px',

      background: '#F2F4F6',

      marginTop:'29px'
      }}>
    </div>
  );
}
