import React from 'react';
import { motion } from 'framer-motion';

export default function SecondaryButtonSmall({ Text }) {
  const buttonContainerStyle = {
    position: 'fixed',
    bottom: '28px',
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const buttonStyle = {
    display: 'flex',
    width: '100px',
    padding: '14px 80px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#fff',
    border: '2px solid var(--Hoxy-Orange, #FF5C00)',    
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    color: 'var(--Hoxy-Orange, #FF5C00)',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  return (
    <div>
      <motion.button
        style={buttonStyle}
        whileTap={{ scale: 0.95 }}
      >
        <span style={buttonTextStyle}>{Text}</span>
      </motion.button>
    </div>
  );
}
