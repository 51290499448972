import React, { useState, useEffect } from 'react';
import Header from '../../NewComponent/header';
import OfferLetter_img from '../../NewComponent/assets/offerLetter.svg'
import jwt_decode from 'jsonwebtoken/decode';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import PrimaryButton_Admin from '../../NewComponent/PrimaryButton_Admin';
import Intelligence from '../../NewComponent/Intelligence';
import kakao from '../../NewComponent/assets/kakako.webp';
import SliceBar from '../../NewComponent/SliceBar';
import Admin1 from '../../NewComponent/assets/admin_1.svg';
import Admin2 from '../../NewComponent/assets/admin_2.svg';
import Admin3 from '../../NewComponent/assets/admin_3.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import axios from 'axios';
import { Link } from 'react-router-dom';

function OfferLetter() {

  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");
  const [MyUrl, setMyUrl] = useState('');
  const [copiedUrl, setCopiedUrl] = useState(''); 
  const [schoolCode, setSchoolCode] = useState('');
  const [studentCount, setStudentCount] = useState(0);
  const [SchoolWebsite, setSchoolWebsite] = useState(0);
  const [referralCount, setreferralCount] = useState(0);

  useEffect(() => {

    localStorage.setItem('school_ceo', "ture");


    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);
    
        const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        setUserData({
          user_no: data.user_no,
          user_name: data.user_name,
          school_name: data.school_name,
          user_email: data.user_email,
          user_phonenumber: data.user_phonenumber,
          user_kakao_year: data.user_kakao_year,
          user_gender: data.user_gender,
          user_profile : data.user_insta_img,
          school_code : data.school_code
        });



        const schoolApiUrl = 'https://backend-hoxy-one-api.hoxy.one:4004/api/school/school_info';

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ school_code: data.school_code }),
        };
        
        fetch(schoolApiUrl, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error('학생수를 가져올 수 없습니다.');
            }
            return response.json();
          })
          .then((data) => {
            setStudentCount(data.student_count);

            const website = data.school_website.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '');
            const hoxy_website = "https://hoxy.one/school/" + (website);

            setMyUrl(hoxy_website)

            const referralCount = 'https://backend-hoxy-one-api.hoxy.one:4004/api/referral/count';

            const requestOptions2 = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ referral_user_id: website }),
            };
            
            fetch(referralCount, requestOptions2)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('레퍼럴 성공에 대한 데이터가 없습니다');
                }
                return response.json();
              })
              .then((data) => {
                setreferralCount(data.contribution_count);
    
          
              })
              .catch((error) => {
                console.error(error);
              });
      

          })
          .catch((error) => {
            console.error(error);
          });



   
    
        // 나머지 코드
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
    
    fetchData();
  }, []);
  



  
  const handleCopyToClipboard = () => {
    setCopiedUrl(MyUrl); 

    axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/referral', {
      user_id: tokenUserID,
      user_no:tokenUserNo,
      referral_user_id: MyUrl,
      referral_page: window.location.href
    });

    alert('복사되었어요. 이제 이 링크를 스토리나, Bio에 공유해보세요 :)');
  };






  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
        <Intelligence type={'Admin_1'}></Intelligence>

      <br></br>

      <br></br>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={userData.user_profile} alt="사진1" style={{ marginLeft: '25px', width: '50px', height: '50px', borderRadius: '50px', marginTop:'-10px' }} />
      <div style={{ marginLeft: '10px', marginTop:'5px' }}>
          <p style={{ fontSize: '28px', marginTop: '-3px', textAlign: 'left', fontWeight: '800' }}>{userData.user_name} CEO님</p>
          <p style={{ fontSize: '14px', marginTop: '-28px', textAlign: 'left', fontWeight: '600', color: '#6E747D' }}>{userData.school_name}</p>
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <a href={"https://open.kakao.com/o/gISHo24f"} target='_blank'>
          <img src={kakao} alt="맨 끝 사진" style={{ marginRight: '30px', width:'30px', height:'30px', borderRadius:'30px' }} />
          </a>
      </div>
  </div>

  <SliceBar></SliceBar>


  <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{marginRight:'auto',marginTop:'10px'}}>
            <h2 style={{marginLeft:'25px'}}>우리학교 현황</h2>      
      </div>
      </div>

      <div>
        <div style={{display:'flex', marginLeft:'25px', marginTop:'-15px'}}>
        <img src={Admin1}></img>
        <div style={{ marginLeft: '10px'}}>
          <p style={{ fontSize: '15px', marginTop: '20px', textAlign: 'left', fontWeight: '600', color:'#8B95A1' }}>우리학교 누적 가입자수</p>
          <p style={{ fontSize: '18px', marginTop: '-15px', textAlign: 'left', fontWeight: '800', color: '#000000' }}>{studentCount}명</p>
      </div>
        </div>
      </div>
      

      <div>
        <div style={{display:'flex', marginLeft:'25px', marginTop:'-10px'}}>
        <img src={Admin2}></img>
        <div style={{ marginLeft: '10px'}}>
          <p style={{ fontSize: '15px', marginTop: '20px', textAlign: 'left', fontWeight: '600', color:'#8B95A1' }}>우리학교 예상 매출</p>
          <p style={{ fontSize: '18px', marginTop: '-15px', textAlign: 'left', fontWeight: '800', color: '#000000' }}>{studentCount * 373}원 (기대치)</p>
      </div>
        </div>
      </div>


      <div>
        <div style={{display:'flex', marginLeft:'25px', marginTop:'-10px'}}>
        <img src={Admin3}></img>
        <div style={{ marginLeft: '10px'}}>
          <p style={{ fontSize: '15px', marginTop: '20px', textAlign: 'left', fontWeight: '600', color:'#8B95A1' }}>CEO 마케팅으로 가입한 사용자</p>
          <p style={{ fontSize: '18px', marginTop: '-15px', textAlign: 'left', fontWeight: '800', color: '#000000' }}>{referralCount}명 (가입의 {Math.round((referralCount / studentCount) * 100)}% 기여)</p>
      </div>
        </div>
      </div>

      <SliceBar></SliceBar>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{marginRight:'auto',marginTop:'10px'}}>
            <h2 style={{marginLeft:'25px'}}>CEO 전용, 마케팅 링크</h2>      
      </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{marginRight:'auto',marginTop:'-30px'}}>
            <p style={{marginLeft:'25px', color:'#8B95A1', fontSize:'14px', fontWeight:'700'}}>*CEO 전용 마케팅 링크를 사용해야지, 가입자를 확인할 수 있어요</p>      
      </div>
      </div>


      <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>

          <div className="LinkBox">{MyUrl}</div>
       
          </div>
          <div style={{marginTop: '15px'}}>
            <CopyToClipboard className="Toram" text={MyUrl} onCopy={handleCopyToClipboard}>
              <text className="underlineT" style={{ cursor: 'pointer', fontWeight:'400', textDecoration:'underline', fontSize:'14px'}}>
                링크 복사하기
              </text>
            </CopyToClipboard>
          </div>




      <div className="bottom_box">
          <div>*본 페이지는 ‘메인 → 프로필 공유’를 통해 접속할 수 있어요.</div>
      </div>
      <Link to={`/${tokenUserID}?explore=yes`}>
        <PrimaryButton_Admin Text={'내 HOXY 프로필로 돌아가기'}></PrimaryButton_Admin>
        </Link>
        </div>
        </div>
        </div>
  );
}

export default OfferLetter;
