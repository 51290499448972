import React, { useEffect, useState } from 'react';
import '../App.css';
import { Box } from '../stories/Box';
import { Tittle } from '../stories/Tittle';
import jwt_decode from 'jsonwebtoken/decode';
import Header from '../NewComponent/header';

function Accepted() {
  const [messages, setMessages] = useState(null);
  const [user_name, setUserName] = useState('');
  const [user_id, setUserId] = useState('');
  const [visitProfileID, setVisitProfileID] = useState('');
  const [tokenUserID, setTokenUserID] = useState('');
  const [fetchedReply, setFetchedReply] = useState(false); 


  const fetchMessages = () => {
    fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/${user_id}`)
      .then(response => response.json())
      .then(data => {
        setMessages(data)
          window.scrollTo(0, 0);
      })
      .catch(error => console.error('Yes Error fetching messages:', error));
  };

  const fetchMessagesNone = () => {
    fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/none/${user_id}`)
      .then(response => response.json())
      .then(data => {
        setMessages(data);
         console.log("스크롤 초기화 완료 - 데이터 기준");
         window.scrollTo(0, 0);
      })
      .catch(error => console.error('None Error fetching messages:', error));
  };

  const fetchReply = async (requestDbNumber) => {
    try {
      const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/reply/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestDbNumber }),
      });
      const data = await response.json();
      return data.recive_reply;
    } catch (error) {
      return null;
    }
  };
  

  useEffect(() => {
    const visitProfileName = localStorage.getItem('VisitprofileName');
    const visitProfileID = localStorage.getItem('VisitprofileID');
    setUserName(visitProfileName);
    setUserId(visitProfileID);
    setVisitProfileID(visitProfileID);

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);

      } catch (error) {
        console.log('토큰 디코딩 에러');
      }
    } else {
      setTokenUserID('none');
    }
   
  }, []);

  useEffect(() => {
    if (tokenUserID === 'none' && messages === null) {
      fetchMessagesNone();
    }
  }, [visitProfileID, messages]);


  useEffect(() => {
    if (visitProfileID !== tokenUserID && messages === null) {
            fetchMessagesNone();
          }
  }, [visitProfileID, tokenUserID, messages]);

  useEffect(() => {
    if (visitProfileID === tokenUserID && messages === null) {
      fetchMessages();
    }
  }, [visitProfileID, tokenUserID, messages]);

  useEffect(() => {
    if (messages !== null && !fetchedReply) {
      const requestDbNumbers = messages
        .filter(message => message.request_db_number) // request_db_number가 있는 메시지 필터링
        .map(message => message.request_db_number);

      const replyPromises = requestDbNumbers.map(dbNumber => fetchReply(dbNumber));

      Promise.all(replyPromises)
        .then(replies => {
          setMessages(prevMessages => {
            const updatedMessages = prevMessages.map((message, index) => {
              if (message.request_db_number && replies[index]) {
                return { ...message, reply: replies[index] };
              }
              return message;
            });
            return updatedMessages;
          });
        })
        .catch(error => console.error('답장 API 오류'))
        .finally(() => setFetchedReply(true));
    }
  }, [messages, fetchedReply]);

  if (messages === null) {
   return;
  }

  const visitProfileId = localStorage.getItem('VisitprofileID');


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
       <br></br>
        <Header Link={`/${visitProfileId}?explore=yes`}/>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-55px' }}>
            <Tittle className="check" label1="답장된 메시지" label2={`지금, ${user_name}님이 답장한 메시지를 확인해보세요`} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
          {messages.length === 0 || messages.every(message => !message.reply) ? (
            <div className='gray_text'>아직 {user_name}님이 답장한 메시지가 없어요</div>
            ) : (
              messages
                .filter(message => message.reply) 
                .map((message, index) => (
                  <div key={index} style={{ marginBottom: '15px' }}>
                    <Box
                      className="LargeBox"
                      Label1={`${message.request_age}세 ${message.request_gender}`}
                      Label2={
                        <>
                          나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인{' '}
                          {message.request_age}세 {message.request_gender}의{' '}
                          {message.request_keyword ? message.request_keyword : '***'} 분께서{' '}
                          {message.request_want ? message.request_want : '***'} 해요!
                          <br />                          <br />

                          {message.request_db_number && message.reply && (
                            <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap' }}>{`ㄴ "${message.reply}"`}</span>
                          )}
                        </>
                      }
                      ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.png`}
                      Label4="#ffffff"
                    />
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accepted;
