import React, { useEffect,useState } from 'react';
import { Link, useLocation   } from 'react-router-dom';
import HoxyWordIcon from '../component/Hoxyworldicon';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import axios from 'axios';
import jwt_decode from 'jsonwebtoken/decode';
import S_ImgShare from '../NewComponent/Skeletons/S_ImgShare'; // Import your SkeletonTest component
import Header from '../NewComponent/header';


function ImgShare() {
  const [copiedUrl, setCopiedUrl] = useState(''); 
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');
  const location = useLocation();
  const typeQueryParam = new URLSearchParams(location.search).get('type');

  const [isLoading, setIsLoading] = useState(true); // Step 2: Add loading state

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);

      } else {
        console.log("로그인되어있지 않음");
        setTokenUserID("anonymous");
      }

      // Simulate an API call
      // Replace this with your actual data fetching logic
      await new Promise(resolve => setTimeout(resolve, 2000));

      setIsLoading(false); // Step 4: Set isLoading to false after data is loaded
    };

    fetchData();
  }, []);


  const MyUrl = `https://hoxy.one/${tokenUserID}`;

  const handleCopyToClipboard = () => {
    setCopiedUrl(tokenUserID); 

    axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/referral', {
      user_id: tokenUserID,
      user_no:tokenUserNo,
      referral_user_id: tokenUserID,
      referral_page: window.location.href
    });

    alert('복사되었어요. 이제 이 링크를 스토리나, Bio에 공유해보세요 :)');
  };


 

 

  

  const getImageByType = (type) => {
    switch (type) {
      case '공부':
        return require('../assets/img/ImgShare/공부.webp');
      case '농구':
        return require('../assets/img/ImgShare/농구.webp');
      case '디엠':
        return require('../assets/img/ImgShare/디엠.webp');
      case '마라탕':
        return require('../assets/img/ImgShare/마라탕.webp');
      case '미술관':
        return require('../assets/img/ImgShare/미술관.webp');
      case '신발':
        return require('../assets/img/ImgShare/신발.webp');
      case '첫인상투표':
        return require('../assets/img/ImgShare/첫인상.webp');
      case '친해지고싶은사람':
        return require('../assets/img/ImgShare/친해지고.webp');
      default:
        return ''; // Return an empty string for unknown types
    }
  };


  const handleImageDownload = () => {
    const imageSrc = getImageByType(typeQueryParam);
    if (imageSrc) {
      const link = document.createElement('a');
      link.href = imageSrc; // 이미지 URL
      link.download = `${typeQueryParam}.webp`; // 저장될 파일 이름
      link.click();

      axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/referral', {
        user_id: tokenUserID,
        user_no:tokenUserNo,
        referral_user_id: tokenUserID + "+ Img Download " + typeQueryParam,
        referral_page: window.location.href
      });

    } else {
      alert('이미지를 찾을 수 없습니다.');
    }
  };
  

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>
      {isLoading ? (
          <S_ImgShare />
        ) : (
          <div>
         
         <Header></Header>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>

              <img src={getImageByType(typeQueryParam)} height={'420px'} style={{ borderRadius: '35px' }} alt={`${typeQueryParam} 이미지`} />

              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '150px',
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#FF5C00',
                marginTop: '30px',
                fontSize: '15px',
                color: '#ffffff',
                fontWeight: 700,
                cursor: 'pointer',
              }}
                onClick={handleImageDownload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 7.5H12.4998V2.5H7.49984V7.5H4.1665L9.99984 13.3333L15.8332 7.5ZM4.1665 15V16.6667H15.8332V15H4.1665Z" fill="white" />
                </svg>
                <span style={{ lineHeight: '40px' }}>이미지 저장하기</span>
              </div>

              <div style={{ marginTop: '30px' }}>
                <div className="LinkBox">{MyUrl}</div>
                <div style={{ marginTop: '15px' }}>
                  <CopyToClipboard className="Toram" text={MyUrl} onCopy={handleCopyToClipboard}>
                    <text className="underlineT" style={{ cursor: 'pointer', fontWeight: '400', textDecoration: 'underline', fontSize: '14px' }}>
                      링크 복사하기
                    </text>
                  </CopyToClipboard>
                </div>
              </div>

              <br></br> <br></br>

            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default ImgShare;
