import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from 'jsonwebtoken/decode';
import { useLocation, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const PaymentCallbackHOXYNAPg = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const imp_uid = queryParams.get('imp_uid');
      const merchant_uid = queryParams.get('merchant_uid');
      const error_msg = queryParams.get('error_msg');
      const imp_success = queryParams.get('imp_success');
  
      if (imp_success === "true") {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const decodedToken = jwt_decode(token);
            const tokenUserID = decodedToken.user_id;
            const tokenUserNo = decodedToken.user_no;
            setTokenUserID(tokenUserID);
            setTokenUserNo(tokenUserNo);  
  
            try {
              const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
                },
                body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
              });
  
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
  
              const data = await response.json();
  
              setUserData({
                user_no: data.user_no,
                user_name: data.user_name,
                user_email: data.user_email,
                user_phonenumber: data.user_phonenumber,
                user_kakao_year: data.user_kakao_year,
                user_gender: data.user_gender,
              });
  
  
              axios({
                url: "https://backend-hoxy-one-api.hoxy.one:4004/api/payment/pg/result",
                method: "post",
                headers: { "Content-Type": "application/json" },
                data: {
                  pg: "html5_inicis",
                  merchant_uid: merchant_uid,
                  name: "HOXY:NA 선물 꾸러미",
                  imp_uid: imp_uid,
                  pay_method: "Card",
                  amount: "5900",
                  buyer_name: data.user_name, // userData 사용
                  buyer_tel: data.user_phonenumber, // userData 사용
                  userId: tokenUserID,
                  userNo: tokenUserNo,
                  location: window.location.href,
                },
              })
              .then((data) => {
                alert("결제에 성공했어요!");
                navigate(`/jokercard`);
              })
              .catch((error) => {
                alert("결제가 실패했어요. 고객센터로 문의해주세요");
                navigate("/JokerDiscovery");
              });
            } catch (error) {
              console.error('Fetch 에러:', error);
            }
          } catch (error) {
            alert('로그인이 되지 않았어요');
            navigate(`/`);
          }
        } else {
          alert('로그인이 되지 않았어요');
          navigate(`/`);
        }
      } else {
        alert(`앗, 결제에 실패했어요`, error_msg);
        navigate("/JokerDiscovery");
      }
    };
  
    fetchData();
  }, []);
  

  return (
   <></>
  );
};

export default PaymentCallbackHOXYNAPg;