import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tittle } from '../stories/Tittle';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import S_Request from '../NewComponent/Skeletons/S_Request'; // Import your SkeletonTest component

function Request() {
  const navigate = useNavigate();
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [relationship, setRelationship] = useState('');
  const [keyword, setKeyword] = useState('');
  const [want, setWant] = useState('');
  const [relationshipData, setRelationshipData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const [wantData, setWantData] = useState([]);
  const [shuffledRelationshipData, setShuffledRelationshipData] = useState([]);
  const [shuffledKeywordData, setShuffledKeywordData] = useState([]);
  const [shuffledWantData, setShuffledWantData] = useState([]);
  const [tokenUserID, setTokenUserID] = useState('');
  const [userData, setUserData] = useState({});
  const [hoxyGender, setHoxyGender] = useState(''); 
  const [hoxyAge, setHoxyAge] = useState(''); 
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Step 1: Add loading state


  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
  
        if (token) {
          try {
            const decodedToken = jwt_decode(token);
            const tokenUserID = decodedToken.user_id;
            setTokenUserID(tokenUserID);
  
            fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
              },
              body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
            })
              .then((response) => response.json())
              .then((data) => {
                setUserData({
                  user_kakao_year: data.user_kakao_year,
                  user_gender: data.user_gender,
                });
              })
              .catch((error) => {
                console.log('API Error:', error);
              });
          } catch (error) {
            console.log('토큰 디코딩 에러:', error);
          }
        } else {
          console.log('로그인되어있지 않음');
          setTokenUserID('anonymous');
          handlePopupOpen();
        }
  
        // Simulate an API call
        // Replace this with your actual data fetching logic
        await new Promise(resolve => setTimeout(resolve, 2000));
  
        setIsLoading(false); // Step 2: Set isLoading to false after data is loaded
      } catch (error) {
        console.log('API Error:', error);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    if (userData && userData.user_kakao_year && userData.user_gender) {
      const today = new Date();
      const birthDate = new Date(userData.user_kakao_year, 1, 16);
      const hoxy_age =
        today.getFullYear() - birthDate.getFullYear() + 1;
      const hoxy_gender = userData.user_gender === 'female' ? '여성' : '남성';

      setAge(hoxy_age); 
      setGender(hoxy_gender); 
    }
  }, [userData]);

  const handleClickGender = (value) => {
    setGender(value);
  };

  const handleClickRelationship = (value) => {
    setRelationship(value);
  };

  const handleClickKeyword = (value) => {
    setKeyword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!gender || !age || !relationship || !keyword || !want) {
      alert('아직 채워지지 않았거나, 입력할 수 없는 문자가 있어요');
      return;
    }

    const recive_user_id = localStorage.getItem('VisitprofileID');

    try {
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/request', {
        request_user_id: tokenUserID,
        request_gender: gender,
        request_age: age,
        request_relationship: relationship,
        request_keyword: keyword,
        request_want: want,
        recive_user_id: recive_user_id,
      });


      alert('성공적으로 요청이 전송되었어요!');

      navigate(`/${recive_user_id}?explore=yes`);

    } catch (error) {
      console.error(error);
      alert('내부 서버의 오류가 발생했어요. 나중에 다시 시도해보세요!');
    }
  };

  const recive_user_name = localStorage.getItem('VisitprofileName');

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleShuffleRelationship = () => {
    const shuffledRelationships = shuffleArray(relationshipData);
    setShuffledRelationshipData(shuffledRelationships.slice(0, 4));
  };
  
  const handleShuffleKeyword = () => {
    const shuffledKeywords = shuffleArray(keywordData);
    setShuffledKeywordData(shuffledKeywords.slice(0, 4));
  };
  
  const handleShuffleWant = () => {
    const shuffledWants = shuffleArray(wantData);
    setShuffledWantData(shuffledWants.slice(0, 4));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const relationshipResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/relationship/');
        const keywordResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/keyword/');
        const wantResponse = await axios.get('https://backend-hoxy-one-api.hoxy.one:4004/api/request_select/want/');

         const shuffledRelationships = shuffleArray(relationshipResponse.data);
        setRelationshipData(shuffledRelationships);
        setShuffledRelationshipData(shuffledRelationships.filter((data) => data.select_setting === 'default').slice(0, 4));

        const shuffledKeywords = shuffleArray(keywordResponse.data);
        setKeywordData(shuffledKeywords);
        setShuffledKeywordData(shuffledKeywords.filter((data) => data.select_setting === 'default').slice(0, 4));

        const shuffledWants = shuffleArray(wantResponse.data);
        setWantData(shuffledWants);
        setShuffledWantData(shuffledWants.filter((data) => data.select_setting === 'default').slice(0, 6));
      } 
      
      catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return(
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>

      {isLoading ? ( // Step 3: Conditional rendering
          <S_Request /> // Show the skeleton component while loading
        ) : (

        <div>
        <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', justifyContent: 'center', textAlign: 'center' }}>
          <a href="javascript:window.history.back();">
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>

  {userData && userData.user_kakao_year && userData.user_gender ? null : (
    <>
      <div style={{ marginTop: '35px' }}>
      <div style={{ marginRight: '40px' }}>
        <Tittle className="normalQ" label1="나의 성별은 어떻게 되나요?" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginRight: '135px' }}>
        <button
          className={`select_request ${gender === '남성' ? 'active' : ''}`}
          data-value="남성"
          onClick={() => handleClickGender('남성')}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>남성</div>
            <img
              className="request_img"
              src="https://resource.hoxy.one/request_3d_emoji/boy.webp"
              alt="남성 이미지"
              effect="blur"
            />
          </div>
        </button>
        <button
          className={`select_request ${gender === '여성' ? 'active' : ''}`}
          data-value="여성"
          onClick={() => handleClickGender('여성')}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>여성</div>
            <img
              className="request_img"
              src="https://resource.hoxy.one/request_3d_emoji/girl.png"
              alt="여성 이미지"
              effect="blur"
            />
          </div>
        </button>
      </div>

      {/* 추가적인 내용 */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
        <div style={{ marginRight: '40px' }}>
          <Tittle className="normalQ" label1="나의 나이는 어떻게 되나요?" />
        </div>
        <div style={{ marginTop: '0px' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <input
            className="input_request"
            placeholder="나이를 입력해주세요"
            type="number"
            onChange={(e) => setAge(e.target.value)}
          />
        </div>
      </div>
      </div>
    </>
  )}



          {/* Display shuffled relationshipData */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
            <div style={{ marginRight: '25px', height: 'auto' }}>
              <Tittle className="normalQ" label1={`나와 ${recive_user_name}님과 현재 관계는?`} />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:"column"}}>
              <div>
                {shuffledRelationshipData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${relationship === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickRelationship(data.select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
              <button className="shuffle-button" onClick={handleShuffleRelationship}>
              &#8592; &#8594;               
              </button>
            </div>
          </div>

          {/* Display shuffled keywordData */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '20px' }}>
              <Tittle className="normalQ" label1="나를 설명할 수 있는 키워드는?" />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
                {shuffledKeywordData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${keyword === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => handleClickKeyword(data.select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
              </div>
              <button className="shuffle-button" onClick={handleShuffleKeyword}>
              &#8592; &#8594;               
              </button>
            </div>
          </div>

          {/* Display shuffled wantData */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
            <div style={{ marginRight: '50px' }}>
              <Tittle className="normalQ" label1="어떻게 친해지고 싶나요?" />
            </div>
            <div style={{ marginTop: '0px' }}></div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <div>
                {shuffledWantData.map((data) => (
                  <button
                    key={data.idx}
                    className={`select_request2 ${want === data.select_name ? 'active' : ''}`}
                    data-value={data.select_name}
                    onClick={() => setWant(data.select_name)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{data.select_name}</div>
                      <img
                        className="request_img"
                        src={data.select_img}
                        alt={data.select_name}
                        effect="blur"
                      />
                    </div>
                  </button>
                ))}
                </div>
              <button className="shuffle-button" onClick={handleShuffleWant}>
              &#8592; &#8594;               
              </button>
            </div>
          </div>

          <div style={{ justifyContent: 'center', marginTop: '30px' }}>
            <a href="https://bit.ly/%ED%98%B9%EC%8B%9C%ED%94%BC%EB%93%9C%EB%B0%B1" target="_blank" rel="noopener noreferrer">
              <img src="https://resource.hoxy.one/img_component/request_survey.svg" width={'340px'} alt="설문 조사 이미지" />
            </a>
          </div>
          <div style={{ marginTop: '30px', marginBottom: '25px' }}>
            <button className="primary" onClick={handleSubmit}>
              요청 보내기
            </button>
            <br></br>
          </div>
        </div>

             {/* Render the popup when showPopup is true */}
      {showPopup && (
        <div className="popup-container" onClick={handlePopupClose}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2 >답장이 오면, 알려드릴까요? 🔔</h2>
             

            <p>가입 후 메시지를 보내도, 친해지고 싶은 사람은<br></br>내가 누군지 정확히는 알 수 없어요 💌</p>
            <div className="button-container">
              <button className="delete-button" onClick={handlePopupClose}>
                계속하기
              </button>
              <Link to="/welcome">
              <button className="retry-button" >
                가입하고, 알림받기
              </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      </div>
     )}

      </div>
    </div>
  );
}

export default Request;
