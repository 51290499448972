import React, { useState, useEffect } from 'react';
import Header from '../../NewComponent/header';
import OfferLetter_img from '../../NewComponent/assets/offerLetter.svg'
import jwt_decode from 'jsonwebtoken/decode';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import { useNavigate } from 'react-router-dom';

function OfferLetter() {

  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);
    
        const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        setUserData({
          user_no: data.user_no,
          user_name: data.user_name,
          user_email: data.user_email,
          user_phonenumber: data.user_phonenumber,
          user_kakao_year: data.user_kakao_year,
          user_gender: data.user_gender,
        });
    
    
        // 나머지 코드
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
    
    fetchData();
  }, []);
  



  
  const handleAccept = async () => {
    try {

      const response = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/application/join', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: tokenUserID,
          application_type: 'school-ceo', // 신청 유형 (예: CEO)
          location: window.location.href,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // 성공적으로 요청을 보내고 처리한 후에 원하는 작업을 수행할 수 있습니다.
      alert('축하드립니다! 이제부터, 우리학교 HOXY CEO에요!')
      navigate('/ceo/DashBoard')

    } catch (error) {
      console.log('API Error 입니다:', error);
    }
  };






  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
      <br></br>
      <Header Text={`${userData.user_name}님의 CEO 오퍼레터`} />

      <img src={OfferLetter_img}></img>


      <div className="bottom_box">
        <div>*우리학교 CEO는 학교별로 자체적으로 선별되어 운영되고 <br></br>
수락을 거절할 시, 다른 우리학교 친구에게 오퍼레터를 발송해요</div>
    </div>
        <div onClick={handleAccept}>
        <PrimaryButton  Text={'수락하기'} ></PrimaryButton>
        </div>
        </div>
        </div>
        </div>
  );
}

export default OfferLetter;
