import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './OneBox.css';

export const OneBox = ({ Label1, Label2, Label3,Label4, className }) => {
  const OneBoxClassName = `${className}`;

useEffect(() => {
  const max = 100;
  const colors = [['#FF9635', '#FF0009'], '#FFFFFF'];

  function animatePieChart(i) {
    if (i <= max) {
      maintain();
      fill(i);
      i++;
      requestAnimationFrame(() => animatePieChart(i));
    } else {
    }
  }

  function fill(i) {
    const gradient = `conic-gradient(${colors} 0% 0%, ${colors[0]} ${i}%, #ffffff ${i}% 100%)`;
    applyGradient(gradient);
  }

  function maintain() {
    const gradient = `conic-gradient(${colors} 0% 0%, ${colors[0]} 100%, #ffffff 100% 100%)`;
    applyGradient(gradient);
  }

  function applyGradient(gradient) {
    const element = document.querySelector(`.${OneBoxClassName}`);
    if (element) {
      element.style.background = gradient;
    }
  }


  animatePieChart(1);

}, [OneBoxClassName]);

  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <div className={OneBoxClassName}>
        <div style={{color:"#000000"}} className={`pie-chart pie-chart1 ${OneBoxClassName}`}>
          <span className="center3" style={{color:"#000000"}}>
            <span className="center" style={{color:"#000000"}}>
              <div className="center1" style={{color:"#000000"}}>
                {Label1} <br />
              </div>
              <div className="center2" style={{color:"#000000"}}>
                {Label2}</div>       
                <div className="center4">
                {Label3}
                </div>
                <div className="center5">
                {Label4}
                </div>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

OneBox.propTypes = {
  Label1: PropTypes.string.isRequired,
  Label2: PropTypes.string.isRequired,
  Label3: PropTypes.string.isRequired,
  Label4: PropTypes.string.isRequired,
  className: PropTypes.string,
};
