import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from 'jsonwebtoken/decode';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';



const KakaoCallback = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
    

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    const grant_type = "authorization_code";

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI; 


    axios
      .post(
        `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&code=${code}`,
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log(res);
        const { data } = res;
        const { access_token } = data;

     
        


        if (access_token) {
          console.log(access_token);
          axios
            .post(
              "https://kapi.kakao.com/v2/user/me",
              {},
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((res) => {


              //마케팅 관련 데이터 확인
              axios
              .post(
                "https://kapi.kakao.com/v2/user/service_terms",
                {},
                {
                  headers: {
                    Authorization: `Bearer ${access_token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res) => {
                console.log("마케팅 데이터 성공");
            
                const informationAgreed = res.data.service_terms.find(
                  (term) => term.tag === "information_20230727"
                )?.agreed;
            
                const marketingAgreed = res.data.service_terms.find(
                  (term) => term.tag === "marketing_20230727"
                )?.agreed;
            
                // 로컬 스토리지에 정보를 저장합니다.
                localStorage.setItem("information_check", informationAgreed);
                localStorage.setItem("marketing_check", marketingAgreed);
              })
              .catch((error) => {
                console.error("마케팅 데이터 실패", error);
              });
            

              console.log("사용자 데이터 성공");
              console.log(res);

              let data = res.data;

             // 전화번호가 존재하면 변환
              if (data.kakao_account.phone_number) {
                let originalPhoneNumber = data.kakao_account.phone_number;
                if (originalPhoneNumber.startsWith('+82 ')) {
                  let convertedPhoneNumber = originalPhoneNumber.replace('+82 10', '010');
                  data.kakao_account.phone_number = convertedPhoneNumber;
                }
              }


              let birthyearInfo = data.kakao_account.birthyear

              const today = new Date();
              const birthDate = new Date(birthyearInfo, 1, 16); // 2000년 8월 10일
                
              let grade = today.getFullYear()
                          - birthDate.getFullYear()
                          + 1;

              setUserInfo(data);

              // 사용자 정보 변수들을 콘솔에 출력
              const {
                id,
                kakao_account: {
                  age_range,
                  birthyear,
                  email,
                  gender,
                  name,
                  phone_number,
                  profile: {
                    nickname,
                    thumbnail_image_url,
                    profile_image_url,
                    is_default_image,
                  },
                },
              } = res.data;


              console.log("ID:", id);
              console.log("연령대:", age_range);
              console.log("출생년도:", birthyear);
              console.log("이메일:", email);
              console.log("성별:", gender);
              console.log("이름:", name);
              console.log("전화번호:", phone_number);              
              console.log("닉네임:", nickname);
              console.log("썸네일 이미지 URL:", thumbnail_image_url);
              console.log("프로필 이미지 URL:", profile_image_url);
              console.log("기본 이미지 여부:", is_default_image ? "예" : "아니오");
              console.log("혹시 나이(학년):", grade);

              function login() {
                let userId; // userId 변수를 블록 바깥에 선언
                let userNo; // userId 변수를 블록 바깥에 선언
              
                axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/oauth/login', {
                  name: name, 
                  phone_number: phone_number,
                  gender: gender
                })
                .then((response) => {
                  const { token } = response.data;
                
                  // 토큰을 로컬 스토리지에 저장
                  localStorage.setItem('token', token);
                  const decodedToken = jwt_decode(token);

                  userId = decodedToken.user_id; // userId 변수에 값 할당
                  userNo = decodedToken.user_no; // userId 변수에 값 할당

                  return axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
                    user_id: userId,
                    user_no: userNo,
                    login_page: window.location.href, // Include the current URL in the request
                  });
                })
                .then(() => {
                  amplitude.init("4cb7df50686c6cb578ab1fedb0e182ab", userId); 

                  navigate(`/${userId}`);
                })
                
                
                
                
                .catch((error) => {
                  console.error('로그인 실패:', error);
                  console.error('로그인 실패1:', error.response);

                  // 로그인 실패 처리
                  if (error.response && error.response.status === 401) {
                    //회원가입 절차 시작

                    //공통
                    localStorage.setItem('user_name', name); 
                    localStorage.setItem('user_phoneNumber', phone_number);
                    localStorage.setItem('user_gender', gender);
                    localStorage.setItem('user_grade', grade);



                    //개인별 난독화 불가능한 강력암호 생성
                    function generateStrongPassword(length = 16) {
                      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:',.<>?";
                      let password = "";
                    
                      for (let i = 0; i < length; i++) {
                        password += characters[Math.floor(Math.random() * characters.length)];
                      }
                    
                      return password;
                    }
                    
                    // 16자리의 강력한 암호 생성 예시
                    const strongPassword = generateStrongPassword(10);
                    console.log(strongPassword); // 예시: "Jg0!$mTfz_8+5DxU"
                    
                    localStorage.setItem('user_Password', strongPassword);

                    //카카오 로그인만 적용
                    localStorage.setItem('user_kakao_email', email);
                    localStorage.setItem('user_kakao_year', birthyear);
                    localStorage.setItem('user_kakao_age', age_range);
                    localStorage.setItem('user_kakao_id', id);
                    localStorage.setItem('user_kakao_imgurl', profile_image_url);

                    const currentYear = new Date().getFullYear();
                    const userBirthYear = parseInt(birthyear, 10);
                    const koreanAge = currentYear - userBirthYear + 1;
                    const numericKoreanAge = parseInt(koreanAge, 10);

                    if (numericKoreanAge <= 15) {
                      navigate('/onboarding_underage');
                    } else if (numericKoreanAge >= 20) {
                      navigate('/OnBoarding_University');
                    } else {
                      navigate('/onboarding');
                    }



                  } else {
                    alert('서버 오류가 발생했습니다. 나중에 다시 시도해주세요.');
                  }
                });
              }
              
              login();
              
            })
            .catch((error) => {
              console.error("데이터를 카카오로 부터 가져오는 것을 실패했어요.");
              console.error(error);
              navigate('/start');

            });
        } else {
          console.log("access_token을 찾을 수 없어요");
          navigate('/start');

        }
      })
      .catch((error) => {
        console.error("토큰 요청 실패에 실패했어요.");
        console.error(error);
        navigate('/start');

      });
  }, []);

  return (
   <></>
  );
};

export default KakaoCallback;
