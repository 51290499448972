import lightning from '../assets/img/lighting.webp'
import microphone from '../assets/img/microphone.webp'
import football from '../assets/img/football.webp'
import Yonsei from '../assets/img/Yonsei.webp'
import TigarFace from '../assets/img/TigerFace.webp'
import HotAirBallon from '../assets/img/hot-air-ballon.webp'
import {ReactComponent as UploadRed} from '../stories/assets/Upload-Red.svg'
import {ReactComponent as ArrowRight} from '../stories/assets/icon-arrow-right-small-mono.svg'
import Earth from '../assets/img/Earth.webp'
import HoxyWordIcon from '../component/Hoxyworldicon'
import { Link } from 'react-router-dom'
import jwt_decode from 'jsonwebtoken/decode';
import { useState, useEffect } from 'react';
import Footer from '../component/Footer'
import axios from 'axios';
import Header from '../NewComponent/header'
import S_Vote from '../NewComponent/Skeletons/S_Vote'; // Import your SkeletonTest component


function FIvote() {
  const [isLoading, setIsLoading] = useState(true);
  const [tokenUserID, setTokenUserID] = useState('');
  const [voteCount, setVoteCount] = useState(0);
  const [peopleData, setPeopleData] = useState([]);
  const [QuestionData, setQuestionData] = useState([]);
  const [RankData, setRankData] = useState([]);

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const postData = {
          vote_name: '첫인상투표',
          vote_num: 1,
        };
  
        const response = await axios.post(
          'https://backend-hoxy-one-api.hoxy.one:4004/api/vote_question',
          postData
        );
  
        if (response.data) {
          setQuestionData(response.data);
        } else {
          setQuestionData(null);
        }
      } catch (error) {
        console.error('Error fetching question data:', error);
      }
    };
  
    const fetchVoteResults = async () => {
      const token = localStorage.getItem('token');
  
      if (token) {
        try {
          await fetchQuestionData();
  
          const requestData = {
            user_id: tokenUserID,
            vote_name: '첫인상투표',
            vote_num: 1,
          };
  
          const response = await axios.post(
            'https://backend-hoxy-one-api.hoxy.one:4004/api/vote/status',
            requestData
          );
  
          if (response.data.vote_count) {
            setVoteCount(response.data.vote_count);
          } else {
            setVoteCount("0");
          }
  
          const peopleResponse = await axios.post(
            'https://backend-hoxy-one-api.hoxy.one:4004/api/vote/people',
            requestData
          );
  
          if (peopleResponse.data) {
            setPeopleData(peopleResponse.data);
          } else {
            setPeopleData(null);
          }
  
          const RankResponse = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote/rank', 
          requestData
          );
          if (RankResponse.data) {
            setRankData(RankResponse.data);
          } else {
            setRankData(null);
          }
  
          setIsLoading(false);
        } catch (error) {
          console.error('Error:', error);
          setIsLoading(false);
        }
      } else {
        setTokenUserID('none');
        alert('정상적이지 않는 접근이에요');
        setIsLoading(false);
      }
    };
  
    fetchVoteResults();
  }, []);
  
  if (isLoading) {
    return <S_Vote />; // Render the skeleton loading component
  }
  
  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF'}}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>      

          <br></br>

        <Header Text="첫인상 투표" Icon="yes"/>

          
           <div style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
            <div className='FIvoteBox'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'13px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'37px', height:'37px', marginRight:'15px'}}src={HotAirBallon} ></img></div>
                        <div style={{marginTop:'3px'}}>
                            <div className='FIvoteText1'>1주차 나의 첫인상 투표 진행중..</div>
                            <div className='FIvoteText2'>현재 {voteCount}명이 투표에 참여했어요</div>
                        </div>
                        <Link to='/share'><div style={{marginTop:'4px', marginLeft:'90px'}}><UploadRed></UploadRed></div></Link>
                    </div>
                </div>
            </div>
           </div>

           <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
             <div className='FIvoteTitle2' >실시간 첫인상 투표</div>
             <Link to='/VoteResult'><div className='DetailButton'>자세히 보기</div></Link>
           </div>

           {RankData.vote_select_imgs && RankData.vote_select_imgs[0] ? (
            <>
            <div>
            <div style={{display:'flex',justifyContent:'center'}}>
            <div className='VoteOption'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'7px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'25px', height:'auto'}}src={RankData.vote_select_imgs[0]}></img></div>
                        <div style={{marginTop:'4px', marginLeft:'4px'}}>나랑 가장 {QuestionData[0].vote_question}은 <b>'{RankData.most_common_votes[0]}'</b></div>

                    </div>
                </div>
            </div>
           </div>

           <div style={{display:'flex',justifyContent:'center'}}>
            <div className='VoteOption'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'7px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'25px', height:'auto'}} src={RankData.vote_select_imgs[1]}></img></div>
                        <div style={{marginTop:'4px', marginLeft:'4px'}}>나랑 가장 {QuestionData[1].vote_question}는 <b>'{RankData.most_common_votes[1]}'</b></div>
                    </div>
                </div>
            </div>
           </div>

           <div style={{display:'flex',justifyContent:'center'}}>
            <div className='VoteOption'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'7px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'25px', height:'auto'}}src={RankData.vote_select_imgs[2]}></img></div>
                        <div style={{marginTop:'4px', marginLeft:'4px'}}>나랑 가장 {QuestionData[2].vote_question}은 <b>'{RankData.most_common_votes[2]}'</b></div>
                    </div>
                </div>
            </div>
           </div>

           <div style={{display:'flex',justifyContent:'center'}}>
            <div className='VoteOption'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'7px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'25px', height:'25px'}}src={RankData.vote_select_imgs[3]}></img></div>
                        <div style={{marginTop:'4px', marginLeft:'4px'}}>나랑 가장 {QuestionData[3].vote_question}는 <b>'{RankData.most_common_votes[3]}'</b></div>
                    </div>
                </div>
            </div>
           </div>

           <div style={{display:'flex',justifyContent:'center'}}>
            <div className='VoteOption'>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{marginTop:'7px', display:'flex', justifyContent:'center'}}>
                        <div><img style={{width:'25px', height:'auto'}}src={RankData.vote_select_imgs[4]}></img></div>
                        <div style={{marginTop:'4px', marginLeft:'4px'}}>나랑 가장 {QuestionData[4].vote_question}는 <b>'{RankData.most_common_votes[4]}'</b></div>
                    </div>
                </div>
            </div>
           </div>
           </div>
          </>
          ) : (
            <div>
               <br></br>
            <br></br>
            <br></br>
              <br></br>아직 아무도 투표하지 않았어요 😭 <br></br>내 프로필을 공유해보세요!
              <br></br>
            <br></br>
            <br></br>
            <br></br>
            </div>
          )}
    


           <div style={{display:'flex',justifyContent:'center',marginTop:'42px'}}>
             <div className='FIvoteTitle2' >투표에 참여한 사람</div>
             <Link to='/VoteResult'><div className='DetailButton'>자세히 보기</div></Link>
           </div>
           {peopleData.length > 0 ? (
           <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginRight: '0px' }}>
          {peopleData.map((person, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'center', marginTop: '27px' }}>
              <div className={person.vote_gender === '남성' ? 'ManInCircle' : 'WomanInCircle'}></div>
              <div className='Unknown'>
                {person.vote_relationship} 사이인 <b>{person.vote_age}세 {person.vote_gender}</b> 투표자
              </div>
              <Link to='/VoteResult'><div style={{ marginTop: '12px', marginRight: '20px' }}><ArrowRight></ArrowRight></div></Link>
            </div>
          ))}
        </div>
        ) : (
          <div>
            <br></br>
            <br></br>
            <br></br>

          </div>
        )}

          </div>    
          <Footer></Footer>
         </div>
    </div>
        
  );
}

export default FIvote;
