import hint_img from '../assets/img/hint.webp';
import hint1_img from '../assets/img/clock.webp';
import hint2_img from '../assets/img/apple-iphone.webp';
import hint3_img from '../assets/img/island.webp';
import jwt_decode from 'jsonwebtoken/decode';
import React, { useState, useEffect } from 'react';
import Footer from '../component/Footer';
import { Box } from '../stories/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BottomSheet from '../NewComponent/BottomSheet/Bottomsheet';
import Header from '../NewComponent/header';
import axios from 'axios';

function Hint2() {
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const requestDbNumber = new URLSearchParams(location.search).get('Mymessage');
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');
  const [showHint1, setShowHint1] = useState(false);
  const [showHint2, setShowHint2] = useState(false);
  const [showHint3, setShowHint3] = useState(false);
  const [hintText1, setHintText1] = useState('시간에 대한 힌트를 알 수 있어요');
  const [hintText2, setHintText2] = useState('휴대폰에 대한 힌트를 알 수 있어요');
  const [hintText3, setHintText3] = useState('위치에 대한 힌트를 알 수 있어요');
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);


    useEffect(() => {
      const token = localStorage.getItem('token');

      if (!requestDbNumber) {
        window.location.href = '/';
        alert('정상적이지 않는 접근이에요');
        return;
      }
  
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          const tokenUserID = decodedToken.user_id;
          const tokenUserNo = decodedToken.user_no;

              
          fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/search`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ request_db_number: requestDbNumber }),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('힌트를 불러오는데 실패했습니다.');
                  }
                  return response.json();
                })
                .then((data) => {
                  // 서버 응답이 온 후에 tokenUserID 설정
                  setTokenUserID(tokenUserID);
                  setTokenUserNo(tokenUserNo);


                  if (tokenUserID === data[0].recive_user_id) {
                    setMessages(data);
                    console.log("스크롤 초기화 완료 - 데이터 기준");
                    window.scrollTo(0, 0);
                  } else {
                    alert('본인의 메시지만 힌트를 확인할 수 있어요!');
                    navigate(`/`);
                  }
          

      
              // localStorage에서 활성화 상태 로드
              const storedActivationStates = JSON.parse(localStorage.getItem('activationStates')) || {};
            
              // 현재 메시지 ID에 대한 활성화 상태 확인
              if (storedActivationStates[requestDbNumber]) {
                setShowHint1(storedActivationStates[requestDbNumber].hint1);
                setShowHint2(storedActivationStates[requestDbNumber].hint2);
                setShowHint3(storedActivationStates[requestDbNumber].hint3);
              }
            
              // 데이터 로드와 함께 힌트 텍스트도 로드
              if (storedActivationStates[requestDbNumber]?.hint1) {
                const hintType = "time"; 
                const requestData = {
                  user_id: tokenUserID,
                  user_no: tokenUserNo,
                  hint_type: hintType,
                  request_db_number: requestDbNumber
                };
            
                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })
                .then(response => response.json())
                .then(data => {
                  const receivedHintText = data.timestamp; 
                  setHintText1(receivedHintText);
                })
                .catch(error => {
                  console.error('Error fetching hint:', error);
                });
              }
            
              if (storedActivationStates[requestDbNumber]?.hint2) {
                const hintType = "device"; 
                const requestData = {
                  user_id: tokenUserID,
                  user_no: tokenUserNo,
                  hint_type: hintType,
                  request_db_number: requestDbNumber
                };
            
                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint/active', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })
                .then(response => response.json())
                .then(data => {
                  const device = data.request_device;
                  setHintText2(`${device}`);
                })
                .catch(error => {
                  console.error('Error fetching hint:', error);
                });
              }
            
              if (storedActivationStates[requestDbNumber]?.hint3) {
                console.log(tokenUserID)
                const hintType = "location"; 
                const requestData = {
                  user_id: tokenUserID,
                  user_no: tokenUserNo,
                  hint_type: hintType,
                  request_db_number: requestDbNumber
                };
            
                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint/active', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })
                .then(response => {
                  if (!response.ok) {
                    if (response.status === 404) {
                      // hint3에 대한 로컬스토리지 데이터 삭제 처리
                      const updatedActivationStates = { ...storedActivationStates };
                      delete updatedActivationStates[requestDbNumber].hint3;
                      localStorage.setItem('activationStates', JSON.stringify(updatedActivationStates));
                      alert('비정상적인 접근이에요.');
                      navigate('/');
                    } else {
                      alert('내부서버의 오류가 발생했어요');
                      navigate('/');
                    }
                    throw new Error('힌트를 가져오는데 실패했습니다.');
                  }
                  return response.json();
                })
                .then(data => {
                  const location = `${data.user_r1} ${data.user_r2} ${data.user_r3}`;
                  setHintText3(`${location}`);
                })
                .catch(error => {
                  console.error('Error fetching hint:', error);
                });
              }


            })
            .catch((error) => {
              console.error(error);
              alert('힌트를 불러오는데 실패했습니다.');
              navigate(`/`);
            });
      
        } catch (error) {
          console.error(error);
          navigate(`/`);
          alert('정상적이지 않는 접근이에요');
        }
      } else {
        alert('힌트를 확인하려면, 로그인이 필요해요!');
        navigate(`welcome`);
      }
      
    
      
    }, []);

   


const handleHintButtonClick = (hintNumber) => {
  let hintElement1, hintElement2, hintElement3;

   switch (hintNumber) {
    case 1:
      //const visited = new URLSearchParams(window.location.search).get('visited');
      //if (visited === 'true') {
        hintElement1 = document.getElementById('hintText1');
        hintElement1.classList.add('fade-out');
        setTimeout(() => {
          setShowHint1(true);
          const hintType = "time"; 
          const requestData = {
            user_id :tokenUserID,
            user_no: tokenUserNo,
            hint_type: hintType,
            request_db_number: requestDbNumber
          };

          fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          })
            .then(response => response.json())
            .then(data => {
              const receivedHintText = data.timestamp; 
              setHintText1(receivedHintText);
              hintElement1.classList.remove('fade-out');
              hintElement1.classList.add('fade-in');
              updateActivationState(requestDbNumber, hintNumber);

            })
            .catch(error => {
              console.error('Error fetching hint:', error);
            });
        }, 500);
      //} //else {
        //navigate(`/coupang?Mymessage=${requestDbNumber}`);

      //}
      break;
      case 2:
        hintElement2 = document.getElementById('hintText2');
        hintElement2.classList.add('fade-out');
        setTimeout(() => {
          //fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/${tokenUserID}`)
            //.then(response => response.json())
            //.then(data => {
              //if (data.length >= 5) {
              const hintType = "device"; 
               const requestData = {
                 user_id :tokenUserID,
                 user_no: tokenUserNo,
                  hint_type: hintType,
                  request_db_number: requestDbNumber
               };

                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })
                  .then(response => response.json())
                  .then(data => {
                    const device = data.request_device;
                    setShowHint2(true);
                    setHintText2(`${device}`);
                    hintElement2.classList.remove('fade-out');
                    hintElement2.classList.add('fade-in');
                    updateActivationState(requestDbNumber, hintNumber);

                  })
                  .catch(error => {
                    console.error('Error fetching hint:', error);
                  });
              //}// else {
                //alert('내가 지금까지 받은 누적 요청이 5개 미만이에요. ');
                //setShowHint2(false);

              //}
           // })
            //.catch(error => {
             // console.error('Error fetching messages:', error);
           // });
       }, 500);
        break;
        case 3:
          hintElement3 = document.getElementById('hintText3');
          hintElement3.classList.add('fade-out');
        
      
                const hintType = "location"; // hintType 수정
      
                // requestData 선언
                const requestData = {
                  user_id: tokenUserID,
                  user_no: tokenUserNo,
                  hint_type: hintType,
                  request_db_number: requestDbNumber,
                };
      
                fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/hint', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })
                  .then(response => response.json())
                  .then(data => {
                    const location = `${data.user_r1} ${data.user_r2} ${data.user_r3}`;
                    setShowHint3(true);
                    setHintText3(`${location}`);
                    hintElement3.classList.remove('fade-out');
                    hintElement3.classList.add('fade-in');
                    updateActivationState(requestDbNumber, hintNumber);

                  })
                  .catch(error => {
                    console.error('Error fetching hint:', error);
                  });
               
           
            
      
          break;
      
        default:
          break;

            
          };



const updateActivationState = (messageId, hintNumber) => {
  const storedActivationStates = JSON.parse(localStorage.getItem('activationStates')) || {};

  if (!storedActivationStates[messageId]) {
    storedActivationStates[messageId] = {};
  }

  storedActivationStates[messageId][`hint${hintNumber}`] = true;

  localStorage.setItem('activationStates', JSON.stringify(storedActivationStates));
};
  };


  const handleHintButtonClick3 = () => {
    const apiUrl = 'https://backend-hoxy-one-api.hoxy.one:4004/api/jokercard/hint/check';
  
    // 전달할 데이터 설정
    const requestData = {
      requestdbnumber: requestDbNumber,
      user_no: tokenUserNo
    };
  
    axios.post(apiUrl, requestData)
      .then((response) => {
        const message = response.data.message;
  
        if (message === 'Success') {
          handleHintButtonClick(3)
        }
        else if (message === 'Nodata') {
          setIsBottomSheetVisible(!isBottomSheetVisible);
        } 
    
      })
      .catch((error) => {
        console.error('POST 요청 중 오류 발생:', error);
        alert('내부 서버 오류로, 현재 힌트를 확인할 수 없어요. 지속된다면 고객센터로 문의해주세요')
      });
  };
       


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>

  {/* Overlay layer when BottomSheet is visible */}
  {isBottomSheetVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for transparency
            zIndex: 1, // Ensure this overlay is above other content
          }}
        ></div>
      )}

        <div>
          <br></br>
         
         <Header Text='친해지고 싶은 사람 힌트' />

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                {messages.length === 0 ? (
                  <div></div>
                ) : (
                  messages.map((message, index) => (
                    <div key={index} style={{ marginBottom: '15px' }}>

                
                      <Box
                        className="LargeBox"
                        Label1={`${message.request_age}세 ${message.request_gender}`}
                        Label2={
                          <>
                            나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인 {message.request_age}세 {message.request_gender}의 {message.request_keyword ? message.request_keyword : '***'} 분께서{' '}
                            {message.request_want ? message.request_want : '***'} 해요!
                          </>
                        }
                        ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.png`}
                        Label4="#ffffff"
                      /> 

                                               

                    </div>
                    ))
                )}  
              </div>


              <hr style={{width:'320px', border:'solid 1px #EAEAEA', marginTop:'10px'}}></hr>
              
              
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'10px', marginBottom:'130px'}}>
              
              <div style={{overflowY:'hidden', overflowX: 'hidden' }}>
              <h3 style={{fontSize:'17px', textAlign:'left'}}>친해지고 싶은 사람의 요청 시간</h3>
              <div className='hint_box' style={{ backgroundColor: showHint1 ? '#FBF5F5' : '#F2F3F5'}}>

              <div style={{display:'flex'}}>
              <div style={{
                  marginLeft:'20px'
                }}><img src={hint1_img} className='hint_img'></img></div>
                
                <div style={{marginLeft:'15px', textAlign:'left'}}>
                <p className='hint_p1'>나에게 언제 친해지고 싶다고 보낸걸까요?</p>
                <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px'}}  id="hintText1">{hintText1}</p>

                </div>
                
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                <svg
                  className='hint_button'
                  onClick={() => handleHintButtonClick(1)}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                  <path
                    d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                    fill="white"
                  />
                </svg>
                  </div>
                  </div>
              </div>
              </div>
           


              <div>
              <h3 style={{fontSize:'17px', textAlign:'left'}}>친해지고 싶은 사람의 기기</h3>
              <div className='hint_box' style={{ backgroundColor: showHint2 ? '#FBF5F5' : '#F2F3F5'}}>

              <div style={{display:'flex'}}>
              <div style={{
                  marginLeft:'20px'
                }}><img src={hint2_img} className='hint_img'></img></div>
                
                <div style={{marginLeft:'15px', textAlign:'left'}}>
                <p  className='hint_p1'>나랑 친해지고 싶은 사람은 어떤 폰을 쓸까요?</p>
                <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px', letterSpacing:'-0.04rem'}} id="hintText2">{hintText2}</p>

                </div>
                
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                  <svg
                  className='hint_button'
                  onClick={() => handleHintButtonClick(2)}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                  <path
                    d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                    fill="white"
                  />
                </svg>
                  </div>
                </div>
              </div>
         

              </div>



           <div>
              <h3 style={{ fontSize: '17px', textAlign: 'left' }}>친해지고 싶은 사람의 위치</h3>
              <div className='hint_box' style={{ backgroundColor: showHint3 ? '#FBF5F5' : '#F2F3F5'}}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      marginLeft: '20px'
                    }}
                  >
                    <img src={hint3_img} className='hint_img'></img>
                  </div>

                  <div style={{ marginLeft: '15px', textAlign: 'left' }}>
                    <p  className='hint_p1'>나랑 친해지고 싶은 사람은 어디에 있을까요?</p>
                    <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px'}} id="hintText3">{hintText3}</p>

                  </div>

                  <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                  <svg
                  className='hint_button'
                  onClick={() => {

                    handleHintButtonClick3();

                  
                    
                  }}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                  <path
                    d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                    fill="white"
                  />
                </svg>
                  </div>
                </div>
              </div>
              
                  </div> 
                  {isBottomSheetVisible && <BottomSheet needCard={'3'} needReasonText={'친해지고 싶은 사람의 힌트 - 위치'} needReason={`hint_location_${requestDbNumber}`}/>}


              </div>
              </div>
        <Footer>
          </Footer>        
          </div>
      </div>

  );
}

export default Hint2;
