import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CastingInput from '../../NewComponent/CastingInput';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import CastingReason from '../../NewComponent/CastingReason';
import Header from '../../NewComponent/header';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import img_src from '../../NewComponent/assets/Complete.png'
import school_img from '../../NewComponent/assets/School.svg'
import { SearchResult2 } from '../../NewComponent/SearchResult2';
import { Link } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import { motion } from 'framer-motion';


function CastingP() {
    const [currentContent, setCurrentContent] = useState(1);
    const [previousContent, setPreviousContent] = useState(1);
    const [schools, setSchools] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [results, setResults] = useState([]); 
    const [searchError, setSearchError] = useState(false); 
    const [tokenUserID, setTokenUserID] = useState("");
    const [tokenUserNo, setTokenUserNo] = useState("");

    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();

    const [casting_name, setName] = useState('');
    const [casting_SchoolName, setSchoolName] = useState('');
    const [casting_instagramId, setInstagramId] = useState('');
    const [casting_reason, setReason] = useState('');

    const token = localStorage.getItem('token');
  
    const [showPopup, setShowPopup] = useState(false);
    
    const handleNextClick = () => {
      if (currentContent < 5) {
        let isValid = true; // 초기에 모든 필드는 유효하다고 가정합니다
        
        // 현재 페이지(currentContent)에 따라 유효성을 확인하되, 2번 페이지(학교 선택)에 대한 유효성 검사는 건너뜁니다
        if (currentContent === 1) {
          isValid = casting_name.trim() !== ''; // 이름 필드가 비어있지 않은지 확인
        } else if (currentContent === 3) {
          isValid = casting_instagramId.trim() !== ''; // 인스타그램 아이디 필드가 비어있지 않은지 확인
        } else if (currentContent === 4) {
          isValid = casting_reason.trim() !== ''; // 추천 이유 필드가 비어있지 않은지 확인
        }
        
        // 2번 페이지(학교 선택)에 대한 유효성 검사를 건너뛰며, 다른 단계(1, 3, 4)에서 빈 필드가 있을 때만 "빈칸이 있어요" 메시지가 표시됩니다
        if (currentContent !== 2 && !isValid) {
          alert('아직 입력되지 않은 빈칸이 있어요');
          return;
        }
        
        setPreviousContent(currentContent);
        setCurrentContent(currentContent + 1);
      }
    };
    
    
    
    
    

    const handleInputChange1 = (event) => {
      setName(event.target.value);
    };

    const handleInputChange2 = (schoolName) => {
      setSchoolName(schoolName);
      handleNextClick(); // 다음 페이지로 이동하도록 호출
    };

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          const tokenUserID = decodedToken.user_id;
          const tokenUserNo = decodedToken.user_no;

          setTokenUserID(tokenUserID);
          setTokenUserNo(tokenUserNo);

    
        } catch (error) {
          console.log('토큰 디코딩 에러');
        }
      } else {
      }
    }, []);
   
    const handleInputChange3 = (event) => {
      const inputValue = event.target.value;
      // 정규 표현식을 사용하여 입력된 값이 영문, 숫자, 밑줄, 그리고 점으로만 이루어져 있는지 확인
      const instagramIdRegex = /^[a-zA-Z0-9._]*$/;
      
      if (!instagramIdRegex.test(inputValue)) {
        // 입력 값이 정규 표현식과 일치하지 않으면 상태를 변경하지 않고 입력 값을 초기값으로 되돌림
        event.target.value = ''; // 입력 값을 초기화
      } else {
        // 입력 값이 정규 표현식과 일치하면 상태를 업데이트
        setInstagramId(inputValue);
      }
    };
 

    const handleInputChange4 = (text) => {
      setReason(text);
    };

    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };

    const handleSubmit = async () => {
      try {
        
        const response = await axios.post(
          'https://backend-hoxy-one-api.hoxy.one:4004/api/casting/join',
          {
            user_id: tokenUserID, // 사용자 아이디를 여기에 추가
            user_no: tokenUserNo, // 사용자 아이디를 여기에 추가
            casting_name: casting_name,
            casting_insta_id: casting_instagramId,
            casting_school_name: casting_SchoolName,
            casting_reason: casting_reason,
          }
        );
    
        if (response.status === 200) {          
          navigate(`/${tokenUserID}?explore=yes`);

        } else {
          alert('내부서버의 오류로 유저캐스팅 신청에 실패했어요. 고객센터로 문의해주세요');

        }
      } catch (error) {
        console.error("An error occurred while submitting data:", error);
      }
    };
    

  
    useEffect(() => {
      const delay = 500; // Debounce delay time
      const timer = setTimeout(() => handleSearch(searchText), delay);
  
      return () => clearTimeout(timer);
    }, [searchText]);
  
    const handleSearch = async () => {
      try {
        const response = await axios.get(
          `https://open.neis.go.kr/hub/schoolInfo`,
          {
            params: {
              KEY: "161fd332328d49d9b0a5af11243034cc", // Replace with a valid API key
              Type: "json",
              pIndex: 1,
              pSize: 10,
              SCHUL_NM: searchText,
            },
          }
        );
  
        const foundSchools =
          response.data &&
          response.data.schoolInfo &&
          response.data.schoolInfo[1].row
            ? response.data.schoolInfo[1].row
            : [];
        setSchools(foundSchools);
        console.log(schools)
      } catch (error) {
        console.error("An error occurred while searching for schools:", error);
      }
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        if (!token) {
          setShowPopup(true);
        }
      }, 2000); // 2000 milliseconds = 2 seconds
    
      return () => clearTimeout(timer); // Clear the timer on component unmount
    }, [token]);

    const handlePopupButtonClick = () => {
      navigate('/start');
    };
  


   

  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className='main_content'>
      <br></br>
      

      <div className='Content-Casting'>
        <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>
        <Header className="CastingTitle" Text="캐스팅이 필요한 친구의" Text2='이름을 알려주세요' />

           <CastingInput onChange={handleInputChange1} what={'이름'}/>

        </div>

        <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>
        <Header className="CastingTitle" Text="캐스팅이 필요한 친구가 다니는" Text2='학교를 알려주세요' />

            <CastingInput   Value={searchText}
          onChange={handleSearchChange}  what={'학교 이름'} />

              <div style={{ position: 'absolute', top: '50%', left: '10%'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', color: '#848484' }}>
                    {schools.map((school, index) => (
                  <Link onClick={() => handleInputChange2(school.SCHUL_NM)}
                  key={index}  style={{marginBottom:'10px'}} >
                    <SearchResult2
                      img={school_img} // You may want to use the appropriate image for each school
                      Label1={school.SCHUL_NM} // Use the school name from the search results
                      Label2={school.SCHUL_KND_SC_NM} // Customize as needed
                      Label3={`${school.LCTN_SC_NM}에 위치하고 있는 학교에요`} // Customize as needed
                      iconType={'arrow'} // Customize as needed

                    />
                  </Link>
                ))}
                    </div>
                    <br></br>
                  </div>

        </div>

        <div id='Content3' style={{ display: currentContent === 3 ? 'block' : 'none' }}>
        <Header className="CastingTitle" Text="캐스팅이 필요한 친구의" Text2='인스타그램 아이디를 알려주세요' />

            <CastingInput onChange={handleInputChange3} what={'@인스타그램 아이디'}/> 

        </div>

        <div id='Content4' style={{ display: currentContent === 4 ? 'block' : 'none'}}>
        <Header className="CastingTitle" Text="캐스팅이 필요한 친구를" Text2='추천하는 이유를 알려주세요' />
        
        <div className='middle_component'>
            <div style={{display:'flex',justifyContent:'center'}}>
                 <CastingReason onSaveText={handleInputChange4}/>
            </div>
        </div>
        </div>

        <div id='Content5' style={{ display: currentContent === 5 ? 'block' : 'none' }}>
          
           <MiddleCompoent img={img_src} Text1="유저 캐스팅 신청이 완료되었어요" Text2="곧 만날 수 있게 노력할게요"/>

        </div>
      </div>
     
      <div onClick={handleNextClick} style={{ display: currentContent === 5 ? 'none' : 'block' }}>
          <PrimaryButton  Text={'다음'}/>
      </div>

      <div style={{ display: currentContent === 5 ? 'block' : 'none' }}>
        <div onClick={handleSubmit}>
      <PrimaryButton Text={'내 프로필로 돌아가기'}  />
      </div>
      </div>
    

      {showPopup && (
      <motion.div
        className="popup-container"
        initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
        animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
        exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
      >
        <motion.div
          className="popup-content"
          initial={{ opacity: 0 }} // 초기 상태
          animate={{ opacity: 1 }} // 애니메이션 종료 상태
        >
          <h2 style={{ fontSize: '22px' }}>
            캐스팅을 진행하고,<br></br>결과를 가장 먼저 알려드릴게요
          </h2>
          <p>
            가입 후 캐스팅을 요청해도, 캐스팅 받은 친구는
            <br />
            내가 누군지 정확히 알아 낼 수 없어요 🔒
          </p>
          
          <div className="button-container">
            <button
              className="delete-button"
              style={{ visibility: 'hidden' }}
            >
              계속하기
            </button>
            <motion.button
              className="retry-button"
              style={{ fontSize: '14px', fontWeight: '600' }}
              onClick={handlePopupButtonClick}
            >
              가입하고, 계속하기
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    )}
      </div>
    </div>
  );
}

export default CastingP;
