import GoogleAdsense from '../component/GoogleAdsense';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SmallNavi } from '../stories/SmallNavi';
import { OneBox } from '../stories/OneBox';
import { Box } from '../stories/Box';
import { LargeButton } from '../stories/LargeButton';
import { Head } from '../stories/Head';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';
import '../App.css';
import Footer from '../component/Footer';
import KakaoAdFit  from '../component/KakaoAdFit';
import { ReactComponent as StartLogo } from "../stories/assets/startlogo.svg";
import { CSSTransition } from 'react-transition-group';
import qa_img from '../assets/img/qa.webp'
import S_Profile from '../NewComponent/Skeletons/S_Profile'
import PrimaryButton from '../NewComponent/PrimaryButton';
import { useLocation } from 'react-router-dom';
import Intelligence from '../NewComponent/Intelligence';
import { motion, useAnimation  } from 'framer-motion';
import ProfileBox from '../NewComponent/ProfileBox';
import sample_gun  from "../NewComponent/assets/sample_gun.png"
import SecondaryButtonSmall2 from '../NewComponent/SecondaryButtonSmall2';
import BottomNav from '../NewComponent/BottomNav';
import vote_ballot from '../NewComponent/assets/chat_bubble.webp';
import soap_bubble from '../NewComponent/assets/soap-bubble 2.webp';
import love_letter from '../NewComponent/assets/love_letter.webp';
import profile_loading from '../NewComponent/assets/Loading.gif'

function Profile({animate}) {

  const [user, setUser] = useState({ user_name: '', user_id: '', school_name: '' });
  const [requestCount, setRequestCount] = useState(0);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState(null);
  const [requestDbNumber, setRequestDbNumber] = useState('');
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');

  const [visitProfileID, setVisitProfileID] = useState('');
  const [VisitprofileUserNo, setVisitProfileUserNo] = useState('');
  const [visitProfileSchool, setVisitProfileSchool] = useState('');
  const [shouldRenderButtons, setShouldRenderButtons] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [shouldRenderAd, setShouldRenderAd] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [showMainContent, setShowMainContent] = useState(false); // Add a state to control main content rendering
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [buttonAnimate, setButtonAnimate] = useState('initial');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonAnimate((prevAnimate) => (prevAnimate === 'highlight' ? 'initial' : 'highlight'));
    }, 600); // 1초마다 애니메이션을 토글

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시에 인터벌 해제
    };
  }, []);

  const buttonContainerStyle = {
    display:'flex',
    justifyContent:'center',
    marginTop:'15px'
  };

  const buttonStyle = {
    display: 'flex',
    width: '325px',
    padding: '15px 120px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    background: '#FF5C00',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginTop : '-10px'
  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap',
  };

  const buttonVariants = {
    initial: {
      scale: 1,
      x: 0,
      rotate: 0,
    },
    highlight: {
      scale: 1.05,
      x: [0, -5, 5, -3, 3, 0], // 좌우로 움직임
      rotate: [0, -5, 5, -3, 3, 0], // 텍스트 회전 애니메이션
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10,
      },
    },
  };

    useEffect(() => {
      // Check if the path includes "?explore=yes&onboarding=yes"
      if (!location.pathname.includes('/${visitProfileId}?explore=yes')) {
        const fetchData = async () => {
          try {
            // Simulate an API call
            // Replace this with your actual data fetching logic
            await new Promise(resolve => setTimeout(resolve, 2000));
    
            // Step 2: Set isLoading to false after data is loaded
            setIsLoading(false);
          } catch (error) {
            console.error('API Error:', error); // Use console.error for errors
          }
        };
    
        fetchData();
      }
    }, [location.pathname]); // Run the effect whenever the pathname changes
  

    useEffect(() => {
      // 15초 후에 툴팁을 숨김
      const timeout = setTimeout(() => {
        setShowTooltip(false);
      }, 15000); // 15000 밀리초 = 15초
  
      // 컴포넌트가 언마운트될 때 타이머 클리어
      return () => clearTimeout(timeout);
    }, []);


    const handlePopupButtonCloseClick = () => {
      setShowPopup(false)
    };

  function isMobileDevice() {
      
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes('android') ||
      userAgent.includes('iphone') ||
      userAgent.includes('ipad') ||
      userAgent.includes('ipod') ||
      userAgent.includes('windows phone')
    );
  }


  const logProfileVisit = async () => {
    try {
      const profile_user_id = visitProfileID;
      const profile_user_no = VisitprofileUserNo;
      const hit_user_id = tokenUserID || 'anonymous'; // tokenUserID가 없을 때 "anonymous"로 설정
      const hit_user_no = tokenUserNo || '0'; 
      const hite_page = window.location.href; // 현재 페이지 경로를 가져옴
      await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/profile_hit', { profile_user_id, profile_user_no, hit_user_id, hit_user_no, hite_page });
    } catch (error) {
      console.error('프로필 방문 로깅에 실패했어요 :', error);
    }
  };
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const exploreParam = urlParams.get('explore');
  
    if (exploreParam === 'yes') {
      setShowMainContent(true);
    } else {
      const delayTimeout = setTimeout(() => {
        setShowMainContent(true);
          if (!exploreParam) {
          const newUrl = `${window.location.pathname}?explore=yes${window.location.hash}`;
          window.history.pushState({}, '', newUrl);
        }
      }, 3000);
      return () => clearTimeout(delayTimeout);
    }
  }, []);
   

  
  useEffect(() => {
    fetchUser(user_id);
    fetchProfileStatus(user_id);
  }, [user_id]);

  useEffect(() => {
    const imageCount = 20;
    const baseUrl = 'https://resource.hoxy.one/Box_emoji/new/';
    const preloadPromises = [];
  
    for (let i = 1; i <= imageCount; i++) {
      const imgUrl = `${baseUrl}${i}.webp`;
      const img = new Image();
      preloadPromises.push(
        new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = imgUrl;
        })
      );
    }
  
    Promise.all(preloadPromises)
      .then(() => console.log('모든 이미지가 성공적으로 로딩되었습니다.'))
      .catch((error) => console.error('이미지 로딩 중 오류가 발생했습니다.', error));

    const tooltipTimeout = setTimeout(() => {
      setFadeOut(true);
    }, 20000); 

    return () => clearTimeout(tooltipTimeout);
    
  }, []);


  const handleHideAd = () => {
    setShouldRenderAd(false);
  };



  const fetchUser = (user_id) => {
    fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('사용자를 찾을 수 없음');
        }
        return response.json(); // JSON 데이터를 파싱하도록 수정
      })
      .then((data) => {
        setUser(data);
        localStorage.setItem('VisitprofileUserNo', data.user_no);
        localStorage.setItem('VisitprofileID', user_id);
        localStorage.setItem('VisitprofileName', data.user_name);
        localStorage.setItem('VisitprofileSchool', data.school_name);
      })
      .catch((error) => {
        console.error(error);
        navigate('/start');
        //navigate('/error'); 원래는 에러페이지로 이동했으나 임시 주석처리
      });
  };

  const fetchProfileStatus= (user_id) => {
    axios
      .post('https://backend-hoxy-one-api.hoxy.one:4004/api/profile/status', {
        user_id: user_id
      })
      .then((response) => {
        // 응답에서 데이터 추출
        const { request_count, accepted_count } = response.data;
  
        // 추출한 데이터를 상태 변수에 저장
        setRequestCount(request_count);
        setAcceptedCount(accepted_count);
  
        console.log('프로필 통계 조회 성공');
      })
      .catch((error) => {
        console.error('프로필 통계 조회 실패:', error);
  
        // 에러 발생 시 상태 변수 초기화
        setRequestCount(0);
        setAcceptedCount(0);
      });
  };
  


  const fetchMessages = () => {
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('요청된 메시지를 가져올 수 없음');
        }
        return response.json();
      })
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  const fetchMessagesNone = () => {
    fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/none/${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('요청된 메시지를 가져올 수 없음');
        }
        return response.json();
      })
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    const visitProfileID = localStorage.getItem('VisitprofileID');
    setVisitProfileID(visitProfileID);
  
    const token = localStorage.getItem('token');
    const now = new Date().getTime();
  
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);

        localStorage.setItem('user_id', tokenUserID);

        const today = new Date();
        const loginDate = localStorage.getItem('loginDate');
        const thirtyMinutesAgo = new Date(today.getTime() - 30 * 60 * 1000);
  
        if (!loginDate || new Date(loginDate) < thirtyMinutesAgo) {
          localStorage.setItem('loginDate', today.toString());
  
          axios
            .post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: tokenUserID,
              user_no: tokenUserNo,
              login_page: window.location.href,
            })
            .then((response) => {
              console.log('로그인 로깅 성공');
            })
            .catch((error) => {
              console.error('로그인 로깅 실패:', error);
            });
        }
  
        if (visitProfileID !== tokenUserID) {
          fetchMessages(); //원래 None
  
          const allProfilesLog = JSON.parse(localStorage.getItem('allProfilesLog') || '{}');
          const profileLog = allProfilesLog[visitProfileID] || { lastLoggedDate: 0 };

          if (!profileLog.lastLoggedDate || is24HoursPassed(profileLog.lastLoggedDate)) {
            const profile_user_id = visitProfileID;
            const profile_user_no = VisitprofileUserNo;
            const hit_user_id = tokenUserID ? tokenUserID : 'anonymous';
            const hit_user_no = tokenUserNo ? tokenUserNo : '0';
            const hite_page = window.location.href;

            axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/profile_hit', {
              profile_user_id,
              profile_user_no,
              hit_user_id,
              hit_user_no,
              hite_page,
            });

            profileLog.lastLoggedDate = now;
            allProfilesLog[visitProfileID] = profileLog;
            localStorage.setItem('allProfilesLog', JSON.stringify(allProfilesLog));
          }
         
  
        } else if (visitProfileID === tokenUserID) {
          fetchMessages();
          setShouldRenderButtons(true);
        }
      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
      fetchMessages(); //원래 None

      const allProfilesLog = JSON.parse(localStorage.getItem('allProfilesLog') || '{}');
      const profileLog = allProfilesLog[visitProfileID] || { lastLoggedDate: 0 };

      if (!profileLog.lastLoggedDate || is24HoursPassed(profileLog.lastLoggedDate)) {
        const profile_user_id = visitProfileID;
        const profile_user_no = VisitprofileUserNo;
        const hit_user_id = tokenUserID ? tokenUserID : 'anonymous';
        const hit_user_no = tokenUserNo ? tokenUserNo : '0';
        const hite_page = window.location.href;

        axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/profile_hit', {
          profile_user_id,
          profile_user_no,
          hit_user_id,
          hit_user_no,
          hite_page,
        });

        profileLog.lastLoggedDate = now;
        allProfilesLog[visitProfileID] = profileLog;
        localStorage.setItem('allProfilesLog', JSON.stringify(allProfilesLog));
      }
    }
  }, []);
  
  function is24HoursPassed(lastLoggedDate) {
    const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
    return new Date().getTime() - lastLoggedDate >= twentyFourHoursInMs;
  }
  
  
    useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const onboardingParam = urlParams.get('onboarding');

    if (onboardingParam === 'yes') {
      setShowPopup(true);
    } 

  }, []);

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);

     // 클로즈 버튼을 누를 때 &onboarding=yes 파라미터를 URL에서 제거
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.delete('onboarding');
     const newUrl = `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`;
     window.history.pushState({}, '', newUrl);
  };

  const handleFirstShareButtonClick = () => {
    navigate('/fristShareLanding');

  };


  const handleShowPopup = () => {
    setShowPopup(true)
  };

  


  const handleVoteBoxClick = () => {
    if (tokenUserID && visitProfileID === tokenUserID) {
      navigate('/TodayQuestion');
    } else {
      navigate('/TodayQuestion');
    }
  };


  if (messages === null) {
    return null; // 로딩 중 메시지를 나타내는 컴포넌트를 반환합니다.
  }

  const messageCount = messages.length;
  const lastIndex = messageCount - 1;


  if (!showMainContent) {
    // Show loading screen with logo during the 3-second delay
    return (
      <div className="main" style={{ backgroundColor: '#FF5C00' }}>
        <div
          className="main_content"
          style={{
            backgroundColor: '#FF5C00',
            touchAction: 'none',
            height: '100vh',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CSSTransition in={showLogo} timeout={1000} classNames="fade">
              <StartLogo className="content" />
            </CSSTransition>
          </div>
        </div>
      </div>
    );
  }


  
  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        {isLoading ? ( // 데이터 로딩 중인 경우
          <S_Profile /> // 스켈레톤 컴포넌트 표시
        ) : (
          <div>
            


        
              {!localStorage.getItem('app') && ( // shouldRenderButtons가 false이고 로컬스토리지의 app 값이 true가 아닌 경우
                <Intelligence type="app" />
              )}
            
            {shouldRenderButtons && ( // showMainContent가 true인 경우에만 아래 내용을 렌더링
            <>
              {messageCount === 0 && ( // messageCount가 0인 경우
                <Intelligence type="share" />
              )}
            </>
            )}
            <br />

            <div style={{ marginTop: '5px' }}>
              <Head className="MainHead" />
            </div>
  
            <div style={{ marginTop: '20px' }}>
            
            <ProfileBox
              profile_name={user.user_name}
              profile_school={
                user.user_age < 20
                  ? `${user.school_name} ${user.user_grade}학년`
                  : `${user.school_name} ${user.user_class}학번`
              }
              profile_insta={`@${user.user_id} ·`} 
              profile_id={user.user_id}
              profile_follower={user.user_insta_img ? `팔로워 ${user.user_insta_followed}명 ·` : '인스타 정보를'}
              profile_following={user.user_insta_img ? `팔로잉 ${user.user_insta_follow}명` : '찾고 있어요'}
              img={user.user_insta_img ? user.user_insta_img : profile_loading }
              button_img={shouldRenderButtons ? 'upload_arrow' : 'instagram'}
            />


            </div>


            
            <div>
              <div style={{display:'flex',justifyContent:'center',marginTop:'35px'}}>
              <Link to='/request'>
              <div>
              <div><SecondaryButtonSmall2 Text='익명 찔러보기' img={love_letter}/></div>

              </div>
            </Link>




                <Link to='/TodayQuestion'>
                <div><SecondaryButtonSmall2 Text='1일 1문답하기' img={vote_ballot}/></div>
                </Link>
              </div>



              <div style={buttonContainerStyle} >
                    <motion.button
                        style={buttonStyle}
                        variants={buttonVariants}
                        initial="initial"
                        animate={animate === 'highlight' ? buttonAnimate : 'initial'} // animate prop에 따라 애니메이션 활성화
                        whileTap={{ scale: 0.95 }}
                        onClick={handleShowPopup}
                    >
                        <span style={buttonTextStyle}>{user.user_name}님에게 익명으로 DM 보내기</span> <img src={soap_bubble} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} />
                    </motion.button>
                </div>
            </div>

            
  
            <div style={{ display: 'flex', flexDirection: 'column' }}>
  
              <br></br>
              <KakaoAdFit unit={"DAN-cFUELCtfG8SU7JTQ"} width={"320"} height={"100"} disabled={false}/>
              <br></br>
  
              <div className="MessageTo" style={{ textAlign: 'left', marginRight: '250px', whiteSpace: 'nowrap' }}>{user.user_name}님에게 온 속마음 편지</div>
  
              {shouldRenderButtons  && (
                <div className="MessageTo" style={{ textAlign: 'left', marginRight: '250px', whiteSpace: 'nowrap' }}>편지를 눌러 답장하고, 힌트도 확인할 수 있어요🍀</div>
              )}
  
              <div className="Boxsquare" style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <div className='vote_box'>
                  <div style={{ display: 'flex', marginTop: '-4px' }}>
                    <div style={{ marginLeft: '20px' }}><img src={qa_img} className='hint_img' width={'35px'} height={'35px'}></img></div>
                    <div style={{ marginLeft: '15px', textAlign: 'left' }}>
                      <p className='hint_p1' style={{ color: '#000000', fontWeight: '300' }}>매일 새로운 질문을, 익명으로 답할 수 있어요</p>
                      <p style={{ fontSize: '14px', fontWeight: '500', color: '#000', marginTop: '-12px', color: '#000000' }}>{user.user_name}님의 <p2 style={{ fontSize: '14px', fontWeight: '700', color: '#000000' }}>1일 1문답에 참여하기</p2></p>
                    </div>
                    <div onClick={handleVoteBoxClick} style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '20px', marginTop: '5px' }}>
                      <svg
                        className='hint_button'
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                        <path
                          d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
  
                {messageCount === 0 ? (
                  <div className='gray_text'>
                    아직 요청된 메시지가 없어요 😭<br />
                    {user.user_name}님께 친해지고 싶은 마음을 표현해보세요!
                  </div>
                ) : (
                  <>
                    {messages.map((message, index) => (
                      <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                        {message.type === '1day1' ? (
                          // '1day1' 타입의 콘텐츠를 렌더링합니다.
                          <Box
                            className="LargeBox"
                            Label1={`Q. ${user.user_name}님${message.question_content}`}
                            Label2={
                              <>
                            {message.question_answer}
                            {message.oneDayOne_reply_recive_reply && (
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing:'-0.06rem' }}>
                                  <p style={{ marginTop: '-5px' }} />
                                {`ㄴ  "${message.oneDayOne_reply_recive_reply}"`}
                              </span>
                            )}
                          </>
                            }
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new2/${(index % 10) + 1}.webp`}
                            Label4="#ffffff"
                            shouldRenderButtons={shouldRenderButtons}
                            requestDbNumber={message.request_db_number}
                          />
                        ) : (
                          // 다른 타입의 콘텐츠를 렌더링합니다.
                          <Box
                            className="LargeBox"
                            Label1={`익명의 ${message.request_age}세 ${message.request_gender}`}
                            Label2={
                              <>
                                나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인 {message.request_age}세 {message.request_gender}의 {message.request_keyword ? message.request_keyword : '***'} 분께서{' '}
                                {message.request_want ? message.request_want : '***'} 해요!
                                {message.reply_recive_reply && (
                                  <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing:'-0.06rem' }}>
                                  <p style={{ marginTop: '-5px' }} />
                                    {`ㄴ  "${message.reply_recive_reply}"`}
                                  </span>
                                )}
                              </>
                            }
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.webp`}
                            Label4="#ffffff"
                            shouldRenderButtons={shouldRenderButtons}
                            requestDbNumber={message.request_db_number}
                          />
                        )}
                      </div>
                    ))}
                  </>
                )}
  
               {/* {showPopup && (
                  <>
                    <div className="popup-container" onClick={handlePopupClose}>
                      <div className="popup-content" onClick={(e) => e.stopPropagation()} style={{ textAlign: 'left' }}>
                        <h2 >이제 시작해볼까요? </h2>
                        <p>이제 내 프로필을 Bio나 스토리에 공유해보세요.<br></br>친해지고 싶은 사람이 오면 힌트를 알려드릴게요🍀</p>
                        <div className="button-container">
                          <button className="delete-button" style={{ display: 'hidden', backgroundColor: '#ffffff' }}>
                          </button>
                          <button className="retry-button" onClick={handleFirstShareButtonClick}>
                            친구들 속마음 확인 시작!
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
               )} */}
  
                  <div className="ButtonBox">
                 
  
                 {/*    {!tokenUserID && (
                      <Link to="/start">
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                          <p2 style={{ marginBottom: '6px', color: '#000000', fontSize: '13px', textDecoration: 'underline' }}>나도, 나랑 친해지고 싶은 사람 알기</p2>
                        </div>
                      </Link>
                 )} */}
  
                  </div>
            
  
              </div>
  
              <Footer></Footer>
              <br></br>
              <br></br>
              <br></br>

              <BottomNav />
            </div>


            
        {showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
          >
            <h2 style={{ fontSize: '22px' }}>
              {user.user_name}님은 아직<br></br>익명 DM을 사용할 수 없어요
            </h2>
            <p>
            {user.user_name}님과 친해지고 싶은 친구가, 5명이 넘으면
              <br />
               익명 DM 기능을 사용할 수 있어요 👋

            </p>

                         
            <div className="button-container">
              <button
                className="delete-button"
                onClick={handlePopupButtonCloseClick}
                style={{visibility:'hidden'}}
              >
                괜찮아요
              </button>
              <motion.button
                className="retry-button"
                onClick={handlePopupButtonCloseClick}
              >
                알겠어요
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}




  
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;  