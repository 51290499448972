import { Box } from '../stories/Box';
import KakaoAdFit  from '../component/KakaoAdFit';
import happythreefriend from '../assets/img/threehappyfriends.webp'
import message1 from '../assets/img/message1.webp'
import candy from '../assets/img/candy.webp'
import goldtropy from '../assets/img/goldtropy.webp'
import silvertropy from '../assets/img/silvertropy.webp'
import bronzetropy from '../assets/img/bronzetropy.webp'
import Rank from '../assets/img/Rank.webp'
import HoxyWordIcon from '../component/Hoxyworldicon';
import studentgirl from '../assets/img/studentgirl.webp'
import studentboy from '../assets/img/studentboy.webp'
import Earth from '../assets/img/Earth.webp'
import hint_img from '../assets/img/hint.webp';
import CrossedSword from '../assets/img/CrossedSword.webp';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jsonwebtoken/decode';
import GoogleAdsense from '../component/GoogleAdsense';
import Header from '../NewComponent/header';
import S_OurSchool from '../NewComponent/Skeletons/S_OurSchool'
import right_arrow from "../NewComponent/assets/arrow-right.webp"
import MiddleSheet from '../NewComponent/SchoolLinkPage/Middlesheet';
import BottomNav from '../NewComponent/BottomNav';
import school_story from '../NewComponent/assets/school_story.svg';
import PageIntelligence from '../NewComponent/Intelligence';
import { motion, useAnimation  } from 'framer-motion';
import joker_mode from '../assets/img/joker_card.webp';
import leader from '../NewComponent/assets/leader.webp';


function OurSchool() {
  const [tokenUserID, setTokenUserID] = useState('');
  const navigate = useNavigate();
  const [userFullName, setUserFullName] = useState('');
  const [userID, setUserID] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [userAge, setUserAge] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userKakaoYear, setUserKakaoYear] = useState('');
  const [UserInstaFollowed, setUserInstaFollowed] = useState('');
  const [firstPlace, setFirstPlace] = useState({});
  const [secondPlace, setSecondPlace] = useState({});
  const [thirdPlace, setThirdPlace] = useState({});
  const [friends, setFriends] = useState([]);
  const [messages, setMessages] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMiddleSheetVisible, setIsMiddleVisible] = useState(false);
  const [selectedFriendInfo, setSelectedFriendInfo] = useState(null);
  const [schoolType, setSchoolType] = useState('');



  useEffect(() => {

    const fetchData = async () => {

    try{ 
    const token = localStorage.getItem('token');
  
    if (token) {
      const decodedToken = jwt_decode(token);
      const tokenUserID = decodedToken.user_id;
      setTokenUserID(tokenUserID);
  
      fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
           method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
      },
      body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error('사용자를 찾을 수 없음');
          }
          return response.json();
        })
        .then((userData) => {
          const { user_name, user_id, school_name, school_code, user_grade, user_gender, user_kakao_year, user_insta_followed } = userData;
  
          setUserFullName(user_name);
          setUserID(user_id);
          setSchoolName(school_name);
          setSchoolCode(school_code);
          setUserAge(user_grade);
          setUserGender(user_gender);
          setUserKakaoYear(user_kakao_year);
          setUserInstaFollowed(user_insta_followed);


  
          // 학교 이름이 설정되면 바로 데이터를 가져오도록 처리
          fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/top_message_receivers', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ school_code: school_code }), // 실제 학교코드 전달
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })
          
          .then(data => {
            if (data.length >= 3) {
              setFirstPlace(data[0]);
              setSecondPlace(data[1]);
              setThirdPlace(data[2]);

            
            }
          })
          .catch(error => console.error('Error fetching data:', error));



          fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/school/${school_code}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })         
           .then(data => {
            setFriends(data); // Set friends data received from the backend
          })


          fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/school/messages_and_replies', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              school_code: school_code, // 원하는 학교명으로 변경해주세요.
            }),
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })            
          .then(data => {
              setMessages(data); // 메시지 데이터를 상태에 업데이트
            })
            .catch(error => {
              console.error('Error fetching messages:', error);
            });

            const schoolApiUrl = 'https://backend-hoxy-one-api.hoxy.one:4004/api/school/school_info';

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ school_code: school_code }),
            };
            
            fetch(schoolApiUrl, requestOptions)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('학생수를 가져올 수 없습니다.');
                }
                return response.json();
              })
              .then((data) => {
                setStudentCount(data.student_count);
                setSchoolType(data.school_type)
              })
              .catch((error) => {
                console.error(error);
              });
            

        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert('로그인이 필요해요');
      navigate('/');
    }

    
        // Simulate an API call
        // Replace this with your actual data fetching logic
        await new Promise(resolve => setTimeout(resolve, 2000));
  
        setIsLoading(false); // Step 2: Set isLoading to false after data is loaded
      } catch (error) {
        console.log('API Error:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const handleFriendClick = (friend) => {
    const { user_id, user_class, user_grade, user_name, user_gender } = friend;
    setSelectedFriendInfo({ user_id, user_class, user_grade, user_name,user_gender });
    setIsMiddleVisible(true);
  };

  function AgetoGrade(age) {
    let grade = '';
    if (age >= 8 && age <= 13) {
      grade = `${age - 7}학년`;
    } else if (age >= 14 && age <= 16) {
      grade = `${age - 13}학년`;
    } else if (age >= 17 && age <= 19) {
      grade = `${age - 16}학년`;
    }
    return grade; // 학년 계산 결과를 반환
  }
  const lastIndex = messages.length - 1;


  const handleCloseMiddleSheet = () => {
    setIsMiddleVisible(false);
  };


  
  

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF'}}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>

         {/* Overlay layer when BottomSheet is visible */}
        {isMiddleSheetVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for transparency
            zIndex: 1, // Ensure this overlay is above other content
          }}

        ></div>
      )}

      {isLoading ? ( // Step 3: Conditional rendering
          <S_OurSchool /> // Show the skeleton component while loading
        ) : ( 

        <div>
        <br></br>
        <Header Icon="yes"/>


        { (schoolType === "초등학교" || schoolType === "중학교" || schoolType === "고등학교") && UserInstaFollowed > 150 && studentCount < 10  &&   (
        <motion.div style={{ justifyContent: 'center', display: 'flex', marginTop: '30px' }}>
        <div style={{ width: '320px', height: '350px', borderRadius: '20px', backgroundColor: '#FBF5F5' }}>

        <motion.img
          src={leader}
          width='100px'
          initial={{ scale: 1 }} // Initial scale
          animate={{ scale: [1, 1.1, 1] }} // Animate to scale 1, 1.1, and back to 1
          transition={{ repeat: Infinity, duration: 0.5 }} // Repeat infinitely with a 1.5-second duration
          style={{marginTop:'50px'}}
        />

        <p style={{
          color: 'var(--Hoxy-Orange, #FF5C00)',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          letterSpacing: '-1px',
          marginTop: '20px',
        }}>
          우리학교의 CEO로 선정되었습니다!<br></br>

          <span style={{ color:'#000000' }}>아래에서 자세한 보상, 혜택을 확인하고 <br></br>
오퍼레터 제안을 수락할 수 있어요 </span>
           
        </p>

 

        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Link to='/ceo/OfferLetter'>
          <motion.button
            style={{
              backgroundColor: 'var(--Hoxy-Orange, #FF5C00)',
              border: '0',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '10px',
              cursor: 'pointer',
              width: '260px',
              marginTop: '20px',
              height: '50px',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              letterSpacing: '-1px',
            }}
          >
            <span style={{
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 600,
                letterSpacing: '-1px'
              }}>{userFullName}님의 오퍼레터 확인하기</span>
          </motion.button>
          </Link>
        </div>
      </div>
    </motion.div>  )}


          <div style={{ marginTop: '10px' }}>
          <div>
        <img style={{ width: '130px', height: 'auto' }} src={Rank} alt="Rank" />
      </div>
      <div style={{ fontWeight: '500' }}>{schoolName}에서<br /><b style={{ fontWeight: '800' }}>모두가 친해지고 싶어하는 TOP3</b></div>

      <div style={{ marginTop: '20px' }}></div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
  <div className='SchoolRankBox'>
    <div className='SchoolRankImo'><img style={{ width: '20px', height: 'auto' }} src={goldtropy} alt="Medal" /></div>
    <div className='SchoolRankName'>
        {firstPlace.user_class || firstPlace.user_grade ? (
      <span>
        {firstPlace.user_class ? `${firstPlace.user_class}학번 ` : ''}
        {firstPlace.user_grade ? `${firstPlace.user_grade}학년 ` : ''}
      </span>
    ) : null}      
    <b>{firstPlace.user_name || '???'}</b>
    </div>
    <div className='SchoolRankRequest' style={{ display: 'flex', flexDirection: 'column' }}>
      <div><b> (누적 {firstPlace.message_count ?? 0}건)</b></div>
    </div>
  </div>
</div>

      <div style={{ marginTop: '4px' }}></div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='SchoolRankBox'>
          <div className='SchoolRankImo'><img style={{ width: '20px', height: 'auto' }} src={silvertropy} alt="Medal" /></div>
          <div style={{ display: 'flex' }}>
            <div className='SchoolRankName'>
            {secondPlace.user_class || secondPlace.user_grade ? (
            <span>
              {secondPlace.user_class ? `${secondPlace.user_class}학번 ` : ''}
              {secondPlace.user_grade ? `${secondPlace.user_grade}학년 ` : ''}
            </span>
          ) : null}           
           <b>{secondPlace.user_name || '???'}</b>
          </div>
            <div className='SchoolRankRequest' style={{ display: 'flex', flexDirection: 'column' }}>
              <div><b> (누적 {secondPlace.message_count ?? 0}건)</b></div>
            </div>
          </div>
        </div>
        <div style={{ marginRight: '7px' }}></div>
        <div className='SchoolRankBox'>
          <div className='SchoolRankImo'><img style={{ width: '20px', height: 'auto' }} src={bronzetropy} alt="Medal" /></div>
          <div className='SchoolRankName'>
          {thirdPlace.user_class || thirdPlace.user_grade ? (
          <span>
            {thirdPlace.user_class ? `${thirdPlace.user_class}학번 ` : ''}
            {thirdPlace.user_grade ? `${thirdPlace.user_grade}학년 ` : ''}
          </span>
        ) : null}            
        <b>{thirdPlace.user_name || '???'}</b>
          </div>
          <div className='SchoolRankRequest' style={{ display: 'flex', flexDirection: 'column' }}>
            <div><b> (누적 {thirdPlace.message_count ?? 0}건)</b></div>
          </div>
        </div>
      </div>


        <br></br>
        <KakaoAdFit unit={"DAN-upZuPApgyk3rJ1xS"} width={"320"} height={"100"} disabled={false}/>
        <br></br>

    
        {friends.length > 1 && (
        <div>
          <div className="SchoolNow" style={{ textAlign: 'left', marginTop: '-10px', marginLeft: '25px', whiteSpace: 'nowrap' }}>{userFullName}님을 위한 우리학교 친구들</div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'-15px' }}>
            <div style={{ display: 'flex', overflowX: 'scroll' }}>
              {friends
                .filter(friend => friend.user_id !== userID) // 본인은 제외
                .map(friend => (
                  <div style={{ flex: '0 0 auto', marginRight: '10px' }} className='FriendBox' key={friend.user_id}>
                    <div><img style={{ width: '60px', height: '60px' }} src={friend.user_gender === 'female' ? studentgirl : studentboy} alt="profile"></img></div>
                    <div>  {friend.user_class ? `${friend.user_class}학번` : `${friend.user_grade}학년`} {friend.user_name || '???'}</div>
                    <div onClick={() => handleFriendClick(friend)} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className='FriendBoxButton2'><div style={{ marginTop: '5px' }}>더보기</div><img src={right_arrow} style={{width:'10px', height:'10px', marginTop:'6px'}}></img></div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}



        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/daejeon/landing'>
            <div className='hint_box'>

              <div style={{display:'flex'}}>
              <div style={{
                  backgroundColor :'#FBF5F5',
                  marginLeft:'20px'
                }}><img src={CrossedSword} className='hint_img'></img>
              </div>
                
                <div style={{marginLeft:'15px', textAlign:'left'}}>
                <p className='hint_p1'>지금, {schoolName}만의</p>
                <p style={{fontSize:'13px', fontWeight:'800', color:'#000', marginTop:'-9px'}}>'대신전해드립니다’를 걸고 대결중.. ({studentCount}/30)</p>

                </div>
                
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                <svg
                  className='hint_button'
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                  <path
                    d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                    fill="white"
                  />
                </svg>                  
                </div>
                  </div>
            </div>
            </Link>
          </div>


          <div className="SchoolNow" style={{textAlign:'left', marginTop:'30px', marginLeft:'25px', whiteSpace: 'nowrap'}}>우리학교끼리만 보는 친구들 소식</div>
            <br></br>
            <img src={school_story}></img>
            <div>
              <div className="Boxsquare" style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                    <div  style={{ marginBottom: '15px' }}>
                    {messages.length === 0 ? (
                    <div className='gray_text'>                    
                    아직도, HOXY를 안쓰는 학교가 있네요? <br></br>어서 친구들을 데려와서 대·전, 스토리 모아보기를 활성화 시켜주세요! <br /><br /><br /><br /><br />
                  </div>
                ) : (
                  messages.map((message, index) => (
                    <div key={index} style={{ marginBottom: '10px', marginTop: '5px' }}>
                      <Box
                        className="LargeBox"
                        Label1={`${message.request_age}세 ${message.request_gender} → ${message.user_class ? `${message.user_class}학번` : `${message.user_grade}학년`} ${message.user_name || '???'}`}
                        Label2={
                          <>
                            나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인 {message.request_age}세 {message.request_gender}의 {message.request_keyword ? message.request_keyword : '***'} 분께서{' '}
                            {message.request_want ? message.request_want : '***'} 해요!
                            
                            { message.recive_reply && (
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap' }}>                            <br></br><br></br>
                              {`ㄴ  "${message.recive_reply}"`}</span>
                            )}
                            </>}
                        ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.webp`}
                        Label4="#ffffff"
                        requestDbNumber={message.request_db_number}
                      />
                  
                   
                  
                      {/* 현재 IOS 새창 동영상 이슈 해결전까지, 블럭
                      {index === lastIndex ? null : (
                        <>
                          {index === 2 && (
                            <GoogleAdsense
                              client="ca-pub-6089539231478899"
                              slot="1174995300"
                              format="rectangle"
                              responsive="false"
                            />
                          )}
                  
                          {index > 4 && (index - 4) % 5 === 0 && (
                            <GoogleAdsense
                              client="ca-pub-6089539231478899"
                              slot="1174995300"
                              format="rectangle"
                              responsive="false"
                            />
                          )}
                        </>
                      )}*/}
                    </div>
                  ))
                )}
            </div>
            </div>
          </div>    
         </div>

         {isMiddleSheetVisible && <MiddleSheet onClose={handleCloseMiddleSheet} {...selectedFriendInfo} />}

        </div>

        )}

1     <BottomNav />

      </div>
    </div>
  );
}

export default OurSchool;
