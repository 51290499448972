import React, { useState, useEffect,useRef,useLayoutEffect } from 'react';
import jwt_decode from 'jsonwebtoken/decode';
import { wrap } from "popmotion";
import styled from "styled-components";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import sheap from '../../NewComponent/assets/sheap.webp';
import { ReactComponent as LeftArrow } from "../../NewComponent/assets/icon-arrow-left-small-mono.svg";
import PrimaryButtonSmall from '../../NewComponent/PrimaryButtonSmall'

function RequestCard() {
  const navigate = useNavigate();
  const location = useLocation();
  const VisitprofileID = localStorage.getItem('VisitprofileID');

  
  const questionAnswerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (questionAnswerRef.current) {
        const currentHeight = questionAnswerRef.current.clientHeight;
        questionAnswerRef.current.style.height =
          currentHeight > 100 ? '100px' : 'auto';
      }
    };

    handleResize(); // Initial check

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleBackClick = () => {
    navigate(`/${VisitprofileID}?explore=yes`);
  };

  const requestDbNumber = new URLSearchParams(location.search).get('Mymessage');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [messages, setMessages] = useState(null);
  const [user_name, setUserName] = useState("");
  const [user_id, setUserId] = useState("");
  const [visitProfileID, setVisitProfileID] = useState("");
  const [tokenUserID, setTokenUserID] = useState("");
  const [back, setBack] = useState(false);

 
  

  useEffect(() => {
    const visitProfileName = localStorage.getItem('VisitprofileName');
    const visitProfileID = localStorage.getItem('VisitprofileID');
    setUserName(visitProfileName);
    setUserId(visitProfileID);
    setVisitProfileID(visitProfileID);
  
    const token = localStorage.getItem('token');
  
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);
  
        fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: tokenUserID }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('요청된 메시지를 가져올 수 없음');
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
            setMessages(data);
            console.log(messages);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
      setTokenUserID('none');
    }
  }, []);
  





  if (messages === null) {
    return (
      <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
          <br></br>
          <div className='header2'>
            <a onClick={handleBackClick}>
              <div className="top_bar" style={{ marginLeft: '20px' }}>
                <LeftArrow />
              </div>
            </a>
            <div className="header_right">
              <p style={{ fontWeight: '500' }}>부적절한 메시지에요</p>
            </div>
          </div>
          <div className='flexbox' style={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Pretendard",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "106%",
            letterSpacing: "-1.76px",
            marginTop: '60px'
          }}>
            나랑 친해지고 싶은 친구에게
            <br></br>
            답장하거나 힌트를 얻을 수 있어요
          </div>
          <div className='Content-1_Joker'>
            <div style={{ display: 'flex', justifyContent: 'center', overflow: 'none' }}>
              <div>
                {/* 로딩 중 UI를 여기에 추가할 수 있습니다. */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const visitProfileId = localStorage.getItem('VisitprofileID');

  // 박스마다 이미지 적용
  const Index = wrap(0, messages.length, currentIndex);

  // animation
  const boxVariants = {
    entry: (back) => ({
      x: back ? -500 : 500,
      opacity: 0,
      scale: 0
    }),
    center: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: { duration: 0.5 }
    },
    exit: (back) => ({
      x: back ? 500 : -500,
      opacity: 0,
      scale: 0,
      transition: { duration: 0.5 }
    })
  };

  const Box = styled(motion.div)``;

  const nextPlease = () => {
    setBack(false);
    setCurrentIndex((prev) => (prev === messages.length - 1 ? prev : prev + 1));
  };

  const prevPlease = () => {
    setBack(true);
    setCurrentIndex((prev) => (prev === 0 ? prev : prev - 1));
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>
        <div className='header2'>
          <a onClick={handleBackClick}>
            <div className="top_bar" style={{ marginLeft: '20px' }}>
              <LeftArrow />
            </div>
          </a>
          <div className="header_right">
          <Link to='/cs' style={{color :'#000'}}>
            <p style={{ fontWeight: '500' }}>부적절한 메시지에요</p>
            </Link>
          </div>
        </div>
        <div className='flexbox' style={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Pretendard",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "106%",
          letterSpacing: "-1.76px",
          marginTop: '40px'
        }}>
          나랑 친해지고 싶은 친구에게
          <br></br>
          답장하거나 힌트를 얻을 수 있어요
        </div>
        <div className='Content-1_Joker'>
          <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
            <div>
            {messages.map((message, index) => (
  <AnimatePresence custom={back} style={{ overflowX: 'hidden' }}>
    <Box
      custom={back}
      variants={boxVariants}
      initial="entry"
      animate="center"
      exit="exit"
      key={index}
      style={{ display: index === currentIndex ? 'block' : 'none' }}
    >
      {message.type === '1day1' ? (
        <div className="RequestBox">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ marginTop: '10px' }}>
              <img src={`https://resource.hoxy.one/Box_emoji/new2/${(index % 10) + 1}.webp`} style={{ width: '65px', height: '65px' }} />
            </div>
            <div
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Pretendard",
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "normal",
                letterSpacing: "-1.2px",
                marginTop: "10px",
              }}
              dangerouslySetInnerHTML={{
                __html: `Q. ${user_name}님${message.question_text_1} <br />${message.question_text_2}`,
              }}
            ></div>
            <div style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Pretendard",
              fontSize: "16px",
              fontWeight: 400,
              padding: "10px",
              lineHeight: "1.5",
              letterSpacing: "-1.2px",
              marginTop: '5px',
            }}>
               <div  className='question_answer'>
                {message.question_answer}
              </div>

               {message.oneDayOne_reply_recive_reply && (

                              <span style={{ fontSize: "13px", fontWeight: 600, letterSpacing:'-0.06rem', color:'#FF5C00'}}>
                                  <p style={{ marginTop: '-15px' }} />
                                {`ㄴ  "${message.oneDayOne_reply_recive_reply}"`}
                              </span>
                            )}

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "0px" }}>
              {/* Add additional content here */}
            </div>
          </div>
        </div>
      ) : (
        <div className="RequestBox">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ marginTop: '25px' }}>
              <img src={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.webp`} style={{ width: '65px', height: '65px' }} />
            </div>
            <div style={{
              color: '#000',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: 'normal',
              letterSpacing: "-1.2px",
              marginTop: '5px'
            }}>{message.request_age}세 {message.request_gender}</div>
            <div style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Pretendard",
              fontSize: "16px",
              fontWeight: 400,
              padding: "10px",
              lineHeight: "1.5",
              letterSpacing: "-1.2px",
              marginTop: '5px',
            }}>
              저는 {user_name}님과 {message.request_relationship} 사이에요.
              저는 {message.request_keyword} 사람이에요.
              우리 같이 {message.request_want}

              {message.reply_recive_reply && (
                                  <span style={{fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing:'-0.06rem', color:'#FF5C00' }}>
                                  <p style={{ marginTop: '-5px',  }} />
                                    {`ㄴ  "${message.reply_recive_reply}"`}
                                  </span>
              )}

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "0px" }}>
              {/* Add additional content here */}
            </div>
          </div>
        </div>
      )}
    </Box>

    <PrimaryButtonSmall
        Link2={messages[currentIndex].type === '1day1' ? '/future' :`/hint?Mymessage=${messages[currentIndex].request_db_number}`}
        Link1={messages[currentIndex].type === '1day1' ?  `/Answer-1day1?Mymessage=${messages[currentIndex].day1_db_number}` : `/Answer?Mymessage=${messages[currentIndex].request_db_number}`}
        Text={messages[currentIndex].type === '1day1' ? '미래모습 예상하기' : '힌트보기'}
        Text2='답장하기'
      />    
  </AnimatePresence>
))}





              <div className='flexbox' style={{ marginTop: '50px', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div onClick={prevPlease} style={{ marginRight: '20px', fontSize: '24px', cursor: 'pointer' }}>&#8592;</div>
                <div>{currentIndex + 1}/{messages.length}</div>
                <div onClick={nextPlease} style={{ marginLeft: '20px', fontSize: '24px', cursor: 'pointer' }}> &#8594;</div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestCard;
