import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LargeButton } from '../stories/LargeButton';
import { Tittle } from '../stories/Tittle';
import jwt_decode from 'jsonwebtoken/decode';
import img_insta from '../assets/img/instagram_logo.webp';

function Almost() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenUserID, setTokenUserID] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);
  
      } catch (error) {
        console.log('토큰 디코딩 에러');
      }
    } else {
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (id.length > 15) {
      setErrorMessage('프로필 아이디는 최대 15자까지만 가능합니다.');
      return;
    }
  
    const regex = /^[a-zA-Z0-9_.]+$/;
    if (!regex.test(id)) {
      setErrorMessage('인스타그램의 아이디 형식이 아니거나, 사용할 수 없는 문자가 있어요');
      return;
    }
  
    try {
      const user_name = localStorage.getItem('user_name');
      const user_phonenumber = localStorage.getItem('user_phoneNumber');
      const school_name = localStorage.getItem('user_school');
      const user_age = localStorage.getItem('user_grade');
      const user_password = localStorage.getItem('user_Password');
      const user_gender = localStorage.getItem('user_gender');

      const user_kakao_email = localStorage.getItem('user_kakao_email');
      const user_kakao_year =  localStorage.getItem('user_kakao_year');
      const user_kakao_age =  localStorage.getItem('user_kakao_age');
      const user_kakao_id = localStorage.getItem('user_kakao_id');
      const user_kakao_imgurl = localStorage.getItem('user_kakao_imgurl');      

      const information_check = localStorage.getItem("information_check");
      const marketing_check = localStorage.getItem("marketing_check");

      const queryParams = new URLSearchParams({
        user_id: id,
        user_name,
        user_phonenumber,
        school_name,
        user_age,
        user_password,
        user_gender,
        user_kakao_email,
        user_kakao_year,
        user_kakao_age,
        user_kakao_id,
        user_kakao_imgurl,
        information_check,
        marketing_check

      }).toString();

      const response = await axios.post(`https://backend-hoxy-one-api.hoxy.one:4004/api/user?${queryParams}`);

      const token = response.data.token;
      localStorage.setItem('token', token);
      navigate('/end');

    } catch (error) {
      alert('이미 가입된 전화번호/인스타그램 아이디 이거나, 문제가 발생했어요');
      navigate('/start');
    }
  };

  const handleInputChange = (e) => {
    setId(e.target.value); 
    setErrorMessage(''); 
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <a href="javascript:window.history.back();">
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <img src={img_insta} width={'65px'} alt="logo" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Tittle className="normal" label1='"이제 대부분 끝났어요!"' label2="사용할 아이디를 알려주세요" />
          </div>
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '43px' }}>
              <div className="NickNameBox">
                <input
                  className="NickName"
                  value={id}
                  onChange={handleInputChange}
                  placeholder="@-"
                  pattern="[a-zA-Z0-9_.]+"
                  title="인스타그램의 아이디 형식이 아니거나, 사용할 수 없는 문자가 있어요"
                  required
                />
              </div>
              <div style={{ marginTop: '16px' }}></div>
              {errorMessage && (
                <div style={{ color: 'red', marginTop: '8px', textAlign: 'center' }}>{errorMessage}</div>
              )}
            </div>
           {/* <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: '#474747',
                  marginTop: '5px'
                }}
              >
                인스타그램 아이디와 동일하면, 더 다양한 기능을 사용할 수 있어요
              </div>
              </div>  */}
        
          <div className="welcome_ButtonBox">
              <LargeButton className="primary"  label2="계속하기" />
            </div>
            </form>

        </div>
      </div>
    </div>
  );
}

export default Almost;
