import Footer from '../component/Footer';
import happythreefriend from '../assets/img/threehappyfriends.webp'
import candy from '../assets/img/candy.webp'
import GiftBox1 from '../assets/img/GiftBox1.webp'
import Bacchus from '../assets/img/Bacchus.webp'
import chupachups from '../assets/img/chupachups.webp'
import ReverseClock from '../assets/img/ReverseClock.webp'
import Ghana from '../assets/img/Ghana.webp'
import ChocolateBar from '../assets/img/ChocolateBar.webp'
import BlackCan from '../assets/img/BlackCan.webp'
import ProgressBar from '../component/ProgressBar';
import HoxyWordIcon from '../component/Hoxyworldicon';
import SecondaryButtonSmall2 from '../NewComponent/SecondaryButtonSmall2';
import Header from '../NewComponent/header';
import giftqa from '../NewComponent/assets/gift_q&a.svg';
import SliceBar from '../NewComponent/SliceBar';
import love_letter from '../NewComponent/assets/love_letter.webp';
import React, { useState, useEffect } from 'react';
import jwt_decode from 'jsonwebtoken/decode';
import { Link } from 'react-router-dom';


function MyGift() {
  const [profileStatus, setProfileStatus] = useState('');
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');
 
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);

        // API 호출
        fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/profile/status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: tokenUserID }),
        })
          .then((response) => response.json())
          .then((data) => {
            // API 응답 결과를 'profileStatus' 상태 변수에 저장
            setProfileStatus(data);
          })
          .catch((error) => {
            console.error('API 호출 중 오류 발생:', error);
          });
      } catch (error) {
        console.error('토큰 디코딩 중 오류 발생:', error);
      }
    }
  }, []);

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF'}}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <br></br>

          <Header Text='내가 받은 선물' Icon='yes'></Header>
      

          <div style={{display:'flex',justifyContent:'left', marginLeft:'25px'}}>
            <div className='SchoolNowBox'>
                <div style={{marginTop:'5px'}} className='SchoolNowImo'><img style={{width:'25px',height:'auto'}} src={candy}></img></div>
                <div style={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                    <div>교환한 기프티콘 수</div>
                    <div style={{fontSize:'11px'}}>0개</div>
                </div>
            </div>
            <div style={{marginRight:'8px'}}></div>
            <div className='SchoolNowBox2'>
                <div style={{marginTop:'5px'}} className='SchoolNowImo'><img style={{width:'25px',height:'auto'}} src={love_letter}></img></div>
                <div style={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                    <div>받은 익명 찔러보기 수</div>
                    <div style={{fontSize:'11px'}}>{profileStatus.request_count}건</div>
                </div>
            </div>
            
          </div>


          <div>
          <div style={{display:'flex',justifyContent:'center', marginTop:'10px'}}>
             <div className='GiftBox'>
                <div style={{display:'flex',justifyContent:'center',marginTop:'20px',marginRight:'100px'}}>
                    <div className='GiftBlock'><img style={{width:'35px',height:'40px'}} src={chupachups}></img></div>
                    <div style={{ textAlign:'left',marginLeft:'10px',marginTop:'5px'}}>
                        <div className='GiftText1'>GS25 츄파츕스 기프티콘 교환까지</div>
                        <div className='GiftText2'>친구들의 선물 2개가 필요해요 (0/2)</div>
                    </div>
                </div>
                       <ProgressBar
                       bgcolor="linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%)"
                       completed={0}
                     />

                     <br></br>

                      <div style={{display:'flex', marginLeft:'10px'}}>
                      <Link to='/share'>
                      <button className="retry-button" style={{fontSize:'15px',fontWeight:'600', letterSpacing:'-0.08rem'}}>
                      선물조각 받기
                      </button>
                      </Link>
                      <button className="delete-button" style={{fontSize:'15px',backgroundColor:'#FEEEDF', marginLeft:'10px', fontWeight:'600', letterSpacing:'-0.08rem'}}>
                      조커카드로 완성하기
                      </button>
                    </div>
                 
               </div>
           </div>

           <div style={{display:'flex',justifyContent:'center'}}>
             <div className='GiftBox'>
                <div style={{display:'flex',justifyContent:'center',marginTop:'20px',marginRight:'100px'}}>
                    <div className='GiftBlock'><img style={{width:'35px',height:'40px'}} src={Bacchus}></img></div>
                    <div style={{ textAlign:'left',marginLeft:'10px',marginTop:'5px'}}>
                        <div className='GiftText1'>GS25 박카스 기프티콘 교환까지</div>
                        <div className='GiftText2'>친구들의 선물 3개가 필요해요 (0/3)</div>
                    </div>
                </div>
                       <ProgressBar
                       bgcolor="linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%)"
                       completed={0}
                     />

            <br></br>

            <div style={{display:'flex', marginLeft:'10px'}}>
              <Link to='/share'>
              <button className="retry-button" style={{fontSize:'15px',fontWeight:'600', letterSpacing:'-0.08rem'}}>
              선물조각 받기
              </button>
              </Link>
              <button className="delete-button" style={{fontSize:'15px', backgroundColor:'#FEEEDF', marginLeft:'10px', fontWeight:'600', letterSpacing:'-0.08rem'}}>
              조커카드로 완성하기
              </button>
            </div>
            </div>
           </div>

                  <SliceBar></SliceBar>

    
                      <img src={giftqa} width={'350px'} style={{marginTop:'30px'}}></img>
      
        
                      </div>

                <Footer></Footer>


          </div>    
         </div>
    </div>
        
  );
}

export default MyGift;