import React, { useEffect, useState } from 'react';
import { Tittle } from '../stories/Tittle';
import { ReactComponent as ChatCX } from "../stories/assets/채팅상담.svg";
import { ReactComponent as CleanCenter } from "../stories/assets/신고센터.svg";
import { ReactComponent as CXLine1 } from "../stories/assets/Line 1.svg";
import { ReactComponent as CXLine2 } from "../stories/assets/Line 2.svg";
import { ReactComponent as CXLine3 } from "../stories/assets/Line 3.svg";
import { ReactComponent as CXLine4 } from "../stories/assets/Line 4.svg";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';
import CSimg_URL from '../assets/img/cs.webp'
import Footer from '../component/Footer';
import Header from '../NewComponent/header';
import S_CS from '../NewComponent/Skeletons/S_CS';

const reasons = [
  '사용방법을 잘 모르겠어요',
  '기대 했던것과 달라요',
  '같이 사용할 학교친구가 없어요',
  '개인정보 사용이 걱정되요',
  '카카오계정이 내 이름이 아니에요',
  '기타',
];

function CS() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [npsScore, setNpsScore] = useState(null);
  const [deletionReason, setDeletionReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate an API call
        // Replace this with your actual data fetching logic
        await new Promise(resolve => setTimeout(resolve, 2000));
  
        // Step 2: Set isLoading to false after data is loaded
        setIsLoading(false);
      } catch (error) {
        console.error('API Error:', error); // Use console.error for errors
      }
    };
  
    fetchData();
  }, []); // Make sure to pass an empty dependency array if you only want to run the effect once
  

  const handleDeleteAccount = async () => {
    if (!npsScore || !deletionReason || (deletionReason === '기타' && !customReason)) {
      alert('모든 항목이 작성되지 않아, 탈퇴 처리가 힘들어요.');
      return;
    }

    const token = localStorage.getItem('token');


    

    if (!token) {
      alert('계정 탈퇴는, 로그인 후에 진행할 수 있어요');
      navigate('/start');
      return;
    }

    const decodedToken = jwt_decode(token);
    const tokenUserID = decodedToken.user_id;
    const tokenUserNo = decodedToken.user_no;


    try {
      if (deletionReason !== '기타') {
        await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/delete', {
          user_id: tokenUserID,
          user_no: tokenUserNo,
          user_nps: npsScore,
          user_reason: deletionReason,
        });
      } else {
        await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/delete', {
          user_id: tokenUserID,
          user_no: tokenUserNo,
          user_nps: npsScore,
          user_reason: customReason,
        });
      }

      await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/users/delete', {
        user_no: tokenUserNo
      });

      localStorage.removeItem('token');
      localStorage.removeItem('VisitprofileID');
      localStorage.removeItem('VisitprofileName');

      alert('계정이 성공적으로 탈퇴 처리되었습니다.');
      navigate('/');
    } catch (error) {
      alert('계정 삭제 실패에 실패했어요. 고객센터로 문의주세요');
    }
  };

  const handleNpsChange = (score) => {
    setNpsScore(score);
  };

  const handleReasonChange = (reason) => {
    if (reason === '기타') {
      setDeletionReason('기타');
    } else {
      setDeletionReason(reason);
    }
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
    setDeletionReason('기타'); 
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupOpen2 = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('loginDate')
    localStorage.removeItem('loginDate')



    alert('계정이 성공적으로 로그아웃 되었어요');
    navigate('/');
  };


  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
                  
      {isLoading ? ( // Step 3: Conditional rendering
          <S_CS /> // Show the skeleton component while loading
        ) : ( 

        <div>
        <br></br>
       
        <Header/>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <img src={CSimg_URL} width={'100'} height={'100'}></img>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '43px' }}>
            <Tittle className='normal' label1='HOXY 고객센터' label2='언제든지 도움이 필요할 때 찾아주세요'></Tittle>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '54px', cursor: 'pointer' }}>
            <Link to="https://hoxy.channel.io/">
              <ChatCX></ChatCX>
            </Link>
            <div style={{ marginRight: '10px' }} ></div>
            <Link to="https://hoxy.channel.io/">
            <CleanCenter></CleanCenter>
            </Link>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '25px', marginBottom:
          '50px', cursor: 'pointer' }}>
            <a href="https://bit.ly/%ED%98%B9%EC%8B%9C%ED%94%BC%EB%93%9C%EB%B0%B1" target="_blank" rel="noopener noreferrer">
              <CXLine1></CXLine1>
            </a>
            <div style={{ marginTop: '10px' }}></div>
            <Link to="https://hoxy.channel.io/">
              <CXLine2></CXLine2>
            </Link>
            <div style={{ marginTop: '10px' }}></div>
            <a href={process.env.PUBLIC_URL + "/term/privacy-20230727.html"} target='_blank' rel="noreferrer">              
              <CXLine3></CXLine3>
            </a>            
            <div style={{ marginTop: '10px' }}></div>
            <a href={process.env.PUBLIC_URL + "/term/terms-20230727.html"} target='_blank' rel="noreferrer">
              <CXLine4></CXLine4>
              </a>            

            <br></br>
           
            
       

            <div style={{display:'flex',  marginTop:'10px'}}>
            <p style={{ textDecoration: 'underline', color: '#404040', fontSize: '13px' }} onClick={handlePopupOpen2}>로그아웃</p>

            <Link to="https://hoxy.channel.io/">
            <p style={{ textDecoration: 'underline', color: '#404040', fontSize: '13px',marginLeft: '25px' }}>카톡 알림 그만 받기</p>
            </Link>

            <p style={{ textDecoration: 'underline', color: '#404040', fontSize: '13px',marginLeft: '25px' }} onClick={handlePopupOpen}>계정 탈퇴</p>
            </div>

          </div>
        </div>
        )}
      </div>

      <Footer></Footer>

      {showPopup && (
        <div className="popup-container" onClick={handlePopupClose}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2>혹시와 함께해주셔서, 감사했어요 😢</h2>
            <p>Hoxy.One 사용경험은 5점 만점에 어느정도 였나요?</p>
            <div className="nps-container">
              {[1, 2, 3, 4, 5].map((score) => (
                <div
                  key={score}
                  className={`nps-option ${npsScore === score ? 'selected' : ''}`}
                  onClick={() => handleNpsChange(score)}
                >
                  {score}
                </div>
              ))}
            </div>
            <p>탈퇴 사유를 선택해주세요:</p>
            <div className="reason-container">
              {reasons.map((reason) => (
                <div
                  key={reason}
                  className={`reason-option ${deletionReason === reason ? 'selected' : ''}`}
                  onClick={() => handleReasonChange(reason)}
                >
                  {reason}
                </div>
              ))}
              {deletionReason === '기타' && (
                <input
                  type="text"
                  value={customReason}
                  onChange={handleCustomReasonChange}
                  placeholder="탈퇴 사유를 직접 입력해주세요."
                  className="custom-reason-input"
                />
              )}
            </div>
            <p>탈퇴 진행시, 프로필은 삭제되고<br></br>나와 친해지고 싶은 사람과 만날 수 없어요 😶‍🌫️</p>
            <div className="button-container">
              <button className="delete-button" onClick={handleDeleteAccount}>
                탈퇴
              </button>
              <button className="retry-button" onClick={handlePopupClose}>
                다시 한번 써볼게요
              </button>
            </div>
          </div>
              
        </div>
      )}
    </div>
  );
}

export default CS;
