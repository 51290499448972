import React, { useState } from 'react';
import { ReactComponent as XIcon } from '../NewComponent/assets/icon-x-mono.svg';
import { ReactComponent as ArrowIcon } from '../NewComponent/assets/arrow-right.svg';

export const SearchResult2 = ({ Label1, Label2, Label3, Label4, NameFindClassName, iconType,img }) => {
  const [isAnsVisible, setIsAnsVisible] = useState(false);

  // 'xicon'을 클릭할 때 실행되는 함수
  const handleDeleteClick = () => {
  };

  // 아이콘 변수에 따라 다른 아이콘을 렌더링
  const renderIcon = () => {
    if (iconType === 'xicon') {
      return <XIcon onClick={handleDeleteClick}/>;
    } else if (iconType === 'arrow') {
      return <ArrowIcon />;
    } else {
      return null; // 다른 경우에는 아이콘 없음
    }
  };

  return (
    <div className={NameFindClassName}>
      <div style={{display: 'flex', justifyContent:'flex-start'}}> 
        <div><img style={{width:'35px',height:'35px'}} src={img}></img></div>
        <div style={{display: 'flex', flexDirection:'column',textAlign:'left',marginLeft:'20px',marginTop:'3px'}}> 
          <div className='UserName'>{Label1}</div>
          <div className='SchoolName' style={{marginLeft:'0'}}>{Label2} · {Label3} </div>
        </div>
      </div>
    </div>
  );
};
