import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingMiddele2 from '../../NewComponent/OnboardingMiddle2';
import KaKaoLoginbtn from '../../component/KaKaoLoginbtn';
import cupid from '../../NewComponent/assets/Cupid.svg'
import love_letter from '../../NewComponent/assets/love_letter.webp'
import { CSSTransition } from 'react-transition-group';
import HintBox from '../../NewComponent/HintBox';
import KakaoMovingBox from '../../NewComponent/KakaoMovingBox';
import Shoes from '../../NewComponent/assets/Shoes Shopping.webp'
import BasketballHoop from '../../NewComponent/assets/BasketballHoop.webp'
import Art1 from '../../NewComponent/assets/Art1.webp'
import Message from '../../NewComponent/assets/Message.webp'
import StudyLamp from '../../NewComponent/assets/StudyLamp.webp'
import Mara from '../../NewComponent/assets/Mara.webp'
import Landingpage_box from '../../component/Landingpage_box';
import img_cat from '../../assets/img/cat_img.webp';
import img_insta from '../../assets/img/instagram_logo.webp';
import img_iphone from '../../assets/img/apple-iphone.webp';
import img_location from '../../assets/img/island.webp';
import img_earth from '../../assets/img/Earth.webp'
import PhoneLoginbtn from '../../component/PhoneLoginbtn';



function OnBoarding_Kakao() {
  const [tutorialStep, setTutorialStep] = useState(1);
  const [showStep, setShowStep] = useState(true);
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);


  useEffect(() => {
    const changeStep = () => {
      setShowStep(false);
      setTimeout(() => {
        setTutorialStep((prevStep) => (prevStep === 4 ? 1 : prevStep + 1));
        setShowStep(true);
        setCounter((prevCounter) => prevCounter + 1);
      }, 500);
    };
  
    const isSmallDevice = window.innerWidth <= 375; // 작은 디바이스 크기를 변경하세요
  
    let timer = null;

    
  
    if (!isSmallDevice) {
      timer = setInterval(changeStep, 4000);
    }

 
  
    return () => {
      if (!isSmallDevice) {
        clearInterval(timer);
      }
    };
  }, []);
  
  


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  useEffect(() => {
    const delay = 500; // 박스가 나타나는 지연시간 (밀리초)
  
    if (visibleIndex < 5) {
      const timer = setTimeout(() => {
        setVisibleIndex(visibleIndex + 1);
      }, delay);
  
      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 클리어
    }
  }, [visibleIndex]);
  
  
  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className='main_content'>
          
      <div className='Content-Casting'>

      <CSSTransition
            in={showStep && tutorialStep === 1}
            timeout={1000000}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)} 
          >

        <div id='Content1'>   

           <OnboardingMiddele2 middle_compo='middle_component' img={cupid} Text1='HOXY, 우리학교에 나랑' Text2='친해지고 싶은 친구가 있을까요? ' Text3='우리학교 친구들의 속마음을 알려드릴게요'  />
           
           <br></br>

        </div>
      </CSSTransition>

      <KaKaoLoginbtn />
      <PhoneLoginbtn />



      <CSSTransition
            in={showStep && tutorialStep === 2}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)} 
          >
        <div id='Content2'>

      <OnboardingMiddele2 middle_compo='middle_component_25' img={love_letter} Text1='내 프로필로 우리학교 친구들이' Text2='속마음 편지를 보내와요'/>

          <div style={{marginTop:'270px'}}>
                  <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px', marginTop: '30px',  marginRight: 'auto' }}>
                        <Landingpage_box
                          imgSrc={img_cat}
                          imgAlt="Cat Icon"
                          text="우리 학교 18세"
                          boldText="여학생"
                          isVisible={visibleIndex >= 1}
                        />
                        <Landingpage_box
                          imgSrc={img_insta}
                          imgAlt="insta Icon"
                          text="맞팔하고"
                          boldText="싶어해요!"
                          isVisible={visibleIndex >= 2}
                        />
                      </div>
                    </div>



                    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '1px', display: 'flex' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: '25px', marginLeft: 'auto' }}>
                        <Landingpage_box
                          imgSrc={img_iphone}
                          imgAlt="Iphone Img"
                          text="iPhone을"
                          boldText="사용해요!"
                          isVisible={visibleIndex >= 3}
                        />
                        <Landingpage_box
                          imgSrc={img_location}
                          imgAlt="Location Img"
                          text="범어동에"
                          boldText="살아요!"
                          isVisible={visibleIndex >= 4}
                        />
                    </div>
                  </div>
          </div>
                    
        </div>
      </CSSTransition>

      <CSSTransition
            in={showStep && tutorialStep === 3}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)} 
          >
        <div id='Content3'>
            <div className='onboarding_middle_text_1' style={{display:'flex',justifyContent:'center'}}>
                 <div>편지를 보낸 사람의 <br></br> 여러가지 힌트도 알아보세요</div>
            </div>

            <div style={{display:'flex',justifyContent:'center', marginTop:'20px'}}>
              <div style={{display:'flex',flexDirection:'column'}}>
                  <HintBox hintImg={img_earth} hintText1={'나에게 언제 친해지고 싶다고 보낸걸까요?'} hintText2={'2023년 10월 10일 (화) 19시 23분 15초'} />
                  <HintBox hintImg={img_iphone} hintText1={'나랑 친해지고 싶은 사람은 어떤 폰을 쓸까요?'} hintText2={'iPhone'} />
                  <HintBox hintImg={img_location} hintText1={'나랑 친해지고 싶은 사람은 어디에 있을까요?'} hintText2={'대구광역시 수성구 일대'} />
                  <HintBox hintImg={img_insta} hintText1={'나랑 친해지고 싶은 사람의 인스타 아이디는 무엇일까요?'} hintText2={'인스타그램 아이디에 A가 들어가요'} />
              </div>

              
              
          
            </div>
        </div>
        
        </CSSTransition>

      
        <CSSTransition
            in={showStep && tutorialStep === 4}
            timeout={500}
            classNames="fade"
            unmountOnExit
            onExited={() => setShowStep(true)} 
          >
        <div id='Content4'>
          
            <div className='onboarding_middle_text_1' style={{display:'flex',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'column'}}>
                 <div>나도 친해지고 싶었던<br></br> 학교 친구와 친해질 수 있어요 </div>
                 <div className='onboarding_middle_text_3'>우리학교 친구들의 인스타그램을 구경해보세요</div>
              </div>
            </div>

            <br></br>
            <br></br>


            <div style={{justifyContent:'center', overflowX:'hidden'}}>
              <KakaoMovingBox id='left' img1={Shoes} img2={Mara} img3={Art1}/>
              </div>
              <br></br>
            <div style={{justifyContent:'center', overflowX:'hidden'}}>
              <KakaoMovingBox id='right' img1={BasketballHoop} img2={Message} img3={StudyLamp} />
            </div>
        </div>

        </CSSTransition>
      </div>
     
    



      </div>
    </div>
  );
}

export default OnBoarding_Kakao;
