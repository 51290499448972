import React, { useState, useEffect }  from "react";
import PrimaryButton from "./PrimaryButton";
import upload_arrow  from "../NewComponent/assets/upload_arrow.svg"
import right_arrow  from "../NewComponent/assets/right-arrow.svg"
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function ProfileBox({img,profile_insta, profile_id, profile_follower, profile_following, profile_school, profile_name,animate,button_img} ) {
  const navigate = useNavigate();
  const [buttonAnimate, setButtonAnimate] = useState('initial');

  function isMobileDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes('android') ||
      userAgent.includes('iphone') ||
      userAgent.includes('ipad') ||
      userAgent.includes('ipod') ||
      userAgent.includes('windows phone')
    );
  }

  function handleButtonClick() {

    if(button_img === 'upload_arrow')
    {
      navigate('/share')
    }

    else {

    if (isMobileDevice()) {
      window.location.href = `instagram://user?username=${profile_id}`;
    } else {
      window.open(`https://www.instagram.com/${profile_id}`, '_blank');
    }
  }

  }


  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonAnimate((prevAnimate) => (prevAnimate === 'highlight' ? 'initial' : 'highlight'));
    }, 600); // 1초마다 애니메이션을 토글

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시에 인터벌 해제
    };
  }, []);

  const buttonContainerStyle = {
    display:'flex',
    justifyContent:'center',
    marginTop:'50px',
    marginLeft:'-30px'
  };

  const buttonStyle = {
    width: '80px',
    height: '30px',
    flexShrink: '0',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: '10px',
    background: '#FF5C00'
  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.3px',
    whiteSpace: 'nowrap',
    marginTop:'3px'
  };

  const buttonVariants = {
    initial: {
      scale: 1,
      x: 0,
      rotate: 0,
    },
    highlight: {
      scale: 1.05,
      x: [0, -5, 5, -3, 3, 0], // 좌우로 움직임
      rotate: [0, -5, 5, -3, 3, 0], // 텍스트 회전 애니메이션
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10,
      },
    },
  };

  return (
    <div style={{display:'flex',justifyContent:'center', background:'#fff'}}>
        <div style={{marginTop:'24px', borderRadius:'80px'}}><img style={{width:'80px', height:'80px', borderRadius:'80px',marginTop:'-10px'}}   width={'80px'} height={'80px'}     src={img} ></img></div>
        <div style={{display:'flex',justifyContent:'center',marginLeft:'15px'}}>
            <div>
                <div className="profile_insta">{profile_insta} {profile_follower} {profile_following}</div>
                <div className="profile_school">{profile_school}</div>
                <div className="profile_name">{profile_name}님과 <br></br>친해져볼까요?</div>
            </div>
        </div>
        <div style={buttonContainerStyle} >



            <motion.button
                style={buttonStyle}
                variants={buttonVariants}
                initial={{ scale: 1 }} // Initial scale
                animate={{ scale: [1, 1.1, 1] }} // Animate to scale 1, 1.1, and back to 1
                transition={{ repeat: Infinity, duration: 0.5 }} // Repeat infinitely with a 1.5-second duration
                onClick={handleButtonClick} // 클릭 이벤트 핸들러 추가
            >   
                <div style={{display:'flex',justifyContent:'center', textAlign:'center'}}>


                        {button_img === 'upload_arrow' ? (
                        <div style={buttonTextStyle}>프로필 공유</div>
                        ) : (
                          <div style={buttonTextStyle}>인스타 이동</div>
                          )}    
                        {button_img === 'upload_arrow' ? (
                        <img src={upload_arrow} style={{ marginLeft: '2px', marginTop: '3px' }} />
                      ) : (
                        <img src={right_arrow} style={{ marginLeft: '2px', marginTop: '3px' }} />
                      )}                
                      
                      </div>
            

            </motion.button>


       
            </div>
    </div>
  );
}
