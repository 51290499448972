import Earth from '../assets/img/Earth.webp'
import jwt_decode from 'jsonwebtoken/decode';
import React, { useState, useEffect } from 'react';

function HoxyWorld() {
  const [showPopup, setShowPopup] = useState(false);
  const [tokenUserID, setTokenUserID] = useState('');


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);


      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
        console.log("로그인되어있지 않음");
 }
  }, []);

  const handlePopupOpen = async () => {
    try {
      const response = await fetch(
        'https://backend-hoxy-one-api.hoxy.one:4004/api/application/join',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: tokenUserID,
            application_type: 'hoxy-world',
            location: window.location.href,
          }),
        }
      );

      if (response.ok) {
        setShowPopup(true);
      } else if (response.status === 400) {
        alert('이미 신청했어요 곧 찾아올게요!');
      } else {
        console.error('Backend insertion failed');
      }
    } catch (error) {
      console.error('Error connecting to the backend:', error);
    }
  };


  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className="main" style={{ background: "linear-gradient(to bottom, #0B091C, #362697)" }}>
      <div className="main_content" style={{ background: "linear-gradient(to bottom, #0B091C, #362697)", height: '100vh',  overflow:'hidden', touchAction:'none', overflowX:'hidden'}}>

        <a href="javascript:window.history.back();">
          <div className="top_bar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white" // 흰색으로 변경
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left top_icon"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </div>
        </a>

        <div className='WorldMessage'>내 근처에 나랑 친해지고<br></br>싶은 사람이 있어요</div>
        <div style={{ marginTop: '25px' }}>
          <img className="earth-img" src={Earth} alt="Earth"></img> {/* Added 'alt' attribute */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ color: '#fff', marginTop: '15px', fontSize: '13px' }}>반경 500m 이내에</div>
          <div className='WorldNum'>?명</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='WorldButtonBox'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ color: '#fff', marginTop: '0px' }}>아직 준비중인 서비스에요 <br></br><b>어서 준비해서 곧 여러분께 찾아올게요</b></p>
            </div>
            <div className='WorldButton'>
              <div className='WorldButtonText' onClick={handlePopupOpen}>오픈하면, <b>&nbsp;가장 먼저 알림받기</b></div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup-container" onClick={handlePopupClose}>
          <div className="custom-popup">
            <h2>사전예약 완료, 곧 찾아올게요. 👋<br></br></h2>
            <p>사전예약 순서와 무관하게, HOXY에 매일 더 많이 <br></br>이용하는 사람이 우선권을 부여받을 수 있어요</p>
            <button className="pop-up-button" onClick={handlePopupClose}>
              기다리고 있을게요 🙏
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default HoxyWorld;
