import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import jwt_decode from 'jsonwebtoken/decode';
import { LargeButton } from '../stories/LargeButton';
import { useNavigate  } from 'react-router-dom';
import Header from '../NewComponent/header';
import PrimaryButton from '../NewComponent/PrimaryButton';



function FirstShare() {
  const [copiedUrl, setCopiedUrl] = useState(''); 
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);


      } catch (error) {
        setTokenUserID("anonymous");
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
        console.log("로그인되어있지 않음");
        setTokenUserID("anonymous");

        }
  }, []);


  const MyUrl = `https://hoxy.one/${tokenUserID}`;

  const handleCopyToClipboard = () => {
    setCopiedUrl(MyUrl); 

    axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/referral', {
      user_id: tokenUserID,
      user_no: tokenUserNo,
      referral_user_id: tokenUserID,
      referral_page: window.location.href
    });

    alert('복사되었어요. 이제 이 링크를 스토리나, Bio에 공유해보세요 :)');
  };

  const handleButtonClick = () => {
    if (copiedUrl === MyUrl) {
      navigate(`/${tokenUserID}?explore=yes`);
    } else {
      alert('아직 나의 인스타그램에 공유되지 않았어요. 복사하기를 눌러, 공유해주세요!');
    }
  };



  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
     
        <br></br>
        <Header />


        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'10px', marginBottom:'0px'}}>
              



              <div style={{marginTop: '0px' }}>
              <h3 style={{fontSize:'18px', textAlign:'left'}}>링크를 Bio나 스토리에 공유하기</h3>
    
                <div className="LinkBox">{MyUrl}</div>
                <div style={{marginTop: '15px' }}>
                <CopyToClipboard className="Toram" text={MyUrl} onCopy={handleCopyToClipboard}>
                  <text className="underlineT" style={{ cursor: 'pointer', fontWeight:'400', textDecoration:'underline', fontSize:'14px'}}>
                    복사하기
                  </text>
                </CopyToClipboard>
              </div>
              </div>

 
    
    
    {/*
              <div style={{marginTop: '40px' }}>
              <h3 style={{fontSize:'18px', textAlign:'left'}}>인스타그램 스토리로 공유하기</h3>
    
              <div className='hint_box' style={{ backgroundColor:'#FBF5F5'}}>
    
              <div style={{display:'flex'}}>
              <div style={{
                  marginLeft:'20px'
                }}><img src={Link_img1} className='hint_img'></img></div>
                
                <div style={{marginLeft:'15px', textAlign:'left'}}>
                <p className='hint_p1' style={{ color:'#000000', fontWeight:'300'}}>스토리로 공유하면</p>
                <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px', color:'#000000'}} >더 많이 친해지고 싶은 사람을 알 수 있어요</p>
    
    
                </div>
                
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                <svg
                      className='hint_button'
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                      <path
                        d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  </div>
              </div>
    
              <div className='hint_box' style={{ backgroundColor:'#FBF5F5', marginTop:'15px'}}>
    
                  <div style={{display:'flex'}}>
                  <div style={{
                      marginLeft:'20px'
                    }}><img src={Link_img2} className='hint_img'></img></div>
                    
                    <div style={{marginLeft:'15px', textAlign:'left'}}>
                    <p className='hint_p1' style={{ color:'#000000', fontWeight:'300'}}>스토리로 공유하면</p>
                    <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px', color:'#000000'}} >더 많은 첫인상 투표를 받을 수 있어요</p>
    
    
                    </div>
                    
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                    <svg
                          className='hint_button'
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                          <path
                            d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      </div>
                         
                  </div>
             
    
    
    
              </div>
    
              <div style={{marginTop:'20px'}}></div>
    
              <div className='LMBoxLine1'>
                    <div className='LMBox1'>
                      <div><img style={{width:'90px', height:'90px'}} src={message2}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>디엠할 친구 찾기</div></div>
                    </div>
    
                    <div style={{marginLeft:'25px'}}></div>
    
                    <div className='LMBox2'>
                      <div><img style={{width:'90px', height:'90px'}} src={study}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>공부할 친구 찾기</div></div>
                    </div>
              </div>
    
              <div className='LMBoxLine2'>
                    <div className='LMBox3'>
                      <div><img style={{width:'90px', height:'90px'}} src={shoes}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>신발 쇼핑 친구 찾기</div></div>
                    </div>
    
                    <div style={{marginLeft:'25px'}}></div>
    
                    <div className='LMBox4'>
                      <div><img style={{width:'90px', height:'90px'}} src={art}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>미술관 갈 친구 찾기</div></div>
                    </div>
              </div>
    
              <div className='LMBoxLine3'>
                    <div className='LMBox5'>
                      <div><img style={{width:'90px', height:'90px'}} src={maratang}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>마라탕 친구 찾기</div></div>
                    </div>
    
                    <div style={{marginLeft:'25px'}}></div>
                    
                    <div className='LMBox6'>
                      <div><img style={{width:'90px', height:'90px'}} src={basketball}></img></div>
                      <div className='LMBoxText'><div style={{marginTop:'8px'}}>농구할 친구 찾기</div></div>
                    </div>
              </div>
    
    
                  */}
              </div>     
    

              <div className="welcome_ButtonBox">

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:'60px'}}>
              <h4 style={{ color: '#FF5C00', fontWeight: '600' }}>
                  인스타그램 스토리나 Bio에 내 링크를 공유해야지만
                  <br />
                  <b style={{ color: '#000000', fontWeight: '800' }}>나랑 친해지고 싶은 사람이 찾아올 수 있어요 👀</b>
                </h4>
                </div>

                  <div onClick={handleButtonClick}>
                  <PrimaryButton Text={"내 링크 공유완료, 이제 HOXY 시작하기"} />
                  </div>

              </div>
    
                </div>
              </div>

  );
}

export default FirstShare;