import React, { useEffect } from "react";
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";

const PhoneLoginbtn = () => {
  const navigate = useNavigate();

  const PhoneLoginHandler = () => {
    navigate('/onboarding_phonenum');
  };

  return (
    <div style={{ position: 'fixed', bottom: '25px', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <motion.button
        onClick={PhoneLoginHandler}
        whileTap={{ scale: 0.95 }}
        style={{ background: 'transparent', border: 'none', outline: 'none' }}
      >
        <div style={{
          width: '345px',
          height: '50px',
          borderRadius: '12px',
          backgroundColor: '#FBF5F5',
          color: '#FF5C00',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '25px',
          letterSpacing: '-0.08rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // 가로 가운데 정렬
          textAlign: 'center', // 텍스트의 가로 가운데 정렬
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ textAlign: 'center' }}>
              카카오가 내 명의가 아니라면? <b style={{ fontWeight: '800' }}> 전화번호로 시작하기</b>
            </p>
          </div>
        </div>
      </motion.button>
    </div>
  );
}
export default PhoneLoginbtn;
