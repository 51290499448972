import { useNavigate } from 'react-router-dom';
import { ReactComponent as EndLogo } from "../stories/assets/End.svg";
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';

function End() {
  const navigate = useNavigate();
  const [showLogo] = useState(true);


  useEffect(() => {

    localStorage.removeItem('user_gender');
    localStorage.removeItem('user_Password');
    localStorage.removeItem('user_school');
    localStorage.removeItem('user_phoneNumber');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_grade');

    localStorage.removeItem('user_kakao_email');
    localStorage.removeItem('user_kakao_year');
    localStorage.removeItem('user_kakao_age');
    localStorage.removeItem('user_kakao_id');
    localStorage.removeItem('user_kakao_imgurl');    

    localStorage.removeItem('information_check');
    localStorage.removeItem('marketing_check');



    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    const userId = decodedToken.user_id;
    const userNo = decodedToken.user_no;

       {/* await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); */}

    const timeout = setTimeout(() => {
      navigate(`/${userId}?explore=yes&onboarding=yes`);
    }, 2000);
    

    return () => clearTimeout(timeout);
  }, [navigate]);

  

  return (
    <div className="main" style={{ backgroundColor: '#FF5C00' }}>
    <div className="main_content" style={{ backgroundColor: '#FF5C00', touchAction: 'none', height: '100vh', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-80px'}}>
      <CSSTransition in={EndLogo} timeout={1000} classNames="fade">
        <EndLogo className="content" />
      </CSSTransition>
    </div>
    </div>
  </div>
  );
}

export default End;
