import { BrowserRouter as  Link, useParams } from 'react-router-dom';
import Profile from './Profile';
import HoxyPassMain from './HoxyPassMain';
import OnBoarding_Kakao from './newpage/OnBoarding_Kakao';
import React, { useState, useEffect } from 'react';

function SchoolP() {
  const { school_website } = useParams();
  localStorage.setItem('referral_source', "hoxy");
  localStorage.setItem('referral_content', "school_ceo");
  localStorage.setItem('referral_user_id', school_website);


  return (
    <div>
      <OnBoarding_Kakao></OnBoarding_Kakao>  
    </div>
  );
}

export default SchoolP;
