import { useEffect, useState } from 'react';
import { Box } from '../stories/Box';
import jwt_decode from 'jsonwebtoken/decode';
import { Link } from 'react-router-dom';
import axios from 'axios';

function VoteResult() {
  const [messages, setMessages] = useState([]);
  const [user_id, setUserId] = useState("");
  const [tokenUserID, setTokenUserID] = useState("");
  const abortController = new AbortController();
  const [QuestionData, setQuestionData] = useState([]);

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const postData = {
          vote_name: '첫인상투표',
          vote_num: 1,
        };

        const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/vote_question', postData);
        console.log(response.data); // 서버로부터 받은 응답 출력
        setQuestionData(response.data);
      } catch (error) {
        console.error('Error fetching question data:', error);
      }
    };

    const fetchVoteResults = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          const tokenUserID = decodedToken.user_id;
          setTokenUserID(tokenUserID);

          await fetchQuestionData();

          fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/vote/result`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: tokenUserID,
              vote_name: "첫인상투표",
              vote_num: 1
            }),
          })
            .then(response => response.json())
            .then(data => {
              setMessages(data);
            })
            .catch(error => {
              console.error('Error fetching vote results:', error);
            });
        } catch (error) {
          console.log('토큰 디코딩 에러:', error);
        }
      } else {
        setTokenUserID('none');
        alert('정상적이지 않는 접근이에요');
      }
    };

    fetchVoteResults();
  }, []);



  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF'}}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <a href="javascript:window.history.back();">
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
          <br></br>

          <div style={{display:'flex', textAlign:'left'}}>
            <div style={{marginTop:'40px', marginLeft:'25px', display:'flex', }}>
              <h2 className='title'>실시간 첫인상 투표 결과</h2>
            </div>
          </div>
         
         {/* <div className="MessageTo" style={{textAlign:'left', marginTop: '5px', marginRight:'250px', whiteSpace: 'nowrap'}}>메시지를 클릭 해, 투표한 사람의 힌트를 확인할 수 있어요 🍀</div> */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            {messages.length === 0 ? (
              <div><br></br><br></br><br></br>아직 투표 결과가 없어요 😭</div>
            ) : (
              messages.map((message, index) => (
                <div key={index} style={{ marginBottom: '15px' }}>
                  <Box
                    className="LargeBox"
                    Label1={`${message.vote_relationship}사이인 ${message.vote_age}세 ${message.vote_gender}`}
                    Label2={(
                      <div>
                          ㄴ {QuestionData[0].vote_question}은 <span style={{ fontWeight: 'bold' }}>' {message.vote_a1} '</span>로 투표했어요<br></br>
                          ㄴ {QuestionData[1].vote_question}은 <span style={{ fontWeight: 'bold' }}>' {message.vote_a2} '</span>로 투표했어요<br></br>
                          ㄴ {QuestionData[2].vote_question}은 <span style={{ fontWeight: 'bold' }}>' {message.vote_a3} '</span>로 투표했어요<br></br>
                          ㄴ {QuestionData[3].vote_question}는 <span style={{ fontWeight: 'bold' }}>' {message.vote_a4} '</span>로 투표했어요<br></br>
                          ㄴ {QuestionData[4].vote_question}는 <span style={{ fontWeight: 'bold' }}>' {message.vote_a5} '</span>로 투표했어요
                      </div>
                    )}                    ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${message.vote_gender === '여성' ? 'girl' : 'boy'}.png`}
                    Label4="#ffffff"

                  />
                </div>
              ))
            )}
          </div>
          
       
        </div>    
      </div>
    </div>
  );
}

export default VoteResult;
