import Footer from '../component/Footer';
import happythreefriend from '../assets/img/threehappyfriends.webp'
import candy from '../assets/img/candy.webp'
import GiftBox1 from '../assets/img/GiftBox1.webp'
import Bacchus from '../assets/img/Bacchus.webp'
import chupachups from '../assets/img/chupachups.webp'
import ReverseClock from '../assets/img/ReverseClock.webp'
import Ghana from '../assets/img/Ghana.webp'
import ChocolateBar from '../assets/img/ChocolateBar.webp'
import BlackCan from '../assets/img/BlackCan.webp'
import ProgressBar from '../component/ProgressBar';
import HoxyWordIcon from '../component/Hoxyworldicon';
import Tutorial from '../component/TutorialMessage';


function MyGift() {

  

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF'}}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div className='overlay'> 
        </div>
          <div style={{position:'relative'}}>
              <Tutorial></Tutorial>

              <div className='header'>
              <a href="javascript:window.history.back();">
                <div className="top_bar">
                <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-left top_icon"
                    >
                      <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </div>
              </a>
              <div style={{ backgroundColor:'inherit'}} className="hoxyworld_icon">
                <HoxyWordIcon></HoxyWordIcon>
              </div>
            </div>
            <br></br>

                <div style={{display:'flex', textAlign:'left'}}>
                  <div style={{marginTop:'40px', marginLeft:'25px', display:'flex', }}>
                  <h2 className='title'>내가 받은 선물</h2> <img src={GiftBox1} width={'32px'}height={'31px'} style={{marginTop:'-30px', marginLeft:'5px'}}></img>
                  </div>
                </div>
            

                <div style={{display:'flex',justifyContent:'center',marginRight:'40px'}}>
                  <div className='SchoolNowBox'>
                      <div style={{marginTop:'5px',backgroundColor:'inherit'}} className='SchoolNowImo'><img style={{width:'25px',height:'auto'}} src={candy}></img></div>
                      <div style={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                          <div>우리학교 친구수</div>
                          <div style={{fontSize:'11px'}}>200명</div>
                      </div>
                  </div>
                  <div style={{marginRight:'8px'}}></div>
                  <div className='SchoolNowBox2'>
                      <div style={{marginTop:'5px',backgroundColor:'inherit'}} className='SchoolNowImo'><img style={{width:'25px',height:'auto'}} src={BlackCan}></img></div>
                      <div style={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                          <div>받은 누적요청 수</div>
                          <div style={{fontSize:'11px'}}>200명</div>
                      </div>
                  </div>
                  <div style={{marginRight:'8px'}}></div>
                  <div className='SchoolNowBox2'>
                      <div style={{marginTop:'5px',backgroundColor:'inherit'}} className='SchoolNowImo'><img style={{width:'25px',height:'auto'}} src={ChocolateBar}></img></div>
                      <div style={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                          <div>받은 츄파춥스 수</div>
                          <div style={{fontSize:'11px'}}>200명</div>
                      </div>
                  </div>
                </div>


                <div>
                <div style={{display:'flex',justifyContent:'center', marginTop:'10px'}}>
                  <div className='GiftBox'>
                      <div style={{display:'flex',justifyContent:'center',marginTop:'20px',marginRight:'105px'}}>
                          <div className='GiftBlock'><img style={{width:'35px',height:'39.516px'}} src={chupachups}></img></div>
                          <div style={{display:'flex', flexDirection:'column', marginLeft:'17px',marginTop:'4px'}}>
                              <div className='GiftText1'>GS25 츄파츕스 기프티콘 교환까지</div>
                              <div className='GiftText2'>친구들의 선물 1개가 필요해요 (1/2)</div>
                          </div>
                      </div>
                            <ProgressBar
                            bgcolor="linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%)"
                            completed={50}
                          />
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'center',marginRight:'5px'}}>
                  <div className='GiftBox'>
                      <div style={{display:'flex',justifyContent:'center',marginTop:'20px',marginRight:'105px'}}>
                          <div className='GiftBlock'><img style={{width:'35px',height:'39.516px'}} src={Bacchus}></img></div>
                          <div style={{display:'flex', flexDirection:'column', marginLeft:'17px',marginTop:'4px'}}>
                              <div className='GiftText1'>GS25 박카스 기프티콘 교환까지</div>
                              <div className='GiftText2'>친구들의 선물 3개가 필요해요 (0/3)</div>
                          </div>
                      </div>
                            <ProgressBar
                            bgcolor="linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%)"
                            completed={0}
                          />
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'center',marginRight:'5px'}}>
                  <div className='GiftBox'>
                      <div style={{display:'flex',justifyContent:'center',marginTop:'17px',marginRight:'105px'}}>
                          <div className='GiftBlock'><img style={{width:'35px',height:'39.516px'}} src={Ghana}></img></div>
                          <div style={{display:'flex', flexDirection:'column', marginLeft:'17px',marginTop:'4px'}}>
                              <div className='GiftText1'>GS25 가나 초콜릿 기프티콘 교환까지</div>
                              <div className='GiftText2'>친구들의 선물 1개가 필요해요 (2/4)</div>
                          </div>
                      </div>
                            <ProgressBar
                            bgcolor="linear-gradient(90deg, #FF5C00 0%, #FF8A00 100%)"
                            completed={50}
                          />
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'center',marginTop:'35px'}}>
                  <div className='MiniGiftBox'>
                      <div style={{display:'flex', justifyContent:'center'}}>
                          <div style={{marginTop:'13px', display:'flex', justifyContent:'center'}}>
                              <div><img style={{width:'25px', height:'auto',backgroundColor:'inherit'}}src={happythreefriend}></img></div>
                              <div style={{marginTop:'4px', marginLeft:'6px'}}>친구들한테, <b>내 프로필 공유하고 선물 받기</b></div>
                              <div style={{marginTop:'4px', marginLeft:'60px'}}></div>
                          </div>
                      </div>
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'center', marginTop:'10px'}}>
                  <div className='MiniGiftBox'>
                      <div style={{display:'flex', justifyContent:'center'}}>
                          <div style={{marginTop:'13px', display:'flex', justifyContent:'center'}}>
                              <div><img style={{width:'25px', height:'auto'}}src={ReverseClock}></img></div>
                              <div style={{marginTop:'4px', marginLeft:'10px'}}>그동안 내가 교환한 <b>기프티콘 기록</b></div>
                              <div style={{marginTop:'4px', marginLeft:'90px'}}></div>
                          </div>
                      </div>
                  </div>
                </div>
                            </div>


                      <Footer></Footer>

                      </div>
         </div>
    </div>
        
  );
}

export default MyGift;
