import PrimaryButton from '../../NewComponent/PrimaryButton';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import jwt_decode from 'jsonwebtoken/decode';


function AdSuccess() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const drawnCards = queryParams.get('drawnCards');

  const token = localStorage.getItem('token');
  
  const decodedToken = jwt_decode(token);
  const tokenUserID = decodedToken.user_id;


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <br></br>

        <MiddleCompoent Text1='선물을 열고'
          Text2={
            <span>
              조커카드 <span style={{ color: '#FF5C00' }}>{drawnCards}장</span>을 얻었어요
            </span>
          } />

        <Link to={`/${tokenUserID}?explore=yes`}>
          <PrimaryButton Text="내 프로필로 이동하기" />
        </Link>
      </div>
    </div>
  );
}

export default AdSuccess;
