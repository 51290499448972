import React, { useState } from "react";
import { ReactComponent as Home } from '../NewComponent/assets/icon-home-mono.svg';
import { ReactComponent as Message } from '../NewComponent/assets/icon-chat-bubble-dots-mono.svg';
import { ReactComponent as Ourschool } from '../NewComponent/assets/icon-crown-mono.svg';
import { ReactComponent as Menu } from '../NewComponent/assets/icon-line-three-mono.svg';
import { motion  } from 'framer-motion';
import jwt_decode from 'jsonwebtoken/decode';
import { Link, useParams, useNavigate } from 'react-router-dom';



const BottomNav = () => {
  // 현재 선택된 아이콘을 관리하는 state
  const [activeNav, setActiveNav] = useState(1);
  const [showPopup2, setShowPopup2] = useState(false);
  const [tokenUserID, setTokenUserID] = useState('');
  const visitProfileName = localStorage.getItem('VisitprofileName');
  const navigate = useNavigate();

  const handlePopupButtonMain = () => {
    const token = localStorage.getItem('token');
    const VisitprofileID = localStorage.getItem('VisitprofileID');
    
    console.log('Token:', token);
    console.log('Visit Profile ID:', VisitprofileID);
  
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        console.log('Decoded Token User ID:', tokenUserID);
  
        setTokenUserID(tokenUserID);
        const targetPath = `/${tokenUserID}?explore=yes`;
        console.log('Target Path:', targetPath);
        
        navigate(targetPath);
  
      } catch (error) {
        console.error("JWT 디코딩 중 오류 발생:", error);
      }
    } else {
      const targetPath = `/${VisitprofileID}?explore=yes`;
      console.log('Target Path (No Token):', targetPath);
      navigate(targetPath);
    }
  };

  const handlePopupButton2 = () => {
    setShowPopup2(true)
  };
  const handlePopupButtonClose2 = () => {
    setShowPopup2(false)
  };



  return (
    <div>
    <nav className="wrapper" style={{border:'none'}}>
      {/* 하단 네비게이션 최상위 태그 */}
      <div>
      <div className="nav-link" onClick={() => { setActiveNav(1); handlePopupButtonMain(); }}>
          <Home 
            icon="home"
            style={{border:'none'}}
            className={activeNav === 1 ? "nav-item active" : "nav-item"}
          />
          {/* 네비게이션을 구성하고 있는 하나의 버튼 */}
          <div className="bottom_nav_font">홈</div>
        </div>
      </div>
      <div>
        <Link  className="nav-link" onClick={() => handlePopupButton2()}>
          <Message 
            icon="home"
            style={{border:'none'}}
            className={activeNav === 1 ? "nav-item active" : "nav-item"}
          />
          <div className="bottom_nav_font">시크릿 DM</div>
        </Link>
      </div>
      <div>
        <Link to="/ourschool" className="nav-link" onClick={() => setActiveNav(3)}>
           <Ourschool 
            icon="home"
             style={{border:'none'}}
            className={activeNav === 1 ? "nav-item active" : "nav-item"}
          />
         <div className="bottom_nav_font">우리 학교</div>
        </Link>
      </div>
      <div>
        <Link to="/menu" className="nav-link" onClick={() => setActiveNav(4)}>
          <Menu 
            icon="home"
            style={{border:'none'}}
            className={activeNav === 1 ? "nav-item active" : "nav-item"}
          />
         <div className="bottom_nav_font">메뉴</div>
        </Link>
      </div>
    </nav>


{showPopup2 && (
  <motion.div
    className="popup-container"
    initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
    animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
    exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
  >
    <motion.div
      className="popup-content"
      initial={{ opacity: 0 }} // 초기 상태
      animate={{ opacity: 1 }} // 애니메이션 종료 상태
    >
      <h2 style={{ fontSize: '22px' }}>
        {visitProfileName}님은 아직<br></br>익명 DM을 사용할 수 없어요
      </h2>
      <p>
      {visitProfileName}님과 친해지고 싶은 친구가, 5명이 넘으면
        <br />
         익명 DM 기능을 사용할 수 있어요 👋

      </p>

                   
      <div className="button-container">
        <button
          className="delete-button"
          onClick={handlePopupButtonClose2}
          style={{visibility:'hidden'}}
        >
          괜찮아요
        </button>
        <motion.button
          className="retry-button"
          onClick={handlePopupButtonClose2}
        >
          알겠어요
        </motion.button>
      </div>
    </motion.div>
  </motion.div>
)}
</div>
  );
};

export default BottomNav;
