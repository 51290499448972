import React from "react";

export default function ThemeTitle({ themetitle,LookClass}) {
  
  return (
    <div className="flexbox">
      <div className="ThemeTitle">{themetitle}</div>
      <div className={LookClass}>
        자세히 보기
      </div>
    </div>
  );
}
