import { useEffect, useState } from "react";
import "./Hoxyworldicon.css";
import joker_card from "../assets/img/joker_card.webp";
import { Link } from 'react-router-dom';

function HoxyWordIcon({ color }) {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const textColor = color === "#FFFFFF" ? "#FF5C00" : "#FFF"; // 흰색일 때는 오렌지, 그 외에는 흰색으로 설정

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationCompleted(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Link to='/jokercard'>
      <div className={`hoxy-box ${animationCompleted ? "shrink" : ""}`} style={{ backgroundColor: color }}>
        <div className={`text ${animationCompleted ? "hide" : ""}`} style={{ color: textColor }}>
          조커카드는 <b style={{ fontWeight: "800" }}>&nbsp;HOXY?</b>
        </div>
        {animationCompleted && (
          <img
            src={joker_card}
            width={'25px'}
            height={'25px'}
            alt="Earth"
            className={`earth-image ${animationCompleted ? "show" : ""}`}
          />
        )}
      </div>
    </Link>
  );
}

export default HoxyWordIcon;
