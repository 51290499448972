import React, { useState } from 'react';
import InformBox from '../../NewComponent/InformBox';
import SliceBar from '../../NewComponent/SliceBar';
import hands1 from "../../NewComponent/assets/Hands1.webp";
import clovahands from "../../NewComponent/assets/ClovaHands.webp";
import instagram from "../../assets/img/instagram_logo.webp";
import ThemeTitle from '../../NewComponent/ThemeTitle';
import InstaSearch from '../../NewComponent/InstaSearch';
import Header from '../../NewComponent/header';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import { Link } from 'react-router-dom';


function NoResultP() { 

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
    <div className="main_content" style={{ backgroundColor: '#FFFFFF'}}>      
    <br></br>

      <Header  Text="내가 친해지고 싶은 친구 찾기"/>


      <div style={{marginTop:"25px"}}></div>


        <div style={{marginRight:'190px'}}>
          <ThemeTitle themetitle={'HOXY를 쓰는 친구라면'} LookClass={'NoLook'} style={{marginRight:'50px'}}/>
        </div>     
        
        <div style={{marginTop:'15px'}}></div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <InstaSearch label={'친해지고 싶은 사람 인스타를 검색해보세요'}/>
        </div>  
    



      <SliceBar  />

      <div style={{marginTop:"25px"}}></div>

      <div className='Content-2_NoResult'>
          
        <div style={{marginRight:'170px'}}>
            <ThemeTitle themetitle={'HOXY를 안쓰는 친구라면'} LookClass={'NoLook'} style={{marginRight:'50px'}}/>
          </div>  
      
          <div style={{display:'flex',justifyContent:'center',marginTop:'15px',flexDirection:'column'}}>
          <div style={{display:'flex',justifyContent:'center', marginLeft:'-50px'}}>
            <InformBox src={hands1} iftext={'HOXY팀에 친해지고 싶은 친구를 정보를 알려주면'} howtext={'친해지고 싶은 친구를 캐스팅해올게요'}  />
          </div>
          
         
          <div style={{display:'flex',justifyContent:'center',marginLeft:'-25px', marginTop:'15px'}}>
            <InformBox src={instagram} iftext={'나에 대한 정보는 공개되지 않고, HOXY팀이'} howtext={'인스타그램 DM으로 친구를 캐스팅할게요'}  />
          </div>

          
          <div style={{display:'flex',justifyContent:'center', marginLeft:'-45px', marginTop:'15px'}}>
            <InformBox src={clovahands} iftext={'캐스팅을 통해서 친해지고 싶은 친구가 가입하면'} howtext={'HOXY를 통해서 마음을 표현해보세요'}   />
          </div>
        </div>
      </div>

      <br></br>
    
    <Link to={"/casting"}>
    <PrimaryButton Text={"친해지고 싶은 친구 캐스팅 요청하기"} />
    </Link>
</div>
</div>

  );
}

export default NoResultP;
