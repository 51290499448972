import React, { useEffect, useState } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import cat_footprint from '../../../NewComponent/assets/cat_footprint.svg';
import cat_footprint_2 from '../../../NewComponent/assets/cat_footprint_2.svg';
import vote_ballot from '../../../NewComponent/assets/chat_bubble.webp';
import soap_bubble from '../../../NewComponent/assets/soap-bubble 2.webp';
import love_letter from '../../../NewComponent/assets/love_letter.webp';
import { motion, useAnimation} from 'framer-motion';
import OnboardingMiddele2 from '../../../NewComponent/OnboardingMiddle2';

function ToWhere() {
    const navigate = useNavigate();
    const [currentContent, setCurrentContent] = useState(1);
    const [previousContent, setPreviousContent] = useState(1);
    const VisitprofileName = localStorage.getItem('VisitprofileName');
    const token = localStorage.getItem('token');
    const controls = useAnimation();

    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [footprintImage, setFootprintImage] = useState(cat_footprint);
  
    const handleFootprintClick = () => {
      // Change the image source when the div is clicked
      setFootprintImage(cat_footprint_2);
    };
  
    const handleMessageMainonClick = () => {
      setCurrentContent(2)

      setTimeout(() => {
        navigate('/MessageMain');
      }, 3000); // 3000 밀리초 = 3초

    };

      const handleTodayQuestionClick = () => {
        setCurrentContent(4)

        setTimeout(() => {
          navigate('/TodayQuestion');
        }, 3000); // 3000 밀리초 = 3초
    };

    
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!token) {
        setShowPopup(true);
      }
    }, 2000); // 2000 milliseconds = 2 seconds
  
    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, [token]); 
  


  const handlePopupButton2 = () => {
    setShowPopup2(true)
  };
  const handlePopupButtonClose2 = () => {
    setShowPopup2(false)
  };

  
  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className="main_content">
        <br></br>
        <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>
        <div className='middle_component'>
                <div style={{
                    color: "rgba(144, 145, 154, 0.80)",
                    textAlign: "center",
                    fontFamily: "Pretendard",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.8px"
                }}>{VisitprofileName}님과 나의 거리를 생각하며</div>

                <div style={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Pretendard",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "22px",
                    letterSpacing: "-0.88px",
                    marginTop:'5px'
                }}>아래에서 다가갈 방법을 골라보세요</div>   

        <div style={{display:'flex',justifyContent:'center', marginTop:'5px', height:'300px'}}>

        <motion.div
            className='footprint_1'
            initial={{ opacity: 1 }} // 초기 상태를 visible로 설정
            animate={{
                opacity: [1.5, 1.5, 1.5], // opacity가 계속해서 1로 유지되도록 변경
                y: [34, 64, 34],
                transition: { duration: 4, repeat: Infinity },
            }}
            onClick={() => {
                handleFootprintClick();
                handleMessageMainonClick();
            }}
            >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='footprint_font_1'>한 발짝</div>
                <div style={{ marginTop: '15px' }}><img style={{ marginBottom: "2px", marginLeft: "2px" }} src={footprintImage} alt="footprint"></img></div>
            </div>

            <div><img style={{ width: "65px" }} src={love_letter} alt="love letter"></img></div>

            <div className='footprint_font_2'>익명 찔러보기</div>
            </motion.div>

           
           <motion.div
           
           className='footprint_2'

           initial={{ opacity: 1 }} // 초기 상태를 visible로 설정
           animate={{
               opacity: [1.5, 1.5, 1.5], // opacity가 계속해서 1로 유지되도록 변경
               y: [40, 90, 40],
               transition: { duration: 4, repeat: Infinity },
           }}
           onClick={() => {
            handleFootprintClick();
            handlePopupButton2();
          }}



            > 
              <div style={{display:'flex', justifyContent:'center'}}>
                <div className='footprint_font_1'>두 발짝</div>
                <div style={{marginTop:'15px'}}><img style={{marginBottom:"2px", marginLeft:"2px"}} src={footprintImage}></img></div>
                <div style={{marginTop:'15px'}}><img style={{marginBottom:"2px", marginLeft:"2px"}} src={footprintImage}></img></div>
              </div>

              <div><img style={{width:"65px"}} src={soap_bubble}></img></div>

              <div className='footprint_font_2'>시크릿 DM</div>
           </motion.div>

           
           <motion.div

            className='footprint_3'
           
            initial={{ opacity: 1 }} // 초기 상태를 visible로 설정
            animate={{
                opacity: [1.5, 1.5, 1.5], // opacity가 계속해서 1로 유지되도록 변경
                y: [20, 70, 20],
                transition: { duration: 4, repeat: Infinity },
            }}
           onClick={() => {
            handleFootprintClick();
            handleTodayQuestionClick();
          }}


           >
              <div style={{display:'flex', justifyContent:'center'}}>
                <div className='footprint_font_1'>세 발짝</div>
                <div style={{marginTop:'15px'}}><img style={{marginBottom:"2px", marginLeft:"2px"}} src={footprintImage}></img></div>
                <div style={{marginTop:'15px'}}><img style={{marginBottom:"2px", marginLeft:"2px"}} src={footprintImage}></img></div>
                <div style={{marginTop:'15px'}}><img style={{marginBottom:"2px", marginLeft:"2px"}} src={footprintImage}></img></div>
              </div>

              <div><img style={{width:"65px"}} src={vote_ballot}></img></div>

              <div className='footprint_font_2'>1일 1문답</div>
           </motion.div>


           
           </div>
        </div>
        </div>

        <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>

        <OnboardingMiddele2
              middle_compo="middle_component"
              img={love_letter}
              Text1={<span style={{ color: '#FF5C00' }}>{`${VisitprofileName}님에게 익명 찔러보기`}</span>}
              Text2="내 마음을 익명으로 표현할 수 있어요"
              Text3={`${VisitprofileName}님과 나와의 거리 : 한 발짝`}
            />
        </div>

        <div id='Content4' style={{ display: currentContent === 4 ? 'block' : 'none' }}>

      <OnboardingMiddele2
            middle_compo="middle_component"
            img={vote_ballot}
            Text1={<span style={{ color: '#FF5C00' }}>{`${VisitprofileName}님과 1일 1문답`}</span>}
            Text2="매일 새로운 문답으로 더 가까워져보세요"
            Text3={`${VisitprofileName}님과 나와의 거리 : 세 발짝`}
          />
      </div>
        

      {showPopup2 && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
          >
            <h2 style={{ fontSize: '22px' }}>
              {VisitprofileName}님은 아직<br></br>익명 DM을 사용할 수 없어요
            </h2>
            <p>
            {VisitprofileName}님과 친해지고 싶은 친구가, 5명이 넘으면
            
              <br />
               익명 DM 기능을 사용할 수 있어요 👋

            </p>

                         
            <div className="button-container">
              <button
                className="delete-button"
                onClick={handlePopupButtonClose2}
                style={{visibility:'hidden'}}
                >
                괜찮아요
              </button>
              <motion.button
                className="retry-button"
                onClick={handlePopupButtonClose2}
              >
                알겠어요
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
      </div>
      </div>
  );
}

export default ToWhere;
