import React from 'react';
import { motion } from 'framer-motion';
import hand_img from '../NewComponent/assets/Hands1.webp';
import instagram_img from '../NewComponent/assets/Instagram.webp';
import eyes_img from '../assets/img/eye.png';
import stamp from '../NewComponent/assets/stamp.webp';
import { Link } from 'react-router-dom';


const AppHeader = () => (
  <a href='https://hoxy.page.link/Pihz'>
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 1 }}
      style={{
        backgroundColor: '#FF5C00'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px', border: '0' }}>
        <img src={hand_img} style={{ width: '20px', height: '20px' }} alt="App Hands"></img>
        <span style={{ color: '#fff', fontSize: '12px', fontWeight: '600', letterSpacing: '-0.32px', textAlign: 'center' }}>
          앱 설치하고, <b style={{ fontWeight: '800' }}>친해지고 싶은 사람 찾는 확률 높히기 ▶</b>
        </span>
      </div>
    </motion.div>
  </a>
);

const ShareHeader = () => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={{
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 }
    }}
    transition={{ duration: 1 }}
    style={{
      backgroundColor: '#FBF5F5'
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px', border: '0' }}>
      <img src={instagram_img} style={{ width: '20px', height: '20px' }} alt="App Hands"></img>
      <span style={{ color: '#000', fontSize: '12px', fontWeight: '600', letterSpacing: '-0.32px', textAlign: 'center' }}>
      &nbsp;프로필을 공유해,<b style={{ fontWeight: '800' }}>친해지고 싶은 사람이 다가올 수 있게 해주세요</b>
      </span>
    </div>
  </motion.div>
);


const Preview = () => (
  <Link to='/start'>
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 1 }}
      style={{
        backgroundColor: '#FF5C00'
      }}
    >
      <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '55px',
      border: '0'
    }}>
      <img src={eyes_img} style={{ width: '30px', height: '30px', marginLeft:'-5px' }} alt="App Hands" />
      <span style={{
        color: '#fff',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '-0.32px',
        textAlign: 'left',
        marginLeft:'10px'
      }}>
        실제 HOXY 프로필을 미리 체험하고 있어요!<br />
        <b style={{ fontWeight: '800', fontSize:'13px' }}>지금 내 프로필 만들고, 나랑 친해지고 싶은 사람 알아보기 ▶</b>
      </span>
    </div>

    </motion.div>
  </Link>
);


const WeAreHoxyHeader = () => (
  <a href='https://example.com/wearehoxy'>
    {/* WeAreHoxy에 대한 컴포넌트 내용 */}
  </a>
);


const HoxyAdmin1 = () => (
  <Link to='/start'>
  <motion.div
    initial="hidden"
    animate="visible"
    variants={{
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 }
    }}
    transition={{ duration: 1 }}
    style={{
      backgroundColor: '#1D87E7'
    }}
  >
    <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0',
    height : '35px'
  }}>
    <img src={stamp} style={{ width: '20px', height: '20px', marginLeft:'-5px' }} alt="App Hands" />
    <span style={{
      color: '#fff',
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '-0.32px',
      textAlign: 'left',
      marginLeft:'10px'
    }}>
      신입 CEO 업무를 진행하고
      <b style={{ fontWeight: '800', fontSize:'13px' }}>, 첫번째 보상을 받아가세요 ▶</b>
    </span>
  </div>

  

  </motion.div>
</Link>
);

const HoxyAdmin_share = () => (
  <Link to={'/ceo/DashBoard'}>
  <motion.div
    initial="hidden"
    animate="visible"
    variants={{
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 }
    }}
    transition={{ duration: 1 }}
    style={{
      backgroundColor: '#1D87E7'
    }}
  >
    <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0',
    height : '35px'
  }}>
    <img src={stamp} style={{ width: '20px', height: '20px', marginLeft:'-5px' }} alt="App Hands" />
    <span style={{
      color: '#fff',
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '-0.32px',
      textAlign: 'left',
      marginLeft:'10px'
    }}>
      CEO님, 이곳에서
      <b style={{ fontWeight: '800', fontSize:'13px' }}>, CEO 업무시스템으로 접속할 수 있어요 ▶</b>
    </span>
  </div>

  

  </motion.div>
</Link>
);


const Intelligence = ({ type }) => {
  let headerComponent = null;

  // type에 따라 다른 컴포넌트를 선택
  if (type === 'app') {
    headerComponent = <AppHeader />;
  } else if (type === 'share') {
    headerComponent = <ShareHeader />;
  } else if (type === 'wearehoxy') {
    headerComponent = <WeAreHoxyHeader />;
  }
  else if (type === 'Preview') {
    headerComponent = <Preview />;
  }
  else if (type === 'Admin_1') {
    headerComponent = <HoxyAdmin1 />;
  }
  else if (type === 'Admin_share') {
    headerComponent = <HoxyAdmin_share />;
  }


  return (
    <div>
      {headerComponent}
    </div>
  );
}

export default Intelligence;
