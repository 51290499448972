import React from "react";
import { ReactComponent as Cupid } from "./assets/Cupid.svg";
import { ReactComponent as Hands } from "./assets/Hands.svg";
import { ReactComponent as Instagram } from "./assets/Instagram.svg";
import { ReactComponent as ClovaHands } from "./assets/ClovaHands.svg";
import { ReactComponent as Hands2 } from "./assets/Hands2.svg";
import { ReactComponent as Earth } from "./assets/Earth.svg";
import { ReactComponent as Vote } from "./assets/Vote.svg";
import { ReactComponent as Noon } from "./assets/Noon.svg";
import { ReactComponent as First } from "./assets/First.svg";
import { ReactComponent as Second } from "./assets/Second.svg";
import { ReactComponent as Third } from "./assets/Third.svg";

import { ReactComponent as LookDetail } from "./assets/LookDetail.svg";
import { ReactComponent as Arrow } from "./assets/Arrow.svg";

import { motion } from 'framer-motion';

export default function HintBox({HintTitle,whattext,hinthowtext,CompoName,CompoName2}) {

    const components = {
        Cupid: <Cupid />,
        Hands: <Hands />,
        Instagram : <Instagram />,
        ClovaHands : <ClovaHands />,
        Hands2 : <Hands2 />,
        Vote : <Vote />,
        Noon : <Noon />,
        First : <First />,
        Second : <Second />,
        Third : <Third />,
        Earth : <Earth />,
        Earth : <Earth />,

      };

      const components2 = {
        LookDetail: <LookDetail />,
        Arrow: <Arrow />
      };

  return (
    <div style={{display:'flex',flexDirection:'column'}}>
        <div className='HintTitle'>{HintTitle}</div>

        <motion.div className="HintBox"> 
            <div style={{display:'flex',justifyContent:'flex-start'}}> 
                <div style={{ marginLeft: '18px',marginTop: '10px' }}>{components[CompoName]}</div>
                <div style={{ marginLeft: '8px',marginTop: '18px', display:'flex',flexDirection:'column',textAlign:'left' }}>
                    <div className="WhatText">{whattext}</div>
                    <div className="HintHowText">{hinthowtext}</div>
                </div>
                <div>{components2[CompoName2]}</div>
            </div>
        </motion.div>
    </div>
  );
}
