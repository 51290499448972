import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SmallNavi } from '../stories/SmallNavi';
import { OneBox } from '../stories/OneBox';
import { Box } from '../stories/Box';
import { Head } from '../stories/Head';
import '../App.css';
import Footer from '../component/Footer';
import KakaoAdFit  from '../component/KakaoAdFit';
import qa_img from '../assets/img/qa.webp'
import PrimaryButton from '../NewComponent/PrimaryButton';
import Intelligence from '../NewComponent/Intelligence';
import { motion  } from 'framer-motion';


function Preview() {

  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);


  const handleVoteBoxClick = () => {
      navigate('/TodayQuestion-preview');
    
  };

  useEffect(() => {
    const timer = setTimeout(() => {
        setShowPopup(true);
    }, 240000); // 2000 milliseconds = 2 seconds
  
    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

    
  const handlePopupButtonClick = () => {
    navigate('/start');
  };

  const PopupOpenClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
          <div>
  
            <Intelligence type="Preview" />
            <br />


            <div style={{ marginTop: '10px' }}>
              <Head className="MainHead" />
            </div>
  
            <div style={{ marginTop: '25px' }}>
              <OneBox
                className="LargeOneBox"
                Label1={<span dangerouslySetInnerHTML={{ __html: `채리우님과<br>친해질 수 있어요` }}></span>}
                Label2='@ruuuuu._.'
                Label3='율무중학교 1학년'
                Label4={  
                  <Link onClick={PopupOpenClick} style={{ color: '#000000', textDecoration: 'underline' }}>프로필 공유하기</Link>
                }
              ></OneBox>
            </div>


            
  
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'center' }}>
                <Link>
                  <SmallNavi className="primary" label1="요청됨" label2={`5건`} />
                  <div style={{ marginRight: '15px' }}></div>
                </Link>
  
                <Link>
                  <SmallNavi className="primarynf" label1="답장됨" label2={`5건`} />
                </Link>
              </div>
  
              <br></br>
              <KakaoAdFit unit={"DAN-cFUELCtfG8SU7JTQ"} width={"320"} height={"100"} disabled={false}/>
              <br></br>
  
              <div className="MessageTo" style={{ textAlign: 'left', marginRight: '250px', whiteSpace: 'nowrap' }}>채리우님에게 온 속마음 편지</div>
  
                <div className="MessageTo" style={{ textAlign: 'left', marginRight: '250px', whiteSpace: 'nowrap' }}>채리우님은 편지에 답장하고, 힌트도 확인할 수 있어요🍀</div>
  
              <div className="Boxsquare" style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <div className='vote_box'>
                  <div style={{ display: 'flex', marginTop: '-4px' }}>
                    <div style={{ marginLeft: '20px' }}><img src={qa_img} className='hint_img' width={'30px'} height={'30px'}></img></div>
                    <div style={{ marginLeft: '15px', textAlign: 'left' }}>
                      <p className='hint_p1' style={{ color: '#000000', fontWeight: '300' }}>솔직한 첫인상은? 매일 새로운 질문으로</p>
                      <p style={{ fontSize: '14px', fontWeight: '500', color: '#000', marginTop: '-12px' }}>채리우님의 <p2 style={{ fontSize: '14px', fontWeight: '700', color: '#000000' }}>1일 1문답 진행중</p2></p>
                    </div>
                    <div  onClick={handleVoteBoxClick} style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '20px', marginTop: '5px' }}>
                      <svg
                        className='hint_button'
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                        <path
                          d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
  
              
                      <div  style={{ marginBottom: '5px', marginTop: '5px' }}>

                
                          <Box
                            className="LargeBox"
                            Label1='Q. 채리우님에게 지금 듣고 싶은 말은?'
                            Label2={  <div>
                              크리스마스에 만나자
                              <br></br>
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing: '-0.06rem' }}>
                                {`ㄴ "엥???? 너 누구얏 ㅋㅋ 장난치지마ㅏ"`}
                              </span>
                            </div>}
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new2/3.webp`}
                            Label4="#ffffff"
                            popup='true'
                            
                            ></Box>
                            <br></br>

                            <Box
                            className="LargeBox"
                            Label1={`익명의 14세 남성`} 
                            Label2={  <div>
                            나랑 같은 학원에 다니는 사이인 14세 남성의 고양이 좋아하는 분께서 맞팔하고 싶어해요!                              <br></br>
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing: '-0.06rem' }}>
                                {`ㄴ "먼저 팔로우해주라!!"`}
                              </span>
                            </div>}
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new/3.webp`}
                            Label4="#ffffff"
                            popup='true'

                          />
                          <br></br>

                            <Box
                            className="LargeBox"
                            Label1='Q. 채리우님과 같이 먹고 싶은 음식은?'
                            Label2={  <div>
                              같이 킹크랩? 먹고 싶어! 리우 최애 음식이잖아
                              <br></br>
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing: '-0.06rem' }}>
                                {`ㄴ "ㅋㅋㅋㅋㅋ 채잘알 인졍..."`}
                              </span>
                            </div>}
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new2/2.webp`}
                            Label4="#ffffff"
                            popup='true'

                            ></Box>
                            <br></br>

                          <Box
                            className="LargeBox"
                            Label1='Q. 채리우님과 같이 먹고 싶은 음식은?'
                            Label2={  <div>
                              눈 오는 날 우리 학교 앞에서 붕어빵 먹기
                              <br></br>
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing: '-0.06rem' }}>
                                {`ㄴ "ㅋㅋㅋ 팥붕 킬러 유강준이다!! ☃️"`}
                              </span>
                            </div>}
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new2/1.webp`}
                            Label4="#ffffff"
                            popup='true'
                            ></Box>
                            <br></br>
                          <Box
                            className="LargeBox"
                            Label1={`익명의 15세 남성`} 
                            Label2={  <div>
                            나랑 얼굴만 아는 사이인 15세 남성의 축구를 좋아하는 분께서 디엠하고 싶어해요!                              <br></br>
                              <span style={{ fontWeight: 600, whiteSpace: 'pre-wrap', letterSpacing: '-0.06rem' }}>
                                {`ㄴ "앗 ㅋㅋ..! 어떤 선배님인지 알 것 같은데... 먼저 디엠할게요"`}
                              </span>
                            </div>}
                            ImgUrl={`https://resource.hoxy.one/Box_emoji/new/1.webp`}
                            Label4="#ffffff"
                            popup='true'
                          />
                      </div>
  
             
  
                  <div className="ButtonBox">
                <Link to='/start' >
                  <PrimaryButton  Text={`나도, 채리우님처럼 친해지고 싶은 사람 알기 💌`} animate='highlight'/>
                </Link>
                  </div>
  
              </div>
  
              <Footer></Footer>
            </div>
  
          </div>

  
          {showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
          onClick={handlePopupClose}
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
            onClick={(e) => e.stopPropagation()}
          >
            <h2 style={{ fontSize: '22px' }}>
              지금은 체험모드에요<br></br>내 프로필을 만들어볼까요?
            </h2>
            <p>
              친해지고 싶은 사람 힌트 얻고, 1일 1문답까지
              <br />
              이 모든 것이 내 프로필을 만들면 가능해요 🍀
            </p>
            
            <div className="button-container">
            <motion.button
                className="delete-button"
                style={{ fontSize: '14px', fontWeight: '600' }}
                onClick={handlePopupClose}
              >
                계속 구경하기
              </motion.button>
              <motion.button
                className="retry-button"
                style={{ fontSize: '14px', fontWeight: '600' }}
                onClick={handlePopupButtonClick}
              >
                내 프로필 만들기
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}

      </div>
    </div>
  );
}

export default Preview;  