import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import OnboardingMiddele2 from '../../../NewComponent/OnboardingMiddle2';
import cupid from '../../../NewComponent/assets/angel-girl 2.webp';


function SoftIntro() {
  const navigate = useNavigate();
  const VisitprofileName = localStorage.getItem('VisitprofileName');
  const token = localStorage.getItem('token');
  

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/ToWhere'); // Replace '/MessageMain' with the actual path to MessageMain.js
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer); // Clear the timer on component unmount
  
  }, [navigate]);



  




  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className="main_content">
        <div className="Content-Casting">
          <div id="Content1">
            <OnboardingMiddele2
              middle_compo="middle_component"
              img={cupid}
              Text1={`${VisitprofileName}님과 친해지고 싶으셨군요`}
              Text2="HOXY가 친해질 수 있게 도와드릴게요"
              Text3="친해지고 싶은 마음들을 HOXY로 표현해보세요"
            />
            <br />
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default SoftIntro;
