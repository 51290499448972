//CastingInput 컴포넌트는 CastinP (케스팅 페이지)에 사용되어요!
//'이름'이 label 태그로 입력되면 이름을 적는 칸이, 다른 키워드를 label 태그의 {what}에 입력되면 다른 키워드에 대한 입력이 됩니다!

import React from "react";

export default function CastingInput({what, value, onChange, num, type}) {

  return (

        <div className="CastingInputBox" id={num}> 
            <div style={{flexDirection:'column'}} className="flexbox"> 
            <div className="input-group">
                <div className="input-group-group" style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                  <input
                      spellcheck="false"
                      type={type}
                      id="name" 
                      required 
                      value={value}
                      onChange={onChange}
                      style={{ fontFamily:'Pretendard', textDecoration:'none', color:'#000', fontWeight:'600',
                        textAlign:'left', padding:'0px',
                      border:'none', outline:'none', marginTop:'20px'}}
                      />
                  <label style={{    pointerEvents: 'none', border: 'none'}} htmlFor='name' >{what}</label>
                </div>

                      <div className="bar"></div>
              </div>
            </div>
        </div>

  );
}
