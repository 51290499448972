import React, {useState} from 'react';
import { motion } from 'framer-motion';

export default function SecondaryButton({ Text }) {

  
  return (
    <div className='buttonContainerStyle' >
      <motion.button
        className='buttonStyle1'
        whileTap={{ scale: 0.95 }}
      >
        <span className='buttonTextStyle'>{Text}</span>
      </motion.button>
    </div>
  );
}
