import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import './Box.css';
import AnswerSelec from '../component/AnswerSelec';
import { motion, useAnimation  } from 'framer-motion';

export const Box = ({ Label1, Label2, ImgUrl, Label4, className, shouldRenderButtons, requestDbNumber, popup }) => {
  const BoxClassName = `${className}`;
  const [isAnsVisible, setIsAnsVisible] = useState(false);
  const navigate = useNavigate();  // Use useNavigate hook
  const [showPopup, setShowPopup] = useState(false);


  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsAnsVisible(!isAnsVisible);
  };

  const handleAnswerClose = (e) => {
    e.stopPropagation();
    // Do nothing
  };

  const handlePopupButtonClick = () => {
    navigate('/start');
  };



  const handlePopupClose = () => {
    setShowPopup(false);
  };


  const handleSlideClick = () => {
    if (shouldRenderButtons) {
      navigate("/RequestCard");
    }

    else if (popup === 'true') {
      setShowPopup(true);
    }
  };

  return (


    
    <div onClick ={(e) => {handleAnswerClose(e); handleSlideClick(e); }} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
      <div
        className={BoxClassName + ' block'}
        style={{ position: 'relative', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
      >
        <div className={BoxClassName}>
          <div className={BoxClassName + 'img'}>
            <img src={ImgUrl} width={'25px'} height={'25px'} />
          </div>
          <div className={BoxClassName + 'Who'}>{Label1}</div>
          <div style={{display:'flex', float:'left'}}>
            <div className={BoxClassName + 'Content'}>{Label2}</div>
            {shouldRenderButtons && (
            <div className={BoxClassName + 'navi'}>
                <button
                  style={{
                    width: '53px',
                    height : '33px',
                    background: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    zIndex : '90'
                  }}
                  onClick={handleButtonClick}
                >
                       <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25226 11.6966C5.25226 11.2288 5.43406 10.7609 5.77745 10.3947C6.11816 10.0531 6.57936 9.86133 7.06013 9.86133C7.54091 9.86133 8.00211 10.0531 8.34282 10.3947L16.1601 18.2673L23.9572 10.3947C24.2979 10.0531 24.7591 9.86133 25.2399 9.86133C25.7207 9.86133 26.1819 10.0531 26.5226 10.3947C26.8618 10.7378 27.0522 11.2023 27.0522 11.6865C27.0522 12.1706 26.8618 12.6351 26.5226 12.9782L17.4327 22.1323C17.092 22.4739 16.6308 22.6657 16.15 22.6657C15.6692 22.6657 15.208 22.4739 14.8673 22.1323L5.77745 12.9782C5.43406 12.6324 5.25226 12.1645 5.25226 11.6966Z" fill="white"/>
                      </svg>

                </button>
            
            </div>
              )}
          </div>

          
        </div>
      </div>
      {isAnsVisible  && (
        <div className={BoxClassName + '-box'}>
          <div>
            <AnswerSelec requestDbNumber={requestDbNumber}/>
          </div>
        </div>
      )}

{showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
          onClick={handlePopupClose}
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
            onClick={(e) => e.stopPropagation()}
          >
            <h2 style={{ fontSize: '22px' }}>
              지금은 체험모드에요<br></br>내 프로필을 만들어볼까요?
            </h2>
            <p>
              친해지고 싶은 사람 힌트 얻고, 1일 1문답까지
              <br />
              이 모든 것이 내 프로필을 만들면 가능해요 🍀
            </p>
            
            <div className="button-container">
            <motion.button
                className="delete-button"
                style={{ fontSize: '14px', fontWeight: '600' }}
                onClick={handlePopupClose}
              >
                계속 구경하기
              </motion.button>
              <motion.button
                className="retry-button"
                style={{ fontSize: '14px', fontWeight: '600' }}
                onClick={handlePopupButtonClick}
              >
                내 프로필 만들기
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>

    
  );
};

Box.propTypes = {
  Label1: PropTypes.string.isRequired,
  Label2: PropTypes.string.isRequired,
  ImgUrl: PropTypes.string.isRequired,
  Label4: PropTypes.string.isRequired,
  className: PropTypes.string,
  shouldRenderButtons: PropTypes.bool,
};
