// Tab.js
import React from 'react';
import AlarmBox from '../../../NewComponent/AlarmBox';

export const Alarm = () => {

  return (

    <div>
        <AlarmBox Text1={'최형빈님의 1일 1문답에 답해볼까요? 🔔'} Text2={'지금, 내가 구독한 최형빈님의 1일 1문답에 가장 먼저 답할 수 있어요 '} />
        <div style={{marginTop:'34px'}}></div>
        <AlarmBox Text1={'최형빈님의 1일 1문답에 답해볼까요? 🔔'} Text2={'지금, 내가 구독한 최형빈님의 1일 1문답에 가장 먼저 답할 수 있어요 '} />
        <div style={{marginTop:'34px'}}></div>
        <AlarmBox Text1={'최형빈님의 1일 1문답에 답해볼까요? 🔔'} Text2={'지금, 내가 구독한 최형빈님의 1일 1문답에 가장 먼저 답할 수 있어요 '} />
    </div>
  );
};
