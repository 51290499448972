import jokercard from "../../assets/img/joker_card.webp"
import rightarrow from "../../NewComponent/assets/arrow-right.webp"
import PrimaryButtonSmall from '../../NewComponent/PrimaryButtonSmall';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import React, { useState, useEffect} from 'react';
import jwt_decode from 'jsonwebtoken/decode';
import axios from 'axios';

export default function Content({needCard, needReasonText, needReason}) {

  const [userData, setUserData] = useState({});
  const [tokenUserID, setTokenUserID] = useState("");
  const [tokenUserNo, setTokenUserNo] = useState("");
  const [isJokerMode, setIsJokerMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
  
        if (!token) {
          console.log('로그인되어있지 않음');
          return;
        }
  
        const decodedToken = jwt_decode(token);
        const { user_id, user_no } = decodedToken;
        setTokenUserID(user_id);
        setTokenUserNo(user_no);
  
        // 첫 번째 API 요청: 사용자 정보 가져오기
        const userInfoResponse = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id, user_token: token }),
        });
  
        if (!userInfoResponse.ok) {
          throw new Error('Network response was not ok for user info');
        }
  
        const userInfoData = await userInfoResponse.json();
        setUserData({
          user_no: userInfoData.user_no,
          user_name: userInfoData.user_name,
          joker_count: userInfoData.joker_count,
        });
  
        // 두 번째 API 요청: 조커 모드 활성화 여부 확인
        const jokerModeResponse = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/jokermode/verification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id, user_no }),
        });
        
        if (!jokerModeResponse.ok) {
          throw new Error('Network response was not ok for joker mode verification');
        }
        
        const jokerModeData = await jokerModeResponse.json();
        if (jokerModeData.message === "Joker mode is active") {
          console.log('Joker mode is active');
          setIsJokerMode(true);
        } 
        else if (jokerModeData.message === "Joker mode is not active") {
          console.log('Joker mode is not active');
          setIsJokerMode(false);

        }

        else {
          alert('내부 서버에 문제가 발생했어요. 나중에 시도해주세요')
        }
        
  
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const buttonContainerStyle = {
    display:'flex',
    position: 'fixed',
    bottom: '28px',
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const buttonStyle = {
    display: 'flex',
    width: '100px',
    padding: '14px 80px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#FF5C00',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  const buttonStyle2 = {
    display: 'flex',
    width: '100px',
    padding: '14px 80px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    background: '#fff',
    border: '2px solid var(--Hoxy-Orange, #FF5C00)',    
    outline: 'none',
    cursor: 'pointer',
  };

  const buttonTextStyle2 = {
    color: 'var(--Hoxy-Orange, #FF5C00)',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.68px',
    whiteSpace: 'nowrap', // 텍스트가 한 줄에 다 들어가도록 설정
  };

  const handleButtonClick = () => {
    window.location.reload();

  };

  const handleButtonJokerClick = async () => {
    try {
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/jokercard/out', {
        user_no: tokenUserNo,
        joker_out_count: needCard,
        auth_key: 'hoxy-jna12-xuea',
        joker_out_reason: needReason,
      });
      // API 요청이 성공하면 이곳에서 응답 데이터를 처리할 수 있습니다.
      alert('조커카드 사용에 성공했어요!');
      window.location.reload();  
      // 응답 데이터에서 서버에서 내려주는 메시지를 받아올 수 있습니다.
      const serverMessage = response.data.message;
      console.log('서버에서 받은 메시지:', serverMessage);
  
    } catch (error) {
      // API 요청이 실패하면 에러를 처리할 수 있습니다.
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Not enough joker cards") {
          alert('보유한 조커카드 개수가 부족해, 조커카드 사용에 실패했어요');
        } else {
          alert("서버 오류가 발생했어요. 고객센터로 연락주세요");
        }
      } else {
        alert("API 요청 중에 오류가 발생했어요");
      }
    }
  };
  
  

  return(
 
<div style={{width:'100%', height:'100%'}}>

      <div 
      style={{
        color: "#000",
        fontFamily: "Pretendard",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "-1.1px",
        marginRight:'150px',
      }}>
        조커카드가 필요해요
      </div>
 
      <div style={{ marginTop:'20px', marginLeft:'35px'}}>
        <div style={{display:'flex'}}>
        <div style={{
           width: '65px',
           height: '65px',
           flexShrink: '0',
           borderRadius: '15px',
           background: '#F2F3F5'
           
        }}>
          <img style={{width:'40px', marginTop:'10px'}} src={jokercard}></img>
        </div>

        <div style={{textAlign:'left', marginLeft:'15px', marginTop:'8px'}}>
          <div
           
           style={{
            color: '#8B95A1',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '-0.88px',

          }}
          
          >{needReasonText}</div>
          <div    style={{
            color: '#505967',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '-0.88px',
            marginTop:'10px'

          }}>필요한 조커카드 수 <b>{needCard}개</b></div>
          <div    style={{
            color: '#505967',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '-0.88px',

          }}>현재 나의 조커카드 개수 <b>{isJokerMode
            ? "무제한 조커카드 🃏"
            : `${userData.joker_count}개`
          }</b></div>
        </div>
      </div>
      </div>

      <div style={{marginTop:'30px'}}>
      <Link to='/jokercard'>
        <div style={{display:'flex', justifyContent:'center'}}>
          <div style={{marginRight:'140px',
          color: '#353D4A',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          letterSpacing: '-0.65px'
        }}>내가 가진 조커카드 늘리러 가기</div>
          <div ><img src={rightarrow} width={'16px'}></img></div>
        </div>
        </Link>

    

      </div>

      <div style={buttonContainerStyle} >
      <motion.button style={buttonStyle2}  whileTap={{ scale: 0.95 }}  onClick={handleButtonClick}>
        <span style={buttonTextStyle2}>닫기</span>
      </motion.button>
  
    <div style={{ marginLeft: '10px' }}></div>
  
      <motion.button style={buttonStyle} whileTap={{ scale: 0.95 }}     onClick={handleButtonJokerClick}        >
        <span style={buttonTextStyle}>사용하기</span>
      </motion.button>
  </div>
    
    </div>
  )
}