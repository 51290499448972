import React, {useState } from 'react';
import { Tittle } from '../stories/Tittle';
import { isMobilePhone } from '@toss/validators';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jsonwebtoken/decode';
import { LargeButton } from '../stories/LargeButton';
import { Link } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';


function Login() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false); // 변수 이름 수정
  const [errorMessage, setErrorMessage] = useState('');


  const handleSvg1Click = () => {
    navigate("/Register");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/login', {
        user_phonenumber: phoneNumber, 
        user_password: userPassword
      });

      const { token } = response.data;

      localStorage.setItem('token', token);
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.user_id;
      const userNo = decodedToken.user_no;

      localStorage.setItem('user_id', userId);


        {/* await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/login', {
              user_id: userId,
              user_no: userNo,
              login_page: window.location.href
            }); */}

      amplitude.init("4cb7df50686c6cb578ab1fedb0e182ab", userId); 

      navigate(`/${userId}`);
    } catch (error) {
      console.error('로그인 실패:', error);
      if (error.response && error.response.status === 401) {
        setErrorMessage('없는 계정이거나, 아이디 또는 비밀번호가 잘못되었습니다.');
      } else {
        setErrorMessage('서버 오류가 발생했습니다. 나중에 다시 시도해주세요.');
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value.replace(/[^0-9]/g, '');
    const formattedWithHyphens = formattedPhoneNumber.replace(
      /^([0-9]{3})([0-9]{4})([0-9]{4})$/,
      '$1-$2-$3'
    );
    setPhoneNumber(formattedWithHyphens);
    setIsPhoneNumberValid(isMobilePhone(formattedWithHyphens));
  };

  const handlePasswordChange = (e) => {
    setUserPassword(e.target.value);
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
      <div>

      <a href="javascript:window.history.back();">
      <div class="top_bar">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left top_icon">
        <polyline points="15 18 9 12 15 6"></polyline></svg>        
      </div>
      </a>
        <div style={{display:'flex',justifyContent:'center', marginTop:'20px'}}>
          <img src='https://resource.hoxy.one/logos/hoxy_logo_2.svg' width={'65px'} alt="logo"></img>
        </div>          
        
        
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '55px' }}>
            <Tittle className="normal" label1='"누가 나랑 친해지고 싶어할까?"' label2="간단한 정보를 입력하고, 로그인 할 수 있어요" />
          </div>

          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
              <div style={{ marginTop: '16px' }}></div>
              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className="Nameinput"
                  id="user_phonenumber"
                  name="user_phonenumber"
                  placeholder='전화번호'
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
              </div>

              <div style={{ marginTop: '16px' }}></div>
              <div style={{ display: 'flex',  justifyContent: 'center', textAlign: 'center' }}>
                <input
                  className="Nameinput"
                  type="password"
                  id="user_password"
                  name="user_password"
                  placeholder='비밀번호'
                  value={userPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <br></br>

              <div style={{marginTop:'15px'}} onClick={handleSvg1Click}>
                <span style={{color:'#000000', textDecoration:'underline'}}>계정이 아직 없어요 🙌</span>
              </div>
              <div style={{marginTop:'5px'}}>
              <Link to="https://hoxy.channel.io/">
                <span style={{color:'#C9C9C9', textDecoration:'underline', }}>로그인에 도움이 필요하신가요?</span>
              </Link>
            </div>


           
              

              <div style={{ marginTop: '15px' }}></div>
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

              <div style={{ marginTop: '55px', marginBottom:'35px' }}>
                <LargeButton type="submit" className='primary' label='로그인하고,' label2='내 프로필 보기'></LargeButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
