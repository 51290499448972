import React, { useEffect } from "react";

const GoogleAdsense = ({
  className = "adsbygoogle",
  client = "",
  slot = "",
  format = "",
  responsive = "",
  layoutKey = ""
}) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      console.log("Advertise is pushed");
    } catch (e) {
      if (process.env.NODE_ENV !== "production")
        console.error("AdvertiseError", e);
    }
  }, []);



  //production이 아닌 경우 대체 컴포넌트 표시
  if (process.env.NODE_ENV !== "production")
    return (
      <div
        style={{
          display: "inline-block",
          width: "300px", // Limit the max width to prevent stretching
          height: "250px", // Limit the max height to prevent stretching
          marginTop :"10px",
          background: "#e9e9e9",
          borderRadius:"30px",
          color: "black",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
          padding: "16px"
        }}
      >
        구글 애드센스 광고 표시 영역
      </div>
    );

  return (
  

      <ins
        className={className}
        style={{
          display: "inline-block",
          width: "300px", // Limit the max width to prevent stretching
          height: "250px", // Limit the max height to prevent stretching
          marginTop :"10px",
          background: "#FBF6F6",
          borderRadius:"30px",


        }}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
        data-ad-layout-key={layoutKey}
        data-auto-ad-size="false"
      />


  );
};

export default GoogleAdsense;
