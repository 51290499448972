import React from "react";

export default function HintBox({
  showHint,
  hintImg,
  hintText1,
  hintText2,
  handleHintButtonClick
}) {
  return (
    <div className="hint_box" style={{ marginTop:'20px', backgroundColor: showHint ? '#FBF5F5' : '#F2F3F5' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: '20px' }}>
          <img src={hintImg} className='hint_img' alt="Hint Image" />
        </div>
        <div style={{ marginLeft: '15px', textAlign: 'left' }}>
          <p className='hint_p1'>{hintText1}</p>
          <p style={{ fontSize: '14px', fontWeight: '700', color: '#000', marginTop: '-12px' }} id="hintText1">{hintText2}</p>
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '20px', marginTop: '5px' }}>
          {showHint &&
            <svg
              className='hint_button'
              onClick={handleHintButtonClick}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="#FF5C00" />
              <path
                d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                fill="white"
              />
            </svg>
          }
        </div>
      </div>
    </div>
  );
}
