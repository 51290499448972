import React from "react";

export default function KakaoMovingBox({img1,img2,img3,id}) {

    // id 값에 따라 클래스 변수 설정
  const MovingClass = id === "left" ? "Moving_Left" : "Moving_right";
  
  

  return (
 <div className={MovingClass} id = {id} >
    <div className="MovingBox" style={{ marginLeft: '22px', marginTop: '10px', overflowX:'hidden'}}><img style={{ marginTop: '20px', width:'60px'}} src={img1} alt="img1"></img></div>
    <div className="MovingBox" style={{ marginLeft: '22px', marginTop: '10px', overflowX:'hidden' }}><img style={{ marginTop: '20px', width:'60px' }} src={img2} alt="img2"></img></div>
    <div className="MovingBox" style={{ marginLeft: '22px', marginTop: '10px', overflowX:'hidden' }}><img style={{ marginTop: '20px', width:'60px' }} src={img3} alt="img3"></img></div>
  </div>
  );
}
