import { useEffect, useState } from 'react';
import { Box } from '../stories/Box';
import { Tittle } from '../stories/Tittle';
import jwt_decode from 'jsonwebtoken/decode';
import { Link } from 'react-router-dom';
import Header from '../NewComponent/header';

function Requested() {
  const [messages, setMessages] = useState(null);
  const [user_name, setUserName] = useState("");
  const [user_id, setUserId] = useState("");
  const [visitProfileID, setVisitProfileID] = useState("");
  const [tokenUserID, setTokenUserID] = useState("");
  const abortController = new AbortController();


  const fetchMessages = () => {
    const requestOptions = {
      method: 'POST', // POST 요청 설정
      headers: {
        'Content-Type': 'application/json', // JSON 형식의 데이터를 전송한다고 설정
      },
      body: JSON.stringify({ user_id }), // user_id를 JSON 형식으로 전송
    };
  
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request', requestOptions)
      .then(response => response.json())
      .then(data => {
        setMessages(data);
        console.log("스크롤 초기화 완료 - 데이터 기준");
        window.scrollTo(0, 0);
      })
      .catch(error => console.error('Error fetching messages:', error));
  };
  
  const fetchMessagesNone = () => {
    const requestOptions = {
      method: 'POST', // POST 요청 설정
      headers: {
        'Content-Type': 'application/json', // JSON 형식의 데이터를 전송한다고 설정
      },
      body: JSON.stringify({ user_id }), // user_id를 JSON 형식으로 전송
    };
  
    fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/msg/request/none', requestOptions)
      .then(response => response.json())
      .then(data => {
        setMessages(data);
        console.log("스크롤 초기화 완료 - 데이터 기준");
        window.scrollTo(0, 0);
      })
      .catch(error => console.error('Error fetching messages:', error));
  };
  

  useEffect(() => {
    const visitProfileName = localStorage.getItem('VisitprofileName');
    const visitProfileID = localStorage.getItem('VisitprofileID');
    setUserName(visitProfileName);
    setUserId(visitProfileID);
    setVisitProfileID(visitProfileID);

    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        setTokenUserID(tokenUserID);
      } catch (error) {
        console.log('토큰 디코딩 에러:', error);
      }
    }
    else {
      setTokenUserID('none');
    }
   
  }, []);

  useEffect(() => {
    if ( tokenUserID === "none" && messages === null) {
      fetchMessagesNone();
    }
  }, [visitProfileID, messages]);
  

  useEffect(() => {
    if (visitProfileID === tokenUserID && messages === null) {
      fetchMessages();
    }
  }, [visitProfileID, tokenUserID, messages]);

  useEffect(() => {
    if (visitProfileID != tokenUserID && messages === null) {
      fetchMessagesNone();
    }
  }, [visitProfileID, tokenUserID, messages]);


  if (messages === null) {
    return;
   }
   
   const visitProfileId = localStorage.getItem('VisitprofileID');


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
        <br></br>
        <Header Link={`/${visitProfileId}?explore=yes`}/>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-55px' }}>
            <Tittle className="check" label1="요청된 메시지" label2={`지금, ${user_name}님께 요청한 메시지를 확인해보세요`} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
            {messages.length === 0 ? (
            <div className='gray_text'>아직 {user_name}님에게 요청된 메시지가 없어요</div>
            ) : (
              messages.map((message, index) => (
                <div  key={index} style={{ marginBottom: '15px' }}>
                  <Box
                    className="LargeBox"
                    Label1={`${message.request_age}세 ${message.request_gender}`}
                    Label2={(
                      <>
                        나랑 {message.request_relationship ? message.request_relationship : '***'} 사이인 {message.request_age}세 {message.request_gender}의 {message.request_keyword ? message.request_keyword : '***'} 분께서 {message.request_want ? message.request_want : '***'} 해요!
                      </>
                    )}
                    ImgUrl={`https://resource.hoxy.one/Box_emoji/new/${(index % 20) + 1}.webp`}
                    Label4="#ffffff"
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Requested;
