import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { Head_old } from '../stories/Head_old';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import jwt_decode from 'jsonwebtoken/decode';
import Link_img1 from '../assets/img/happy-friends-jumping.webp'
import Link_img2 from '../assets/img/hot-air-ballon.webp'
import message2 from'../assets/img/message2.webp'
import shoes from'../assets/img/shoes.webp'
import study from'../assets/img/study1.webp'
import art from'../assets/img/art.webp'
import maratang from'../assets/img/maratang.webp'
import basketball from'../assets/img/basketball.webp'
import timeline from'../assets/img/timeline.webp'
import open_door from'../assets/img/open-door.webp'
import { Link } from 'react-router-dom';
import S_Linkmaker from '../NewComponent/Skeletons/S_Linkmaker';
import Intelligence from '../NewComponent/Intelligence';



function Linkmaker() {
  const [MyUrl, setMyUrl] = useState('');
  const [copiedUrl, setCopiedUrl] = useState(''); 
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState(''); 
  const [todayVisitCount, setTodayVisitCount] = useState(0);
  const [totalVisitCount, setTotalVisitCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [schoolCeo, setschoolCeo] = useState('');


  useEffect(() => {
    const token = localStorage.getItem('token');
    const school_ceo = localStorage.getItem('school_ceo')
    setschoolCeo(school_ceo)

    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);


        setMyUrl(`https://hoxy.one/${tokenUserID}`);


        axios
        .post('https://backend-hoxy-one-api.hoxy.one:4004/api/profile_hit_counts', {
          profile_user_no: tokenUserNo,
        })
        .then((response) => {
          setTodayVisitCount(response.data.today_hit_count);
          setTotalVisitCount(response.data.total_hit_count);
        })
        .catch((error) => {
          console.error('API 호출 오류:', error);
        });
      

      } catch (error) {
        setTokenUserID("anonymous");
        console.log('토큰 디코딩 에러:', error);
      }
    } else {
        console.log("로그인되어있지 않음");
        setTokenUserID("anonymous");

}
}, []);


 
useEffect(() => {
  const fetchData = async () => {
    try {
      // Simulate an API call
      // Replace this with your actual data fetching logic
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Step 2: Set isLoading to false after data is loaded
      setIsLoading(false);
    } catch (error) {
      console.error('API Error:', error); // Use console.error for errors
    }
  };

  fetchData();
}, []); // Make sure to pass an empty dependency array if you only want to run the effect once





  const handleCopyToClipboard = () => {
    setCopiedUrl(MyUrl); 

    axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/logging/referral', {
      user_id: tokenUserID,
      user_no:tokenUserNo,
      referral_user_id: MyUrl,
      referral_page: window.location.href
    });

    alert('복사되었어요. 이제 이 링크를 스토리나, Bio에 공유해보세요 :)');
  };

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>

      {schoolCeo ? (
      <Intelligence type={'Admin_share'}></Intelligence>
    ) : null}

      {isLoading ? ( // Step 3: Conditional rendering
          <S_Linkmaker /> // Show the skeleton component while loading
        ) : ( 

        <div>

          <div style={{ height: '30px' }}></div>
          <div style={{ position: 'relative' }}>
            <Head_old className="ImoHeadWhite"></Head_old>
          </div>

          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'10px', marginBottom:'0px'}}>
              
          <div style={{marginRight:'100px', marginTop:'30px'}}>
            <h3 style={{fontSize:'18px', textAlign:'left'}}>친해지고 싶어, 내 프로필에 방문한 사람</h3>
            <div style={{marginTop:'21'}}></div>
            <div style={{display:'flex'}}>
              <div className='VisitNumBox'>
                <div><img style={{width:'16.923px',height:'20px',marginTop:'10px'}} src={open_door}></img></div>
                <div style={{display:'flex',flexDirection:'column',textAlign:'left',marginLeft:'5px',marginTop:'5px'}}>
                  <div>오늘 방문자</div>
                  <div style={{fontSize:'13px',fontWeight:'700'}}>{todayVisitCount}명</div>
                </div>
              </div>
              
              <div style={{marginLeft:'8px'}}></div>
              
              <div className='VisitNumBox'>
                <div><img style={{width:'20px',height:'20px',marginTop:'10px'}} src={timeline}></img></div>
                <div style={{display:'flex',flexDirection:'column',textAlign:'left',marginLeft:'5px',marginTop:'5px'}}>
                  <div>누적 방문자</div>
                  <div style={{fontSize:'13px',fontWeight:'700'}}>{totalVisitCount}명</div>
                </div>
              </div>
            </div>
          </div>
       


          <div style={{marginTop: '30px' }}>
          <h3 style={{fontSize:'18px', textAlign:'left'}}>링크로 Bio나 스토리에 공유하기</h3>

            <div className="LinkBox">{MyUrl}</div>
            <div style={{marginTop: '15px'}}>
            <CopyToClipboard className="Toram" text={MyUrl} onCopy={handleCopyToClipboard}>
              <text className="underlineT" style={{ cursor: 'pointer', fontWeight:'400', textDecoration:'underline', fontSize:'14px'}}>
                링크 복사하기
              </text>
            </CopyToClipboard>
          </div>
          </div>



          <div style={{marginTop: '20px' }}>
          <h3 style={{fontSize:'18px', textAlign:'left'}}>인스타그램 스토리로 공유하기</h3>

          <Link to='/ImgShare?type=친해지고싶은사람'>
          <div className='hint_box' style={{ backgroundColor:'#FBF5F5'}}>

          <div style={{display:'flex'}}>
          <div style={{
              marginLeft:'20px'
            }}><img src={Link_img1} className='hint_img'></img></div>
            
            <div style={{marginLeft:'15px', textAlign:'left'}}>
            <p className='hint_p1' style={{ color:'#000000', fontWeight:'300'}}>스토리로 공유해</p>
            <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px', color:'#000000'}} >더 많이 친해지고 싶은 사람을 알 수 있어요</p>
 

            </div>
            
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
            <svg
                  className='hint_button'
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                  <path
                    d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                    fill="white"
                  />
                </svg>
              </div>
              </div>
          </div> 
          </Link>


          <Link to='/ImgShare?type=첫인상투표'>
          <div className='hint_box' style={{ backgroundColor:'#FBF5F5', marginTop:'15px'}}>
              <div style={{display:'flex'}}>
              <div style={{
                  marginLeft:'20px'
                }}><img src={Link_img2} className='hint_img'></img></div>
                
                <div style={{marginLeft:'15px', textAlign:'left'}}>
                <p className='hint_p1' style={{ color:'#000000', fontWeight:'300'}}>스토리로 공유해</p>
                <p style={{fontSize:'14px', fontWeight:'700', color:'#000', marginTop:'-12px', color:'#000000'}} >더 많은 첫인상 투표를 받을 수 있어요</p>


                </div>
                
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight:'20px', marginTop:'5px' }}>
                <svg
                      className='hint_button'
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="15" cy="15" r="15" fill="#FF5C00" />
                      <path
                        d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929L15.3431 7.92893C14.9526 7.53841 14.3195 7.53841 13.9289 7.92893C13.5384 8.31946 13.5384 8.95262 13.9289 9.34315L19.5858 15L13.9289 20.6569C13.5384 21.0474 13.5384 21.6805 13.9289 22.0711C14.3195 22.4616 14.9526 22.4616 15.3431 22.0711L21.7071 15.7071ZM8 16H21V14H8V16Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  </div>
                     
              </div>
              </Link>
         



          </div>   


          <div>
          <div style={{marginTop:'20px'}}></div> 

 <div className='LMBoxLine1'>
        <Link to='/ImgShare?type=디엠'>
                <div className='LMBox1'>
                  <div><img style={{width:'90px', height:'90px'}} src={message2}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>디엠할 친구 찾기</div></div>
                </div>
            </Link>

                <div style={{marginLeft:'25px'}}></div>
                <Link to='/ImgShare?type=공부'>
                <div className='LMBox2'>
                  <div><img style={{width:'90px', height:'90px'}} src={study}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>공부할 친구 찾기</div></div>
                </div>
                </Link>
          </div> 

          <div className='LMBoxLine2'>
                <Link to='/ImgShare?type=신발'>
                <div className='LMBox3'>
                  <div><img style={{width:'90px', height:'90px'}} src={shoes}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>신발 쇼핑 친구 찾기</div></div>
                </div>
                </Link>

                <div style={{marginLeft:'25px'}}></div>
                <Link to='/ImgShare?type=미술관'>
                <div className='LMBox4'>
                  <div><img style={{width:'90px', height:'90px'}} src={art}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>미술관 갈 친구 찾기</div></div>
                </div>
                </Link>
          </div>

          <div className='LMBoxLine3'>
                <Link to='/ImgShare?type=마라탕'>
                <div className='LMBox5'>
                  <div><img style={{width:'90px', height:'90px'}} src={maratang}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>마라탕 친구 찾기</div></div>
                </div>
                </Link>

                <div style={{marginLeft:'25px'}}></div>
                <Link to='/ImgShare?type=농구'>
                <div className='LMBox6'>
                  <div><img style={{width:'90px', height:'90px'}} src={basketball}></img></div>
                  <div className='LMBoxText'><div style={{marginTop:'8px'}}>농구할 친구 찾기</div></div>
                </div>
                </Link>
                
              </div>
              </div>
          </div>   
        </div> 

        )}
      </div>
    </div>
 
  );
}

export default Linkmaker;
