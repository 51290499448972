import React from "react";

export default function AlarmBox({Text1,Text2} ) {
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
        <div style={{width:'340px', display:'flex',flexDirection:'column'}} >
            <div 
            style={{
                color: "#6D7987",
                fontFamily: "Pretendard",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px",
                letterSpacing: "-0.56px",

                display:"flex",
                justifyContent:'flex-start'
            }}
            >
                <div>{Text1}</div>
                <div style={{marginLeft:'50px'}}>4시간 전</div>
            </div>
            <div 
            style={{
                color: "#000",
                fontFamily: "Pretendard",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "22px",
                letterSpacing: "-0.64px",
                
                display:'flex',
                justifyContent:'flex-start',

                textAlign:'left',

                width:'280px',
                marginTop:'10px'
            }}
            >{Text2}</div>
        </div>
    </div>
  );
}
