import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

export default function SkeletonLoader() {
  const [viewBoxWidth, setViewBoxWidth] = useState(390); // 초기 값 설정

  useEffect(() => {
    // 화면 크기가 변경될 때마다 호출되는 함수
    const handleResize = () => {
      // 원하는 로직에 따라 viewBoxWidth 값을 계산
      const newViewBoxWidth = window.innerWidth > 600 ? 600 : 390;
      setViewBoxWidth(newViewBoxWidth);
    };

    // 처음 컴포넌트가 마운트될 때 resize 이벤트에 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열은 컴포넌트가 처음 마운트될 때만 실행하도록 합니다.

  // viewBoxWidth에 대한 비율 계산
  const viewBoxRatio = viewBoxWidth / 390;

  return (
    <ContentLoader viewBox={`0 0 ${viewBoxWidth} 860`}>

      <rect x={25 * viewBoxRatio} y={115 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={174 * viewBoxRatio} height={21 * viewBoxRatio} />
      <rect x={25 * viewBoxRatio} y={162 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={351 * viewBoxRatio} height={60 * viewBoxRatio} />
    
      <rect x={25 * viewBoxRatio} y={264 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={174 * viewBoxRatio} height={18 * viewBoxRatio} />
      <rect x={300 * viewBoxRatio} y={264 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={72 * viewBoxRatio} height={18 * viewBoxRatio} />
     
      <rect x={17 * viewBoxRatio} y={297 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={17 * viewBoxRatio} y={352 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={17 * viewBoxRatio} y={407 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={17 * viewBoxRatio} y={462 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={17 * viewBoxRatio} y={517 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
     
      <rect x={25 * viewBoxRatio} y={598 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={174 * viewBoxRatio} height={18 * viewBoxRatio} />
      <rect x={300 * viewBoxRatio} y={598 * viewBoxRatio} rx={4 * viewBoxRatio} ry={4 * viewBoxRatio} width={72 * viewBoxRatio} height={18 * viewBoxRatio} />
    
      <rect x={17 * viewBoxRatio} y={643 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
      <rect x={17 * viewBoxRatio} y={699 * viewBoxRatio} rx={8 * viewBoxRatio} ry={8 * viewBoxRatio} width={354 * viewBoxRatio} height={40 * viewBoxRatio} />
    </ContentLoader>
  );
}
