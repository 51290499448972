import React from "react";

export default function InformBox({ src,src2,iftext,howtext}) {
  
  return (



    <div className='InformBox' style={{ textAlign:'center', whiteSpace: 'nowrap', justifyContent:'center'}}>
        <div style={{display:'flex'}}> 
            <div><img src={src} style={{width:'50px', height:'50px'}}></img></div>
            <div style={{ marginLeft: '5px',  display:'flex',flexDirection:'column',textAlign:'left',}}>
              <div className="iftext">{iftext}</div>
              <div className="howtext">{howtext}</div>
            </div>
            <div style={{marginTop:'15px',marginLeft:'20px'}}><img src={src2}></img></div>
        </div>
    </div>
  );
}


