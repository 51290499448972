import React, { useState, useEffect, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import CastingInput from '../../NewComponent/CastingInput';
import OnboardingMiddele1 from '../../NewComponent/OnboardingMiddle1';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import BoyGoingToSchool from '../../NewComponent/assets/boy_going_to_school.webp'
import Instagram from '../../NewComponent/assets/Instagram.webp'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SearchResult2 } from '../../NewComponent/SearchResult2';
import { motion, AnimatePresence  } from 'framer-motion'
import { CSSTransition } from 'react-transition-group';
import MiddleCompoent from '../../NewComponent/MiddleCompoent';
import clovahands from '../../NewComponent/assets/ClovaHands.webp'
import school_img from '../../NewComponent/assets/School.webp'
import vote from '../../NewComponent/assets/Vote.webp'
import love_letter from '../../NewComponent/assets/love_letter.webp'
import Intro_Block from '../../NewComponent/Intro_Block';
import profile_example from '../../NewComponent/assets/ProfileExample.png';
import CastingSelec from '../../NewComponent/CastingSelec';
import * as amplitude from '@amplitude/analytics-browser';

function OnBoardingP_General() {
  const [currentContent, setCurrentContent] = useState(1);
  const [previousContent, setPreviousContent] = useState(1);
  const [schools, setSchools] = useState([]);

  const [tutorialStep, setTutorialStep] = useState(1)
  const [showStep, setShowStep] = useState(true);
  const [counter, setCounter] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [locationValue, setLocationValue] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [searchText, setSearchText] = useState("");
  
  const [schoolName, setSchoolName] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [schoolScCode, setschoolScCode] = useState('');
  const [schoolScName, setschoolScName] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [schoolAdress, setschoolAdress] = useState('');
  const [schoolGender, setschoolGender] = useState('');
  const [schoolWebsite, setschoolWebsite] = useState('');

  const [instagramId, setInstagramId] = useState('');
  const [SchoolGrade, setSchoolGrade] = useState('');


  const user_name = localStorage.getItem('user_name');
  const user_phonenumber = localStorage.getItem('user_phoneNumber');
  const school_name = localStorage.getItem('user_school');
  const user_age = localStorage.getItem('user_grade');
  const user_password = localStorage.getItem('user_Password');
  const user_gender = localStorage.getItem('user_gender');

  const user_kakao_email = localStorage.getItem('user_kakao_email');
  const user_kakao_year =  localStorage.getItem('user_kakao_year');
  const user_kakao_age =  localStorage.getItem('user_kakao_age');
  const user_kakao_id = localStorage.getItem('user_kakao_id');
  const user_kakao_imgurl = localStorage.getItem('user_kakao_imgurl');      

  const information_check = localStorage.getItem("information_check");
  const marketing_check = localStorage.getItem("marketing_check");

  const referral_source = localStorage.getItem("referral_source");
  const referral_content = localStorage.getItem("referral_content");
  const referral_user_id = localStorage.getItem("referral_user_id");

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();



  const handleNextClick = () => {
      if (currentContent < 4) {
          setPreviousContent(currentContent);
          setCurrentContent(currentContent + 1);
      }
  };

  const handlePreviousClick = () => {
      if (previousContent >= 1) {
          setCurrentContent(previousContent);
          setPreviousContent(currentContent - 1);
      }
  };

  const handleInputChange1 = (schoolName, SchoolCode, SchoolScCode, SchoolScName,SchoolType,SchoolAdress, SchoolGender,SchoolWebsite  ) => {
    setSchoolName(schoolName);
    setSchoolCode(SchoolCode);
    setschoolScCode(SchoolScCode);
    setschoolScName(SchoolScName);
    setSchoolType(SchoolType);
    setschoolAdress(SchoolAdress);
    setschoolGender(SchoolGender);
    setschoolWebsite(SchoolWebsite)
    handleNextClick(); // 다음 페이지로 이동하도록 호출
  };



    const handleInputChange2 = (event) => {
      const inputValue = event.target.value;
      const instagramIdRegex = /^[a-zA-Z0-9._]*$/;
      
      if (!instagramIdRegex.test(inputValue)) {
        event.target.value = ''; // 입력 값을 초기화
      } else {
        setInstagramId(inputValue);
      }
    };


    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };


  
  useEffect(() => {
    if (currentContent === 4) {
      const changeStep = () => {
        setShowStep(false);
        setTimeout(() => {
          setTutorialStep((prevStep) => (prevStep === 4 ? 1 : prevStep + 1));
          setShowStep(true);
          setCounter((prevCounter) => prevCounter + 1);
        }, 500);
      };
    const timer = setInterval(changeStep, 4000);
    return () => {
      clearInterval(timer);
    };
  }
}, [currentContent]);



  useEffect(() => {
    if (counter === 3 && currentContent === 4) {
      navigate('/letstart');
    }
  }, [counter, currentContent]);



  const sendUserData = async () => {

    try {
      const queryParams = new URLSearchParams({
        user_id: instagramId,
        user_name,
        user_phonenumber,
        school_code : schoolCode,
        school_name : schoolName,
        school_sc_code : schoolScCode,
        school_sc_name :schoolScName,
        school_type :schoolType,
        school_adress : schoolAdress,
        school_gender : schoolGender,
        school_website : schoolWebsite,
        user_age,
        user_password,
        user_gender,
        user_kakao_email,
        user_grade : SchoolGrade,
        user_kakao_year,
        user_kakao_age,
        user_kakao_id,
        user_kakao_imgurl,
        information_check,
        marketing_check,
        referral_source : referral_source,
        referral_content : referral_content,
        referral_user_id : referral_user_id

      }).toString();


      const response = await axios.post(`https://backend-hoxy-one-api.hoxy.one:4004/api/user?${queryParams}`);

      const token = response.data.token;
      localStorage.setItem('token', token);
      
      amplitude.init("4cb7df50686c6cb578ab1fedb0e182ab", instagramId); 


      handleNextClick();

    } catch (error) {
      alert('이미 가입된 전화번호/인스타그램 아이디 이거나, 문제가 발생했어요');
      navigate('/start');
    }
  };




  useEffect(() => {

    if (user_kakao_id != 'normal_signup') {
      setShowPopup(true);
    }


    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  
  useEffect(() => {
    const delay = 500; // 박스가 나타나는 지연시간 (밀리초)
    if (visibleIndex < 5) {
      const timer = setTimeout(() => {
        setVisibleIndex(visibleIndex + 1);
      }, delay);
  
      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 클리어
    }
  }, [visibleIndex]);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50, // 아래에서 위로 올라오도록 설정
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1, // 애니메이션 지속 시간
        ease: 'easeOut', // 이징 설정
      },
    },
  };


  useEffect(() => {
    const delay = 500; // Debounce delay time
    const timer = setTimeout(() => handleSearch(searchText), delay);

    return () => clearTimeout(timer);
  }, [searchText]);

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://open.neis.go.kr/hub/schoolInfo`,
        {
          params: {
            KEY: "b18268eeb3c94d5d9d053605360ce7b6", // Replace with a valid API key
            Type: "json",
            pIndex: 1,
            pSize: 10,
            SCHUL_NM: searchText,
          },
        }
      );

      const foundSchools =
        response.data &&
        response.data.schoolInfo &&
        response.data.schoolInfo[1].row
          ? response.data.schoolInfo[1].row
          : [];
      setSchools(foundSchools);
      console.log(schools)
    } catch (error) {
      console.error("An error occurred while searching for schools:", error);
    }
  };

  const handleGradeChange = (newValue) => {
    setSchoolGrade(newValue);
  
  };

  const handlePopupButtonClick = () => {
    navigate('/onboarding_phonenum');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

    
  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className='main_content'>
        <br></br>

      {(currentContent === 1 || currentContent === 2 || currentContent === 3) && (
          <a onClick={handlePreviousClick}>
            <div className="top_bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left top_icon"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          </a>
        )}
                
      <div style={{marginTop:"160px"}}></div>

      <div className='Content-Casting'>
        <div id='Content1' style={{ display: currentContent === 1 ? 'block' : 'none' }}>

           <OnboardingMiddele1  middle_compo='middle_component_25' img={BoyGoingToSchool} Text1='우리학교에서 친구를 찾아볼게요' Text2='지금, 내가 다니고 있는 학교를 알려주세요' />

           <CastingInput   Value={searchText}
          onChange={handleSearchChange}  what={'학교 이름'} />


<div style={{ position: 'absolute', top: '50%', left: '10%'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', color: '#848484' }}>
                    {schools.map((school, index) => (
                  <Link  onClick={() => handleInputChange1(school.SCHUL_NM, school.SD_SCHUL_CODE, school.ATPT_OFCDC_SC_CODE, school.ATPT_OFCDC_SC_NM, school.SCHUL_KND_SC_NM, school.ORG_RDNMA, school.COEDU_SC_NM, school.HMPG_ADRES)} key={index}  style={{marginBottom:'10px'}} >
                    <SearchResult2
                      img={school_img} // You may want to use the appropriate image for each school
                      Label1={school.SCHUL_NM} // Use the school name from the search results
                      Label2={school.SCHUL_KND_SC_NM} // Customize as needed
                      Label3={`${school.LCTN_SC_NM}에 위치하고 있는 학교에요`} // Customize as needed
                      iconType={'arrow'} // Customize as needed
                    />
                  </Link>
                ))}
                    </div>
                    <br></br>
                  </div>


        </div>


        <div id='Content2' style={{ display: currentContent === 2 ? 'block' : 'none' }}>

<OnboardingMiddele1  middle_compo='middle_component_25' img={BoyGoingToSchool} Text1='우리학교에서 친구를 찾아볼게요' Text2='나의 학년을 알려주세요' />

<CastingSelec what="학년" type="grade"  onChange={handleGradeChange} />


<AnimatePresence>
                {SchoolGrade && (
                  <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.3 }}
                  onClick={handleNextClick}
                  >
                    <PrimaryButton Text="다음" ></PrimaryButton>
                    </motion.div>
                )}
              </AnimatePresence>


      </div>



        <div id='Content3' style={{ display: currentContent === 3 ? 'block' : 'none' }}>

          <OnboardingMiddele1  onClick={containerVariants} middle_compo='middle_component_25' img={Instagram} Text1='인스타그램에서 친구를 찾아볼게요' Text2='사용중인 인스타그램 아이디를 알려주세요' />

          <CastingInput what={'@인스타 아이디'} value={instagramId} onChange={handleInputChange2} />
          
            <AnimatePresence>
                {instagramId && (
                  <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.3 }}
                  onClick={handleNextClick}
                  >
                    <div onClick={sendUserData}>
                    <PrimaryButton Text="HOXY 시작하기" ></PrimaryButton>
                    </div>
                    </motion.div>
                )}
              </AnimatePresence>
        </div>

        <div id='Content4' style={{ display: currentContent === 4 ? 'block' : 'none'}}>
            <div style={{display:'flex',justifyContent:'center'}}>
                
            <div>
              
              <CSSTransition
                in={showStep && tutorialStep === 1}
                delay= {1000}
                timeout={500}
                classNames="fade"
                unmountOnExit
                onExited={() => setShowStep(true)}
              >
                <div>
                  <MiddleCompoent Text1="회원가입에 성공했어요" Text2="HOXY를 소개해드릴게요" />
                </div>
              </CSSTransition>
          
              <CSSTransition
                in={showStep && tutorialStep === 2}
                timeout={500}
                classNames="fade"
                unmountOnExit
                onExited={() => setShowStep(true)}
              >
                <div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                    <div  isVisible={visibleIndex >= 1} style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                    <Intro_Block img={love_letter} Text1='프로필을 공유하고 속마음 편지을 받는' Text2='나랑 친해지고 싶은 친구 알기'/>
                    <Intro_Block img={vote} Text1='프로필을 공유하고 우리학교 친구들이 적어주는' Text2='나에 대한 첫인상 1일 1문답 받기'/>
                    <Intro_Block img={school_img} Text1='같은 학교이지만 인스타그램을 모른다면?' Text2='우리학교 친구들 모아보기'/>
                    <Intro_Block img={clovahands} Text1='나도 친해지고 싶은 친구가 있었다면' Text2='친해지고 싶은 마음 표현하기'/>
                    </div>
                  </div>

                  <div isVisible={visibleIndex >= 1} className='onboarding_middle_text_1' style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div> 이제 나도 HOXY에서<br></br>이 모든 것들을 즐길 수 있어요</div>
                      <div className='onboarding_middle_text_3'>이미 내 주변학교 친구들도 HOXY를 사용하고 있어요</div>
                    </div>
                  </div>
          
                </div>
              </CSSTransition>
          
              <CSSTransition
                in={showStep && tutorialStep === 3}
                timeout={500}
                classNames="fade"
                unmountOnExit
                onExited={() => setShowStep(true)}
              >



                <div style={{marginTop:'-90px'}}>
                  <div><img className='profile_example' src={profile_example}></img></div>
                  <div className='onboarding_middle_text_1' style={{display:'flex',justifyContent:'center',marginTop:'30px'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div><span style={{color:'#ff5c00'}}>내 프로필을 공유해야지만</span><br></br>이 모든 것들을 즐길 수 있어요</div>
                      <div className='onboarding_middle_text_3'>이미 내 주변학교 친구들도 HOXY를 사용하고 있어요</div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
          
            </div>
            </div>
        </div>
        
      </div>
      {showPopup && (
        <motion.div
          className="popup-container"
          initial={{ opacity: 0, scale: 0.5 }} // 초기 상태
          animate={{ opacity: 1, scale: 1 }} // 애니메이션 종료 상태
          exit={{ opacity: 0, scale: 0.5 }} // 팝업이 사라질 때 애니메이션
        >
          <motion.div
            className="popup-content"
            initial={{ opacity: 0 }} // 초기 상태
            animate={{ opacity: 1 }} // 애니메이션 종료 상태
          >
          

            <h2 style={{ fontSize: '22px' }}>
            본인의 카카오계정의,<br></br>정보가 맞는지 확인이 필요해요
            </h2>
            <p>
              카카오계정 이름 : <b style={{color:'#FF5C00'}}>{user_name}</b> 
              <br />
              <b>내 이름이 아니라면, 전화번호로 가입할 수 있어요</b>        
              <br></br> 
              </p>
            
            <div className="button-container">
              <button
                className="delete-button"
                onClick={handlePopupButtonClick}

              >
                아니에요
              </button>
              <motion.button
                className="retry-button"
                onClick={handlePopupClose}

              >
                내 정보가, 맞아요
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}



      </div>
    </div>
  );
}

export default OnBoardingP_General;