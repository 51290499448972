import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Male from './assets/boy.webp';
import Female from './assets/girl.webp';

export default function SexBtn({ Text, Style, defaultId, defaultSex, onSelect, isSelected }) {
  const [clicked, setClicked] = useState(false);
  const [sex, setSex] = useState(defaultSex || 'female');

  const toggleClicked = () => {
    setClicked(!clicked);
    onSelect(sex);
  };

  const buttonClass = isSelected ? 'sexbuttonStyle2' : 'sexbuttonStyle1';
  const SexClass = sex === 'male' ? Male : Female;

  return (
    <div className={Style}>
      <motion.button
        className={buttonClass}
        whileTap={{ scale: 0.95 }}
        style={{ display: 'flex' }}
        onClick={toggleClicked}
        disabled={isSelected}

      >
        <span className="buttonTextStyle" style={{ marginTop: '12px' }}>
          {Text}
        </span>
        <div>
          <img style={{ marginTop: '8px', marginLeft: '5px' }} src={SexClass} alt="Sex" />
        </div>
      </motion.button>
    </div>
  );
}
