import JokerBox from '../../NewComponent/JokerBox';
import InformBox from '../../NewComponent/InformBox';
import SliceBar from '../../NewComponent/SliceBar';
import Footer from '../../component/Footer';
import hands2 from "../../assets/img/infinity.webp";
import island from "../../assets/img/island.webp";
import vote from "../../assets/img/vote-ballot 2.webp";
import lucky_box from "../../NewComponent/assets/lucky_box.webp";
import lookdetail from "../../NewComponent/assets/LookDetail.svg";
import lookdetail2 from "../../NewComponent/assets/LookDetail2.svg";
import none from "../../NewComponent/assets/none.svg";
import Header from '../../NewComponent/header';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import React, { useState, useEffect } from 'react';


function JokerPage() {

  const navigate = useNavigate();
  const [tokenUserID, setTokenUserID] = useState('');
  const [tokenUserNo, setTokenUserNo] = useState('');
  const [userData, setUserData] = useState({});
  const [isJokerMode, setIsJokerMode] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('조커카드는 로그인 후 이용할 수 있어요');
        navigate('/start'); // 토큰이 없을 때만 페이지 이동
        return;
      }
  
      try {
        const decodedToken = jwt_decode(token);
        const tokenUserID = decodedToken.user_id;
        const tokenUserNo = decodedToken.user_no;
        setTokenUserID(tokenUserID);
        setTokenUserNo(tokenUserNo);
  
        const response = await fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok for user info');
        }
  
        const data = await response.json();
        setUserData({
          joker_count: data.joker_count, // userData에 joker_count를 추가
        });
  
        const jokerModeResponse = await fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/jokermode/verification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: tokenUserID, user_no: tokenUserNo }),
        });
  
        if (!jokerModeResponse.ok) {
          throw new Error('Network response was not ok for joker mode verification');
        }
  
        const jokerModeData = await jokerModeResponse.json();
        if (jokerModeData.message === "Joker mode is active") {
          console.log('Joker mode is active');
          setIsJokerMode(true);
        } else if (jokerModeData.message === "Joker mode is not active") {
          console.log('Joker mode is not active');
          setIsJokerMode(false);
        } else {
          alert('내부 서버에 문제가 발생했어요. 나중에 시도해주세요');
        }
      } catch (error) {
        console.log('API Error 입니다:', error);
      }
    };
  
    fetchData();
  }, []);
  
  
  


  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
    <div className="main_content" style={{ backgroundColor: '#FFFFFF'}}>      
    <br></br>

      <Header Text="내 조커카드" Link={`/${tokenUserID}?explore=yes`}/>

      <div className='Content-1_Joker'>
        <div style={{display:'flex',justifyContent:'center'}}>
          <JokerBox num_joker=
          {isJokerMode
            ? "무제한 🃏"
            : `${userData.joker_count}장`
          }
          />
        </div>
        
      <div style={{display:'flex',justifyContent:'center',marginTop:'30px'}}> <Link to={"/luckybox"}>
          <InformBox src={lucky_box} iftext={'내 조커카드가 부족하다면'} howtext={'랜덤선물 뽑고 조커카드 늘리기'}  src2={lookdetail2} />
          </Link>
      </div>

          <div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
          <Link to={"/JokerDiscovery"}>
          <InformBox src={hands2} iftext={'내 조커카드 개수 상관없이'} howtext={'무제한으로 조커모드 사용하기'}  src2={lookdetail} />
          </Link>
          </div>

        <div style={{display:'flex',justifyContent:'center',marginTop:'45px',marginRight:'270px'}}>
             <div className='JokerIntro1'>조커카드 효과 소개</div>      
        </div>
        <div style={{marginRight:'95px',marginTop:'5px'}}>
            <div className='JokerIntro2'>이러한 능력들을 쓸 수 있어요</div>      
        </div>

       {/* <div style={{display:'flex',justifyContent:'center', marginTop:'20px', marginLeft:'-10px'}}>
            <InformBox  src={instagram} iftext={'나랑 친해지고 싶은 사람에 대한 강력한 힌트'} howtext={'인스타 아이디 초성알기'} src2={none} />
        </div>*/}

        <div style={{display:'flex',justifyContent:'center', marginTop:'20px', marginLeft:'-15px'}}>
            <InformBox  src={island} iftext={'나랑 친해지고 싶은 사람을 알 수 있는 힌트'} howtext={'속마음 편지를 보낸 위치알기'} src2={none} />
        </div>
        

        <div style={{display:'flex',justifyContent:'center', marginTop:'10px', marginLeft:'-15px'}}>
            <InformBox  src={vote} iftext={'1일 1문답과 프로필 방문자에서도 힌트를'} howtext={'더 많은 곳에서 힌트얻기'}  src2={none} />
        </div>
        


       </div>

      <SliceBar  />

    {/*  <div className='Content-2_Joker'>
         <div style={{display:'flex',justifyContent:'center'}}>
            <SelecBox option={'우리학교 조커카드 사용내역 보기'} src={school} />
         </div>
         <div style={{display:'flex',justifyContent:'center',marginTop:'11px'}}>
            <SelecBox src={cs} option={'구매 내역 복원 & 결제관련 문의'}  />
         </div>
</div> */}
      
      <Footer></Footer>

    </div>
    </div>
  );
}

export default JokerPage;
