import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PrimaryButton from '../../NewComponent/PrimaryButton';
import CastingReason from '../../NewComponent/CastingReason';
import Header from '../../NewComponent/header';
import OnboardingMiddele2 from '../../NewComponent/OnboardingMiddle2';
import { motion } from 'framer-motion';
import Intelligence from '../../NewComponent/Intelligence';

function TodayQuestion_Preview() {
  const [currentContent, setCurrentContent] = useState(1);
  const [previousContent, setPreviousContent] = useState(1);
  const [answer, setAnswer] = useState('');
  const [questionText1, setQuestionText1] = useState('');
  const [questionText2, setQuestionText2] = useState('');
  const [questionImg, setQuestionImg] = useState('');
  const [questionNum, setQuestionNum] = useState(''); // 질문 번호 추가
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 9); // 9시간을 더해서 한국 시간대로 변환
  const currentDateStr = currentDate.toISOString().slice(0, 10);
  const dayOfWeek = new Intl.DateTimeFormat('ko-KR', { weekday: 'long' }).format(new Date(currentDateStr));

  console.log(currentDateStr)
  const handleInputChange4 = (text) => {
    setAnswer(text);
  };


  const handleButtonClick = () => {
    navigate('/preview');
  };

  const fetchQuestionText = async () => {
    try {

      const response = await axios.post('https://backend-hoxy-one-api.hoxy.one:4004/api/1day1/question', { question_date: currentDateStr });
      const questionData = response.data[0];
      const { question_text_1, question_text_2, question_img, question_num } = questionData;
      setQuestionText1(`Q. 채리우님${question_text_1}`);
      setQuestionText2(question_text_2);
      setQuestionImg(question_img);
      setQuestionNum(question_num); // 질문 번호 설정
    } catch (error) {
      console.error('질문 텍스트 가져오기 오류:', error);
    }
  };







  useEffect(() => {

  
    fetchQuestionText();
  }, []);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
  };




  return (
    <div className="main" style={{ backgroundColor: '#Fff' }}>
      <div className="main_content">
      <Intelligence type="Preview" />
        <br />
        <div className="Content-Casting">
          <div id="Content1" style={{ display: currentContent === 1 ? 'block' : 'none' }}>
            <Header className="CastingTitle" />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
              <motion.div
                className="today_date"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <div style={{ marginTop: '6px' }}>
                  {currentDateStr} {dayOfWeek} ☀️
                </div>
              </motion.div>
            </div>
            <div style={{ marginTop: '0px' }}>
              <OnboardingMiddele2 img={questionImg} Text1={questionText1} Text2={questionText2} />
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CastingReason onSaveText={handleInputChange4} />
            </div>
          </div>

          <Link to={'/start'}>
          <PrimaryButton Text={'프로필 만들고, 채리우님처럼 1일 1문답 받기 🔔'}></PrimaryButton>
          </Link>
        </div>
       
  
    

      </div>
    </div>



  );
}

export default TodayQuestion_Preview;
