import React from 'react';
import PropTypes from 'prop-types';
import './LargeButton.css';


export const LargeButton = ({ label, label2, className }) => {
  const buttonClassName = `large-button ${className}`;
  
  return (
  <div>
    <button className={buttonClassName}>
      <div style={{display: 'flex', justifyContent:'center'}}>
        <div style={{display:'flex'}}>
        <div>  </div>            
      
        <div>{label} {""}
        <b2>{label2}</b2></div>
        </div>
        </div>
    </button>
  </div>
  );
};

LargeButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};