import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from '../stories/Select';
import jwt_decode from 'jsonwebtoken/decode';
import Hint from '../pages/Hint'; // Hint 컴포넌트를 임포트합니다.

function AnswerSelec({ requestDbNumber }) {
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [reply, setReply] = useState('');

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleSubmit = (selectedLabel) => {

    const selectedAnswer = selectedLabel.trim(); // 공백을 제거한 후 선택된 답변을 저장

    if (!selectedAnswer) {
      // 입력값이 공백인 경우, 서버로 전송하지 않고 경고(alert)을 띄웁니다.
      alert('답변을 선택되거나, 입력되지 않았어요');
      return;
    }

    const token = localStorage.getItem('token');
  
    let tokenUserID = '';
    if (token) {
      const decodedToken = jwt_decode(token);
      tokenUserID = decodedToken.user_id;
    }

    axios
      .post('https://backend-hoxy-one-api.hoxy.one:4004/api/reply', {
        request_db_number: requestDbNumber,
        recive_reply: selectedAnswer, // 선택된 답변을 recive_reply로 설정
        recive_user_id: tokenUserID,
      })
      .then(response => {
        // 서버 응답 처리
        console.log('Answer submitted successfully:', response.data);
        alert('성공적으로 메시지에 대한 답장을 보냈어요!');
            window.location.reload(); // 페이지 새로고침


      })
      .catch(error => {
        console.error('Error submitting answer:', error);
        alert('이미 완료된 답장이에요, 메시지에 대한 답장은 한번만 할 수 있어요.');
      });

    const div = document.querySelector('div[style*="overflowX: hidden"]');
    if (div) {
      div.style.display = 'none';
    }
  };

  useEffect(() => {
    // reply 검색 요청을 POST 방식으로 보내고 응답을 받아옵니다.
    axios
      .post('https://backend-hoxy-one-api.hoxy.one:4004/api/reply/search', { requestDbNumber })
      .then(response => {
        const reciveReply = response.data.recive_reply;
        setReply(reciveReply);
      })
      .catch(error => {
        console.error('Error fetching reply:', error);
      });
  }, [requestDbNumber]);
  

  const labels = ['좋아요 😀', '저도요 👋', '친해져요 👨‍👩‍👦', '디엠해요 💭', '미안해요 🙏', '괜찮아요 😶'];

  return (
    <div style={{overflowY:'hidden', overflowX: 'hidden' }}>
      <div style={{ display:'flex',justifyContent:'center', flexDirection:'column'}}>
        <h1 className="normalQ" style={{margin : '0', marginTop:'14px', marginBottom:'7px', fontSize:'18px'}}>답변선택</h1>
        {reply ?  <h5
        
        style={{
          color: '#000',
          fontFamily: 'Pretendard',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-1.2px',
          marginBottom:'8px',
          marginTop:'0px',
          paddingLeft : '20px',
          paddingRight :'20px',
          paddingTop :'5px',
          paddingBottom : '5px'

        }}

        >✅ 이 메시지는 이미 "{reply}" 로 답장했어요 </h5> : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
        {/* Select 컴포넌트에 labels와 handleSubmit 함수 전달 */}
        <Select labels={labels} className="Selec" onSubmit={handleSubmit} requestDbNumber={requestDbNumber}/>
        <div style={{ color: '#000000', display: 'flex', justifyContent: 'center' }}>


        </div>
      </div>
    </div>
  );
}

export default AnswerSelec;
