import React, {useState, useEffect} from "react";
import x_icon from "../../NewComponent/assets/icon-x-mono.svg"
import student_girl from "../assets/student-girl.webp"
import OneBox2 from "../../stories/OneBox2"
import insta_logo from '../../assets/img/instagram_logo.webp'
import hoxy_logo from '../../NewComponent/assets/HoxyLogo.svg'
import { useNavigate,Link } from "react-router-dom";
import jwt_decode from 'jsonwebtoken/decode';
import studentgirl from '../../assets/img/studentgirl.webp'
import studentboy from '../../assets/img/studentboy.webp'

export default function Content2( { onClose, user_id,  user_class, user_grade, user_name ,user_gender } ){
  const [user, setUser] = useState({ user_name: '', user_id: '', school_name: '' });

  const [tokenUserID, setTokenUserID] = useState('');
  const navigate = useNavigate();
  const [userFullName, setUserFullName] = useState('');
  const [userID, setUserID] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [userAge, setUserAge] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userKakaoYear, setUserKakaoYear] = useState('');
  const [firstPlace, setFirstPlace] = useState({});
  const [secondPlace, setSecondPlace] = useState({});
  const [thirdPlace, setThirdPlace] = useState({});
  const [friends, setFriends] = useState([]);
  const [messages, setMessages] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {

    try{ 
    const token = localStorage.getItem('token');
  
    if (token) {
      const decodedToken = jwt_decode(token);
      const tokenUserID = decodedToken.user_id;
      setTokenUserID(tokenUserID);
  
      fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/users/userinfo`, {
           method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'hoxy-tkz2-rzs9-e1ycd-ahfz',
      },
      body: JSON.stringify({ user_id: tokenUserID, user_token: token }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error('사용자를 찾을 수 없음');
          }
          return response.json();
        })
        .then((userData) => {
          const { user_name, user_id, school_name, school_code, user_age, user_gender, user_kakao_year } = userData;
  
          setUserFullName(user_name);
          setUserID(user_id);
          setSchoolName(school_name);
          setSchoolCode(school_code);
          setUserAge(user_age);
          setUserGender(user_gender);
          setUserKakaoYear(user_kakao_year);
  
          // 학교 이름이 설정되면 바로 데이터를 가져오도록 처리
          fetch('https://backend-hoxy-one-api.hoxy.one:4004/api/top_message_receivers', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ school_code: school_code }), // 실제 학교코드 전달
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })
          
          .then(data => {
            if (data.length >= 3) {
              setFirstPlace(data[0]);
              setSecondPlace(data[1]);
              setThirdPlace(data[2]);

            
            }
          })
          .catch(error => console.error('Error fetching data:', error));



          fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/school/${school_code}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })         
           .then(data => {
            setFriends(data); // Set friends data received from the backend
          })

            fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/school/${school_code}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })         
           .then(data => {
            setFriends(data); // Set friends data received from the backend
          })
          
          fetch(`https://backend-hoxy-one-api.hoxy.one:4004/api/search/school/${school_code}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                return []; // 404 오류인 경우 빈 배열로 처리
              }
              throw new Error('Error fetching messages');
            }
            return response.json();
          })         
           .then(data => {
            setFriends(data); // Set friends data received from the backend
          })



        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert('로그인이 필요해요');
      navigate('/');
    }

    
        // Simulate an API call
        // Replace this with your actual data fetching logic
        await new Promise(resolve => setTimeout(resolve, 2000));
  
        setIsLoading(false); // Step 2: Set isLoading to false after data is loaded
      } catch (error) {
        console.log('API Error:', error);
      }
    };
  
    fetchData();
  }, []);
  

  function isMobileDevice() {
      
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes('android') ||
      userAgent.includes('iphone') ||
      userAgent.includes('ipad') ||
      userAgent.includes('ipod') ||
      userAgent.includes('windows phone')
    );
  }

  return(
    
    
    <div>

      <div style={{marginRight:'260px',marginTop:'3px'}}><img onClick={onClose} className='x_icon' src={x_icon}></img></div>
      
      <div>
        <div
        
        style={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Pretendard",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "106%",
          letterSpacing: "-1.28px"
        }}

        >프로필</div>
      
        <div style={{
             color: "#868686",
             textAlign: "center",
             fontFamily: "Pretendard",
             fontSize: "10px",
             fontStyle: "normal",
             fontWeight: 500,
             lineHeight: "106%",
             letterSpacing: "-0.8px"
        }}>{user_name}님과 친해지고 싶어요</div>
                
      </div>

      <div style={{display:"flex", justifyContent:'center'}}>
        <OneBox2 
            img_source={user_gender === 'female' ? studentgirl : studentboy} 
        />
         <div style={{
         color: "#000",
         textAlign: "center",
         fontFamily: "Pretendard",
         fontSize: "20px",
         fontStyle: "normal",
         fontWeight: 700,
         lineHeight: "106%",
         letterSpacing: "-1.92px",

         position:'absolute', top:'365px', left:'132px'
      }}>{user_name}</div>

      <div style={{
          color: "#6E747D",
          textAlign: "center",
          fontFamily: "Pretendard",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "106%",
          letterSpacing: "-0.96px",
          
          position:'absolute', top:'390px', left:'143px'
      }}>{user_grade}학년</div>

      </div>

      <div style={{display:'flex', justifyContent:'center'}}>
        <div className='bottom_linkbox'>

        <Link to={`/${user_id}?explore=yes`}>
            <div style={{marginTop:'15px', marginRight:'31px'}}>
              <div><img style={{width:'30px',height:'auto'}} src={hoxy_logo}></img></div>
            </div>
          </Link>

          <div style={{width:"1.6px", height:'41px', marginTop:'10px', backgroundColor:'#FFDFDF', marginRight:'31px'}}></div>

          <div style={{marginTop:'15px'}}>
          
                  {isMobileDevice() ? (
                    <a href={`instagram://user?username=${user_id}`}>
                         <div><img style={{width:'40px',height:'auto', marginTop:'-5px'}}  src={insta_logo}></img></div>

                    </a>
                  ) : (
                    <a href={`https://www.instagram.com/${user_id}`} target="_blank">
                         <div><img style={{width:'40px',height:'auto', marginTop:'-5px'}}  src={insta_logo}></img></div>
                     </a>
                  )
                  }
          </div>
        </div>  
      </div>
      
    </div>
  )
}