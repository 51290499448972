import React from 'react';
import Lottie from 'lottie-react';
import { loading } from '../assets/lottie';

function Wait() {
  return (
    <div className="main" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
      <div className="main_content">
        <div>
          <Lottie animationData={loading} style={{ width: '60px', height: '60px' }} />
        </div>
      </div>
    </div>
  );
}

export default Wait;
