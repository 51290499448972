import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import jwt_decode from 'jsonwebtoken/decode';
import * as amplitude from '@amplitude/analytics-browser';
import { useState, useEffect } from 'react';

import './App.css';
import MainP from './pages/MainP';
import Requested from './pages/Requested';
import Menu from './pages/Menu';
import Request from './pages/Request';
import Base from './component/BottomSheet/Base';
import CS from './pages/CS';
import Almost from './pages/Almost';
import End from './pages/End';
import Linkmaker from './pages/Linkmaker';
import Accepted from './pages/Accepted';
import Login from './pages/Login';
import Register from './pages/Register';
import ErrorP from './pages/ErrorP';
import RouteChangeTracker from './RouteChangeTracker';
import HoxyPass from './pages/HoxyPass';
import HoxyPassMain from './pages/HoxyPassMain';
import Welcome from './pages/Welcome';
import KakaoCallback  from './component/KakaoCallback';
import Hint from './pages/Hint';
import Success from './pages/Payment/Success';
import AdSuccess from './pages/Ads/Success';
import Fail from './pages/Ads/Fail';



import Payment from './pages/Payment/Payment';
import HoxynaPayment from './pages/Payment/hoxyna/Payment';
import HoxynaSuccess from './pages/Payment/hoxyna/Success';

import Wait from './pages/Wait';

import Coupang from './pages/Coupang';
import ScrollToTop from './component/ScrollToTop';
import OurSchool from './pages/OurSchool';
import HoxyWorld from './pages/HoxyWorld';
import Giftpayment from './pages/GiftPayment';
import Gift from './pages/Gift';
import Gift2 from './pages/Gift2';
import Vote from './pages/Vote';
import VoteResult from './pages/VoteResult';
import FristShare from './pages/FristShare';
import FristShareLanding from './pages/FristShareLanding';
import DaejeonLanding from './pages/Daejeon/Landing';
import ImgShare from './pages/ImgShare';
import VoteRequest from "./pages/VoteRequest";


import CompoDemo from "./pages/newpage/CompoDemo";
import JokerPage from "./pages/newpage/JokerPage";
import NewSearchP from "./pages/newpage/SearchP";
import NoResultP from "./pages/newpage/NoResultP";
import CastingP from './pages/newpage/CastingP';
import OnBoardingP from './pages/newpage/OnBoarding_Kakao';
import OnBoardingP_General from './pages/newpage/OnBoarding_General';
import LetStart from './NewComponent/LetStart';
import OpenJoker from './pages/Ads/OpenJoker';
import JokerDiscovery from './pages/JokerDiscovery';

import SoftIntro from './pages/newpage/MessagePage/SoftIntro';
import MessageMain from './pages/newpage/MessagePage/MessageMain';
import MessageFinished from './pages/newpage/MessagePage/MessageFinished';
import TodayQuestion from './pages/newpage/TodayQuestion';
import RequestCard from './pages/newpage/RequestCard';
import Test from './NewComponent/Test';
import AnswerP from './pages/newpage/AnswerP';
import Answer_1day1 from './pages/newpage/Answer_1day1';
import Future from './pages/newpage/Future';
import Preview from './pages/Preview';
import TodayQuestion_Preview from './pages/newpage/TodayQuestion-preview';
import OnBoarding_PhoneNum from './pages/newpage/OnBoarding_PhoneNum';
import OnBoarding_University from './pages/newpage/OnBoarding_University';
import OnBoarding_UnderAge from './pages/newpage/OnBoarding_UnderAge';
import ToWhere from './pages/newpage/MessagePage/ToWhere';
import Nav, { Tab } from './pages/newpage/AlarmNav/Tab';
import { Tabs2 } from './pages/newpage/AlarmNav/Tab2';
import PaymentCallback from './component/PaymentCallback';
import PaymentCallbackHOXYNAPg from './component/PaymentCallbackHOXYNAPg';
import OfferLetter from './pages/Ceo/OfferLetter';
import DashBoard from './pages/Ceo/DashBoard';

import SchoolP from './pages/SchoolP';

function App() {
  const [tokenUserID, setTokenUserID] = useState("");


  useEffect(() => {
    const checkTokenAndInitAmplitude = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwt_decode(token);
                const tokenUserID = decodedToken.user_id;
                const isApp = localStorage.getItem('app');

                setTokenUserID((prevTokenUserID) => {
                    return prevTokenUserID === tokenUserID ? prevTokenUserID : tokenUserID;
                });
                amplitude.init("3e3ca2c5a4db2caee2881eeb2336bdca", tokenUserID);

                if (isApp === 'true') {
                    window.amplitude('setUserProperties', { 'isApp': true });
                  }

            } catch (error) {
                console.log('토큰 디코딩 에러');
            }
        } else {
            amplitude.init("3e3ca2c5a4db2caee2881eeb2336bdca");
        }
    };

    checkTokenAndInitAmplitude();
    
    const storageListener = e => {
        if (e.key === 'token') {
            checkTokenAndInitAmplitude();
        }
    };

    window.addEventListener('storage', storageListener);
    return () => window.removeEventListener('storage', storageListener);
}, []);


  return (
    <Router>
    <ScrollToTop />
    <RouteChangeTracker />
    <div className='App'>
        <Routes basename={process.env.PUBLIC_URL}>
        <Route path="/" element={<MainP />} />
            <Route path="/:user_id/*" element={<MainP />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/requested" element={<Requested />} />
            <Route path="/oauth/kakao/callback" element={<KakaoCallback />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/base" element={<Base />} />
            <Route path="/almost" element={<Almost />} />
            <Route path="/end" element={<End />} />
            <Route path="/search" element={<NewSearchP />} />``
            <Route path="/share" element={<Linkmaker />} />
            <Route path="/accepted" element={<Accepted />} />
            <Route path="/preview" element={<Preview />} />
            <Route path="/todayquestion-preview" element={<TodayQuestion_Preview />} />

            <Route path="/CS" element={<CS />} />
        
            <Route path="/hoxypass" element={<HoxyPass />} />
            <Route path="/hoxypassmain" element={<HoxyPassMain />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/hint" element={<Hint />} />
            <Route path="/error" element={<ErrorP />} />


            <Route path="/payment" element={<JokerDiscovery />} />
            <Route path="/payment/callback" element={<Wait />} />
            <Route path="/payment/success" element={<Success />} />
            <Route path="/payment/restore" element={<Wait />} />

            <Route path="/payment/hoxyna" element={<JokerDiscovery />} />
            <Route path="/payment/hoxyna/callback" element={<Wait />} />
            <Route path="/payment/hoxyna/success" element={<HoxynaSuccess />} />


            <Route path="/payment/gift/callback" element={<PaymentCallback />} />
            <Route path="/payment/hoxyna/pg/callback" element={<PaymentCallbackHOXYNAPg />} />
            <Route path="/payment/hoxyna/pg" element={<HoxynaPayment />} />


            <Route path="/JokerDiscovery" element={<JokerDiscovery />} />
            <Route path="/ads/box/callback" element={<Wait />} />
            <Route path="/ads/box/success" element={<AdSuccess />} />
            <Route path="/ads/box/fail" element={<Fail />} />
            
            
            <Route path="/school/:school_website/*" element={<SchoolP />} />



            
            <Route path="/daejeon/landing" element={<DaejeonLanding />} />
            <Route path="/ceo/OfferLetter" element={<OfferLetter />} />
            <Route path="/ceo/DashBoard" element={<DashBoard />} />


            <Route path="/coupang" element={<Coupang/>} />
            <Route path="/ourschool" element={<OurSchool />} />
            <Route path="/hoxyworld" element={<HoxyWorld />} />
            <Route path="/gift" element={<Gift />} />
            <Route path="/Giftpayment" element={<Giftpayment />} />

            <Route path="/vote" element={<Vote />} />
            <Route path="/voteresult" element={<VoteResult />} />
            <Route path="/fristshare" element={<FristShare />} />
            <Route path="/fristsharelanding" element={<FristShareLanding />} />
            <Route path="/imgShare" element={<ImgShare />} />
            <Route path="/voterequest" element={<VoteRequest />} />

            <Route path="/Gift2" element={<Gift2 />} />

            <Route path="/Demo" element={<CompoDemo />} />
            <Route path="/jokercard" element={<JokerPage />} />
            <Route path="/Search" element={<NewSearchP />} />
            <Route path="/casting-intro" element={<NoResultP />} />
            <Route path="/Casting" element={<CastingP />} />

            <Route path="/start" element={<OnBoardingP />} />
            <Route path="/onboarding" element={<OnBoardingP_General />} />
            <Route path="/LetStart" element={<LetStart />} />
            <Route path="/luckybox" element={<OpenJoker />} />

            <Route path="/request" element={<SoftIntro />} />
            <Route path="/MessageMain" element={<MessageMain />} />
            <Route path="/MessageFinished" element={<MessageFinished />} />

            <Route path="/TodayQuestion" element={<TodayQuestion />} />
            <Route path="/RequestCard" element={<RequestCard />} />
            <Route path="/Test" element={<Test />} />

            <Route path="/Answer" element={<AnswerP/>} />
            <Route path="/Answer-1day1" element={<Answer_1day1/>} />
            <Route path="/Future" element={<Future/>} />

            <Route path="/OnBoarding_PhoneNum" element={<OnBoarding_PhoneNum />} />
            <Route path="/OnBoarding_University" element={<OnBoarding_University />} />
            <Route path="/OnBoarding_UnderAge" element={<OnBoarding_UnderAge />} />
            <Route path="/ToWhere" element={<ToWhere />} />

            <Route path="/Tab" element={<Tab />} />
            <Route path="/Tab2" element={<Tabs2 />} />
            

          </Routes>
          </div>
    </Router>
  );
}

export default App;
