import { BrowserRouter as  Link, useParams } from 'react-router-dom';
import Profile from '../pages/Profile';
import HoxyPassMain from './HoxyPassMain';

function MainP() {
  const { user_id } = useParams();
  localStorage.setItem('VisitprofileID', user_id);
  

  return (
    <div>
      {user_id ? (
        <Profile user_name={user_id} />
      ) : (
        <HoxyPassMain />
      )}
    </div>
  );
}

export default MainP;
