import React, { useEffect } from "react";
import { motion } from 'framer-motion';

const KaKaoLoginbtn = () => {
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI; 

  const { Kakao } = window;

  const initKakao = () => {
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(`560a2ed8e1690b84444ce321b2407083`);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const kakaoLoginHandler = () => {
    Kakao.Auth.authorize({
      redirectUri: REDIRECT_URI,
    });
  };

  return (
    <div style={{position:'fixed', bottom:'85px', left:'50%', transform : 'translateX(-50%)'}}>
  

    <motion.button 
    onClick={kakaoLoginHandler}

    whileTap={{ scale: 0.95 }}
              
    style={{ background: 'transparent', border: 'none', outline: 'none' }}>

    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{
    width: '345px',
    height: '50px',
    borderRadius: '12px',
    backgroundColor: '#FEE500',
    color: '#191919',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '25px',
    letterSpacing: '-0.08rem',
    display: 'flex', /* 추가된 스타일 */
    alignItems: 'center', /* 추가된 스타일 */
  }}>
    <div style={{ marginLeft : '25px', marginTop:'5px'}} /* 추가된 스타일 */> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.98047 1.875C11.6932 1.875 13.2772 2.20833 14.7327 2.87498C16.1881 3.54164 17.3387 4.44816 18.1845 5.59453C19.0302 6.74086 19.4531 7.99129 19.4531 9.34578C19.4531 10.7002 19.0302 11.9524 18.1845 13.1023C17.3387 14.2522 16.1898 15.1605 14.7379 15.8271C13.286 16.4938 11.7002 16.8271 9.98047 16.8271C9.43777 16.8271 8.87742 16.7883 8.29949 16.7107C5.79035 18.4532 4.45477 19.335 4.29266 19.3562C4.21512 19.3844 4.14109 19.3809 4.07063 19.3456C4.04242 19.3245 4.02129 19.2962 4.00719 19.2609C3.99309 19.2257 3.98605 19.1939 3.98605 19.1657V19.1234C4.02836 18.8482 4.34902 17.7019 4.94812 15.6843C3.58784 15.0071 2.50771 14.1094 1.70775 12.9912C0.907793 11.8731 0.507812 10.6579 0.507812 9.34578C0.507812 7.99129 0.930699 6.74086 1.77647 5.59453C2.62225 4.44816 3.77285 3.54164 5.22828 2.87498C6.68371 2.20833 8.26777 1.875 9.98047 1.875Z" fill="black" fillOpacity="0.9"/>
    </svg>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <p style={{ textAlign: 'center', marginLeft:'-50px'}}>
        3초만에 <b style={{ fontWeight: '800' }}>카카오로 시작하기</b>
      </p>
      </div>

    </div>
  </div>
  </motion.button>    
  </div>
    )
}
export default KaKaoLoginbtn
