import React, { useEffect } from "react";
import axios from "axios";
import jwt_decode from 'jsonwebtoken/decode';
import { useLocation, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const PaymentCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const tx_id = queryParams.get('tx_id');
  const paymentId = queryParams.get('paymentId');
  const code  = queryParams.get('code');
  const message  = queryParams.get('message');


  useEffect(() => {
    const fetchData = async () => {
      if (code) {
        alert('결제 과정에서 오류가 발생했어요', message);
      } else {
        try {
          const validation = await axios.post("https://backend-hoxy-one-api.hoxy.one:4004/api/payments/gift/complete", {
            paymentId: paymentId,
          });
  
          if (validation.status === 200 && validation.data === "Payment successful") {
            alert("결제 완료!");
          } else {
            alert("결제 인증에 실패했어요!");
          }
        } catch (error) {
          console.error("결제 인증 요청 중 오류 발생:", error);
          alert("결제 인증에 실패했어요!");
        }
      }
    };
  
    fetchData();
  }, []);
  

  return (
   <></>
  );
};

export default PaymentCallback;