import React, { useState }  from "react";
import { motion } from 'framer-motion';


export default function MainBtn({Label}) {
  
    const [id, setId] = useState('unselected');

    const handleButtonClick = () => {
      setId(id === 'selected' ? 'unselected' : 'selected');
    };
  
    // id 값에 따라 클래스 변수 설정
  const buttonClass = id === "selected" ? "MainButton_selected" : "MainButton_unselected";


  return (
    <div className={buttonClass} id = {id} onClick={handleButtonClick}>
        <div className="flexbox">
          
            <div>{Label}</div>
  
        </div>
    </div>
  );
}
