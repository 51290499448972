import React from 'react';
import { motion } from 'framer-motion';
import default_img from '../assets/img/success.png';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 50, // 아래에서 위로 올라오도록 설정
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1, // 애니메이션 지속 시간
      ease: 'easeOut', // 이징 설정
    },
  },
};

const middle_component = {
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px'
};


export default function OnboardingMiddele1({ img, Text1, Text2, middle_compo }) {
  const imageUrl = img || default_img ; // 이미지가 없으면 기본 이미지 사용
  const middle_component_name = middle_compo || middle_component ; // 컴포 지정 없으면 기본 컴포 사용 - 변수 지정 안되어여 확인 필요

  return (
    <div className={middle_component_name}>
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        <motion.img
          src={imageUrl}
          alt="박수 치는 이미지"
          style={{
            width: '130px',
            height: '130px'
          }}
        />

        <motion.p
          className='middle_component_text'
          variants={containerVariants}
          initial='hidden'
          animate='visible'
        >
          {Text1} <br></br>
          <div className='onboarding_middle_text_2'>{Text2}</div>
        </motion.p>
      </motion.div>
    </div>
  );
}
