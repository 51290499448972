import React, {useState} from "react";
import { ReactComponent as LeftArrow } from "./assets/icon-arrow-left-small-mono.svg";


export default function SearchBar({ label,value,onChange}) {
  const [isAnsVisible, setIsAnsVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAnsVisible(!isAnsVisible);
  };

  return (
    <div className="search_header">
        <a href="javascript:window.history.back();">
          <div style={{marginLeft:'15px'}}>
         <LeftArrow />
         </div>
        </a> 
        <input
          type="text"
          className="search-input"
          value={value}
          onChange={onChange}
          placeholder={label}
          style={{ background:'transparent', borderRadius:'6px', width:'350px', marginTop:'-14px',marginLeft:'0px', height:'17px', fontSize:'17px', textIndent:'17px', border:'none', outline:'none',  fontFamily:'Pretendard',
          fontWeight: '500', letterSpacing: '-0.09rem;' }}
        />
    </div>
  );
}
