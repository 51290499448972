import React, { useState, useEffect } from "react";

export default function CastingSelec({ type, what, value: propValue, onChange, num }) {
  const [selectedValue, setSelectedValue] = useState(propValue || "");

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="CastingInputBox" id={num}>
      <div style={{ flexDirection: 'column' }} className="flexbox">
        <div className="input-group">
          <div className="input-group-group" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <select
              id="gender"
              required
              value={selectedValue}
              onChange={handleSelectChange}
              style={{
                fontFamily: 'Pretendard',
                textDecoration: 'none',
                color: '#000',
                fontWeight: '600',
                textAlign: 'left',
                padding: '0px',
                border: 'none',
                outline: 'none',
                marginTop: '20px',
                backgroundColor: '#fff'
              }}
            >
              <option value="" disabled hidden></option>
              {type === "gender" && (
                <>
                  <option value="남성">남성</option>
                  <option value="여성">여성</option>
                </>
              )}
              {type === "grade" && (
                <>
                  <option value="1">1학년</option>
                  <option value="2">2학년</option>
                  <option value="3">3학년</option>
                  <option value="4">4학년</option>
                  <option value="5">5학년</option>
                  <option value="6">6학년</option>
                </>
              )}
            </select>
              <label style={{ border: 'none' }} htmlFor='gender'>{what}</label>

          </div>
          <div className="bar"></div>
        </div>
      </div>
    </div>
  );
}
