import React from "react";
import styled from 'styled-components';
import BottomSheet from "./BottomSheet";
import Login from "../../pages/Register";
import { color } from "framer-motion";




const LoginOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default function Base() {
  return (
  <div class="main" style={{backgroundColor: '#FFFFFF'}}>
    <div class="main_content" style={{backgroundColor: '#FFFFFF'}}>
      <Login />
      <LoginOverlay />
      <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{background:'#000000',zIndex:'999'}}></div>
          <div>
           <BottomSheet />
          </div>
        </div>`
    </div>
  </div>

  );
}
