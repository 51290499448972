import React from 'react';
import PropTypes from 'prop-types';
import './Tittle.css';

export const Tittle = ({ label1, label2, className }) => {
  const TitleClassName = `${className}`;

  return (
    <div className={TitleClassName}>
      <div className={TitleClassName+'sideb'}></div>
      <div className={TitleClassName+'tittle1'}>{label1}</div><br></br>
      <div className={TitleClassName+'tittle2'}>{label2}</div>
    </div>
  );
};

Tittle.propTypes = {
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  className: PropTypes.string,
};


